// @ts-nocheck
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    treeItemCampaignLabel: {
        fontSize: '1.3vw!important',
    },
});

export { useStyles }
