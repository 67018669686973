// @ts-nocheck

import React, { useEffect, useRef } from 'react';
import { useStyles } from './Styles';
import '../../../../../index.scss';
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { getAll } from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import AlertM from '../../../../Helpers/AlertM/AlertM';
import { log } from "../../../../../Services/LoggerService";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import EdcscorecardOverview from '../EdcscorecardOverview/EdcscorecardOverview';
import EdcscorecardTable from '../EdcscorecardTable/EdcscorecardTable';
import { Skeleton } from '@mui/material';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function EdcscorecardMain() {
    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // module(s) for api
    const moduleMain = 'edc';
    const moduleEdcscorecard = 'edcscorecard';

    // data vars
    const [allData, setAllData] = React.useState([]);
    const [allScorecardData, setAllScorecardData] = React.useState([]);
    // ui controls
    const [loading, setLoading] = React.useState(true);
    const [tabValue, setTabValue] = React.useState(0);


    // ui handlers
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const fetch = () => {
        fetchEdc();
        fetchScorecard();
    }
    // fetch dependent data
    const fetchEdc = () => {
        setLoading(true); // show loader
        getAll(moduleMain, 0, 0)
            .then((_res: any) => {
                // set all data
                setAllData(_res);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch dependent data
    const fetchScorecard = () => {
        setLoading(true); // show loader
        getAll(moduleEdcscorecard, 0, 0)
            .then((_res: any) => {
                // set all data
                setAllScorecardData(_res);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    useEffect(() => {
        fetch();
    }, [])



    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* Header */}
            <Header />

            {/* Sider */}
            <Sider />

            {/* Content */}
            <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
                <div className="px-1 px-md-5">

                    {/* Upper Section */}
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="mt-3">
                                {t('module.edc')} Scorecard
                            </h2>
                        </div>
                    </div>

                    {/* Tabs */}
                    {!loading &&
                        <div className="mt-3">
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                                        {
                                            allData.map((_edc, _idx) => <Tab label={_edc.name} {...a11yProps(_idx)} />)
                                        }
                                        <Tab label="Score Overview" {...a11yProps(allData.legth + 1)} />
                                    </Tabs>
                                </Box>
                                {
                                    allData.map((_edc, _idx) => <CustomTabPanel value={tabValue} index={_idx}>
                                        {/* EDC SCORE CARD TABLE */}
                                        <EdcscorecardTable edcData={_edc} refreshDataEvent={fetch} allScorecardData={allScorecardData} />
                                    </CustomTabPanel>)
                                }
                                <CustomTabPanel value={tabValue} index={allData.length}>
                                    <EdcscorecardOverview edc={allData} />
                                </CustomTabPanel>
                            </Box>
                        </div>
                    }
                    {loading &&
                        <div className="mt-3">
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Skeleton variant="rounded" height={20} />
                            </Box>

                        </div>
                    }

                </div>



                {/* Alerts */}
                <AlertM ref={notifications} />

            </Box>


        </Box>
    );
}

export default EdcscorecardMain;
