import React, {useEffect} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";

// Import components
import Landing from "../Components/Screens/Landing/Landing";
import Login from "../Components/Screens/Authentication/Login/Login";
import Register from "../Components/Screens/Authentication/Register/Register";
import Home from "../Components/Screens/Dashboard/Home/Home"
import Logout from "../Components/Screens/Authentication/Logout/Logout";
import {RootStateOrAny, useSelector} from "react-redux";
import {useTranslation, Trans} from 'react-i18next';
import {AuthGuard} from "./Guards/AuthGuard/AuthGuard";
import {ThemeProvider, createTheme} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';


import RoleMain from "../Components/Screens/Dashboard/Role/RoleMain/RoleMain";
import BookfieldMain from "../Components/Screens/Dashboard/Bookfield/BookfieldMain/BookfieldMain";
import AccountstatusMain from "../Components/Screens/Dashboard/Accountstatus/AccountstatusMain/AccountstatusMain";
import LocationMain from "../Components/Screens/Dashboard/Location/LocationMain/LocationMain";
import DispositionstatusMain from "../Components/Screens/Dashboard/Dispositionstatus/DispositionstatusMain/DispositionstatusMain";
import AgentaccountstatusMain from "../Components/Screens/Dashboard/Agentaccountstatus/AgentaccountstatusMain/AgentaccountstatusMain";
import ReportingteamMain from "../Components/Screens/Dashboard/Reportingteam/ReportingteamMain/ReportingteamMain";
import SupplierMain from "../Components/Screens/Dashboard/Supplier/SupplierMain/SupplierMain";
import TypeofdebtMain from "../Components/Screens/Dashboard/Typeofdebt/TypeofdebtMain/TypeofdebtMain";
import TeamMain from "../Components/Screens/Dashboard/Team/TeamMain/TeamMain";
import CampaignMain from "../Components/Screens/Dashboard/Campaign/CampaignMain/CampaignMain";
import BookdetailMain from "../Components/Screens/Dashboard/Bookdetail/BookdetailMain/BookdetailMain";
import AccountMain from "../Components/Screens/Dashboard/Account/AccountMain/AccountMain";
import DebtordetailPreTerminationFiltering from "../Components/Screens/Dashboard/Debtordetail/PreterminationAccountHandler/DebtordetailPreTerminationFiltering/DebtordetailPreTerminationFiltering";
import ClaimdetailMain from "../Components/Screens/Dashboard/Claimdetail/ClaimdetailMain/ClaimdetailMain";
import AccountnotepadMain from "../Components/Screens/Dashboard/Accountnotepad/AccountnotepadMain/AccountnotepadMain";
import BookdebtorsMain from "../Components/Screens/Dashboard/Bookdebtors/BookdebtorsMain/BookdebtorsMain";
import DebtoragentMain from "../Components/Screens/Dashboard/Debtoragent/DebtoragentMain/DebtoragentMain";
import SmstemplateMain from "../Components/Screens/Dashboard/Smstemplate/SmstemplateMain/SmstemplateMain";
import PtpMain from "../Components/Screens/Dashboard/Ptp/PtpMain/PtpMain";
import PtpmethodMain from "../Components/Screens/Dashboard/Ptpmethod/PtpmethodMain/PtpmethodMain";
import BankdetailMain from "../Components/Screens/Dashboard/Bankdetail/BankdetailMain/BankdetailMain";
import SecondarycontactdetailMain from "../Components/Screens/Dashboard/Secondarycontactdetail/SecondarycontactdetailMain/SecondarycontactdetailMain";
import ContacttypeMain from "../Components/Screens/Dashboard/Contacttype/ContacttypeMain/ContacttypeMain";
import PartybankinfoMain from "../Components/Screens/Dashboard/Partybankinfo/PartybankinfoMain/PartybankinfoMain";
import BookImporterMain from '../Components/Screens/Dashboard/BookImporter/BookImporterMain/BookImporterMain';
import UserMain from "../Components/Screens/Dashboard/User/UserMain/UserMain";
import DebtorDetailCollectionsBookImporter from '../Components/Screens/Dashboard/Debtordetail/PreterminationAccountHandler/DebtorDetailCollectionsBookImporter/DebtorDetailCollectionsBookImporter';
import StrategyMain from '../Components/Screens/Dashboard/Strategy/StrategyMain/StrategyMain';
import CollectionAccountHandlerMain from '../Components/Screens/Dashboard/Debtordetail/CollectionAccountHandler/CollectionAccountHandlerMain/CollectionAccountHandlerMain';
import AssignedleadMain from '../Components/Screens/Dashboard/Assignedlead/AssignedleadMain/AssignedleadMain';
import EdcMain from '../Components/Screens/Dashboard/Edc/EdcMain/EdcMain';
import LeadsMain from '../Components/Screens/Dashboard/Leads/LeadsMain/LeadsMain';
import IntegrationsMain from '../Components/Screens/Dashboard/Integrations/IntegrationsMain/IntegrationsMain';
import ReportsMain from '../Components/Screens/Dashboard/Reports/ReportsMain/ReportsMain';
import VintageReport from '../Components/Screens/Dashboard/Reports/VintageReport/VintageReport';
import RollRateReport from '../Components/Screens/Dashboard/Reports/RollRateReport/RollRateReport';
import CommissionReportMain from '../Components/Screens/Dashboard/Reports/CommissionReport/CommissionReportMain';
import ActionReportMain from '../Components/Screens/Dashboard/Reports/ActionReport/ActionReportMain';
import CollectionextractMain from '../Components/Screens/Dashboard/Collectionextract/CollectionextractMain/CollectionextractMain';
import GenesysoutboundlistMain from '../Components/Screens/Dashboard/Genesysoutboundlist/GenesysoutboundlistMain/GenesysoutboundlistMain';
import EdcscorecardMain from '../Components/Screens/Dashboard/Edcscorecard/EdcscorecardMain/EdcscorecardMain';
import WrapupcodeMain from '../Components/Screens/Dashboard/Wrapupcode/WrapupcodeMain/WrapupcodeMain';
import EdcconfigurationMain from '../Components/Screens/Dashboard/Edcconfiguration/EdcconfigurationMain/EdcconfigurationMain';


function Router() {
    const {t, i18n} = useTranslation();

    useEffect(() => {
        const env = process.env.REACT_APP_ENV == 'production' ? '' : process.env.REACT_APP_ENV == 'development' ? 'Dev - ' : 'Stage - '
        document.title = `${env}MTN Collections Manager`;
    }, []);

    // get current language
    // const language = useSelector(
    //     (state: RootStateOrAny) => state.global.global.language,
    // );

    // switch language here
    // i18n.changeLanguage('en')

    // get current theme
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );


    const darkTheme = createTheme({
        palette: {
            mode: theme,
            primary: {
                main: "#F7C601FF",
                contrastText: "#fff" //button text white instead of black to support dark theme
            }
        },
    });

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline/>
            <BrowserRouter>
                {/*<Header/>*/}
                <Routes>
                    <Route path="/" element={<Login/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/register" element={<Register/>}/>
                    <Route path="/logout" element={<Logout/>}/>
                    <Route path="/dashboard" element={<AuthGuard/>}>
                        <Route path="/dashboard" element={<DebtordetailPreTerminationFiltering key={'preterminationfiltering'} />}/>
                    </Route>

                    <Route path="/role" element={<AuthGuard />} >
                        <Route path="/role" element={<RoleMain />} />
                    </Route>

                    <Route path="/user" element={<AuthGuard />} >
                        <Route path="/user" element={<UserMain />} />
                    </Route>

                    <Route path="/bookfield" element={<AuthGuard />} >
                        <Route path="/bookfield" element={<BookfieldMain />} />
                    </Route>

                    <Route path="/collectionsbookimporter" element={<AuthGuard />} >
                        <Route path="/collectionsbookimporter" element={<DebtorDetailCollectionsBookImporter />} />
                    </Route>

                    <Route path="/bookimporter" element={<AuthGuard />} >
                        <Route path="/bookimporter" element={<BookImporterMain />} />
                    </Route>

                    <Route path="/accountstatus" element={<AuthGuard />} >
                        <Route path="/accountstatus" element={<AccountstatusMain />} />
                    </Route>

                    <Route path="/location" element={<AuthGuard />} >
                        <Route path="/location" element={<LocationMain />} />
                    </Route>

                    <Route path="/dispositionstatus" element={<AuthGuard />} >
                        <Route path="/dispositionstatus" element={<DispositionstatusMain />} />
                    </Route>

                    <Route path="/agentaccountstatus" element={<AuthGuard />} >
                        <Route path="/agentaccountstatus" element={<AgentaccountstatusMain />} />
                    </Route>

                    <Route path="/reportingteam" element={<AuthGuard />} >
                        <Route path="/reportingteam" element={<ReportingteamMain />} />
                    </Route>

                    <Route path="/supplier" element={<AuthGuard />} >
                        <Route path="/supplier" element={<SupplierMain />} />
                    </Route>

                    <Route path="/leads" element={<AuthGuard />} >
                        <Route path="/leads" element={<LeadsMain />} />
                    </Route>

                    <Route path="/leads-reports" element={<AuthGuard />} >
                        <Route path="/leads-reports" element={<ReportsMain />} />
                    </Route>

                    <Route path="/assignedLead" element={<AuthGuard />} >
                        <Route path="/assignedLead" element={<AssignedleadMain />} />
                    </Route>

                    <Route path="/typeofdebt" element={<AuthGuard />} >
                        <Route path="/typeofdebt" element={<TypeofdebtMain />} />
                    </Route>

                    <Route path="/team" element={<AuthGuard />} >
                        <Route path="/team" element={<TeamMain />} />
                    </Route>

                    <Route path="/campaign" element={<AuthGuard />} >
                        <Route path="/campaign" element={<CampaignMain />} />
                    </Route>

                    <Route path="/bookdetail" element={<AuthGuard />} >
                        <Route path="/bookdetail" element={<BookdetailMain isCollectionsBook={false} key={'preterminationBook'} />} />
                    </Route>

                    <Route path="/collectionbookdetail" element={<AuthGuard />} >
                        <Route path="/collectionbookdetail" element={<BookdetailMain isCollectionsBook={true} key={'collectionsBook'} />} />
                    </Route>

                    <Route path="/strategy" element={<AuthGuard />} >
                        <Route path="/strategy" element={<StrategyMain />} />
                    </Route>

                    <Route path="/account" element={<AuthGuard />} >
                        <Route path="/account" element={<AccountMain />} />
                    </Route>

                    <Route path="/preterminationfiltering" element={<AuthGuard />} >
                        <Route path="/preterminationfiltering" element={<DebtordetailPreTerminationFiltering key={'preterminationfiltering'} />} />
                    </Route>

                    <Route path="/preterminationaccounts" element={<AuthGuard />} >
                        <Route path="/preterminationaccounts" element={<DebtordetailPreTerminationFiltering key={'preterminationaccounts'} showRecordsForAllStatus={true} />} />
                    </Route>

                    <Route path="/claimdetail" element={<AuthGuard />} >
                        <Route path="/claimdetail" element={<ClaimdetailMain />} />
                    </Route>

                    <Route path="/accountnotepad" element={<AuthGuard />} >
                        <Route path="/accountnotepad" element={<AccountnotepadMain />} />
                    </Route>

                    <Route path="/bookdebtors" element={<AuthGuard />} >
                        <Route path="/bookdebtors" element={<BookdebtorsMain />} />
                    </Route>

                    <Route path="/debtoragent" element={<AuthGuard />} >
                        <Route path="/debtoragent" element={<DebtoragentMain />} />
                    </Route>

                    <Route path="/smstemplate" element={<AuthGuard />} >
                        <Route path="/smstemplate" element={<SmstemplateMain />} />
                    </Route>

                    <Route path="/ptp" element={<AuthGuard />} >
                        <Route path="/ptp" element={<PtpMain />} />
                    </Route>

                    <Route path="/ptpmethod" element={<AuthGuard />} >
                        <Route path="/ptpmethod" element={<PtpmethodMain />} />
                    </Route>

                    <Route path="/bankdetail" element={<AuthGuard />} >
                        <Route path="/bankdetail" element={<BankdetailMain />} />
                    </Route>

                    <Route path="/secondarycontactdetail" element={<AuthGuard />} >
                        <Route path="/secondarycontactdetail" element={<SecondarycontactdetailMain />} />
                    </Route>

                    <Route path="/contacttype" element={<AuthGuard />} >
                        <Route path="/contacttype" element={<ContacttypeMain />} />
                    </Route>

                    <Route path="/partybankinfo" element={<AuthGuard />} >
                        <Route path="/partybankinfo" element={<PartybankinfoMain />} />
                    </Route>

                    <Route path="/collectionAccount" element={<AuthGuard />} >
                        <Route path="/collectionAccount" element={<CollectionAccountHandlerMain />} />
                    </Route>

                    <Route path="/edc" element={<AuthGuard />} >
                        <Route path="/edc" element={<EdcMain />} />
                    </Route>

                    <Route path="/edcscorecard" element={<AuthGuard />} >
                        <Route path="/edcscorecard" element={<EdcscorecardMain />} />
                    </Route>

                    <Route path="/integrations" element={<AuthGuard />} >
                        <Route path="/integrations" element={<IntegrationsMain />} />
                    </Route>

                    <Route path="/vintageReport" element={<AuthGuard />} >
                        <Route path="/vintageReport" element={<VintageReport />} />
                    </Route>

                    <Route path="/rollReport" element={<AuthGuard />} >
                        <Route path="/rollReport" element={<RollRateReport />} />
                    </Route>

                    <Route path="/commissionReport" element={<AuthGuard />} >
                        <Route path="/commissionReport" element={<CommissionReportMain />} />
                    </Route>

                    <Route path="/actionReport" element={<AuthGuard />} >
                        <Route path="/actionReport" element={<ActionReportMain />} />
                    </Route>

                    <Route path="/collectionExtract" element={<AuthGuard />} >
                        <Route path="/collectionExtract" element={<CollectionextractMain />} />
                    </Route>

                    <Route path="/genesysOutboundList" element={<AuthGuard />} >
                        <Route path="/genesysOutboundList" element={<GenesysoutboundlistMain />} />
                    </Route>

                    <Route path="/wrapupCode" element={<AuthGuard />} >
                        <Route path="/wrapupCode" element={<WrapupcodeMain />} />
                    </Route>

                    <Route path="/edcConfiguration" element={<AuthGuard />} >
                        <Route path="/edcConfiguration" element={<EdcconfigurationMain />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default Router;
