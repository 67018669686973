// @ts-nocheck

import React, { useEffect, useState, useRef } from 'react';
import { useStyles } from './Styles';
import '../../../../../index.scss';
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getAll, getById, post, patch, deleteById } from "../../../../../Services/GenericApiService";
import { formatData } from "../../../../../Services/DataFormatService";
import { useTranslation } from "react-i18next";
import AlertM from '../../../../Helpers/AlertM/AlertM';
import CollectionextractCreate from "../CollectionextractCreate/CollectionextractCreate";
import CollectionextractUpdate from "../CollectionextractUpdate/CollectionextractUpdate";
import CollectionextractView from "../CollectionextractView/CollectionextractView";
import { log } from "../../../../../Services/LoggerService";
import { Box, Checkbox, CssBaseline, FormControlLabel, FormGroup } from '@mui/material';
import Paper from '@mui/material/Paper';
import MUIDataTable from "mui-datatables";
import { Button, IconButton, Skeleton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import Tooltip from '@mui/material/Tooltip';
import ConfirmationDialog from "../../../../Helpers/ConfirmationDialog/ConfirmationDialog";

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function CollectionextractMain() {
    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // module(s) for api
    const moduleMain = 'collectionextract';

    // data vars
    const [allData, setAllData] = React.useState([]);
    const [dataToEdit, setDataToEdit] = React.useState({});

    let columns = [
        { name: "Billing Account", options: { filterOptions: { fullWidth: true } } },
        { name: "MTN Account Status", options: { filterOptions: { fullWidth: true } } },
        { name: "Risk Band", options: { filterOptions: { fullWidth: true } } },
        { name: "Current Treatment Level", options: { filterOptions: { fullWidth: true } } },
        { name: "Collection Stage", options: { filterOptions: { fullWidth: true } } },
        { name: "Current Stage Entry Date", options: { filterOptions: { fullWidth: true } } },
        { name: "MSISDN", options: { filterOptions: { fullWidth: true } } },
        { name: "Spul", options: { filterOptions: { fullWidth: true } } },
        { name: "Customer Name", options: { filterOptions: { fullWidth: true } } },
        { name: "Home Tel Nr", options: { filterOptions: { fullWidth: true } } },
        { name: "Work Tel Nr", options: { filterOptions: { fullWidth: true } } },
        { name: "Mobile Tel Nr", options: { filterOptions: { fullWidth: true } } },
        { name: "Outstanding Amount", options: { filterOptions: { fullWidth: true } } },
        { name: "Account Balance", options: { filterOptions: { fullWidth: true } } },
        { name: "Days 0 to 30", options: { filterOptions: { fullWidth: true } } },
        { name: "Days 31 to 60", options: { filterOptions: { fullWidth: true } } },
        { name: "Days 61 to 90", options: { filterOptions: { fullWidth: true } } },
        { name: "Days 91 to 120", options: { filterOptions: { fullWidth: true } } },
        { name: "Days 121 to 150", options: { filterOptions: { fullWidth: true } } },
        { name: "Days 151 to 180", options: { filterOptions: { fullWidth: true } } },
        { name: "Days 180 Plus", options: { filterOptions: { fullWidth: true } } },
        { name: "Last Payment Amount", options: { filterOptions: { fullWidth: true } } },
        { name: "Last Payment Date", options: { filterOptions: { fullWidth: true } } },
        { name: "Payment Method", options: { filterOptions: { fullWidth: true } } },
        { name: "Payment Due Date", options: { filterOptions: { fullWidth: true } } },
        { name: "Debit Order Date", options: { filterOptions: { fullWidth: true } } },
    ];
    const [tableData, setTableData] = React.useState([]);
    const [selectedFilters, setSelectedFilters] = React.useState({
        "range": {},
        "filter": {
            "mtnAccountStatus": [],
            "riskBand": [],
            "currentTreatmentLevel": [],
            "paymentMethod": [],
            "collectionStage": []
        }
    });

    // filter object
    const availableFilters = {
        "range": {},
        "filter": {
            "mtnAccountStatus": {
                "displayName": "MTN Account Status",
                "options": ['Standard', 'New', 'Pre-Legal', 'Closed', 'Legal', '(Blanks)']
            },
            "riskBand": {
                "displayName": "Risk Band",
                "options": ['Very Low', 'Low', 'Medium', 'High', 'Very High', '(Blanks)']
            },
            "currentTreatmentLevel": {
                "displayName": "Current Treatment Level",
                "options": ["Hard Suspend", "SMS", "No Action", "Account Lock", "Change Payment Method to Cash", "Change to Pre-Legal", "Letter", "Soft Suspend Out"]
            },
            "paymentMethod": {
                "displayName": "Payment Method",
                "options": ["Auto-Debit", "Cash", "Credit Card", "Payroll"]
            },
            "collectionStage": {
                "displayName": "Collection Stage",
                "options": ["Early Stage", "Hard Suspension", "Pre-Legal", "Soft Suspension"]
            },
        }
    }


    // ui controls
    const [loading, setLoading] = React.useState(true);
    const [editMode, setEditMode] = React.useState(false);

    // table ui controls
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    // fetch dependent data
    const fetch = () => {
        setTableData([]);  // reset table data
        setLoading(true); // show loader
        post(moduleMain + '/filter', selectedFilters, currentPage, pageSize)
            .then((_res: any) => {
                // set all data
                setAllData(_res.rows)

                // set total count
                setTotalRowCount(_res.count);

                const rows: any = [];
                _res.rows.forEach((_record: any) => {
                    rows.push(formatData([_record?.accountName, _record?.mtnAccountStatus, _record?.riskBand, _record?.currentTreatmentLevel, _record?.collectionStage, _record?.currentStageEntryDate, _record?.msisdn, _record?.spul, _record?.customerName, _record?.homeContact, _record?.workContact, _record?.mobileContact, _record?.outstandingAmount, _record?.accountBalance, _record?.b_0_30, _record?.b_31_60, _record?.b_61_90, _record?.b_91_120, _record?.b_121_150, _record?.b_151_180, _record?.b_180_plus, _record?.lastPaymentAmount, _record?.lastPaymentDate, _record?.paymentMethod, _record?.paymentDueDate, _record?.debitOrderDate]))
                });

                setTableData(rows);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // send data to genesys outbound list 
    const sendDataToGenesysOutboundList = () => {
        setLoading(true); // show loader
        post(moduleMain + '/filter/copy', selectedFilters)
            .then((_res: any) => {
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.successAlert(t('common.copiedSuccess'), '');
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
            })
    }

    useEffect(() => {
        fetch();
    }, [currentPage, pageSize])

    const handleImport = () => {
        setLoading(true); // show loader
        post(moduleMain + '/import', {})
            .then(_res => {
                // @ts-ignore
                notifications.current.successAlert(t('common.genericSuccess'), '');

                fetch();
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
            })
    }

    const resetEdit = (_state) => {
        setEditMode(false);
    }

    let options = {
        search: true,
        download: true,
        print: true,
        viewColumns: true,
        filter: true,
        filterType: "textField",
        responsive: "simple",
        selectableRowsHideCheckboxes: true,
        sort: true,
        onChangePage: (currentPage) => { setCurrentPage(currentPage); },
        onChangeRowsPerPage: (numberOfRows) => { setPageSize(numberOfRows); },
        page: currentPage,
        count: totalRowCount,
        serverSide: true,
        loading: true,
        rowsPerPageOptions: [25, 50, 75, 100],
        rowsPerPage: pageSize,
        textLabels: {
            body: {
                noMatch: loading ?
                    <Box>
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                    </Box> :
                    'Sorry, there is no matching data to display',
            },
        },
        onTableChange: (action: any, state: any) => {
            // console.log(action);
            // console.dir(state);
        }
    };

    if (tableData.length === 0) {
        options = {
            textLabels: {
                body: {
                    noMatch: loading ?
                        <Box>
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                        </Box> :
                        'Sorry, there is no matching data to display',
                },
            },
        };
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* Header */}
            <Header />

            {/* Sider */}
            <Sider />

            {/* Content */}
            <Box component="main" sx={{ display: 'block' }} mt={13}>
                <div className="px-1 px-md-5">

                    {/* Upper Section */}
                    <div className="row">
                        <div className="col-md-7">
                            <h2 className="mt-3">
                                {t('module.collectionextract')}
                            </h2>
                        </div>
                        <div className="col-md-3">
                            {!loading &&
                                <Button
                                    variant="contained"
                                    onClick={sendDataToGenesysOutboundList}
                                    className={'bg-custom-gradient my-2 py-3'}
                                    fullWidth>
                                    Send Data To Genesys Outbound List
                                </Button>
                            }

                            {loading &&
                                <Skeleton className="my-4" />
                            }
                        </div>
                        <div className="col-md-2">
                            {/*<CollectionextractCreate refreshDataEvent={fetch}/>*/}

                            {!loading &&
                                <Button
                                    variant="contained"
                                    onClick={handleImport}
                                    className={'bg-custom-gradient my-2 py-3'}
                                    fullWidth>
                                    {t('common.import')}
                                </Button>
                            }

                            {loading &&
                                <Skeleton className="my-4" />
                            }
                        </div>
                    </div>



                    {/* Filters */}
                    <div className="row mt-5">
                        <div className={" card shadow-lg p-3 ms-0"}>
                            <div className="row mt-1">
                                <div className="col-md-10 col-0"></div>
                                <div className="col-md-2 col-12">
                                    <div className="">
                                        {!loading &&
                                            <Button
                                                variant="contained"
                                                onClick={fetch}
                                                fullWidth>
                                                Apply Filters
                                            </Button>
                                        }
                                        {loading &&
                                            <Skeleton className="my-4" />
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-2 px-2">
                                {Object.keys(availableFilters.filter).map(_key => {
                                    return (
                                        <div className="col-md-4 mt-2 mb-5">
                                            <p className={'m-0 p-0 text-muted'}>
                                                {availableFilters.filter[_key].displayName}
                                            </p>
                                            <div className="row p-0 m-0 mt-2">
                                                {
                                                    availableFilters.filter[_key].options.map((_option, _optionIdx) => (
                                                        <div className="col-md-6 p-0 m-0">
                                                            <FormGroup>
                                                                <FormControlLabel control={<Checkbox />}
                                                                    onChange={(e) => {
                                                                        // get current array of selected options
                                                                        let options = selectedFilters.filter[_key];

                                                                        // select option
                                                                        if (e.target.checked) {
                                                                            // push option in array
                                                                            options.push(_option);
                                                                        } else {
                                                                            // unselect option
                                                                            options = options.filter((item) => {
                                                                                return item !== _option
                                                                            })
                                                                        }

                                                                        // update array
                                                                        setSelectedFilters((prevState) => ({
                                                                            ...prevState,
                                                                            filter: {
                                                                                ...prevState.filter,
                                                                                [_key]: options
                                                                            }
                                                                        }));

                                                                    }}
                                                                    label={availableFilters.filter[_key].optionLabel ? availableFilters.filter[_key].optionLabel[_optionIdx] : _option} />
                                                            </FormGroup>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div>
                    </div>


                    <div className="mt-5" style={{ width: '90vw' }}>
                        <Paper elevation={12}>
                            <MUIDataTable
                                title={t('common.allRecords')}
                                data={tableData}
                                columns={columns}
                                // @ts-ignore
                                options={options}
                            />
                        </Paper>
                    </div>

                </div>

                {/* display edit modal */}
                {editMode &&
                    <div>
                        <CollectionextractUpdate refreshDataEvent={fetch}
                            resetEditState={resetEdit}
                            recordToUpdate={dataToEdit} />
                    </div>
                }


                {/* Alerts */}
                <AlertM ref={notifications} />

            </Box>


        </Box>
    );
}

export default CollectionextractMain;
