// @ts-nocheck

import React, { useEffect, useState, useRef } from 'react';
import { useStyles } from './Styles';
import '../../../../../index.scss';
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { getAll, post } from "../../../../../Services/GenericApiService";
import { formatData } from "../../../../../Services/DataFormatService";
import { useTranslation } from "react-i18next";
import AlertM from '../../../../Helpers/AlertM/AlertM';
import { log } from "../../../../../Services/LoggerService";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useForm } from "react-hook-form";
import MUIDataTable from "mui-datatables";
import { IconButton, Skeleton, Button, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import { FormControl, FormHelperText } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import { RootStateOrAny, useSelector } from "react-redux";
import TextDisplayM from '../../../../Helpers/TextDisplayM/TextDisplayM';
import ApexPieChart from '../../Chart/Apex/ApexPieChart/ApexPieChart';
import CollectionAccountHandlerViewData from '../../Debtordetail/CollectionAccountHandler/CollectionAccountHandlerViewData/CollectionAccountHandlerViewData';
// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function DetailPaymentReport() {
    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // module(s) for api
    const moduleMain = 'debtordetail';
    const moduleCampaign = 'campaign';
    const moduleDebtorAgent = 'debtoragent';
    const moduleExportLeads = '/export/detailPaymentReport';
    const moduleTeam = 'team';

    // data vars
    const [allData, setAllData] = React.useState([]);

    const [allAgent, setAllAgent] = React.useState({});
    const [allTeam, setAllTeam] = React.useState({});
    const [allCampaign, setAllCampaign] = React.useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [paginationFlag, setPaginationFlag] = useState(false);
    const [breachAmount, setBreachAmount] = useState(0);
    const [selectedCampaignIds, setSelectedCampaignIds] = useState([]);
    const [selectedAgentIds, setSelectedAgentIds] = useState([]);
    const [selectedTeamIds, setSelectedTeamIds] = useState([]);
    const [allDispositionStatusData, setDispositionStatusData] = React.useState([]);
    const [allDispositionStatusLabel, setDispositionStatusLabel] = React.useState([]);
    const [allAccountStatusData, setAccountStatusData] = React.useState([]);
    const [allAccountStatusLabel, setAccountStatusLabel] = React.useState([]);


    let columns = [
        { name: "Customer Account No.", options: { filterOptions: { fullWidth: true } } },
        { name: "Team Name", options: { filterOptions: { fullWidth: true } } },
        { name: "Start Age", options: { filterOptions: { fullWidth: true } } },
        { name: "Current Arrears", options: { filterOptions: { fullWidth: true } } },
        { name: "Total Collected", options: { filterOptions: { fullWidth: true } } },
        { name: "% Collected", options: { filterOptions: { fullWidth: true } } },
        { name: "Start Balance", options: { filterOptions: { fullWidth: true } } },
        { name: "Current Balance", options: { filterOptions: { fullWidth: true } } },
        { name: "Agent Name", options: { filterOptions: { fullWidth: true } } },
    ];
    const [tableData, setTableData] = React.useState([]);
    const [selectedData, setSelectedData] = React.useState({});
    // ui controls
    const [loading, setLoading] = React.useState(true);

    // get theme from redux
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    // table ui controls
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");

    const handleSelectCampaignChange = (event) => {
        setSelectedCampaignIds(event.target.value);
    }

    const handleAgentChange = (event) => {
        setSelectedAgentIds(event.target.value);
    }

    const handleTeamChange = (event) => {
        setSelectedTeamIds(event.target.value);
    }

    // fetch dependent data
    const fetch = () => {
        fetchCampaign();
        fetchAgent();
        fetchTeam();
    }

    //table data

    const getCollectionAccount = () => {
        setLoading(true); // show loader
        let payload = {
            "campaignId": selectedCampaignIds,
            "agentId": selectedAgentIds,
            "teamId": selectedTeamIds,
        }
        post(moduleMain + '/collection/detailPaymentReport', payload, currentPage, pageSize)
            .then((_res: any) => {
                setAllData(_res)
                setTotalRowCount(_res.count);
                setBreachAmount(_res.totalBreachAmount)
                setAllData(_res.rows);
                setPaginationFlag(true);

                // store account and disposition lables
                const dispositionStatusData = [];
                const dispositionStatusLabel = [];

                const accountStatusData = [];
                const accountStatusLabel = [];

                _res.countByDispositionStatus.forEach(item => {
                    dispositionStatusData.push(item.count);
                    dispositionStatusLabel.push(item.dispositionStatus);
                });

                _res.countByAccountStatus.forEach(item => {
                    accountStatusData.push(item.count);
                    accountStatusLabel.push(item.accountStatus);
                });

                setDispositionStatusData(dispositionStatusData);
                setDispositionStatusLabel(dispositionStatusLabel);
                setAccountStatusData(accountStatusData);
                setAccountStatusLabel(accountStatusLabel);

                const rows: any = [];
                _res.rows.forEach((_record: any) => {
                    rows.push(formatData([_record?.billAccNo, _record['debtorBookDetail.bookCampaign.campaignLocation.locationTeam.name'],'to be decided',_record.convTotalArrears,'to be decided','to be decided','to be decided','to be decided',_record['debtordetailDebtoragent.debtorAgentUser.email']]))
                });

                setTableData(rows);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch Agents
    const fetchAgent = () => {
        setLoading(true); // show loader
        getAll(moduleDebtorAgent + '/agent/all')
            .then((_res: any) => {
                setAllAgent(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch Team
    const fetchTeam = () => {
        setLoading(true); // show loader
        getAll(moduleTeam)
            .then((_res: any) => {
                setAllTeam(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    const handleExportData = () => {
        setLoading(true);   // Set Loading
        let payload = {
            "campaignId": selectedCampaignIds,
            "agentId": selectedAgentIds,
            "teamId": selectedTeamIds,
        }

        // post filters
        post(moduleMain + moduleExportLeads, payload)
            .then((_res: any) => {
                window.open(_res.url, '_blank');
                // @ts-ignore
                // notifications.current.successAlert('Records Rejected', 'Records Were Rejected');
                setLoading(false);
            })
            .catch((_err: any) => {
                log(_err);
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                setLoading(false);   // loading is set false in fetch
            })
    }


    // fetch Campaign
    const fetchCampaign = () => {
        let id = [];
        setLoading(true); // show loader
        getAll(moduleCampaign)
            .then((_res: any) => {
                setAllCampaign(_res)
                id = _res.map(item => item.id);
                setSelectedCampaignIds(id);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // form methods
    const onSubmit = (_data: any) => {

    }

    useEffect(() => {
        fetch()
    }, [currentPage])

    useEffect(() => {
        if (paginationFlag) {
            getCollectionAccount();
        }
    }, [currentPage])


    let options = {
        search: true,
        download: true,
        print: true,
        viewColumns: true,
        filter: true,
        filterType: "textField",
        responsive: "simple",
        resizableColumns: false,
        rowsPerPage: 25,
        rowsPerPageOptions: [25, 50, 75, 100],
        onChangePage: (currentPage) => {
            setCurrentPage(currentPage);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            setPageSize(numberOfRows);
        },
        onDownload: () => {
            if (!loading) {
                handleExportData();
            }
            // return false to avoid datatable download
            return false;
        },
        page: currentPage,
        count: totalRowCount,
        serverSide: true,
        loading: true,
        tableBodyHeight: tableBodyHeight,
        selectableRowsHideCheckboxes: true,
        sort: true,
        textLabels: {
            body: {
                noMatch: loading ?
                    <Box>
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                    </Box> :
                    'Sorry, there is no matching data to display',
            },
        },
        onTableChange: (action: any, state: any) => {
        },
    };

    if (tableData.length === 0) {
        options = {
            textLabels: {
                body: {
                    noMatch: loading ?
                        <Box>
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                        </Box> :
                        'Sorry, there is no matching data to display',
                },
            },
        };
    }

    return (
        <>
            <Box component="main">
                {!loading &&
                    <>
                        {/* Filter row */}
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className={(theme == 'dark' ? 'bg-dark' : '') + "card shadow-lg ms-0 me-0"}>
                                <div className="card-body">
                                    <div className='row'>

                                        {/* Campaign */}
                                        <div className='col-3 mt-3'>
                                            {/* Field campaignId */}
                                            <FormControl fullWidth={true}>
                                                <InputLabel id="campaignStatusIdLabel">
                                                    Campaign
                                                </InputLabel>
                                                {allCampaign.length > 0 &&
                                                    <Select labelId="campaignStatusIdLabel"
                                                        id="campaignStatusIdSelect"
                                                        {...register("campaignStatusId", { required: false })}
                                                        error={!!errors.campaignStatusId}
                                                        value={selectedCampaignIds}
                                                        onChange={handleSelectCampaignChange} // Handle selection disposition changes
                                                        multiple
                                                        label="campaignStatusId">
                                                        {
                                                            allCampaign.map((el: any) =>
                                                                <MenuItem value={el.id}
                                                                    key={el.id}> {el.name}
                                                                </MenuItem>)
                                                        }
                                                    </Select>
                                                }
                                                {allCampaign.length == 0 &&
                                                    <>
                                                        <Select labelId="campaignStatusLabel"
                                                            disabled
                                                            id="campaignStatusIdSelect"
                                                            {...register("campaignStatusId", { required: false })}
                                                            error={!!errors.campaignStatusId}
                                                            value={watch('campaignStatusId')}
                                                            label="campaignStatusId">
                                                            <MenuItem value={''}>
                                                                No Data
                                                            </MenuItem>
                                                        </Select>
                                                        <FormHelperText>
                                                            No Data
                                                        </FormHelperText>
                                                    </>
                                                }
                                            </FormControl>
                                        </div>

                                        <div className='col-3 mt-3'>
                                            {/* Field Agent */}
                                            <FormControl fullWidth={true}>
                                                <InputLabel id="agentIdLabel">
                                                    Agent
                                                </InputLabel>
                                                {allAgent.length > 0 &&
                                                    <Select labelId="agentIdLabel"
                                                        id="agentIdSelect"
                                                        {...register("id", { required: false })}
                                                        error={!!errors.id}
                                                        value={selectedAgentIds}
                                                        onChange={handleAgentChange} // Handle selection disposition changes
                                                        multiple
                                                        label="agentId">
                                                        {
                                                            allAgent.map((el: any) =>
                                                                <MenuItem value={el.id}
                                                                    key={el.id}> {el.email}
                                                                </MenuItem>)
                                                        }
                                                    </Select>
                                                }
                                                {allAgent.length == 0 &&
                                                    <>
                                                        <Select labelId="agentIdLabel"
                                                            disabled
                                                            id="agentIdSelect"
                                                            {...register("agentId", { required: false })}
                                                            error={!!errors.id}
                                                            value={watch('id')}
                                                            label="agentId">
                                                            <MenuItem value={''}>
                                                                No Data
                                                            </MenuItem>
                                                        </Select>
                                                        <FormHelperText>
                                                            No Data
                                                        </FormHelperText>
                                                    </>
                                                }
                                            </FormControl>
                                        </div>

                                        <div className='col-3 mt-3'>
                                            {/* Field Team */}
                                            <FormControl fullWidth={true}>
                                                <InputLabel id="teamIdLabel">
                                                    Team
                                                </InputLabel>
                                                {allTeam.length > 0 &&
                                                    <Select labelId="teamIdLabel"
                                                        id="teamIdSelect"
                                                        {...register("teamId", { required: false })}
                                                        error={!!errors.id}
                                                        value={selectedTeamIds}
                                                        onChange={handleTeamChange} // Handle selection disposition changes
                                                        multiple
                                                        label="teamId">
                                                        {
                                                            allTeam.map((el: any) =>
                                                                <MenuItem value={el.id}
                                                                    key={el.id}> {el.name}
                                                                </MenuItem>)
                                                        }
                                                    </Select>
                                                }
                                                {allTeam.length == 0 &&
                                                    <>
                                                        <Select labelId="teamIdLabel"
                                                            disabled
                                                            id="teamIdSelect"
                                                            {...register("teamId", { required: false })}
                                                            error={!!errors.id}
                                                            label="agentId">
                                                            <MenuItem value={''}>
                                                                No Data
                                                            </MenuItem>
                                                        </Select>
                                                        <FormHelperText>
                                                            No Data
                                                        </FormHelperText>
                                                    </>
                                                }
                                            </FormControl>
                                        </div>
                                        {/* Apply Filter Button */}
                                        <div className='col-3 mt-3'>
                                            <Button
                                                onClick={getCollectionAccount}
                                                className={'full-width bg-custom-gradient py-3 px-5'}
                                                variant="contained">
                                                Apply Filters
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </>
                }
                {loading &&
                    <Skeleton height={100} />
                }

                <div className='row'>
                    {!loading &&
                        <div className="row mt-5">
                            {/* Total Count */}
                            <div className="col-md-6">
                                <TextDisplayM
                                    data={parseFloat(totalRowCount || 0).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                    label={'Total Records'}
                                    isLoading={loading} />
                            </div>
                            {/* Total Account Balance */}
                            <div className="col-md-6">
                                <TextDisplayM data={parseFloat(breachAmount || 0).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                    label={'Total Breach Amount (ZAR)'}
                                    isLoading={loading}
                                    background={'custom-secondary'} />
                            </div>

                        </div>
                    }

                    {loading &&
                        <Skeleton height={100} />
                    }
                </div>

                {/* Graph */}
                <div className='row'>
                    {!loading &&
                        <div className="row">
                            <div className="col-md-6">
                                <ApexPieChart data={allDispositionStatusData}
                                    labels={allDispositionStatusLabel}
                                    chartTitle={'Disposition Status Breakdown'} />
                            </div>
                            <div className="col-md-6">
                                <ApexPieChart data={allAccountStatusData}
                                    labels={allAccountStatusLabel}
                                    chartTitle={'Account Status Breakdown'} />
                            </div>
                        </div>
                    }

                    {loading &&
                        <Skeleton height={100} />
                    }
                </div>

                {/* Data Table */}
                {!loading &&
                    <div className="mt-5">
                        <Paper elevation={6}>
                            <MUIDataTable
                                title={t('common.allRecords')}
                                data={tableData}
                                columns={columns}
                                // @ts-ignore
                                options={options}
                            />
                        </Paper>
                    </div>
                }


                {loading &&

                    <Skeleton height={250} />
                }

                {/* Alerts */}
                <AlertM ref={notifications} />

            </Box>
        </>
    );
}

export default DetailPaymentReport;
