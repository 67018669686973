// @ts-nocheck

import React, {useEffect, useState} from 'react';
import {useStyles} from './Styles';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import {post, getAll} from "../../../../../Services/GenericApiService";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {log} from "../../../../../Services/LoggerService";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import {Button, FormControl, TextField, FormControlLabel, Checkbox, FormHelperText, Skeleton} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import {DatePicker} from '@mui/lab';
import FormGroup from '@mui/material/FormGroup';


// @ts-ignore
function StrategyForm({handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent}) {
    // css for module
    const classes = useStyles();

    // router object
    const navigate = useNavigate();

    // module(s) for api
    const moduleMain = 'strategy';

    // linked components API modules (endpoints)
    const moduleCampaigns = 'campaign';
    const moduleTeams = 'team';

    // translation
    const {t} = useTranslation();

    // steps
    const steps = ['Select Campaign and Teams', 'Create Strategy'];

    // filter object
    const availableFilters = {
        "range": {
            "convTotalDue": {
                "displayName": "Conv Total Due"
            },
        },
        "filter": {
            "bookName": {
                "displayName": "Book Name",
                "options": ["BULK SMS", "MOBILE", "SME MOBILE", "ICT", "Corporate Consumer", "Corporate Consumer - iLula", "None"]
            },
            "region": {
                "displayName": "Region",
                "options": ["CEN-FS", "CEN-NC", "EC", "GP", "Internal", "KZN", "LIMP", "MPU", "NAT", "NW", "WC", "#N/A", "None"]
            },
            "segment": {
                "displayName": "Segment",
                "options": ["LE", "PE", "SME", "Internal", "#N/A", "None"]
            },
            "subSegment": {
                "displayName": "Sub Segment",
                "options": ["ASSOCIATION", "COE", "INSTITUTION", "Internal", "IOT", "LSME", "MNC", "MSME", "MUNICIPALITY", "ND&A", "NONE", "PROVINCIAL", "SOE", "SOHO", "SSME", "#N/A", "None"]
            },
            "paymentMethod": {
                "displayName": "Payment Method",
                "options": ["EFT", "None"]
            },
            "revenueType": {
                "displayName": "Revenue Type",
                "options": ["ICT", "MOBILE", "OTHER MOBILE", "None"]
            },
        }
    }


    // form data
    const {register, handleSubmit, watch, reset, setValue, formState: {errors}} = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {
        if (!isEditMode) {
            _data.filters = selectedFilters;
            _data.teams = selectedTeams;
            handleAddEvent(_data);
        } else {
            _data.filters = selectedFilters;
            _data.teams = selectedTeams;
            handleEditEvent(_data);
        }
    }

    // data vars
    const [allCampaigns, setAllCampaigns] = React.useState([]);
    const [selectedCampaign, setSelectedCampaign] = React.useState(0);
    const [allTeams, setAllTeams] = React.useState([]);
    const [selectedTeams, setSelectedTeams] = React.useState([]);
    const [otherStrategies, setOtherStrategies] = React.useState('');
    const [selectedFilters, setSelectedFilters] = React.useState({
        "range": {
            "convTotalDue": {
                "min": null,
                "max": null
            },
        },
        "filter": {
            "bookName": [],
            "region": [],
            "segment": [],
            "subSegment": [],
            "paymentMethod": [],
            "revenueType": []
        }
    });


    // ui handlers
    const [loading, setLoading] = React.useState(true);
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNextStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBackStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const onCancel = () => {
        handleCancelEvent();
    }

    const getSelectedFiltersName = (_filterObj, _returnHtml = true) => {
        let allFilterNames = '';

        Object.keys(_filterObj.filter).forEach(_filter => {
            if (_filterObj.filter[_filter].length > 0) {
                if (allFilterNames.length > 0) {
                    allFilterNames += ',';
                }
                allFilterNames += ' ' + availableFilters.filter[_filter].displayName + ' [' + _filterObj.filter[_filter].join(', ') + ']';
            }
        });

        Object.keys(_filterObj.range).forEach(_range => {
            if (_filterObj.range[_range].min != null || _filterObj.range[_range].max != null) {
                if (allFilterNames.length > 0) {
                    allFilterNames += ',';
                }
                allFilterNames += ' ' + availableFilters.range[_range].displayName+ ' [' + (isNaN(_filterObj.range[_range].min) || _filterObj.range[_range].min == null ? '-∞' : _filterObj.range[_range].min) + ' <-> ' + (isNaN(_filterObj.range[_range].max)  || _filterObj.range[_range].max == null ? '∞' : _filterObj.range[_range].max) + ']';
            }
        });

        if(_returnHtml){
            if(allFilterNames.length == 0){
                allFilterNames = 'No Filters Selected';
            }
            return <p>{allFilterNames}</p>;
        } else {
            return allFilterNames;
        }
    }

    // fetch dependent data
    const fetchCampaigns = () => {
        getAll(moduleCampaigns)
            .then((_res: any) => {
                setAllCampaigns(_res);
            })
            .catch(_err => {
                log(_err);
            })
    }

    const fetchOtherStrategiesInCampaigns = (_campaignId) => {
        setLoading(true);
        getAll(moduleCampaigns + `/${_campaignId}/analytics/strategies/totals`)
            .then((_res: any) => {
                let dataStr = '';

                _res.strategies.forEach((_strategy, _idx) => {
                    // if edit mode, dont add the strategy we are editing in the other strategies
                    if((isEditMode && recordToUpdate.id != _strategy.id) || !isEditMode){
                        try {
                            dataStr += `${_strategy.name} [${_strategy.uniqueAccounts} records selected]: ${getSelectedFiltersName(JSON.parse(_res.strategies[_idx].rawFilterString), false)}`;
                        } catch (e) {
                            dataStr += `${_strategy.name} [${_strategy.uniqueAccounts} records selected]: ${getSelectedFiltersName(_res.strategies[_idx].rawFilterString, false)}`;
                        }
                        if(_idx != _res.strategies.length - 1){
                            dataStr += '\n';
                        }
                    }
                });

                // if no other strategies for the campaign
                if(dataStr == ''){
                    dataStr = 'This campaign does not have any other strategy';
                }

                dataStr = `Total ${_res.totalAccounts} Accounts in the campaign\n` + dataStr;
                setOtherStrategies(dataStr);
                setLoading(false);
            })
            .catch(_err => {
                log(_err);
                setLoading(false);
            })
    }

    const fetchTeams = (_campaignId) => {
        // reset all teams
        setAllTeams([]);

        // get all teams in the location of campaign
        let seletecCampaign = allCampaigns.filter(_campaign => _campaign.id == _campaignId);
        if (seletecCampaign.length > 0) {
            const locationId = seletecCampaign[0].locationId;

            getAll(moduleTeams + '/location/' + locationId)
                .then((_res: any) => {
                    setAllTeams(_res);
                    // assign current teams if edit mode\
                    if (isEditMode) {
                        let teamsArray = [];
                        recordToUpdate.strategyStrategyCampaign.forEach(_record => {
                            if (!teamsArray.includes(_record.teamId)) {
                                teamsArray.push(_record.teamId);
                            }
                        })
                        setSelectedTeams(teamsArray);
                    }
                })
                .catch(_err => {
                    log(_err);
                })
        }
    }

    const fetch = () => {
        fetchCampaigns();
    }


    // hooks
    useEffect(() => {
        if (isEditMode) {
            reset(recordToUpdate);
            setValue('campaignId', recordToUpdate.strategyStrategyCampaign[0]?.campaignId);
            try {
                setSelectedFilters(JSON.parse(recordToUpdate.filters));
            } catch (e) {
                setSelectedFilters(recordToUpdate.filters);
            }
            setSelectedCampaign(recordToUpdate.strategyStrategyCampaign[0]?.campaignId);
        }
        fetch();
    }, []);


    // the following two useEffect are to asynchronously set selected campaign and teams in sequence when updating record
    useEffect(() => {
        if (selectedCampaign > 0) {
            // fetch teams available in the location of campaign in edit mode only
            fetchTeams(selectedCampaign);

            // display other strategies filters
            fetchOtherStrategiesInCampaigns(selectedCampaign);
        }
    }, [selectedCampaign]);

    useEffect(() => {
        if (isEditMode && selectedCampaign > 0) {
            // fetch teams available in the location of campaign in edit mode only
            fetchTeams(selectedCampaign);
        }
    }, [allCampaigns]);

    return (
        <Box sx={{display: 'flex'}}>
            <Card sx={{width: '90vw', maxHeight: '90vh', overflowY: 'auto'}}>

                {/* Stepper */}
                <div className="p-3">
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps: { completed?: boolean } = {};
                            const labelProps: {
                                optional?: React.ReactNode;
                            } = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </div>


                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">

                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.strategy')}
                                    </h3>
                                </div>


                                {/* Step 1 */}
                                {activeStep == 0 &&
                                    <div className={'row'}>
                                        {/* Field name */}
                                        <div className="col-md-6">
                                            <FormControl fullWidth>
                                                <TextField id="nameInput"
                                                           {...register("name", {required: true})}
                                                           label={t('strategy.name')}
                                                           type={"text"}
                                                           error={!!errors.name}
                                                           variant="outlined"
                                                           className="full-width"/>
                                            </FormControl>
                                        </div>

                                        {/* Field campaign id */}
                                        <div className="col-md-6">
                                            <FormControl fullWidth={true}>
                                                <InputLabel id="bookTemplateIdLabel">
                                                    Select Campaigns
                                                </InputLabel>
                                                {allCampaigns.length > 0 &&
                                                    <Select labelId="campaignIdLabel"
                                                            id="campaignIdSelect"
                                                            {...register("campaignId", {required: true})}
                                                            error={!!errors.campaignId}
                                                            value={watch('campaignId')}
                                                            onChange={(event) => {
                                                                setSelectedCampaign(event.target.value);
                                                            }
                                                            }
                                                            label="Select Campaign">
                                                        {
                                                            allCampaigns.map((el: any) =>
                                                                <MenuItem value={el.id}
                                                                          key={el.id}> {el.name}
                                                                </MenuItem>)
                                                        }
                                                    </Select>
                                                }
                                                {allCampaigns.length == 0 &&
                                                    <>
                                                        <Select
                                                            disabled>
                                                            <MenuItem value={''}>
                                                                No Data
                                                            </MenuItem>
                                                        </Select>
                                                        <FormHelperText>
                                                            No Data
                                                        </FormHelperText>
                                                    </>
                                                }
                                            </FormControl>
                                        </div>

                                        {/* Field team ids */}
                                        <div className="col-md-6 mt-3">
                                            <FormControl fullWidth={true}>
                                                <InputLabel id="teamsIdLabel">
                                                    Select Teams
                                                </InputLabel>
                                                {allTeams.length > 0 &&
                                                    <Select labelId="teamsIdLabel"
                                                            id="teamsIdLabel"
                                                            multiple
                                                            onChange={(event) => {
                                                                setSelectedTeams(event.target.value);
                                                            }
                                                            }
                                                            value={selectedTeams}
                                                            label="Select Teams">
                                                        {
                                                            allTeams.map((el: any) =>
                                                                <MenuItem value={el.id}
                                                                          key={el.id}> {el.name}
                                                                </MenuItem>)
                                                        }
                                                    </Select>
                                                }
                                                {allTeams.length == 0 &&
                                                    <>
                                                        <Select labelId="teamsIdLabel"
                                                                label="Select Teams"
                                                                disabled>
                                                            <MenuItem value={''}>
                                                                No Data
                                                            </MenuItem>
                                                        </Select>
                                                        <FormHelperText>
                                                            No Data
                                                        </FormHelperText>
                                                    </>
                                                }
                                            </FormControl>
                                        </div>
                                    </div>
                                }


                                {/* Step 2 */}
                                {activeStep == 1 &&
                                    <div className={'row'}>
                                        {/* Other strategies for same strategy */}
                                        <div className="col-md-6">
                                            <div className="alert alert-info">
                                                <h4 className={'fw-bolder'}>
                                                    Other Strategies
                                                </h4>
                                                {loading &&
                                                    <>
                                                        <Skeleton />
                                                        <Skeleton />
                                                        <Skeleton />
                                                        <Skeleton />
                                                    </>
                                                }
                                                {!loading &&
                                                    <p style={{whiteSpace: 'pre-line'}}>
                                                        {otherStrategies}
                                                    </p>
                                                }
                                            </div>
                                        </div>

                                        {/* Selected filters and ranges in strategy */}
                                        <div className="col-md-6">
                                            <div className="alert alert-warning">
                                                <h4 className={'fw-bolder'}>
                                                    Selected Filters
                                                </h4>
                                                <p>
                                                    {getSelectedFiltersName(selectedFilters)}
                                                </p>

                                            </div>
                                        </div>

                                        <div className="col-md-12 mt-3">

                                            {/* Filters */}
                                            <div className="card p-3 mb-3 shadow-lg">
                                                <div className="row">
                                                {Object.keys(availableFilters.filter).map(_key => {
                                                    return (
                                                            <div className="col-md-3 mt-2 mb-5">
                                                                <p className={'m-0 p-0 text-muted'}>
                                                                    {availableFilters.filter[_key].displayName}
                                                                </p>
                                                                <div className="row p-0 m-0 mt-2">
                                                                    {
                                                                        availableFilters.filter[_key].options.map((_option, _optionIdx) => (
                                                                            <div className="col-md-6 p-0 m-0">
                                                                                <FormGroup>
                                                                                    <FormControlLabel control={<Checkbox/>}
                                                                                                      checked={selectedFilters.filter[_key].includes(_option)}
                                                                                                      onChange={(e) => {
                                                                                                          // get current array of selected options
                                                                                                          let options = selectedFilters.filter[_key];

                                                                                                          // select option
                                                                                                          if (e.target.checked) {
                                                                                                              // push option in array
                                                                                                              options.push(_option);
                                                                                                          } else {
                                                                                                              // unselect option
                                                                                                              options = options.filter((item) => {
                                                                                                                  return item !== _option
                                                                                                              })
                                                                                                          }

                                                                                                          // update array
                                                                                                          setSelectedFilters((prevState) => ({
                                                                                                              ...prevState,
                                                                                                              filter: {
                                                                                                                  ...prevState.filter,
                                                                                                                  [_key]: options
                                                                                                              }
                                                                                                          }));

                                                                                                      }}
                                                                                                      label={availableFilters.filter[_key].optionLabel ? availableFilters.filter[_key].optionLabel[_optionIdx] : _option}/>
                                                                                </FormGroup>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                    )
                                                })
                                                }
                                                </div>
                                            </div>


                                            {/* Ranges */}
                                            {Object.keys(availableFilters.range).map(_key => {
                                                return (
                                                    <div className="col-md-3 mt-2">
                                                        <div className={'card p-3 shadow-lg'}>
                                                            <h5 className={''}>
                                                                {availableFilters.range[_key].displayName}
                                                            </h5>

                                                            <div className="row mt-2">
                                                                <div className="col-md-6">
                                                                    <TextField id="crLimitMin"
                                                                               type={'number'}
                                                                               fullWidth
                                                                               value={selectedFilters.range[_key].min}
                                                                               onChange={(_e) => {
                                                                                   setSelectedFilters((prevState) => ({
                                                                                       ...prevState,
                                                                                       range: {
                                                                                           ...prevState.range,
                                                                                           [_key]: {
                                                                                               ...prevState.range[_key],
                                                                                               min: parseFloat(_e.target.value)
                                                                                           }
                                                                                       }
                                                                                   }));
                                                                               }}
                                                                               label="Min"
                                                                               variant="outlined"/>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <TextField id="crLimitMax"
                                                                               fullWidth
                                                                               value={selectedFilters.range[_key].max}
                                                                               type={'number'}
                                                                               onChange={(_e) => {
                                                                                   setSelectedFilters((prevState) => ({
                                                                                       ...prevState,
                                                                                       range: {
                                                                                           ...prevState.range,
                                                                                           [_key]: {
                                                                                               ...prevState.range[_key],
                                                                                               max: parseFloat(_e.target.value)
                                                                                           }
                                                                                       }
                                                                                   }));
                                                                               }}
                                                                               label="Max"
                                                                               variant="outlined"/>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                );
                                            })
                                            }


                                        </div>

                                    </div>
                                }

                            </div>

                        </div>
                    </CardContent>


                    {/* Footer */}
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{width: '100%', textAlign: 'right'}}>

                            <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                                {/* Cancel Button */}
                                {activeStep === 0 &&
                                    <Button variant="outlined"
                                            onClick={onCancel}>
                                        {t('common.cancel')}
                                    </Button>
                                }

                                {/* Back Button */}
                                {activeStep !== 0 &&
                                    <Button variant="outlined"
                                            onClick={handleBackStep}>
                                        {t('common.back')}
                                    </Button>
                                }

                                <Box sx={{flex: '1 1 auto'}}/>

                                {/* Next Button */}
                                {activeStep !== steps.length - 1 &&
                                    <LoadingButton onClick={handleNextStep}
                                                   variant="contained"
                                                   className="bg-custom-gradient">
                                        Next
                                    </LoadingButton>
                                }

                                {/* Okay Button */}
                                {activeStep === steps.length - 1 &&
                                    <LoadingButton loading={isLoading}
                                                   type="submit"
                                                   variant="contained"
                                                   className="bg-custom-gradient">
                                        {isEditMode ? t('common.edit') : t('common.add')}
                                    </LoadingButton>
                                }
                            </Box>
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default StrategyForm;
