// @ts-nocheck

import React, {useEffect, useState, useRef} from 'react';
import {useStyles} from './Styles';
import '../../../../../../index.scss';
import Header from "../../../../../Layout/Header/Header";
import Sider from "../../../../../Layout/Sider/Sider";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {getAll, getById, post, patch, deleteById} from "../../../../../../Services/GenericApiService";
import {formatData} from "../../../../../../Services/DataFormatService";
import {useTranslation} from "react-i18next";
import AlertM from '../../../../../Helpers/AlertM/AlertM';
import DebtordetailCreate from "../DebtordetailCreate/DebtordetailCreate";
import DebtordetailUpdate from "../DebtordetailUpdate/DebtordetailUpdate";
import DebtordetailView from "../DebtordetailView/DebtordetailView";
import {log} from "../../../../../../Services/LoggerService";
import {getUserData} from "../../../../../../Services/LocalStorageService";
import TextDisplayM from "../../../../../Helpers/TextDisplayM/TextDisplayM";
import {FormControl, TextField, FormControlLabel, Checkbox, FormHelperText} from "@mui/material";
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import InputLabel from '@mui/material/InputLabel';
import FormGroup from '@mui/material/FormGroup';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MUIDataTable from "mui-datatables";
import {Button, IconButton, Skeleton} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {library} from '@fortawesome/fontawesome-svg-core';
import Tooltip from '@mui/material/Tooltip';
import ConfirmationDialog from "../../../../../Helpers/ConfirmationDialog/ConfirmationDialog";
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import PieChart from '../../../Chart/PieChart/PieChart';
import ScatterChart from '../../../Chart/ScatterChart/ScatterChart';
import LoadingButton from '@mui/lab/LoadingButton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Alert from '@mui/material/Alert';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function useForceUpdate() {
    const [value, setValue] = React.useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
    // An function that increment 👆🏻 the previous state like here
    // is better than directly setting `value + 1`
}

function DebtordetailPreTerminationFiltering({showRecordsForAllStatus = false}) {

    // get user data from jwt
    const userData = getUserData();

    const serverUrl = process.env.REACT_APP_SERVER;

    const leadRoleId = process.env.REACT_APP_LEAD_ROLE_ID;
    const managerRoleId = process.env.REACT_APP_MANAGER_ROLE_ID;

    let userRoleToAccountStatusMap = {}
    userRoleToAccountStatusMap[leadRoleId] = 2;     // lead: 2 (scored)
    userRoleToAccountStatusMap[managerRoleId] = 3;      // manager: 3 (pending)

    const accountStatusLabelToIdMap = JSON.parse(process.env.REACT_APP_ACCOUNT_STATUS_NAME_TO_ID_MAP);

    // css for module
    const classes = useStyles();

    // force update method
    const forceUpdate = useForceUpdate();

    // translation
    const {t} = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // get theme from redux
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );

    // module(s) for api
    const moduleMain = 'debtordetail/';
    const moduleMainSubModule = 'status/';
    const moduleBookDetails = 'bookdetail/';
    const moduleExportPreterminationAccounts = 'export/pretermination';

    // filter object
    const availableFilters = {
        "range": {
            "ageDays": {
                "displayName": "Age Days"
            },
            "accountBalance": {
                "displayName": "Account Balance"
            },
            "availCreditAmt": {
                "displayName": "Available Credit Limit"
            },
            "collectabilityScore": {
                "displayName": "Collectability Score"
            }
        },
        "filter": {
            "migrationFlag": {
                "displayName": "Migration Flag",
                "options": ["Y", "N"]
            },
            "openBillQuery": {
                "displayName": "Open Bill Query",
                "options": ["Y", "N"]
            },
            "openDispute": {
                "displayName": "Open Dispute",
                "options": ["Y", "N"]
            },
            "simOnlyFlag": {
                "displayName": "SIM Only Flag",
                "options": ["Y", "N"]
            },
            "currTreatmentLvl": {
                "displayName": "Current Treatment Level",
                "options": ["Account Lock", "Change Payment Method to Cash", "Change to Legal", "Change to Pre-Legal", "Hard Suspend", "Letter", "SMS", "Soft Suspend Out", ""]
            },
            "customerType": {
                "displayName": "Customer Type",
                "options": ["Mtn Staff Non Payroll", "Onbiller", "Retail Consumer", "Retail Consumer M2m", "Retail Employee"]
            },
            "collectionsPriority": {
                "displayName": "Collections Priority",
                "options": ["A 932 - 999", "B 891 - 931", "C 847 - 890", "D 793 - 846", "E 734 - 792", "F 662 - 733", "G 581 - 661", "H 482 - 580", "I 368 - 481", "J 0 - 367", "X Debt Review", "X Deceased", "X Notices", "X No Trades", null],
                "optionLabel": ["A 932 - 999", "B 891 - 931", "C 847 - 890", "D 793 - 846", "E 734 - 792", "F 662 - 733", "G 581 - 661", "H 482 - 580", "I 368 - 481", "J 0 - 367", "X Debt Review", "X Deceased", "X Notices", "X No Trades", 'N/A']

            },
            "legalStatus": {
                "displayName": "Legal Status",
                "options": ["Legal", "Pre-legal", "Standard", "New", ""],
                "optionLabel": ["Legal", "Pre-legal", "Standard", "New", "N/A"]
            },
            "billPaymentMethod": {
                "displayName": "Bill Payment Method",
                "options": ["Cash", "Debit Order", "Credit Card"]
            },
            "serviceTypeNm": {
                "displayName": "Service Type NM",
                "options": ["Dual Call Service", "eSIM", "GSM"]
            },
            "accountStatusId": {
                "displayName": "Account Status",
                "options": Object.values(accountStatusLabelToIdMap),
                "optionLabel": Object.keys(accountStatusLabelToIdMap)
            },
            "category": {
                "displayName": "Category",
                "options": ["Base Invol", "Never Paid", "Potential Never Paid", ""],
                "optionLabel": ["Base Invol", "Never Paid", "Potential Never Paid", "N/A"]
            },
        }
    }

    // data vars
    const [allBookDetails, setAllBookDetails] = React.useState([]);
    const [selectedBookDetails, setSelectedBookDetails] = React.useState({});
    const [chartDetails, setChartDetails] = React.useState({});
    const [allData, setAllData] = React.useState([]);       // all data
    const [managerViewRecordsToggle, setManagerViewRecordsToggle] = React.useState('pending');    // toggle to view all records or pending records
    const [dataToEdit, setDataToEdit] = React.useState({});
    const [tableData, setTableData] = React.useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [exportUrl, setExportUrl] = React.useState('');
    const [selectedFilters, setSelectedFilters] = React.useState({
        "bookId": null,
        "range": {
            "ageDays": {
                "min": null,
                "max": null
            },
            "accountBalance": {
                "min": null,
                "max": null
            },
            "availCreditAmt": {
                "min": null,
                "max": null
            },
            "collectabilityScore": {
                "min": null,
                "max": null
            }
        },
        "filter": {
            "migrationFlag": [],
            "legalStatus": [],
            "billPaymentMethod": [],
            "serviceTypeNm": [],
            "currTreatmentLvl": [],
            "customerType": [],
            "openBillQuery": [],
            "openDispute": [],
            "simOnlyFlag": [],
            "collectionsPriority": [],
            "affordabilityBands": [],
            "numberOfMsisdn": [],
            "lastExclusionDate": [],
            "numberOfExclusions": [],
            "accountStatusId": managerViewRecordsToggle == 'pending' && userData.roleId == managerRoleId ? [accountStatusLabelToIdMap['Pending']] : [],
            "category": []
        }
    });


    let columns = [
        {name: "Account Name", options: {filter: false}},
        {name: "ID Passport Number", options: {filter: false}},
        {name: "Available Credit Amount (ZAR)", options: {filter: false}},
        {name: "Legal Status", options: {filterOptions: {fullWidth: false}}},
        {name: "Migration Flag", options: {filterOptions: {fullWidth: false}}},
        {name: "Bill Payment Method", options: {filterOptions: {fullWidth: false}}},
        {name: "Service Status NM", options: {filterOptions: {fullWidth: false}}},
        {name: "Account Balance (ZAR)", options: {filter: false}},
        {name: "Age Days", options: {filter: false}},
        {name: "Current Treatment Level", options: {filterOptions: {fullWidth: false}}},
        {name: "Customer Type", options: {filterOptions: {fullWidth: false}}},
        {name: "Open Bill Query", options: {filterOptions: {fullWidth: false}}},
        {name: "Open Dispute", options: {filterOptions: {fullWidth: false}}},
        {name: "SIM Only Flag", options: {filterOptions: {fullWidth: false}}},
        {name: "Collection Score", options: {filter: false}},
        {name: "Affordability Bands", options: {filterOptions: {fullWidth: false}}},
        {name: "Collections Priority", options: {filterOptions: {fullWidth: false}}},
        {name: "Number Of MSISDNs", options: {filterOptions: {fullWidth: false}}},
        {name: "Last Exclusion Date", options: {filterOptions: {fullWidth: false}}},
        {name: "Number Of Exclusions", options: {filterOptions: {fullWidth: false}}},
        {name: "Account Status", options: {filterOptions: {fullWidth: false}}},
        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                setCellHeaderProps: () => {
                    return {align: "right"}
                },
                setCellProps: () => ({
                    align: "right"
                }),
                customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
                    return (
                        <div className='d-inline-flex'>
                            <div className="px-2">
                                <DebtordetailView data={allData[rowIndex]}/>
                            </div>
                        </div>
                    )
                        ;
                }
            }
        }
    ];

    // ui controls
    const [loading, setLoading] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);
    const [isExcludedStatusSelected, setIsExcludedStatusSelected] = React.useState({
        isVisible: false,
        isEnable: false
    });

    // ui handlers
    const onSelectBookChange = (event: any) => {
        let book = allBookDetails.filter(_rec => _rec.id == event.target.value);
        if (book.length > 0) {
            setSelectedBookDetails(book[0]);

            // set in filter
            setSelectedFilters((prevState) => ({
                ...prevState,
                bookId: book[0].id
            }));
        }
    }

    // table ui controls
    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("auto");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [searchBtn, setSearchBtn] = useState(true);
    const [downloadBtn, setDownloadBtn] = useState(true);
    const [printBtn, setPrintBtn] = useState(true);
    const [viewColumnBtn, setViewColumnBtn] = useState(true);
    const [filterBtn, setFilterBtn] = useState(true);


    useEffect(() => {
        // if (showRecordsForAllStatus) {
        //     fetchBookDetails();
        // } else {
        //     fetch();
        // }
        fetchBookDetails();
    }, [])


    useEffect(() => {
        fetch();
    }, [selectedBookDetails, currentPage])


    useEffect(() => {
        if (userData.roleId == managerRoleId && managerViewRecordsToggle != '') {
            fetch();
        }
    }, [selectedFilters])

    // show/hide undo exclusion button
    useEffect(() => {
        // if account status = excluded is selected
        if (selectedFilters.filter.accountStatusId.includes(accountStatusLabelToIdMap['Excluded'])) {
            setIsExcludedStatusSelected({
                isVisible: true,
                isEnable: false // enable button when apply filters button is clicked (data is fetched based on filters)
            });
        } else {
            setIsExcludedStatusSelected({
                isVisible: false,
                isEnable: false     // enable button when apply filters button is clicked (data is fetched based on filters)
            });
        }
    }, [selectedFilters])


    const fetchBookDetails = () => {
        getAll(moduleBookDetails + '?isCollectionsBook=false')
            .then((_res: any) => {
                setAllBookDetails(_res)
            })
            .catch(_err => {
                log(_err)
            })
    }

    // fetch dependent data
    const fetch = () => {
        // reset
        setTableData([]);
        setAllData([]);

        // enable undo exlcusion btn (if visible) bec now fresh data is fetched
        setIsExcludedStatusSelected((prevState) => ({
            ...prevState,
            isEnable: true
        }));


        let url = moduleMain;

        let noBookSelectedFlag = false;

        // get records with status allowed for this role
        // if (!showRecordsForAllStatus) {
        //     url += moduleMainSubModule + userRoleToAccountStatusMap[userData.roleId];
        // } else {
        //     if (selectedBookDetails.id != undefined) {
        //         url += 'book/' + selectedBookDetails.id;
        //     } else {
        //         noBookSelectedFlag = true;
        //     }
        // }

        if (selectedBookDetails.id != undefined) {
            url += 'book/' + selectedBookDetails.id;
        } else {
            noBookSelectedFlag = true;
        }

        // get only pending accounts for manager
        // if(userData.roleId == managerRoleId){
        //     setSelectedFilters((prevState) => ({
        //         ...prevState,
        //         filter: {
        //             ...prevState.filter,
        //             accountStatusId: [userRoleToAccountStatusMap[userData.roleId]]
        //         }
        //     }));
        // }

        if (!noBookSelectedFlag) {
            setLoading(true); // show loader
            post(url, selectedFilters, currentPage, pageSize)
                .then((_res: any) => {
                    // set total count
                    setTotalRowCount(_res.count);
                    setAllData(_res.rows);
                    setChartDetails(_res.chartDetails);

                    const rows: any = [];
                    _res.rows.forEach((_record: any) => {
                        rows.push(formatData([_record?.accountName, _record?.idPassportNumber, _record?.availableCreditAmount, _record?.legalStatus, _record?.migrationFlag, _record?.billPaymentMethod, _record?.serviceStatusNm, _record?.accountBalance, _record?.ageDays, _record?.currentTreatmentLevel, _record?.customerType, _record?.openBillQuery, _record?.openDispute, _record?.simOnlyFlag, _record?.collectabilityScore, _record?.affordabilityBands, _record?.collectionsPriority, _record?.numberOfMsisdn, _record?.lastExclusionDate, _record?.numberOfExclusions, _record?.debtorAccountStatus?.name])); // need to create table attributes and pass in the value
                    });
                    setTableData(rows);

                    setLoading(false); // hide loader
                })
                .catch(_err => {
                    log(_err)
                    setLoading(false); // hide loader
                })
        }
    }


    // event handlers
    const onEditClick = (_dataIndex: any, _rowIndex: any) => {
        setDataToEdit(allData[_dataIndex]);
        setEditMode(true);
    }

    const resetEdit = (_state) => {
        setEditMode(false);
    }

    const handleManagerViewData = async (
        event: React.MouseEvent<HTMLElement>,
        accountsStatus: string,
    ) => {
        if (accountsStatus != null) {
            setManagerViewRecordsToggle(accountsStatus);

            // update filters
            // let accountStatusIdArr = accountsStatus == 'all' ? [] : [ accountStatusLabelToIdMap['Pending'] ];
            setSelectedFilters((prevState) => ({
                ...prevState,
                filter: {
                    ...prevState.filter,
                    accountStatusId: accountsStatus == 'all' ? [] : [accountStatusLabelToIdMap['Pending']]
                }
            }));
        }
    };

    const handleDelete = (_dataItemIndex: number) => {
        setLoading(true);   // show spinner
        deleteById(moduleMain, allData[_dataItemIndex].id)
            .then(async (_res) => {
                setLoading(false);  // hide loader
                // @ts-ignore
                notifications.current.successAlert(t('common.deleteSuccess'), '');
                fetch();    // refresh data
            })
            .catch(_err => {
                log(_err)
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                setLoading(false); // hide loader
            })
    }

    const handleSubmitForReview = () => {
        setLoading(true);   // Set Loading

        // post data
        post(moduleMain + moduleMainSubModule + 'pending', selectedFilters)
            .then((_res: any) => {
                fetch();
                // @ts-ignore
                notifications.current.successAlert('Records Submitted', 'Records Submitted For Review');
                //setLoading(false);   // loading is set false in fetch
            })
            .catch((_err: any) => {
                log(_err);
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                setLoading(false);   // loading is set false in fetch
            })
    }

    const handleSubmitForApprove = () => {
        setLoading(true);   // Set Loading

        // post data
        post(moduleMain + moduleMainSubModule + 'approve', selectedFilters)
            .then((_res: any) => {
                fetch();
                // @ts-ignore
                notifications.current.successAlert('Records Submitted', 'Records Submitted For Review');
                //setLoading(false);   // loading is set false in fetch
            })
            .catch((_err: any) => {
                log(_err);
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                setLoading(false);   // loading is set false in fetch
            })
    }

    const handleSubmitForReject = () => {
        setLoading(true);   // Set Loading

        // post data
        post(moduleMain + moduleMainSubModule + 'reject', selectedFilters)
            .then((_res: any) => {
                fetch();
                // @ts-ignore
                notifications.current.successAlert('Records Rejected', 'Records Were Rejected');
                //setLoading(false);   // loading is set false in fetch
            })
            .catch((_err: any) => {
                log(_err);
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                setLoading(false);   // loading is set false in fetch
            })
    }

    const handleSubmitForUndoExclusion = () => {
        setLoading(true);   // Set Loading

        // post data
        post(moduleMain + moduleMainSubModule + 'undoExclusion', selectedFilters)
            .then((_res: any) => {
                fetch();
                // @ts-ignore
                notifications.current.successAlert('Exclusion Reverted', 'Records Were Removed From Exclusion List');
                //setLoading(false);   // loading is set false in fetch
            })
            .catch((_err: any) => {
                log(_err);
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                setLoading(false);   // loading is set false in fetch
            })
    }

    const handleExportData = () => {
        setLoading(true);   // Set Loading

        // post filters
        post(moduleMain + moduleExportPreterminationAccounts, selectedFilters)
            .then((_res: any) => {
                setExportUrl(_res.url)
                // window.open(_res.url, '_blank');
                // @ts-ignore
                // notifications.current.successAlert('Records Rejected', 'Records Were Rejected');
                setLoading(false);
            })
            .catch((_err: any) => {
                log(_err);
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                setLoading(false);   // loading is set false in fetch
            })
    }


    let options = {
        search: false,
        download: true,
        print: true,
        viewColumns: true,
        filter: false,
        filterType: "checkbox",
        responsive: "scroll",
        resizableColumns: true,
        rowsPerPage: 25,
        rowsPerPageOptions: [25, 50, 75, 100],
        onChangePage: (currentPage) => {
            setCurrentPage(currentPage);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            setPageSize(numberOfRows);
        },
        onDownload: () => {
            if (!loading) {
                handleExportData();
            }
            // return false to avoid datatable download
            return false;
        },
        page: currentPage,
        count: totalRowCount,
        serverSide: true,
        loading: true,
        tableBodyHeight: tableBodyHeight,
        // tableBodyMaxHeight,
        selectableRowsHideCheckboxes: true,
        sort: true,
        textLabels: {
            body: {
                noMatch: loading ?
                    <Box>
                        <Skeleton className="my-4"/>
                        <Skeleton className="my-4"/>
                        <Skeleton className="my-4"/>
                    </Box> :
                    'Sorry, there is no matching data to display',
            },
        },
        onTableChange: (action: any, state: any) => {
        },
    };

    if (allData.length === 0) {
        options = {
            textLabels: {
                body: {
                    noMatch: loading ?
                        <Box>
                            <Skeleton className="my-4"/>
                            <Skeleton className="my-4"/>
                            <Skeleton className="my-4"/>
                        </Box> :
                        'Sorry, there is no matching data to display',
                },
            },
        };
    }

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>

            {/* Header */}
            <Header/>

            {/* Sider */}
            <Sider/>

            {/* Content */}
            <Box component="main" sx={{flexGrow: 2, p: 3}} mt={10}>
                <div className="px-1 px-md-5">

                    {/* Upper Section Buttons */}
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="mt-3">
                                {!showRecordsForAllStatus &&
                                    'Pre Termination Filtering'
                                }
                                {showRecordsForAllStatus &&
                                    'Pre Termination Books'
                                }
                            </h2>
                        </div>

                        {allData.length > 0 && !showRecordsForAllStatus &&
                            <>
                                {/* Team lead to upload */}
                                {userData.roleId == 1 &&
                                    <div className="col-md-4">
                                        <ConfirmationDialog title={'Confirm submission'}
                                                            body={'Are you sure you want to submit selected records?'}
                                                            type={'confirm'}
                                                            isLoading={loading}
                                                            showButton={true}
                                                            buttonText={'Submit Filtered Accounts For Review'}
                                                            confirmEvent={handleSubmitForReview}/>

                                    </div>
                                }

                                {/* Manager to approve or reject */}
                                {userData.roleId == 2 &&
                                    <>
                                        <div className="col-md-2">
                                            <ConfirmationDialog title={'Confirm Approval'}
                                                                body={'Are you sure you want to approve records?'}
                                                                type={'accept'}
                                                                isLoading={loading}
                                                                showButton={true}
                                                                buttonText={'Approve'}
                                                                confirmEvent={handleSubmitForApprove}/>

                                        </div>

                                        <div className="col-md-2">
                                            <ConfirmationDialog title={'Confirm Rejection'}
                                                                body={'Are you sure you want to reject records?'}
                                                                type={'reject'}
                                                                isLoading={loading}
                                                                showButton={true}
                                                                buttonText={'Reject'}
                                                                confirmEvent={handleSubmitForReject}/>

                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>


                    {/* Select Book (For View Books Only */}
                    {/*{showRecordsForAllStatus &&*/}
                    <div className="row">
                        <div className={(theme == 'dark' ? 'bg-dark' : '') + " container card shadow-lg p-3 mt-5 ms-0"}>
                            <div className="row">

                                {/* Book Select */}
                                <div className="col-12">
                                    <h4 className={'fw-bolder'}>
                                        Select Book To View Accounts
                                    </h4>
                                    <div className={'py-3 mt-2'}>
                                        <FormControl fullWidth={true}>
                                            <InputLabel id="bookIdLabel">
                                                Select Book
                                            </InputLabel>
                                            {allBookDetails.length > 0 &&
                                                <Select labelId="bookIdLabel"
                                                        id="bookIdSelect"
                                                        onChange={(event: any) => {
                                                            onSelectBookChange(event);
                                                        }}
                                                        value={selectedBookDetails.id}
                                                        label="Select Book">
                                                    {
                                                        allBookDetails.map((el: any) =>
                                                            <MenuItem value={el.id}
                                                                      key={el.id}> {el.bookReference}
                                                            </MenuItem>)
                                                    }
                                                </Select>
                                            }
                                            {allBookDetails.length == 0 &&
                                                <>
                                                    <Select
                                                        label="Select Book"
                                                        disabled>
                                                        <MenuItem value={''}>
                                                            No Data
                                                        </MenuItem>
                                                    </Select>
                                                    <FormHelperText>
                                                        No Data
                                                    </FormHelperText>
                                                </>
                                            }
                                        </FormControl>
                                    </div>
                                </div>


                                {Object.keys(selectedBookDetails).length > 0 &&
                                    <>
                                        {/* Book Details */}
                                        <div className="col-9 mt-5">
                                            <h4 className={'fw-bolder mt-5'}>
                                                Book Details
                                            </h4>
                                            <div className="row">
                                                {/* Book Name */}
                                                <div className="col-md-6">
                                                    <div className="mt-4">
                                                        <h6 className={'text-muted'}>
                                                            Book Name
                                                        </h6>
                                                        <h5 className={'text-break'}>
                                                            {selectedBookDetails.bookReference}
                                                        </h5>
                                                    </div>
                                                </div>

                                                {/* Book Date */}
                                                <div className="col-md-6">
                                                    <div className="mt-4">
                                                        <h6 className={'text-muted'}>
                                                            Upload Date
                                                        </h6>
                                                        <h5>
                                                            {new Date(selectedBookDetails.date).toString()}
                                                        </h5>
                                                    </div>
                                                </div>

                                                {/*  Book Path  */}
                                                <div className="col-md-6">
                                                    <div className="mt-4">
                                                        <h6 className={'text-muted'}>
                                                            View Book
                                                        </h6>
                                                        <a href={serverUrl + selectedBookDetails.filePath}
                                                           target={'_blank'}>
                                                            <h5>
                                                                Download Book &nbsp;
                                                                <FontAwesomeIcon icon='arrow-up-right-from-square'/>
                                                            </h5>
                                                        </a>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>


                                        {!loading &&
                                            <div className="col-md-3 mx-auto">
                                                <PieChart labels={chartDetails?.accountStatusAccountsCount?.labels}
                                                          title={chartDetails?.accountStatusAccountsCount?.datasets[0].label}
                                                          dataset={chartDetails?.accountStatusAccountsCount?.datasets[0].data}/>
                                            </div>
                                        }

                                        {loading &&
                                            <div className="col-md-3 mx-auto">
                                                <Skeleton className={'mb-2'} width={380} height={20}/>
                                                <Skeleton key={'piechart'} variant="circular" width={380} height={380}/>
                                            </div>
                                        }

                                    </>
                                }

                            </div>
                        </div>
                    </div>
                    {/* } */}

                    {userData.roleId == managerRoleId &&
                        <div className="d-flex flex-row-reverse mt-5">
                            {loading &&
                                <Skeleton height={80} width={350}/>
                            }
                            {!loading &&
                                <ToggleButtonGroup
                                    color="primary"
                                    value={managerViewRecordsToggle}
                                    exclusive
                                    onChange={handleManagerViewData}
                                    aria-label="Platform"
                                >
                                    <ToggleButton value="all">View All Accounts</ToggleButton>
                                    <ToggleButton value="pending">View Pending Accounts</ToggleButton>
                                </ToggleButtonGroup>
                            }
                        </div>

                    }

                    {/* Filter Ranges and Data Count Row and Charts */}
                    <div className="row">

                        {/*<div className="mb-5 row">*/}
                        {/*    <div className="col-md-6">*/}
                        {/*        /!*{ageBreachAmountScatterChartData.length > 0 && !loading &&*!/*/}
                        {/*        /!*    <div className="card shadow-lg p-3">*!/*/}
                        {/*        /!*        <ScatterChart dataset={ageBreachAmountScatterChartData}*!/*/}
                        {/*        /!*                      xAxisLabel={'Breach Amount (ZAR)'}*!/*/}
                        {/*        /!*                      yAxisLabel={'Age (Days)'}*!/*/}
                        {/*        /!*                      labels={'Age VS Breach Amount Distribution'}/>*!/*/}
                        {/*        /!*    </div>*!/*/}

                        {/*        /!*}*!/*/}
                        {/*        /!*{ageBreachAmountScatterChartData.length == 0 && loading &&*!/*/}
                        {/*        /!*    <div className="card shadow-lg p-3">*!/*/}
                        {/*        /!*        <Skeleton height={420}/>*!/*/}
                        {/*        /!*    </div>*!/*/}
                        {/*        /!*}*!/*/}
                        {/*    </div>*/}

                        {/*    <div className="col-md-6">*/}
                        {/*        /!*{collectionScoreAccountBalanceScatterChartData.length > 0 && !loading &&*!/*/}
                        {/*        /!*    <div className="card shadow-lg p-3">*!/*/}
                        {/*        /!*        <ScatterChart dataset={collectionScoreAccountBalanceScatterChartData}*!/*/}
                        {/*        /!*                      yAxisLabel={'Collection Score'}*!/*/}
                        {/*        /!*                      xAxisLabel={'Account Balance (ZAR)'}*!/*/}
                        {/*        /!*                      labels={'Collection Score VS Account Balance Distribution'}/>*!/*/}
                        {/*        /!*    </div>*!/*/}

                        {/*        /!*}*!/*/}
                        {/*        /!*{collectionScoreAccountBalanceScatterChartData.length == 0 && loading &&*!/*/}
                        {/*        /!*    <div className="card shadow-lg p-3">*!/*/}
                        {/*        /!*        <Skeleton height={420}/>*!/*/}
                        {/*        /!*    </div>*!/*/}
                        {/*        /!*}*!/*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className="col-md-12 mb-2 mt-5">
                            <h4 className={'fw-bolder'}>
                                Totals
                            </h4>
                        </div>

                        {/* Total Count */}
                        <div className="col-md-3">
                            <TextDisplayM label={'Total Records'}
                                          data={parseFloat(chartDetails?.totalAccounts || 0).toLocaleString('en-US', {maximumFractionDigits: 2})}
                                          isLoading={loading}/>
                        </div>

                        {/* Filtered Count */}
                        <div className="col-md-3">
                            <TextDisplayM
                                data={parseFloat(totalRowCount || 0).toLocaleString('en-US', {maximumFractionDigits: 2})}
                                label={'MSISDN Numbers Total'}
                                isLoading={loading}/>
                        </div>

                        {/* Total Unique Accounts Count */}
                        <div className="col-md-3">
                            <TextDisplayM label={'Total Unique Accounts'}
                                          data={parseFloat(chartDetails?.uniqueAccounts || 0).toLocaleString('en-US', {maximumFractionDigits: 2})}
                                          isLoading={loading}/>
                        </div>

                        {/* Total Account Balance */}
                        <div className="col-md-3">
                            <TextDisplayM
                                data={parseFloat(chartDetails?.totalBreachAmount || 0).toLocaleString('en-US', {maximumFractionDigits: 2})}
                                label={'Total Breach Amount (ZAR)'}
                                isLoading={loading}
                                background={'custom-secondary'}/>
                        </div>


                        {userData.roleId == 1 && !showRecordsForAllStatus &&
                            <div className={'row'}>
                                {/* Filters */}
                                <div className="col-md-12 mt-5">
                                    <div className="row mb-3">
                                        <div className="col-md-8">
                                            <h4 className={'mt-3 fw-bolder'}>
                                                Filters
                                            </h4>
                                        </div>

                                        <div className="col-md-2">
                                            {isExcludedStatusSelected.isVisible &&
                                                <ConfirmationDialog title={'Undo Exclusion'}
                                                                    body={'Are you sure you want to remove selected records from exclusion list?'}
                                                                    type={'confirm'}
                                                                    isLoading={loading}
                                                                    showButton={true}
                                                                    disabled={!isExcludedStatusSelected.isEnable}
                                                                    customButtonUI={'bg-custom-gradient'}
                                                                    buttonText={'Undo Exclusion'}
                                                                    confirmEvent={handleSubmitForUndoExclusion}/>

                                            }
                                        </div>

                                        <div className="col-md-2">
                                            <LoadingButton variant="contained"
                                                           loading={loading}
                                                           loadingPosition="start"
                                                           fullWidth
                                                           onClick={fetch}>
                                                Apply Filters
                                            </LoadingButton>
                                        </div>

                                    </div>

                                    <div className={(theme == 'dark' ? 'bg-dark' : '') + " card shadow-lg p-3 ms-0"}>
                                        <div className="row mt-2 px-2">
                                            {Object.keys(availableFilters.filter).map(_key => {
                                                return (
                                                    <div className="col-md-3 mt-2 mb-5">
                                                        <p className={'m-0 p-0 text-muted'}>
                                                            {availableFilters.filter[_key].displayName}
                                                        </p>
                                                        <div className="row p-0 m-0 mt-2">
                                                            {
                                                                availableFilters.filter[_key].options.map((_option, _optionIdx) => (
                                                                    <div className="col-md-6 p-0 m-0">
                                                                        <FormGroup>
                                                                            <FormControlLabel control={<Checkbox/>}
                                                                                              onChange={(e) => {
                                                                                                  // get current array of selected options
                                                                                                  let options = selectedFilters.filter[_key];

                                                                                                  // select option
                                                                                                  if (e.target.checked) {
                                                                                                      // push option in array
                                                                                                      options.push(_option);
                                                                                                  } else {
                                                                                                      // unselect option
                                                                                                      options = options.filter((item) => {
                                                                                                          return item !== _option
                                                                                                      })
                                                                                                  }

                                                                                                  // update array
                                                                                                  setSelectedFilters((prevState) => ({
                                                                                                      ...prevState,
                                                                                                      filter: {
                                                                                                          ...prevState.filter,
                                                                                                          [_key]: options
                                                                                                      }
                                                                                                  }));

                                                                                              }}
                                                                                              label={availableFilters.filter[_key].optionLabel ? availableFilters.filter[_key].optionLabel[_optionIdx] : _option}/>
                                                                        </FormGroup>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-10 mt-5">
                                    <h4 className={'fw-bolder'}>
                                        Range Filters
                                    </h4>
                                </div>

                                {Object.keys(availableFilters.range).map(_key => {
                                    return (
                                        <div className="col-md-3 mt-2">
                                            <div
                                                className={(theme == 'dark' ? 'bg-dark' : '') + " container card shadow-lg p-3 ms-0"}>
                                                <h5 className={''}>
                                                    {availableFilters.range[_key].displayName}
                                                </h5>

                                                <div className="row mt-2">
                                                    <div className="col-md-6">
                                                        <TextField id="crLimitMin"
                                                                   type={'number'}
                                                                   fullWidth
                                                                   onChange={(_e) => {
                                                                       setSelectedFilters((prevState) => ({
                                                                           ...prevState,
                                                                           range: {
                                                                               ...prevState.range,
                                                                               [_key]: {
                                                                                   ...prevState.range[_key],
                                                                                   min: parseFloat(_e.target.value)
                                                                               }
                                                                           }
                                                                       }));
                                                                   }}
                                                                   label="Min"
                                                                   variant="outlined"/>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <TextField id="crLimitMax"
                                                                   fullWidth
                                                                   type={'number'}
                                                                   onChange={(_e) => {
                                                                       setSelectedFilters((prevState) => ({
                                                                           ...prevState,
                                                                           range: {
                                                                               ...prevState.range,
                                                                               [_key]: {
                                                                                   ...prevState.range[_key],
                                                                                   max: parseFloat(_e.target.value)
                                                                               }
                                                                           }
                                                                       }));
                                                                   }}
                                                                   label="Max"
                                                                   variant="outlined"/>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    );
                                })

                                }

                            </div>
                        }

                    </div>

                    {exportUrl.length > 0 &&
                        <div className="row px-2 mt-5">
                            <>

                                <Alert severity="info">The export process has started.
                                    The file can be downloaded by visiting <a href={exportUrl}
                                                                              target={"_blank"}>{exportUrl}</a> once the
                                    process is completed.</Alert>

                            </>
                        </div>
                    }
                    
                    {/* Data Tables */}
                    <div className="mt-5" style={{height: '80vh', width: '90vw',}}>
                        <MUIDataTable
                            title={t('common.allRecords')}
                            data={tableData}
                            columns={columns}
                            // @ts-ignore
                            options={options}
                        />
                    </div>


                </div>

                {/* display edit modal */}
                {editMode &&
                    <div>
                        <DebtordetailUpdate refreshDataEvent={fetch}
                                            resetEditState={resetEdit}
                                            recordToUpdate={dataToEdit}/>
                    </div>
                }


                {/* Alerts */}
                <AlertM ref={notifications}/>

            </Box>


        </Box>
    );
}

export default DebtordetailPreTerminationFiltering;
