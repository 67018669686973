import React, {useEffect} from 'react';
import {useStyles} from './Styles';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import {post, getAll} from "../../../../../Services/GenericApiService";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {log} from "../../../../../Services/LoggerService";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import {Button, FormControl, TextField, FormControlLabel, Checkbox, FormHelperText} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import {DatePicker} from '@mui/lab';


// @ts-ignore
function ClaimdetailForm({handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent}) {
    // css for module
    const classes = useStyles();

    // router object
    const navigate = useNavigate();

    // module(s) for api
    const moduleMain = 'claimdetail';

    // linked components API modules (endpoints)
    const moduleDebtordetail = 'debtordetail';


    // translation
    const {t} = useTranslation();

    // UI methods
    const [loading, setLoading] = React.useState(true);

    // form data
    const {register, handleSubmit, watch, reset, setValue, formState: {errors}} = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {
        if (!isEditMode){
            handleAddEvent(_data);
        } else {
            handleEditEvent(_data);
        }
    }

    // data vars
    const [selectedLastpaymentdate, setSelectedLastpaymentdate] = React.useState<Date | null>(null);
const [selectedContractenddate, setSelectedContractenddate] = React.useState<Date | null>(null);


    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    // fetch dependent data
    const [allDebtordetail, setAllDebtordetail] = React.useState([]);



    const fetch = () => {
        fetchDebtordetail();

    }

        // fetch Debtordetail
    const fetchDebtordetail = () => {
        setLoading(true); // show loader
        getAll(moduleDebtordetail)
            .then((_res: any) => {
                setAllDebtordetail(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }



    // hooks
    useEffect(() => {
        fetch();
        if (isEditMode) {
            reset(recordToUpdate);

                        setSelectedLastpaymentdate(recordToUpdate.lastPaymentDate);
            setSelectedContractenddate(recordToUpdate.contractEndDate);

        }
    }, []);


    return (
        <Box sx={{display: 'flex'}}>
            <Card sx={{minWidth: 275}}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.claimdetail')}
                                    </h3>

                                </div>


                                
                                {/* Field arrearsToCollect */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="arrearsToCollectInput"
                                                   {...register("arrearsToCollect", {required: false})}
                                                   label={t('claimdetail.arrearsToCollect')}
                                                   type={"text"}
                                                   error={!!errors.arrearsToCollect}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field balance */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="balanceInput"
                                                   {...register("balance", {required: false})}
                                                   label={t('claimdetail.balance')}
                                                   type={"text"}
                                                   error={!!errors.balance}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field lastPaymentDate */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                     <DatePicker
                                           label={t('claimdetail.lastPaymentDate')}
                                            value={selectedLastpaymentdate}
                                            onChange={(_newValue) => {
                                                setSelectedLastpaymentdate(_newValue);
                                                setValue("lastPaymentDate", _newValue, {
                                                shouldValidate: true,
                                                shouldDirty: true
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    {...register("lastPaymentDate", {required: false})}
                                                    error={!!errors.lastPaymentDate}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </div>

                                {/* Field lastPaymentAmount */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="lastPaymentAmountInput"
                                                   {...register("lastPaymentAmount", {required: false})}
                                                   label={t('claimdetail.lastPaymentAmount')}
                                                   type={"text"}
                                                   error={!!errors.lastPaymentAmount}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field statusDescription */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="statusDescriptionInput"
                                                   {...register("statusDescription", {required: false})}
                                                   label={t('claimdetail.statusDescription')}
                                                   type={"text"}
                                                   error={!!errors.statusDescription}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field contractId */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="contractIdInput"
                                                   {...register("contractId", {required: false})}
                                                   label={t('claimdetail.contractId')}
                                                   type={"text"}
                                                   error={!!errors.contractId}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field tariff */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="tariffInput"
                                                   {...register("tariff", {required: false})}
                                                   label={t('claimdetail.tariff')}
                                                   type={"text"}
                                                   error={!!errors.tariff}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field processTypeId */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="processTypeIdInput"
                                                   {...register("processTypeId", {required: false})}
                                                   label={t('claimdetail.processTypeId')}
                                                   type={"text"}
                                                   error={!!errors.processTypeId}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field bankName */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="bankNameInput"
                                                   {...register("bankName", {required: false})}
                                                   label={t('claimdetail.bankName')}
                                                   type={"text"}
                                                   error={!!errors.bankName}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field lastFourDigitsAccountNumber */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="lastFourDigitsAccountNumberInput"
                                                   {...register("lastFourDigitsAccountNumber", {required: false})}
                                                   label={t('claimdetail.lastFourDigitsAccountNumber')}
                                                   type={"text"}
                                                   error={!!errors.lastFourDigitsAccountNumber}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field contractEndDate */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                     <DatePicker
                                           label={t('claimdetail.contractEndDate')}
                                            value={selectedContractenddate}
                                            onChange={(_newValue) => {
                                                setSelectedContractenddate(_newValue);
                                                setValue("contractEndDate", _newValue, {
                                                shouldValidate: true,
                                                shouldDirty: true
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    {...register("contractEndDate", {required: false})}
                                                    error={!!errors.contractEndDate}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </div>

                                {/* Field debtorId */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="debtorIdLabel">
                                            Debtor
                                        </InputLabel>
                                        {  allDebtordetail.length > 0 &&
                                        <Select labelId="debtorIdLabel"
                                                id="debtorIdSelect"
                                                {...register("debtorId", {required: false})}
                                                error={!!errors.debtorId}
                                                value={watch('debtorId')}
                                                label="debtorId">
                                            {
                                                allDebtordetail.map((el: any) =>
                                                    <MenuItem value={el.id}
                                                              key={el.id}> {el.name}
                                                    </MenuItem>)
                                            }
                                        </Select>
                                        }
                                         { allDebtordetail.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                        disabled
                                                        id="debtorIdSelect"
                                                        {...register("debtorId", {required: false})}
                                                        error={!!errors.debtorId}
                                                        value={watch('debtorId')}
                                                        label="debtorId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>


                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{width: '100%', textAlign: 'right'}}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                    onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                           type="submit"
                                           variant="contained"
                                           className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default ClaimdetailForm;
