import React, {useEffect} from 'react';
import {useStyles} from './Styles';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import {post, getAll} from "../../../../../Services/GenericApiService";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {log} from "../../../../../Services/LoggerService";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import {Button, FormControl, TextField, FormControlLabel, Checkbox, FormHelperText} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import {DatePicker} from '@mui/lab';


// @ts-ignore
function AccountnotepadForm({handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent}) {
    // css for module
    const classes = useStyles();

    // router object
    const navigate = useNavigate();

    // module(s) for api
    const moduleMain = 'accountnotepad';

    // linked components API modules (endpoints)
    const moduleDebtordetail = 'debtordetail';
const moduleUser = 'user';


    // translation
    const {t} = useTranslation();

    // UI methods
    const [loading, setLoading] = React.useState(true);

    // form data
    const {register, handleSubmit, watch, reset, setValue, formState: {errors}} = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {
        if (!isEditMode){
            handleAddEvent(_data);
        } else {
            handleEditEvent(_data);
        }
    }

    // data vars
    

    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    // fetch dependent data
    const [allDebtordetail, setAllDebtordetail] = React.useState([]);
const [allUser, setAllUser] = React.useState([]);



    const fetch = () => {
        fetchDebtordetail();
fetchUser();

    }

        // fetch Debtordetail
    const fetchDebtordetail = () => {
        setLoading(true); // show loader
        getAll(moduleDebtordetail)
            .then((_res: any) => {
                setAllDebtordetail(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch User
    const fetchUser = () => {
        setLoading(true); // show loader
        getAll(moduleUser)
            .then((_res: any) => {
                setAllUser(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }



    // hooks
    useEffect(() => {
        fetch();
        if (isEditMode) {
            reset(recordToUpdate);

            
        }
    }, []);


    return (
        <Box sx={{display: 'flex'}}>
            <Card sx={{minWidth: 275}}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.accountnotepad')}
                                    </h3>

                                </div>


                                
                                {/* Field notes */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="notesInput"
                                                   {...register("notes", {required: true})}
                                                   label={t('accountnotepad.notes')}
                                                   type={"text"}
                                                   error={!!errors.notes}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field debtorId */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="debtorIdLabel">
                                            Debtor
                                        </InputLabel>
                                        {  allDebtordetail.length > 0 &&
                                        <Select labelId="debtorIdLabel"
                                                id="debtorIdSelect"
                                                {...register("debtorId", {required: false})}
                                                error={!!errors.debtorId}
                                                value={watch('debtorId')}
                                                label="debtorId">
                                            {
                                                allDebtordetail.map((el: any) =>
                                                    <MenuItem value={el.id}
                                                              key={el.id}> {el.name}
                                                    </MenuItem>)
                                            }
                                        </Select>
                                        }
                                         { allDebtordetail.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                        disabled
                                                        id="debtorIdSelect"
                                                        {...register("debtorId", {required: false})}
                                                        error={!!errors.debtorId}
                                                        value={watch('debtorId')}
                                                        label="debtorId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* Field agentId */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="agentIdLabel">
                                            Agent
                                        </InputLabel>
                                        {  allUser.length > 0 &&
                                        <Select labelId="agentIdLabel"
                                                id="agentIdSelect"
                                                {...register("agentId", {required: false})}
                                                error={!!errors.agentId}
                                                value={watch('agentId')}
                                                label="agentId">
                                            {
                                                allUser.map((el: any) =>
                                                    <MenuItem value={el.id}
                                                              key={el.id}> {el.name}
                                                    </MenuItem>)
                                            }
                                        </Select>
                                        }
                                         { allUser.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                        disabled
                                                        id="agentIdSelect"
                                                        {...register("agentId", {required: false})}
                                                        error={!!errors.agentId}
                                                        value={watch('agentId')}
                                                        label="agentId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* Field event */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="eventInput"
                                                   {...register("event", {required: false})}
                                                   label={t('accountnotepad.event')}
                                                   type={"text"}
                                                   error={!!errors.event}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>


                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{width: '100%', textAlign: 'right'}}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                    onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                           type="submit"
                                           variant="contained"
                                           className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default AccountnotepadForm;
