// @ts-nocheck
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    h40: {
        height: '40vh',
        overflowY: 'auto'
    },
    h25: {
        height: '25vh',
        overflowY: 'auto'
    },
    treeItemCampaignLabel: {
        fontSize: '1.3vw!important',
    },
    treeItemStrategyLabel: {
        fontSize: '1.1vw!important'
    }
});

export { useStyles }
