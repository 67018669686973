// @ts-nocheck

import React, { useEffect, useRef } from 'react';
import { useStyles } from './Styles';
import Box from '@mui/material/Box';
import Tooltip from "@mui/material/Tooltip";
import { Button, Card, CardContent, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import CardActions from "@mui/material/CardActions";
import DataDisplayM from "../../../../Helpers/DataDisplayM/DataDisplayM";
import { log } from "../../../../../Services/LoggerService";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getUserData } from '../../../../../Services/LocalStorageService';
import { patch } from '../../../../../Services/GenericApiService';
import AlertM from '../../../../Helpers/AlertM/AlertM';
import Badge from '@mui/material/Badge';
import ConfirmationDialog from "../../../../Helpers/ConfirmationDialog/ConfirmationDialog";

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function PtpView({ data = null, showCardView = false, refreshDataEvent = null }) {

    // css for module
    const classes = useStyles();

    // child ref for alert
    const notifications = useRef();

    //api modules
    const modulePtp = 'ptp';

    const userDate = getUserData();

    // translation
    const { t } = useTranslation();

    // get theme from redux
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );

    // data vars
    const [cardColor, setCardColor] = React.useState('');
    const [cardText, setCardText] = React.useState('');

    // ui handlers
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    // handle cancel event
    const handleCancelEvent = () => {
        handleClose();
    }

    // handle honored event
    const handleHonoredEvent = () => {
        let payload = {
            debtorId: data.debtorId,
            agentId: userDate.id,
            event: "PTP",
            notes: "PTP Honored",
            isHonored: true
        }

        ptpUpdateEvent(payload);
    }

    // ptp update api
    const ptpUpdateEvent = (_payload: any) => {
        setIsLoading(true); // show loader
        patch(modulePtp, data.id, _payload)
            .then(async (_res) => {
                setIsLoading(false); // hide loader
                if (refreshDataEvent) {
                    refreshDataEvent();
                }
                handleCancelEvent();

                // @ts-ignore
                notifications.current.successAlert('Honored update successfully', '');
            })
            .catch(_err => {
                log(_err)
                setIsLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
            })
    }


    // hooks
    useEffect(() => {
        const currentDate = new Date();
        const ptpDateObj = new Date(data?.ptpDate);

        if (!data?.ptpDate) {
            //no data available
            setCardColor('warning')
            setCardText('No Date')
        } else if (
            ptpDateObj >= currentDate &&
            ptpDateObj <= new Date(currentDate.getTime() + 3 * 24 * 60 * 60 * 1000) // Check if ptpDate is within 3 days from now
        ) {
            //within 3 days
            setCardColor('info')
            setCardText('!')
        } else if (ptpDateObj < currentDate) {
            //less then today date
            setCardColor('error')
            setCardText('✗')
        }

        if (data.isHonored) {
            // honored
            setCardColor('success')
            setCardText('✓')
        }

    }, [data.isHonored]);


    return (
        <div>
            <Box sx={{ display: 'flex' }}>
                {!showCardView &&
                    <Tooltip title={t('common.view')}>
                        <IconButton aria-label="delete" size="small" onClick={handleOpen}>
                            <FontAwesomeIcon className="edit-btn" icon='eye' />
                        </IconButton>
                    </Tooltip>
                }
                {showCardView && cardColor != '' &&
                    <Badge badgeContent={cardText} color={cardColor} className={'full-width'} size="small">
                        <div className={(theme == 'dark' ? 'bg-dark' : '') + " container card shadow mb-3 ms-0 me-0"}
                            onClick={handleOpen}>
                            <div className="row ps-3">
                                <div className="col-md-6">
                                    <DataDisplayM label={'Ptm Amount'} data={data?.amount} />
                                </div>
                                <div className="col-md-6">
                                    <DataDisplayM label={'Ptp Date'} data={data?.ptpDate} />
                                </div>
                            </div>
                        </div>
                    </Badge>
                }
                {showCardView && cardColor == '' &&

                    <div className={(theme == 'dark' ? 'bg-dark' : '') + " container card shadow ms-0 me-0 mb-3"}
                        onClick={handleOpen}>
                        <div className="row ps-3">
                            <div className="col-md-6">
                                <DataDisplayM label={'Ptm Amount'} data={data?.amount} />
                            </div>
                            <div className="col-md-6">
                                <DataDisplayM label={'Ptp Date'} data={data?.ptpDate} />
                            </div>
                        </div>
                    </div>
                }
            </Box>


            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={classes.modal}>
                        <Card sx={{ minWidth: '75vw' }}>
                            <CardContent>
                                <div className="py-4 px-md-4 px-0">
                                    <div className="row">
                                        <div className="col-9">
                                            <h1 className="mb-3">{t('common.recordDetails')}</h1>
                                        </div>
                                        {showCardView && !data?.isHonored &&
                                            <div className="col-3">
                                                <div className="" >
                                                    {/* Honored Button */}
                                                    <ConfirmationDialog title={'Confirmation'}
                                                        body={'Are you sure you want to mark this PTP as honored?'}
                                                        type={'accept'}
                                                        showButton={true}
                                                        buttonText={'Honor PTP'}
                                                        isLoading={isLoading}
                                                        dataItemIdx={1}
                                                        confirmEvent={handleHonoredEvent} />
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="row full-width">

                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('ptp.ptpDate')} data={data.ptpDate} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('ptp.amount')} data={data?.amount} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('ptp.ptpMethodId')} data={data?.ptpMethod?.name} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('ptp.isRecurring')} data={data?.isRecurring} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('ptp.paymentInterval')}
                                                    data={data.paymentInterval} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('ptp.numberOfPayments')}
                                                    data={data.numberOfPayments} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('ptp.bankDetailsId')} data={data?.ptpBankDetails?.bank} />
                                            }                                        </div>

                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('ptp.isHonored')} data={data.isHonored} />
                                            }                                        </div>


                                    </div>
                                </div>
                            </CardContent>
                            <CardActions>
                                <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                                    {/* Cancel Button */}
                                    <Button variant="outlined"
                                        onClick={handleCancelEvent}>
                                        {t('common.cancel')}
                                    </Button>

                                    &nbsp;&nbsp;
                                </div>
                            </CardActions>
                        </Card>
                    </Box>
                </Fade>
            </Modal>
            {/* Alerts */}
            <AlertM ref={notifications} />
        </div>
    );
}

export default PtpView;
