// @ts-nocheck

import React, { useEffect, useRef } from 'react';
import { useStyles } from './Styles';
import '../../../../../index.scss';
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { getAll, deleteById, patch, post } from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import AlertM from '../../../../Helpers/AlertM/AlertM';
import EdcCreate from "../EdcCreate/EdcCreate";
import EdcUnallocatedView from "../EdcUnallocatedTabView/EdcUnallocatedView";
import EdcClosedView from '../EdcClosedTabView/EdcClosedView';
import { log } from "../../../../../Services/LoggerService";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { Skeleton } from "@mui/material";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import EdcTable from '../Edc Table/EdcTable';
import ConfirmationDialog from '../../../../Helpers/ConfirmationDialog/ConfirmationDialog';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function EdcMain() {
    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // module(s) for api
    const moduleMain = 'edc';
    const moduleEdcDebtorDetail = 'edcdebtordetail/';
    const modulepaymentReport = 'paymentreport/'

    // data vars
    const [allData, setAllData] = React.useState([]);

    // ui controls
    const [loading, setLoading] = React.useState(true);
    const [tabValue, setTabValue] = React.useState(0);


    // ui handlers
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleUpdateBreachAmountFromPaymentBalanceFile = () => {
        setLoading(true);
        patch(moduleEdcDebtorDetail + 'paymentbalance', '', {})
            .then(_res => {
                setLoading(false);
                // @ts-ignore
                notifications.current.successAlert('Updated Breach Amount File Export Completed', 'Account breach amounts were exported to EDCs successfully');
            })
            .catch(_err => {
                log(_err);
                setLoading(false);
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
            })
    }

    const handleExportPaymentReportFile = () => {
        setLoading(true);
        post(modulepaymentReport + 'paymentReport/export')
            .then(_res => {
                setLoading(false);
                // @ts-ignore
                notifications.current.successAlert('Payment Report File Export Completed', 'Payment reports were exported to EDCs successfully');
            })
            .catch(_err => {
                log(_err);
                setLoading(false);
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
            })
    }

    const reallocateAccountCall = () => {
        setLoading(true);
        getAll(moduleEdcDebtorDetail + 'reallocate')
            .then(_res => {
                setLoading(false);
                // @ts-ignore
                notifications.current.successAlert('Reallocation Process Completed', 'Accounts reallocated successfully');
            })
            .catch(_err => {
                log(_err);
                setLoading(false);
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
            })
    }

    const freezeAccountCall = () => {
        setLoading(true);
        getAll(moduleEdcDebtorDetail + 'freeze')
            .then(_res => {
                setLoading(false);
                // @ts-ignore
                notifications.current.successAlert('Account Freezing Process Completed', 'Accounts Freeze successfully');
            })
            .catch(_err => {
                log(_err);
                setLoading(false);
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
            })
    }

    // fetch dependent data
    const fetch = () => {
        setLoading(true); // show loader
        getAll(moduleMain, 0, 0)
            .then((_res: any) => {
                // set all data
                setAllData(_res);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    useEffect(() => {
        fetch();
    }, [])


    const handleDelete = (_dataItemIndex: number) => {
        setLoading(true);   // show spinner
        deleteById(moduleMain, allData[_dataItemIndex].id)
            .then(async (_res) => {
                setLoading(false);  // hide loader
                // @ts-ignore
                notifications.current.successAlert(t('common.deleteSuccess'), '');
                fetch();    // refresh data
            })
            .catch(_err => {
                log(_err)
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                setLoading(false); // hide loader
            })
    }


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* Header */}
            <Header />

            {/* Sider */}
            <Sider />

            {/* Content */}
            <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
                <div className="px-1 px-md-5">

                    {/* Upper Section */}
                    <div className="row">
                        <div className="col-md-2">
                            <h2 className="mt-3">
                                {t('module.edc')} Management
                            </h2>
                        </div>

                        {/* close account btn */}
                        <div className="col-md-2">
                            {!loading &&
                                <ConfirmationDialog
                                    confirmEvent={(_itemIdx) => {
                                        freezeAccountCall()
                                    }}
                                    type={'confirm'}
                                    showButton={true}
                                    title={'Freeze Accounts'}
                                    body={'Are you sure you want to freeze accounts?'}
                                    buttonText={'Freeze Accounts'} />
                            }
                            {loading &&
                                <Skeleton variant="rounded" height={60} />
                            }
                        </div>

                        {/* reallocated account btn */}
                        <div className="col-md-2">
                            {!loading &&
                                <ConfirmationDialog
                                    confirmEvent={(_itemIdx) => {
                                        reallocateAccountCall()
                                    }}
                                    type={'confirm'}
                                    showButton={true}
                                    title={'Reallocate Accounts'}
                                    body={'Are you sure you want to reallocated accounts?'}
                                    buttonText={'Reallocate Accounts'} />
                            }
                            {loading &&
                                <Skeleton variant="rounded" height={60} />
                            }
                        </div>

                        <div className="col-md-2">
                            {!loading &&
                                <ConfirmationDialog
                                    confirmEvent={(_itemIdx) => {
                                        handleUpdateBreachAmountFromPaymentBalanceFile()
                                    }}
                                    type={'confirm'}
                                    showButton={true}
                                    title={'Update Breach Amount'}
                                    body={'Are you sure you want to export updated breach amounts to EDCs?'}
                                    buttonText={'EXPORT BALANCE UPDATES'} />
                            }
                            {loading &&
                                <Skeleton variant="rounded" height={60} />
                            }
                        </div>

                        <div className="col-md-2">
                            {!loading &&
                                <ConfirmationDialog
                                    confirmEvent={(_itemIdx) => {
                                        handleExportPaymentReportFile()
                                    }}
                                    type={'confirm'}
                                    showButton={true}
                                    title={'Export Paymnet Amount'}
                                    body={'Are you sure you want to export payment amounts to EDCs?'}
                                    buttonText={'EXPORT PAYMENT UPDATES'} />
                            }
                            {loading &&
                                <Skeleton variant="rounded" height={60} />
                            }
                        </div>

                        <div className="col-md-2">
                            {!loading &&
                                <EdcCreate refreshDataEvent={fetch} />
                            }
                            {loading &&
                                <Skeleton variant="rounded" height={60} />
                            }
                        </div>
                    </div>

                    {/* Tabs */}
                    {!loading &&
                        <div className="mt-3">
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                                        {
                                            allData.map((_edc, _idx) => <Tab label={_edc.name} {...a11yProps(_idx)} />)
                                        }
                                        <Tab label="Unallocated Accounts" {...a11yProps(allData.legth + 1)} />
                                        <Tab label="Closed Accounts" {...a11yProps(allData.legth + 2)} />
                                    </Tabs>
                                </Box>
                                {
                                    allData.map((_edc, _idx) => <CustomTabPanel value={tabValue} index={_idx}>
                                        {/* <EdcTabView edcData={_edc} refreshDataEvent={fetch}/> */}

                                        {/* EDC TABLE */}
                                        <EdcTable edcData={_edc} refreshDataEvent={fetch} />
                                    </CustomTabPanel>)
                                }

                                <CustomTabPanel value={tabValue} index={allData.length}>
                                    <EdcUnallocatedView />
                                </CustomTabPanel>
                                <CustomTabPanel value={tabValue} index={allData.length + 1}>
                                    <EdcClosedView />
                                </CustomTabPanel>
                            </Box>
                        </div>
                    }

                    {loading &&
                        <div className="mt-5">
                            <Skeleton key={'piechart'} variant="rounded" height={'80vh'} />
                        </div>
                    }

                </div>



                {/* Alerts */}
                <AlertM ref={notifications} />

            </Box>


        </Box>
    );
}

export default EdcMain;
