// @ts-nocheck
import {Box, CssBaseline, Tab, Tabs, Typography, Button } from '@mui/material';
import React, {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {getAll, deleteById, patch, post} from "../../../../../Services/GenericApiService";
import {log} from "../../../../../Services/LoggerService";
import Header from '../../../../Layout/Header/Header';
import Sider from '../../../../Layout/Sider/Sider';
import CommissionReportTable from "./CommissionReportTable";
import {Skeleton} from "@mui/material";
import ActionReportTable from './ActionReportTable';
import AlertM from '../../../../Helpers/AlertM/AlertM';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ActionReportMain = () => {

    // child ref for alert
    const notifications = useRef();

    // translation
    const {t} = useTranslation();

    // module(s) for api
    const moduleMain = 'edc';
    const moduleActionReport = 'actionreport';

    // data vars
    const [allData, setAllData] = React.useState([]);

    // ui controls
    const [loading, setLoading] = React.useState(true);
    const [tabValue, setTabValue] = React.useState(0);

    // ui handlers
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleFetchData = () => {
        setLoading(true);
        post(moduleActionReport + '/import', {
            gsPath: allData[tabValue].remotePath.replace('from_mtn', 'to_mtn'),
            edcId: allData[tabValue].id
        })
            .then(_res => {
                notifications.current.successAlert(t('common.genericSuccess'), '');
                setLoading(false);

            })
            .catch(_err => {
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                setLoading(false);
            })
    }


    // fetch dependent data
    const fetch = () => {
        setLoading(true); // show loader
        getAll(moduleMain, 0, 0)
            .then((_res: any) => {
                // set all data
                setAllData(_res);
                setLoading(false); // hide loader
                // notifications.current.successAlert(t('common.genericSuccess'), '');
            })
            .catch(_err => {
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                log(_err)
                setLoading(false); // hide loader
            })
    }

    useEffect(() => {
        fetch();
    }, []);


    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>

            {/* Header */}
            <Header/>

            {/* Sider */}
            <Sider/>

            {/* Content */}
            <Box component="main" sx={{flexGrow: 2, p: 3}} mt={10}>
                <div className="px-1 px-md-5">

                    {/* Upper Section */}
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="mt-3">
                                Action File Report
                            </h2>
                        </div>
                        <div className="col-md-4">
                            {!loading &&
                                <Button
                                    onClick={handleFetchData}
                                    variant="contained"
                                    className={'bg-custom-gradient my-2 py-3'}
                                    fullWidth>
                                    Import Action File
                                </Button>
                            }
                            {loading &&
                                <Skeleton className="my-4" />
                            }

                        </div>
                    </div>

                    {/* Contents */}
                    <div className="row">
                        <div>

                            {/* Tabs */}
                            {!loading &&
                                <div className="mt-3">
                                    <Box sx={{width: '100%'}}>
                                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                            <Tabs value={tabValue} onChange={handleTabChange}
                                                  aria-label="basic tabs example">
                                                {
                                                    allData.map((_edc, _idx) => <Tab
                                                        label={_edc.name} {...a11yProps(_idx)} />)
                                                }
                                            </Tabs>
                                        </Box>
                                        {
                                            allData.map((_edc, _idx) => <CustomTabPanel value={tabValue} index={_idx}>
                                                {/* Data */}
                                                <ActionReportTable edcId={_edc.id} edcConversion={_edc.expectedConversionRate} refreshDataEvent={fetch}/>
                                            </CustomTabPanel>)
                                        }
                                    </Box>
                                </div>
                            }

                            {loading &&
                                <div className="mt-5">
                                    <Skeleton key={'piechart'} variant="rounded" height={'80vh'}/>
                                </div>
                            }


                            {/* Alerts */}
                            <AlertM ref={notifications}/>

                        </div>
                    </div>
                </div>
            </Box>
        </Box>
    );
};

export default ActionReportMain;
