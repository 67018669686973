// @ts-nocheck

import React, {forwardRef, useRef, useImperativeHandle, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import axios from 'axios';
import useFileUpload from 'react-use-file-upload';
import {read, writeFileXLSX, utils} from "https://cdn.sheetjs.com/xlsx-0.18.7/package/xlsx.mjs";
import Button from '@mui/material/Button';
import {getAll, getById, post, patch, deleteById, postFormData} from "../../../../../Services/GenericApiService";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import {log} from "../../../../../Services/LoggerService";
import Skeleton from '@mui/material/Skeleton';
import {FormControl, FormControlLabel, Checkbox, FormHelperText} from "@mui/material";
import AlertM from '../../../../Helpers/AlertM/AlertM';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";


var XLSX = require("xlsx");

//create your forceUpdate hook
function useForceUpdate() {
    const [value, setValue] = React.useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render

}

// @ts-ignore
const KillListFileUpload = forwardRef((props, ref) => {
    const {
        files,
        fileNames,
        fileTypes,
        totalSize,
        totalSizeInBytes,
        handleDragDropEvent,
        clearAllFiles,
        createFormData,
        setFiles,
        removeFile,
    } = useFileUpload();

    const inputRef = useRef();

    // child ref for alert
    const notifications = useRef();

    let isCollectionsBook = false;

    // get theme from redux
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );

    // hard coded column names
    const accountNameCol = process.env.REACT_APP_ACCOUNT_NAME_COLUMN;
    // const exclusionStatusCol = process.env.REACT_APP_EXCLUSION_STATUS_COLUMN;
    const exclusionReasonCol = process.env.REACT_APP_EXCLUSION_REASON_COLUMN;

    // data vars
    const [rowCount, setRowCount] = React.useState(0);
    const [headerRow, setHeaderRow] = React.useState([]);
    const [allBookFieldsData, setAllBookFieldsData] = React.useState([]);
    const [allBooks, setAllBooks] = React.useState([]);
    const [mapper, setMapper] = React.useState({});
    const [accountFile, setAccountFile] = React.useState();
    const [importResponse, setImportResponse] = React.useState({});
    const [bookTemplate, setBookTemplate] = React.useState({});
    const [allExcludedAccounts, setAllExcludedAccounts] = React.useState([]);
    const [selectedBookDetails, setSelectedBookDetails] = React.useState({});

    // ui vars
    const [isMapperUIVisible, setIsMapperUIVisible] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isUploading, setIsUploading] = React.useState(false);
    const [isProcessing, setIsProcessing] = React.useState(false);
    const [isImported, setIsImported] = React.useState(false);


    // module(s) for api
    const moduleBookField = 'bookfield';
    const moduleBookUpload = 'debtordetail/upload/book';
    const moduleProcessBook = 'debtordetail/status/excluded';
    const moduleBookTemplate = 'booktemplate'
    const moduleBookDetails = 'bookdetail';

    const forceUpdate = useForceUpdate();

    // fetch dependent data
    const fetch = () => {
        fetchBookFields();
        fetchBookTemplates();
        fetchBookDetails();
    }

    const fetchBookFields = () => {
        getAll(moduleBookField)
            .then((_res: any) => {
                setAllBookFieldsData(_res);
            })
            .catch(_err => {
                log(_err);
            })
    }

    const fetchBookDetails = () => {
        getAll(moduleBookDetails + '?isCollectionsBook=' + (isCollectionsBook ? 'true' : 'false'))
            .then((_res: any) => {
                setAllBooks(_res);
            })
            .catch(_err => {
                log(_err);
            })
    }

    const fetchBookTemplates = () => {
        getAll(moduleBookTemplate)
            .then((_res: any) => {
                _res.forEach(_template => {
                    if(_template.name == process.env.REACT_APP_PRE_TERMINATION_FILE_TEMPLATE_NAME){
                        if(typeof _template.mapping == 'string'){
                            setBookTemplate(JSON.parse(_template.mapping));
                        } else {
                            setBookTemplate(_template.mapping);
                        }
                   }
                });
            })
            .catch(_err => {
                log(_err);
            })
    }

    useEffect(() => {
        fetch()
    }, [])


    const getNonEmptyFieldsFromMapper = () => {
        let processedObj = {};
        for (var key in mapper) {
            if (mapper.hasOwnProperty(key)) {
                if (mapper[key].value != "") {
                    processedObj[key] = {
                        value: mapper[key].value,
                        default: mapper[key].default
                    };
                }
            }
        }
        return processedObj;
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        // set processing loader
        setIsProcessing(true);

        post(moduleProcessBook, {
            excludedAccounts: allExcludedAccounts,
            bookDetails: selectedBookDetails
        })
            .then(_res => {
                // set processing loader
                setIsProcessing(false);
                setIsImported(true);
                // @ts-ignore
                notifications.current.successAlert('Exclusion List Import Completed', 'Account were excluded successfully');
            })
            .catch(_err => {
                log(_err);
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                // set processing loader
                setIsProcessing(false);
            })
    };


    const readExcel = (_file) => {
        setIsLoading(true);

        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(_file);
            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, {type: "buffer"});
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws, {
                    header: 1,
                    defval: '',
                    blankrows: true
                });
                resolve(data);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        });

        promise.then(d => {
            // store index to exclude data
            // let exclusionStatusIdx = 0;
            let exclusionReasonIdx = 0;
            let accountNameIdx = 0;
            let indexCounter = 0;

            // create a json to track mappings
            let mapperObj = {};
            d[0].forEach(_item => {
                if(bookTemplate.hasOwnProperty(_item)){
                    // store index in array to extract columns out
                    if(bookTemplate[_item].value == accountNameCol){
                        accountNameIdx = indexCounter;
                    }

                    if(bookTemplate[_item].value == exclusionReasonCol){
                        exclusionReasonIdx = indexCounter;
                    }

                    // if(bookTemplate[_item].value == exclusionStatusCol){
                    //     exclusionStatusIdx = indexCounter;
                    // }

                    mapperObj[_item] = {
                        value: bookTemplate[_item].value,
                        default: bookTemplate[_item].default
                    }
                } else {
                    mapperObj[_item] = {
                        value: "",
                        default: ""
                    }
                }

                indexCounter++;
            })
            setMapper(mapperObj);

            setRowCount(d.length);
            setHeaderRow(d[0]);

            // extractFieldsFromExcel(d, exclusionStatusIdx, exclusionReasonIdx, accountNameIdx);
            extractFieldsFromExcel(d, exclusionReasonIdx, accountNameIdx);


            // set ui for excel
            setIsMapperUIVisible(true);
            setIsLoading(false);
        })
    }

    // get excluded accounts
    const extractFieldsFromExcel = (_data, _exclusionReasonIdx, _accountNameIdx) => {
        const extractedData = [];

        // remove first element of array (its header row with column names)
        _data = _data.slice(1);

        // get excluded accounts
        _data.forEach(_row => {
            // if(_row[_exclusionStatusIdx].toLowerCase() == 'y'){
                extractedData.push({
                    accountName: _row[_accountNameIdx],
                    exclusionReason: _row[_exclusionReasonIdx],
                    exclusionStatus: 'y' // _row[_exclusionStatusIdx]
                })
            // }
        })

        setAllExcludedAccounts(extractedData);
    }

    const onSelectBookChange = (event: any) => {
        let book = allBooks.filter(_rec => _rec.id == event.target.value);
        if (book.length > 0) {
            setSelectedBookDetails(book[0]);
        }
    }

    return (
        <div>


            <div className={(theme == 'dark' ? 'bg-dark' : '') + " container card shadow-lg py-4 px-3"}>
                <div className="px-3 pb-3">
                    <h2 className={' mb-5'}>
                        Exclusion List Import
                    </h2>

                    {/* Info Screen */}
                    {fileNames.length > 0 &&
                        <div className={''}>
                            {/* File Info */}
                            <div className="alert alert-secondary">
                                <div className="row">
                                    {/* File Name */}
                                    <div className="col-12 col-md-12 mb-4">
                                        <p className={'text-muted p-0 m-0'}>
                                            File Name
                                        </p>
                                        <h5>
                                            {isLoading &&
                                                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                            }
                                            {!isLoading &&
                                                fileNames[0]
                                            }
                                        </h5>
                                    </div>
                                    {/* No. of Records */}
                                    <div className="col-6 col-md-4">
                                        <p className={'text-muted p-0 m-0'}>
                                            Number Of Records
                                        </p>
                                        <h5>
                                            {isLoading &&
                                                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                            }
                                            {!isLoading &&
                                                rowCount - 1
                                            }
                                        </h5>
                                    </div>
                                    {/* No. of Columns */}
                                    <div className="col-6 col-md-4">
                                        <p className={'text-muted p-0 m-0'}>
                                            Number Of Fields
                                        </p>
                                        <h5>
                                            {isLoading &&
                                                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                            }
                                            {!isLoading &&
                                                headerRow.length
                                            }
                                        </h5>
                                    </div>
                                    {/* No. of System Fields */}
                                    <div className="col-6 col-md-4">
                                        <p className={'text-muted p-0 m-0'}>
                                            Number Of System Fields
                                        </p>
                                        <h5>
                                            {isLoading &&
                                                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                            }
                                            {!isLoading &&
                                                allBookFieldsData.length
                                            }
                                        </h5>
                                    </div>

                                    <div className="col-md-4 col-6 mt-3">
                                        <p className={'text-muted p-0 m-0'}>
                                            Number Of Excluded Accounts
                                        </p>
                                        <h5>
                                            {isLoading &&
                                                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                            }
                                            {!isLoading &&
                                                allExcludedAccounts.length
                                            }
                                        </h5>
                                    </div>

                                    {/* Action Buttons */}
                                    <div className="col-md-8">
                                        {!isImported &&
                                            <div className={'row'}>
                                                <div className="col-md-6 col-6 mt-3">
                                                    <Button variant="outlined" className={'full-width my-2 py-3'}
                                                            onClick={() => clearAllFiles()}>
                                                        Clear
                                                    </Button>
                                                </div>
                                                <div className="col-md-6 col-6 mt-3">
                                                    <Button onClick={handleSubmit}
                                                            className={'full-width bg-custom-gradient my-2 py-3'}
                                                            variant="contained">
                                                        Import
                                                    </Button>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                </div>
                            </div>


                            {/* Header Mapper */}
                            {isMapperUIVisible && !isImported &&
                                <div className="row mt-5">
                                    <h3>
                                        {isLoading &&
                                            <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                                        }
                                        {!isLoading &&
                                            'Column Mapper'
                                        }
                                    </h3>
                                    {headerRow.map(function (item, i) {
                                        return <div className={'row my-3'} key={i}>
                                            <div className="col-4">
                                                <p className={'pt-3'}>
                                                    {item}
                                                </p>
                                            </div>
                                            <div className="col-4">
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">System Field</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={mapper[item].value}
                                                        label="System Field"
                                                        onChange={(event: SelectChangeEvent) => {
                                                            let currentMapping = mapper;
                                                            currentMapping[item].value = event.target.value;
                                                            setMapper(currentMapping);
                                                            forceUpdate();
                                                        }}
                                                    >
                                                        {allBookFieldsData.map(function (data, idx) {
                                                            return <MenuItem key={idx}
                                                                             value={data.title}>{data.displayName}</MenuItem>;
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="col-4">
                                                <TextField id="outlined-basic"
                                                           className={'full-width'}
                                                           label="Default Value"
                                                           variant="outlined"
                                                           value={mapper[item]['default']}
                                                           onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                               let currentMapping = mapper;
                                                               currentMapping[item].default = event.target.value;
                                                               setMapper(currentMapping);
                                                               forceUpdate();
                                                           }}
                                                />
                                            </div>
                                        </div>;
                                    })}
                                </div>
                            }
                        </div>
                    }

                    {/* Selection Screen */}
                    {fileNames.length == 0 &&
                        <div className={'row'}>

                            {/* Select Book */}
                            <div className="col-md-8">
                                <FormControl fullWidth={true}>
                                    <InputLabel id="bookIdLabel">
                                        Select Book
                                    </InputLabel>
                                    {allBooks.length > 0 &&
                                        <Select labelId="bookIdLabel"
                                                id="bookIdSelect"
                                                onChange={(event: any) => {
                                                    onSelectBookChange(event);
                                                }}
                                                value={selectedBookDetails.id}
                                                label="Select Book">
                                            {
                                                allBooks.map((el: any) =>
                                                    <MenuItem value={el.id}
                                                              key={el.id}> {el.bookReference}
                                                    </MenuItem>)
                                            }
                                        </Select>
                                    }
                                    {allBooks.length == 0 &&
                                        <>
                                            <Select
                                                label="Select Book"
                                                disabled>
                                                <MenuItem value={''}>
                                                    No Data
                                                </MenuItem>
                                            </Select>
                                            <FormHelperText>
                                                No Data
                                            </FormHelperText>
                                        </>
                                    }
                                </FormControl>
                            </div>

                            {/* Upload File */}
                            <div className="col-md-4">
                                {Object.keys(selectedBookDetails).length > 0 &&
                                    <Button onClick={() => inputRef.current.click()}
                                            fullWidth
                                            className={'py-3'}
                                            variant="outlined">
                                        select exclusion list accounts file
                                    </Button>
                                }

                                {/* Hide the crappy looking default HTML input */}
                                <input
                                    ref={inputRef}
                                    type="file"
                                    multiple
                                    style={{display: 'none'}}
                                    onChange={(e) => {
                                        setAccountFile(e.target.files[0]);
                                        setFiles(e, 'a');
                                        readExcel(e.target.files[0])
                                        inputRef.current.value = null;
                                    }}
                                />
                            </div>

                        </div>
                    }
                </div>
            </div>

            {/* UI to indicate uploading process */}
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isUploading || isProcessing}
            >
                <div className={'d-block text-center'}>
                    <CircularProgress color="inherit"/>

                    {isUploading &&
                        <h5>
                            Uploading Book
                        </h5>
                    }

                    {isProcessing &&
                        <h5>
                            Processing Book
                        </h5>
                    }

                </div>
            </Backdrop>

            {/* Alerts */}
            <AlertM ref={notifications}/>

        </div>
    );
});

export default KillListFileUpload;
