// @ts-nocheck

import React, { useEffect } from 'react';
import { useStyles } from './Styles';
import Box from '@mui/material/Box';
import { Skeleton } from "@mui/material";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from "react-i18next";
import DataDisplayM from "../../../../Helpers/DataDisplayM/DataDisplayM";
import { log } from "../../../../../Services/LoggerService";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { getAll } from "../../../../../Services/GenericApiService";
import ApexColumnChart from "../../Chart/Apex/ApexColumnChart/ApexColumnChart";
import TextDisplay from "../../Chart/TextDisplay/TextDisplay";
import ApexPieChart from "../../Chart/Apex/ApexPieChart/ApexPieChart";
import CampaignAccordionView from "../CampaignAccordionView/CampaignAccordionView";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function CampaignView({ data = null }) {
    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // module(s) for api
    const moduleCampaignAnalytics = 'campaign/analytics/';
    const moduleCampaign = 'campaign'

    // data vars
    const [strategies, setStrategies] = React.useState('');
    const [allTeamsData, setAllTeamsData] = React.useState('');
    const [teamCount, setTeamCount] = React.useState(0);
    const [strategyCount, setStrategCount] = React.useState(0);
    const [tabValue, setTabValue] = React.useState(0);
    const [campaignAnalytics, setCampaignAnalytics] = React.useState({});
    const [campaignTeam, setCampaignTeam] = React.useState<{ [key: string]: string[] }>({});
    const [accountsByBookBarChart, setAccountsByBookBarChart] = React.useState({});
    const [chartData, setChartData] = React.useState({
        labels: [],
        data: [],
    });
    const [campaignAnalyticsData, setCampaignAnalyticsData] = React.useState({});

    // ui handlers
    const [loading, setLoading] = React.useState(false);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        // get analytics
        if (newValue == 1) {
            fetchAnalytics(data.id);
        }
    };

    // Analytics API
    const analyticsApi = () => {
        setLoading(true); // show loader
        getAll(moduleCampaign + '/' + data.id + '/analytics/strategies/totals')
            .then((_res: any) => {
                setCampaignAnalyticsData(_res)
                // Assuming res.data contains the API response data
                const strategiesData = _res.strategies;

                // Extract labels (strategy names) and data (unique accounts) from the API response
                const labels = strategiesData.map(strategy => strategy.name);
                const data = strategiesData.map(strategy => strategy.uniqueAccounts);

                // Now you can use labels and data to render your PieChart component
                setChartData({ labels, data });
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }


    // hooks
    useEffect(() => {
        // create a string to add all names of strategies attached to campaign
        let strategiesStr = '';
        let strategiesArr = [];
        const processedData: { [key: string]: string[] } = {};
        data.campaignStrategyCampaign.forEach((_strategies, _idx) => {
            strategiesStr += _strategies.strategyCampaignStrategy.name;
            // Check if the value is not already in the array
            if (!strategiesArr.includes(_strategies.strategyCampaignStrategy.name)) {
                // Push the value into the array
                strategiesArr.push(_strategies.strategyCampaignStrategy.name);
            }

            if (_idx != data.campaignStrategyCampaign.length - 1) {
                strategiesStr += ', ';
            }

            // make a map of strategy as key and team array as value
            const strategyName = _strategies.strategyCampaignStrategy.name;
            const teamName = _strategies.strategyCampaignTeam.name;

            if (strategyName in processedData) {
                processedData[strategyName].push(teamName);
            } else {
                processedData[strategyName] = [teamName];
            }
        })
        setCampaignTeam(processedData);
        setStrategies(strategiesStr);
        setStrategCount(strategiesArr.length);
        extractTeamNamesAsString();
        analyticsApi();
    }, []);


    const extractTeamNamesAsString = () => {
        //----------------- add all team names in a string to display them -----------------//
        let dataString = '';
        let teamArry = [];

        data.campaignStrategyCampaign.forEach((_strategy, _idx) => {
            dataString += _strategy.strategyCampaignTeam.name;
            // Check if the value is not already in the array
            if (!teamArry.includes(_strategy.strategyCampaignTeam.name)) {
                // Push the value into the array
                teamArry.push(_strategy.strategyCampaignTeam.name);
            }

            if (_idx != data.campaignStrategyCampaign.length - 1) {
                dataString += ', ';
            }
        })
        setAllTeamsData(dataString);
        setTeamCount(teamArry.length)
    }

    function processStrategy(strategy) {
        let filterStr = '';

        let range = {};
        
        if (strategy.rawFilterString) {
            try {
                range = JSON.parse(strategy.rawFilterString).range;
            } catch (e) {
                range = strategy.rawFilterString.range;
            }

            if (range.convTotalDue.min !== null || range.convTotalDue.max !== null) {
                filterStr = filterStr + 'convTotalDue' + ',';
            }

            let filters = {};
            
            try{
                filters = JSON.parse(strategy.rawFilterString).filter;
            } catch (e) {
                filters = strategy.rawFilterString.filter;
            }

            // Additional conditions for other filters
            if (filters.bookName.length > 0) {
                filterStr = filterStr + 'bookName' + ', ';
            }

            if (filters.region.length > 0) {
                filterStr = filterStr + 'region' + ', ';
            }

            if (filters.segment.length > 0) {
                filterStr = filterStr + 'segment' + ', ';
            }

            if (filters.subSegment.length > 0) {
                filterStr = filterStr + 'subSegment' + ', ';
            }

            if (filters.paymentMethod.length > 0) {
                filterStr = filterStr + 'paymentMethod' + ', ';
            }

            if (filters.revenueType.length > 0) {
                filterStr = filterStr + 'revenueType' + ', ';
            }
        }

        // Remove the trailing comma if it exists
        if (filterStr.endsWith(', ')) {
            filterStr = filterStr.slice(0, -2);
        }

        return filterStr;
    }

    // fetch data
    const fetchAnalytics = (_campaignId) => {
        setLoading(true); // show loader
        getAll(moduleCampaignAnalytics + _campaignId)
            .then((_res: any) => {
                // prepare accountsByBookBarChart data
                let accountsByBookBarChart_data = [];
                let accountsByBookBarChart_labels = [];

                // prepare dispositionStatusByBookPieChart data
                let dispositionStatusByBookPieChart_arr = [];

                // prepare accountStatusByBookPieChart data
                let accountStatusByBookPieChart_arr = [];

                _res.books.forEach(_book => {
                    // pie chart data
                    let dispositionStatusByBookPieChart_Data = []
                    let dispositionStatusByBookPieChart_Label = []
                    _book.countByDispositionStatus.forEach(_count => {
                        dispositionStatusByBookPieChart_Data.push(_count.count)
                        dispositionStatusByBookPieChart_Label.push(_count.dispositionStatus)
                    })
                    // add pie chart data to original response
                    _book.dispositionStatusByBookPieChart = {
                        data: dispositionStatusByBookPieChart_Data,
                        labels: dispositionStatusByBookPieChart_Label
                    }

                    let accountStatusByBookPieChart_Data = []
                    let accountStatusByBookPieChart_Label = []
                    _book.countByAccountStatus.forEach(_count => {
                        accountStatusByBookPieChart_Data.push(_count.count)
                        accountStatusByBookPieChart_Label.push(_count.accountStatus)
                    })
                    // add pie chart data to original response
                    _book.accountStatusByBookPieChart = {
                        data: accountStatusByBookPieChart_Data,
                        labels: accountStatusByBookPieChart_Label
                    }


                    // bar chart data
                    accountsByBookBarChart_data.push(_book.recordCount);
                    accountsByBookBarChart_labels.push(_book.bookReference)
                })

                // set state vars
                setAccountsByBookBarChart({
                    dataset: [{
                        name: 'Accounts Count',
                        data: accountsByBookBarChart_data
                    }],
                    labels: accountsByBookBarChart_labels
                })

                setCampaignAnalytics(_res);

                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    return (
        <div className={'mt-5'}>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="General" {...a11yProps(0)} />
                        <Tab label="Accounts" {...a11yProps(1)} />
                        <Tab label="Teams" {...a11yProps(2)} />
                    </Tabs>
                </Box>

                {/* Campaign General Details */}
                <CustomTabPanel value={tabValue} index={0}>
                    <div className="shadow-lg card">
                        <div className="row full-width m-3">
                            <div className="col-md-3">
                                {data &&
                                    <DataDisplayM label={t('campaign.name')} data={data.name} />
                                }                                        </div>

                            <div className="col-md-3">
                                {data &&
                                    <DataDisplayM label={t('campaign.isActive')} data={data.isActive} />
                                }                                        </div>

                            <div className="col-md-3">
                                {data &&
                                    <DataDisplayM label={t('campaign.dateStart')} data={data.dateStart} />
                                }                                        </div>


                            <div className="col-md-3">
                                {data &&
                                    <DataDisplayM label={t('campaign.dateExpire')} data={data.dateExpire} />
                                }                                        </div>


                            <div className="col-md-3">
                                {data &&
                                    <DataDisplayM label={t('campaign.supplierId')}
                                        data={data.campaignSupplier?.name} />
                                }                                        </div>


                            <div className="col-md-3">
                                {data &&
                                    <DataDisplayM label={t('campaign.showLinkedAccounts')}
                                        data={data.showLinkedAccounts} />
                                }                                        </div>


                            <div className="col-md-3">
                                {data &&
                                    <DataDisplayM label={t('campaign.collectionTargetPercentage')}
                                        data={data.collectionTargetPercentage} />
                                }                                        </div>


                            <div className="col-md-3">
                                {data &&
                                    <DataDisplayM label={t('campaign.typeOfDebtId')}
                                        data={data.campaignTypeOfDebt?.name} />
                                }                                        </div>


                            <div className="col-md-3">
                                {data &&
                                    <DataDisplayM label={t('campaign.transactionsApi')}
                                        data={data.transactionsApi} />
                                }                                        </div>


                            <div className="col-md-3">
                                {data &&
                                    <DataDisplayM label={t('campaign.paymentsApi')} data={data.paymentsApi} />
                                }                                        </div>


                            <div className="col-md-3">
                                {data &&
                                    <DataDisplayM label={t('campaign.minPayment')} data={data.minPayment} />
                                }                                        </div>


                            <div className="col-md-12">
                                {data &&
                                    <DataDisplayM label={t('campaign.notes')} data={data.notes} />
                                }                                        </div>


                        </div>
                    </div>
                </CustomTabPanel>

                {/* Campaign Account Details */}
                <CustomTabPanel value={tabValue} index={1}>
                    <div className="row">

                        {/* Left hand stats */}
                        <div className="col-md-2">
                            <div className="mt-5">
                                <div className="my-2">
                                    <TextDisplay data={campaignAnalytics?.books?.length || 0} label={'Total Books'}
                                        isLoading={loading} />
                                </div>

                                <div className="mt-4">
                                    <TextDisplay data={campaignAnalytics?.totalAccountsCount || 0}
                                        label={'Total Accounts'} isLoading={loading} />
                                </div>
                            </div>
                        </div>

                        {/* accountsByBookBarChart chart */}
                        <div className="col-md-10">
                            {loading &&
                                <div className={'mt-5'}>
                                    <Skeleton variant="rectangular" height={450} />
                                </div>
                            }
                            {!loading && Object.keys(accountsByBookBarChart).length > 0 &&
                                <ApexColumnChart
                                    chartTitle={'Accounts Distribution'}
                                    dataLabelUnit={' accounts'}
                                    yAxisText={'Count'}
                                    data={accountsByBookBarChart.dataset}
                                    labels={accountsByBookBarChart.labels} />
                            }
                        </div>

                        <div className="mt-5 mb-3">
                            {!loading &&
                                <h2 className="mt-4 mb-3">
                                    Books Analytics
                                </h2>
                            }
                            {loading &&
                                <Skeleton variant="rectangular" height={50} width={250} />
                            }
                        </div>

                        {/* Book details */}
                        <>
                            {loading &&
                                <>
                                    <Skeleton variant="rectangular" height={50} className={'my-1'} />
                                    <Skeleton variant="rectangular" height={50} className={'my-1'} />
                                    <Skeleton variant="rectangular" height={50} className={'my-1'} />
                                </>

                            }

                            {!loading && Object.keys(campaignAnalytics).length > 0 &&
                                campaignAnalytics.books.map(_chart => <>
                                    <Accordion className={'shadow-lg'}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>
                                                {_chart.bookReference} Analytics
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="px-2 pb-5">
                                                <CampaignAccordionView _chart={_chart} />
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </>)

                            }
                        </>


                    </div>
                </CustomTabPanel>


                <CustomTabPanel value={tabValue} index={2}>
                    <div className="row">
                        {/* Location */}
                        <div className="col-3">
                            <div className="row m-3">
                                <div className="card shadow-lg p-3">
                                    <h6 className='text-muted'>Location</h6>
                                    {!loading &&
                                        <h5>{data.campaignLocation?.location}</h5>
                                    }
                                    {loading &&
                                        <Skeleton variant="text" height={50} />
                                    }

                                </div>
                            </div>
                        </div>

                        {/* Total Teams */}
                        <div className="col-3">
                            <div className="row m-3">
                                <div className="card shadow-lg p-3">
                                    <h6 className='text-muted'>Total Teams</h6>
                                    {!loading &&
                                        <h5>{teamCount}</h5>
                                    }
                                    {loading &&
                                        <Skeleton variant="text" height={50} />
                                    }

                                </div>
                            </div>
                        </div>

                        {/* Total Strategies */}
                        <div className="col-3">
                            <div className="row m-3">
                                <div className="card shadow-lg p-3">
                                    <h6 className='text-muted'>Total Strategies</h6>
                                    {!loading &&
                                        <h5>{strategyCount}</h5>
                                    }
                                    {loading &&
                                        <Skeleton variant="text" height={50} />
                                    }

                                </div>
                            </div>
                        </div>

                        {/* Total Accounts */}
                        <div className="col-3">
                            <div className="row m-3">
                                <div className="card shadow-lg p-3">
                                    <h6 className='text-muted'>Total Accounts</h6>
                                    {!loading &&
                                        <h5>{campaignAnalyticsData.totalAccounts}</h5>
                                    }
                                    {loading &&
                                        <Skeleton variant="text" height={50} />
                                    }

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Pie Chart Card */}

                    <div className='row mt-5 mb-3'>
                        {!loading &&
                            <h2 className="mt-4">
                                Strategy Analytics
                            </h2>
                        }
                        {loading &&
                            <Skeleton variant="rectangular" height={50} width={250} />
                        }
                    </div>

                    <div className='row'>
                        {!loading &&
                            <div className="card shadow-lg">
                                <div className="row m-3">
                                    {/* Data div */}
                                    <div className="col-8 p-4 py-5 d-flex align-items-center">
                                        <div className="row">
                                            {campaignAnalyticsData?.strategies?.length > 0 && campaignAnalyticsData?.strategies.map(strategy => (
                                                <div className="col-6 mb-5">
                                                    <div className="card shadow-lg" style={{ height: '15em', overflowX: 'hidden' }}>
                                                        <div className="row p-2">
                                                            {/* Strategy Name */}
                                                            <div className="col-6 ps-4 pt-2">
                                                                <h6 className='text-muted p-0 m-0 mb-2'>Strategy Name</h6>
                                                                <p className='p-0 m-0'>{strategy.name}</p>
                                                            </div>

                                                            {/* Account Count */}
                                                            <div className="col-6 ps-4 pt-2">
                                                                <h6 className='text-muted p-0 m-0 mb-2'>Account Count</h6>
                                                                <p className='p-0 m-0'>{strategy.uniqueAccounts}</p>
                                                            </div>
                                                            <br />

                                                            {/* Team Name */}
                                                            <div className="col-12 ps-4 pt-2">
                                                                <h6 className='text-muted p-0 m-0 mb-2 mt-3'>Team Name</h6>
                                                                <p className='p-0 m-0'>{campaignTeam[strategy.name].join(', ')}</p>
                                                            </div>

                                                            {/* Filter String */}
                                                            <div className="col-12 ps-4 pt-2 mt-3">
                                                                <h6 className='text-muted p-0 m-0 mb-2'>Filters</h6>
                                                                <p className='p-0 m-0'>{processStrategy(strategy)}</p>
                                                            </div>
                                                            <br />

                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {/* Pie Chart Div */}
                                    <div className="col-4">
                                        {!loading &&

                                            <ApexPieChart
                                                labels={chartData?.labels}
                                                chartTitle={"Unique Accounts by Strategy"}
                                                data={chartData?.data}
                                            />

                                        }
                                    </div>
                                </div>
                            </div>
                        }

                        {loading &&
                            <Skeleton variant="text" height={200} />
                        }
                    </div>
                </CustomTabPanel>
            </Box>
        </div>
    );
}

export default CampaignView;
