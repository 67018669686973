// @ts-nocheck

import {Box, Checkbox, CssBaseline, FormControlLabel, FormGroup, Button} from '@mui/material';
import React, {useEffect, useState, useRef} from 'react';
import Header from '../../../../Layout/Header/Header';
import Sider from '../../../../Layout/Sider/Sider';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {getAll, getById, post, patch, deleteById} from "../../../../../Services/GenericApiService";
import VintageReportTable from './VintageReportTable';
import {log} from '../../../../../Services/LoggerService';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import {useStyles} from './Styles';


const RollRateReport = () => {

    const classes = useStyles();

    // get theme from redux
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );

    // APIs module
    const moduleMain = 'vintagerollratereport/'

    // filter object
    const availableFilters = {
        "range": {},
        "filter": {
            "billCycle": {
                "displayName": "Bill Cycle",
                "options": ["SKA BR1", "SKA BR 5", "SKA BR 11", "SKA BR 16", "SKA BR 21", "SKA BR M2M"]
            },
            "paymentMethod": {
                "displayName": "Payment Method",
                "options": ["Auto-Debit", "Cash", "Credit Card", "Payroll"]
            },
            "contractPeriod": {
                "displayName": "Contract Period",
                "options": [1, 2, 3, 4],
                "optionLabel": ["12 Months", "24 Months", "36 Months", "36+ Months"]

            },
        }
    }

    // data vars
    const [selectedFilters, setSelectedFilters] = React.useState({
        "range": {},
        "filter": {
            "billCycle": [],
            "paymentMethod": [],
            "contractPeriod": []
        }
    });

    const [rollData, setRollData] = React.useState({});

    // UI vars
    const [loading, setLoading] = React.useState(false);


    // Get Data From API
    const fetch = async () => {
        setLoading(true);

        post(moduleMain + 'report/roll', selectedFilters, 0, 0)
            .then(_res => {
                // calculate avg balance
                _res = _res.data
                Object.keys(_res).forEach(_month => {
                    Object.keys(_res[_month]).forEach(_bucket => {
                        _res[_month][_bucket]['total_current_due_amount'] = _res[_month][_bucket]['total_current_due_amount'] == 0 ? 0 : parseFloat(_res[_month][_bucket]['total_current_due_amount']/_res[_month][_bucket]['unique_msisdn_count']).toFixed(2)
                    })
                })

                setRollData(_res);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
            })
    };

    // hooks
    useEffect(() => {
        fetch();
    }, [])


    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>

            {/* Header */}
            <Header/>

            {/* Sider */}
            <Sider/>

            {/* Content */}
            <Box component="main" sx={{flexGrow: 2, p: 3}} mt={10}>
                <div className="px-1 px-md-5">

                    <div style={{maxWidth: '88vw'}}>
                        {/* Upper Section Buttons */}
                        <div className="row">
                            <div className="col-md-8">
                                <h2 className="mt-3">
                                    Roll Rate Report
                                </h2>
                            </div>
                            <div className="col-md-4">
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        fetch()
                                    }}
                                    className={'bg-custom-gradient my-2 py-3'}
                                    fullWidth>
                                    Apply Filters
                                </Button>
                            </div>
                        </div>

                        {/* Filters */}
                        <div className="row">
                            <div className={(theme == 'dark' ? 'bg-dark' : '') + " card shadow-lg p-3 ms-0"}>
                                <div className="row mt-2 px-2">
                                    {Object.keys(availableFilters.filter).map(_key => {
                                        return (
                                            <div className="col-md-12 mt-2 mb-5">
                                                <p className={'m-0 p-0 text-muted'}>
                                                    {availableFilters.filter[_key].displayName}
                                                </p>
                                                <div className="row p-0 m-0 mt-2">
                                                    {
                                                        availableFilters.filter[_key].options.map((_option, _optionIdx) => (
                                                            <div className="col-md-1 p-0 m-0">
                                                                <FormGroup>
                                                                    <FormControlLabel control={<Checkbox/>}
                                                                                      onChange={(e) => {
                                                                                          // get current array of selected options
                                                                                          let options = selectedFilters.filter[_key];

                                                                                          // select option
                                                                                          if (e.target.checked) {
                                                                                              // push option in array
                                                                                              options.push(_option);
                                                                                          } else {
                                                                                              // unselect option
                                                                                              options = options.filter((item) => {
                                                                                                  return item !== _option
                                                                                              })
                                                                                          }

                                                                                          // update array
                                                                                          setSelectedFilters((prevState) => ({
                                                                                              ...prevState,
                                                                                              filter: {
                                                                                                  ...prevState.filter,
                                                                                                  [_key]: options
                                                                                              }
                                                                                          }));

                                                                                      }}
                                                                                      label={availableFilters.filter[_key].optionLabel ? availableFilters.filter[_key].optionLabel[_optionIdx] : _option}/>
                                                                </FormGroup>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        </div>

                        {/* Table Keys */}
                        <div className="row text-left mb-2 mt-5">
                            <div className="col-md-8"></div>
                            <div className="col-md-2">
                                <Chip
                                    className={'full-width'}
                                    color="info"
                                    size="medium"
                                    variant="elevated"
                                    label={'Current Month'}
                                />
                            </div>
                            <div className="col-md-2">
                                <Chip
                                    className={'full-width'}
                                    color="primary"
                                    size="medium"
                                    variant="elevated"
                                    label={'Previous Month'}
                                />
                            </div>
                        </div>

                        {/* Table */}
                        <div className="row card shadow-lg mt-4">
                            <TableContainer component={Paper} className={classes.tableBorder}>
                                <Table sx={{minWidth: 650}} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell align="left">0</TableCell>
                                            <TableCell align="left">30</TableCell>
                                            <TableCell align="left">60</TableCell>
                                            <TableCell align="left">90</TableCell>
                                            <TableCell align="left">120</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* 0th Row */}
                                        <TableRow
                                            key={1}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                <div className="row">
                                                    <div className="col-md-2 fw-bolder">
                                                        0
                                                    </div>
                                                    <div className="col-md-10">
                                                        Distinct Account Count
                                                        <div className={'p-0 m-0'}>
                                                            <hr className={classes.hrStyle} />
                                                        </div>
                                                        Distinct MSISDN Count
                                                        <div className={'p-0 m-0'}>
                                                            <hr className={classes.hrStyle} />
                                                        </div>
                                                        Average Balance
                                                    </div>
                                                </div>
                                            </TableCell>
                                            {Object.keys(rollData).length > 0 &&
                                                <>
                                                    <TableCell align="left">
                                                        <Chip
                                                            color="info"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.current['0']?.unique_msisdn_count ?? 0}
                                                        />
                                                        &nbsp;
                                                        <Chip
                                                            color="primary"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.previous['0']?.unique_msisdn_count ?? 0}
                                                        />

                                                        <div className={'p-0 m-0'}>
                                                            <hr className={classes.hrStyle} />
                                                        </div>

                                                        <Chip
                                                            color="info"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.current['0']?.unique_accountName_count ?? 0}
                                                        />
                                                        &nbsp;
                                                        <Chip
                                                            color="primary"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.previous['0']?.unique_accountName_count ?? 0}
                                                        />
                                                        <div className={'p-0 m-0'}>
                                                            <hr className={classes.hrStyle} />
                                                        </div>

                                                        <Chip
                                                            color="info"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.current['0']?.total_current_due_amount ?? 0}
                                                        />
                                                        &nbsp;
                                                        <Chip
                                                            color="primary"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.previous['0']?.total_current_due_amount ?? 0}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left"></TableCell>
                                                </>
                                            }
                                        </TableRow>

                                        {/* 30th Row */}
                                        <TableRow
                                            key={1}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                <div className="row">
                                                    <div className="col-md-2 fw-bolder">
                                                        30
                                                    </div>
                                                    <div className="col-md-10">
                                                        Distinct Account Count
                                                        <div className={'p-0 m-0'}>
                                                            <hr className={classes.hrStyle} />
                                                        </div>
                                                        Distinct MSISDN Count
                                                        <div className={'p-0 m-0'}>
                                                            <hr className={classes.hrStyle} />
                                                        </div>
                                                        Average Balance
                                                    </div>
                                                </div>
                                            </TableCell>
                                            {Object.keys(rollData).length > 0 &&
                                                <>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left">
                                                        <Chip
                                                            color="info"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.current['30']?.unique_msisdn_count ?? 0}
                                                        />
                                                        &nbsp;
                                                        <Chip
                                                            color="primary"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.previous['30']?.unique_msisdn_count ?? 0}
                                                        />

                                                        <div className={'p-0 m-0'}>
                                                            <hr className={classes.hrStyle} />
                                                        </div>

                                                        <Chip
                                                            color="info"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.current['30']?.unique_accountName_count ?? 0}
                                                        />
                                                        &nbsp;
                                                        <Chip
                                                            color="primary"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.previous['30']?.unique_accountName_count ?? 0}
                                                        />
                                                        <div className={'p-0 m-0'}>
                                                            <hr className={classes.hrStyle} />
                                                        </div>

                                                        <Chip
                                                            color="info"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.current['30']?.total_current_due_amount ?? 0}
                                                        />
                                                        &nbsp;
                                                        <Chip
                                                            color="primary"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.previous['30']?.total_current_due_amount ?? 0}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left"></TableCell>
                                                </>
                                            }
                                        </TableRow>

                                        {/* 60th Row */}
                                        <TableRow
                                            key={1}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                <div className="row">
                                                    <div className="col-md-2 fw-bolder">
                                                        60
                                                    </div>
                                                    <div className="col-md-10">
                                                        Distinct Account Count
                                                        <div className={'p-0 m-0'}>
                                                            <hr className={classes.hrStyle} />
                                                        </div>
                                                        Distinct MSISDN Count
                                                        <div className={'p-0 m-0'}>
                                                            <hr className={classes.hrStyle} />
                                                        </div>
                                                        Average Balance
                                                    </div>
                                                </div>
                                            </TableCell>
                                            {Object.keys(rollData).length > 0 &&
                                                <>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left">
                                                        <Chip
                                                            color="info"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.current['60']?.unique_msisdn_count ?? 0}
                                                        />
                                                        &nbsp;
                                                        <Chip
                                                            color="primary"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.previous['60']?.unique_msisdn_count ?? 0}
                                                        />

                                                        <div className={'p-0 m-0'}>
                                                            <hr className={classes.hrStyle} />
                                                        </div>

                                                        <Chip
                                                            color="info"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.current['60']?.unique_accountName_count ?? 0}
                                                        />
                                                        &nbsp;
                                                        <Chip
                                                            color="primary"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.previous['60']?.unique_accountName_count ?? 0}
                                                        />

                                                        <div className={'p-0 m-0'}>
                                                            <hr className={classes.hrStyle} />
                                                        </div>

                                                        <Chip
                                                            color="info"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.current['60']?.total_current_due_amount ?? 0}
                                                        />
                                                        &nbsp;
                                                        <Chip
                                                            color="primary"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.previous['60']?.total_current_due_amount ?? 0}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left"></TableCell>
                                                </>
                                            }
                                        </TableRow>

                                        {/* 90th Row */}
                                        <TableRow
                                            key={1}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                <div className="row">
                                                    <div className="col-md-2 fw-bolder">
                                                        90
                                                    </div>
                                                    <div className="col-md-10">
                                                        Distinct Account Count
                                                        <div className={'p-0 m-0'}>
                                                            <hr className={classes.hrStyle} />
                                                        </div>
                                                        Distinct MSISDN Count
                                                        <div className={'p-0 m-0'}>
                                                            <hr className={classes.hrStyle} />
                                                        </div>
                                                        Average Balance
                                                    </div>
                                                </div>
                                            </TableCell>
                                            {Object.keys(rollData).length > 0 &&
                                                <>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left">
                                                        <Chip
                                                            color="info"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.current['90']?.unique_msisdn_count ?? 0}
                                                        />
                                                        &nbsp;
                                                        <Chip
                                                            color="primary"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.previous['90']?.unique_msisdn_count ?? 0}
                                                        />

                                                        <div className={'p-0 m-0'}>
                                                            <hr className={classes.hrStyle} />
                                                        </div>

                                                        <Chip
                                                            color="info"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.current['90']?.unique_accountName_count ?? 0}
                                                        />
                                                        &nbsp;
                                                        <Chip
                                                            color="primary"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.previous['90']?.unique_accountName_count ?? 0}
                                                        />

                                                        <div className={'p-0 m-0'}>
                                                            <hr className={classes.hrStyle} />
                                                        </div>

                                                        <Chip
                                                            color="info"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.current['90']?.total_current_due_amount ?? 0}
                                                        />
                                                        &nbsp;
                                                        <Chip
                                                            color="primary"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.previous['90']?.total_current_due_amount ?? 0}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="left"></TableCell>
                                                </>
                                            }
                                        </TableRow>

                                        {/* 120th Row */}
                                        <TableRow
                                            key={1}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                <div className="row">
                                                    <div className="col-md-2 fw-bolder">
                                                        120
                                                    </div>
                                                    <div className="col-md-10">
                                                        Distinct Account Count
                                                        <div className={'p-0 m-0'}>
                                                            <hr className={classes.hrStyle} />
                                                        </div>
                                                        Distinct MSISDN Count
                                                        <div className={'p-0 m-0'}>
                                                            <hr className={classes.hrStyle} />
                                                        </div>
                                                        Average Balance
                                                    </div>
                                                </div>
                                            </TableCell>
                                            {Object.keys(rollData).length > 0 &&
                                                <>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left">
                                                        <Chip
                                                            color="info"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.current['120']?.unique_msisdn_count ?? 0}
                                                        />
                                                        &nbsp;
                                                        <Chip
                                                            color="primary"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.previous['120']?.unique_msisdn_count ?? 0}
                                                        />

                                                        <div className={'p-0 m-0'}>
                                                            <hr className={classes.hrStyle} />
                                                        </div>

                                                        <Chip
                                                            color="info"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.current['120']?.unique_accountName_count ?? 0}
                                                        />
                                                        &nbsp;
                                                        <Chip
                                                            color="primary"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.previous['120']?.unique_accountName_count ?? 0}
                                                        />

                                                        <div className={'p-0 m-0'}>
                                                            <hr className={classes.hrStyle} />
                                                        </div>

                                                        <Chip
                                                            color="info"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.current['120']?.total_current_due_amount ?? 0}
                                                        />
                                                        &nbsp;
                                                        <Chip
                                                            color="primary"
                                                            size="small"
                                                            variant="elevated"
                                                            label={rollData?.previous['120']?.total_current_due_amount ?? 0}
                                                        />
                                                    </TableCell>
                                                </>
                                            }
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>

                    </div>

                </div>
            </Box>
        </Box>
    );
};

export default RollRateReport;
