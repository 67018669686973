import React, {useEffect} from 'react';
import {useStyles} from './Styles';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import {post, getAll} from "../../../../../Services/GenericApiService";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {log} from "../../../../../Services/LoggerService";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import {Button, FormControl, TextField, FormControlLabel, Checkbox, FormHelperText} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import {DatePicker} from '@mui/lab';


// @ts-ignore
function CampaignForm({handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent}) {
    // css for module
    const classes = useStyles();

    // router object
    const navigate = useNavigate();

    // module(s) for api
    const moduleMain = 'campaign';

    // linked components API modules (endpoints)
    const moduleLocation = 'location';
    const moduleSupplier = 'supplier';
    const moduleTypeofdebt = 'typeofdebt';


    // translation
    const {t} = useTranslation();

    // UI methods
    const [loading, setLoading] = React.useState(true);

    // form data
    const {register, handleSubmit, watch, reset, setValue, formState: {errors}} = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {
        if (!isEditMode) {
            handleAddEvent(_data);
        } else {
            handleEditEvent(_data);
        }
    }

    // data vars
    const [selectedDatestart, setSelectedDatestart] = React.useState<Date | null>(null);
    const [selectedDateexpire, setSelectedDateexpire] = React.useState<Date | null>(null);
    const [isCheckedIsactive, setIsCheckedIsactive] = React.useState(false);
    const [isCheckedShowlinkedaccounts, setIsCheckedShowlinkedaccounts] = React.useState(false);


    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    // fetch dependent data
    const [allLocation, setAllLocation] = React.useState([]);
    const [allSupplier, setAllSupplier] = React.useState([]);
    const [allTypeofdebt, setAllTypeofdebt] = React.useState([]);


    const fetch = () => {
        fetchLocation();
        fetchSupplier();
        fetchTypeofdebt();

    }

    // fetch Location
    const fetchLocation = () => {
        setLoading(true); // show loader
        getAll(moduleLocation)
            .then((_res: any) => {
                setAllLocation(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch Supplier
    const fetchSupplier = () => {
        setLoading(true); // show loader
        getAll(moduleSupplier)
            .then((_res: any) => {
                setAllSupplier(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch Typeofdebt
    const fetchTypeofdebt = () => {
        setLoading(true); // show loader
        getAll(moduleTypeofdebt)
            .then((_res: any) => {
                setAllTypeofdebt(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }


    // hooks
    useEffect(() => {
        fetch();
        if (isEditMode) {
            reset(recordToUpdate);

            setSelectedDatestart(recordToUpdate.dateStart);
            setSelectedDateexpire(recordToUpdate.dateExpire);
            setIsCheckedIsactive(recordToUpdate.isActive);
            setIsCheckedShowlinkedaccounts(recordToUpdate.showLinkedAccounts);

        }
    }, []);


    return (
        <Box sx={{display: 'flex'}}>
            <Card sx={{minWidth: 275}}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.campaign')}
                                    </h3>

                                </div>


                                {/* Field name */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="nameInput"
                                                   {...register("name", {required: true})}
                                                   label={t('campaign.name')}
                                                   type={"text"}
                                                   error={!!errors.name}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field locationId */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="locationIdLabel">
                                            Location
                                        </InputLabel>
                                        {allLocation.length > 0 &&
                                            <Select labelId="locationIdLabel"
                                                    id="locationIdSelect"
                                                    {...register("locationId", {required: false})}
                                                    error={!!errors.locationId}
                                                    value={watch('locationId')}
                                                    label="locationId">
                                                {
                                                    allLocation.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                                  key={el.id}> {el.location}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allLocation.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                        disabled
                                                        id="locationIdSelect"
                                                        {...register("locationId", {required: false})}
                                                        error={!!errors.locationId}
                                                        value={watch('locationId')}
                                                        label="locationId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* Field dateStart */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <DatePicker
                                            label={t('campaign.dateStart')}
                                            value={selectedDatestart}
                                            onChange={(_newValue) => {
                                                setSelectedDatestart(_newValue);
                                                setValue("dateStart", _newValue, {
                                                    shouldValidate: true,
                                                    shouldDirty: true
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params}
                                                           {...register("dateStart", {required: false})}
                                                           error={!!errors.dateStart}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </div>

                                {/* Field dateExpire */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <DatePicker
                                            label={t('campaign.dateExpire')}
                                            value={selectedDateexpire}
                                            onChange={(_newValue) => {
                                                setSelectedDateexpire(_newValue);
                                                setValue("dateExpire", _newValue, {
                                                    shouldValidate: true,
                                                    shouldDirty: true
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params}
                                                           {...register("dateExpire", {required: false})}
                                                           error={!!errors.dateExpire}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </div>

                                {/* Field isActive */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth error={!!errors.isActive}>
                                        <FormControlLabel
                                            {...register("isActive", {})}
                                            label={t('campaign.isActive')}
                                            control={<Checkbox checked={isCheckedIsactive}
                                                               onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
                                                                   setIsCheckedIsactive(_event.target.checked)
                                                                   setValue("isActive", _event.target.checked);
                                                               }}/>}
                                        />
                                    </FormControl>
                                </div>


                                {/* Field supplierId */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="supplierIdLabel">
                                            Supplier
                                        </InputLabel>
                                        {allSupplier.length > 0 &&
                                            <Select labelId="supplierIdLabel"
                                                    id="supplierIdSelect"
                                                    {...register("supplierId", {required: false})}
                                                    error={!!errors.supplierId}
                                                    value={watch('supplierId')}
                                                    label="supplierId">
                                                {
                                                    allSupplier.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                                  key={el.id}> {el.name}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allSupplier.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                        disabled
                                                        id="supplierIdSelect"
                                                        {...register("supplierId", {required: false})}
                                                        error={!!errors.supplierId}
                                                        value={watch('supplierId')}
                                                        label="supplierId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* Field showLinkedAccounts */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth error={!!errors.showLinkedAccounts}>
                                        <FormControlLabel
                                            {...register("showLinkedAccounts", {})}
                                            label={t('campaign.showLinkedAccounts')}
                                            control={<Checkbox checked={isCheckedShowlinkedaccounts}
                                                               onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
                                                                   setIsCheckedShowlinkedaccounts(_event.target.checked)
                                                                   setValue("showLinkedAccounts", _event.target.checked);
                                                               }}/>}
                                        />
                                    </FormControl>
                                </div>


                                {/* Field collectionTargetPercentage */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="collectionTargetPercentageInput"
                                                   {...register("collectionTargetPercentage", {required: false})}
                                                   label={t('campaign.collectionTargetPercentage')}
                                                   type={"number"}
                                                   error={!!errors.collectionTargetPercentage}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field minPayment */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="minPaymentInput"
                                                   {...register("minPayment", {required: false})}
                                                   label={t('campaign.minPayment')}
                                                   type={"number"}
                                                   error={!!errors.minPayment}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field typeOfDebtId */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="typeOfDebtIdLabel">
                                            Type of Debt
                                        </InputLabel>
                                        {allTypeofdebt.length > 0 &&
                                            <Select labelId="typeOfDebtIdLabel"
                                                    id="typeOfDebtIdSelect"
                                                    {...register("typeOfDebtId", {required: false})}
                                                    error={!!errors.typeOfDebtId}
                                                    value={watch('typeOfDebtId')}
                                                    label="typeOfDebtId">
                                                {
                                                    allTypeofdebt.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                                  key={el.id}> {el.name}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allTypeofdebt.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                        disabled
                                                        id="typeOfDebtIdSelect"
                                                        {...register("typeOfDebtId", {required: false})}
                                                        error={!!errors.typeOfDebtId}
                                                        value={watch('typeOfDebtId')}
                                                        label="typeOfDebtId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* Field transactionsApi */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="transactionsApiInput"
                                                   {...register("transactionsApi", {required: false})}
                                                   label={t('campaign.transactionsApi')}
                                                   type={"text"}
                                                   error={!!errors.transactionsApi}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field paymentsApi */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="paymentsApiInput"
                                                   {...register("paymentsApi", {required: false})}
                                                   label={t('campaign.paymentsApi')}
                                                   type={"text"}
                                                   error={!!errors.paymentsApi}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field notes */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="notesInput"
                                                   {...register("notes", {required: false})}
                                                   label={t('campaign.notes')}
                                                   type={"text"}
                                                   error={!!errors.notes}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>


                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{width: '100%', textAlign: 'right'}}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                    onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                           type="submit"
                                           variant="contained"
                                           className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default CampaignForm;
