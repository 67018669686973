// @ts-nocheck
import {Box, CssBaseline, Tab, Tabs, Typography, Button} from '@mui/material';
import React, {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {getAll, deleteById, patch, post} from "../../../../../Services/GenericApiService";
import {log} from "../../../../../Services/LoggerService";
import Header from '../../../../Layout/Header/Header';
import Sider from '../../../../Layout/Sider/Sider';
import CommissionReportTable from "./CommissionReportTable";
import {Skeleton} from "@mui/material";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CommissionReportMain = () => {

    // translation
    const {t} = useTranslation();

    // module(s) for api
    const moduleMain = 'edc';

    // data vars
    const [allData, setAllData] = React.useState([]);
    const [monthCounter, setMonthCounter] = React.useState([]);
    const [monthsArray, setMonthsArray] = React.useState([]);

    // ui controls
    const [loading, setLoading] = React.useState(true);
    const [tabValue, setTabValue] = React.useState(0);

    // ui handlers
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handlePrevMonthFetch = () => {
        if (monthCounter[tabValue].counter < 8) {
            let idx = tabValue;
            let counter = monthCounter[tabValue].counter - 1;

            console.log(monthsArray.slice(0, counter * -1));
            console.log(counter*-1);


            setMonthCounter(prevState => ({
                ...prevState,
                [idx]: {
                    ...prevState[idx], // Spread the previous state of the specific index
                    counter: counter,
                    months: monthsArray.slice(0, counter * -1 )
                }
            }));
        }
    }

    // fetch dependent data
    const fetch = () => {
        setLoading(true); // show loader
        getAll(moduleMain, 0, 0)
            .then((_res: any) => {
                // set all data
                setAllData(_res);

                let counter = {}
                console.log(monthsArray);
                let initCounter = {
                    counter: -1,
                    months: [monthsArray[0]]
                }
                _res.forEach((_row, _idx) => {
                    counter[_idx] = initCounter
                })

                setMonthCounter(counter);


                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // helper methods to get name of months
    function createMonthArray() {
        function getMonthName(index) {
            const months = [
                "January", "February", "March", "April",
                "May", "June", "July", "August",
                "September", "October", "November", "December"
            ];
            return months[index];
        }

        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const maxArrayLength = 8;

        const monthArray = [];
        let startIndex = currentMonth;

        for (let i = 0; i < maxArrayLength; i++) {
            monthArray.unshift(getMonthName(startIndex % 12));
            startIndex--;

            if (startIndex < 0) {
                startIndex = 11; // Wrap around to the end of the year
            }
        }

        return monthArray.reverse();
    }

    // hooks
    useEffect(() => {
        setMonthsArray(createMonthArray());
    }, []);

    useEffect(() => {
        fetch();
    }, [monthsArray]);


    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>

            {/* Header */}
            <Header/>

            {/* Sider */}
            <Sider/>

            {/* Content */}
            <Box component="main" sx={{flexGrow: 2, p: 3}} mt={10}>
                <div className="px-1 px-md-5">

                    {/* Upper Section */}
                    <div className="row">
                        <div className="col-md-10">
                            <h2 className="mt-3">
                                Commission Report
                            </h2>
                        </div>
                        <div className="col-md-2">
                            <Button
                                onClick={handlePrevMonthFetch}
                                variant="contained"
                                className={'bg-custom-gradient my-2 py-3'}
                                fullWidth>
                                Get Month {monthCounter[tabValue]?.counter}
                            </Button>
                        </div>
                    </div>

                    {/* Contents */}
                    <div className="row">
                        <div>

                            {/* Tabs */}
                            {!loading &&
                                <div className="mt-3">
                                    <Box sx={{width: '100%'}}>
                                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                            <Tabs value={tabValue} onChange={handleTabChange}
                                                  aria-label="basic tabs example">
                                                {
                                                    allData.map((_edc, _idx) => <Tab
                                                        label={_edc.name} {...a11yProps(_idx)} />)
                                                }
                                            </Tabs>
                                        </Box>
                                        {
                                            allData.map((_edc, _idx) => <CustomTabPanel value={tabValue} index={_idx}>
                                                {/* Data */}
                                                <div className="d-flex flex-row"
                                                     style={{overflowX: 'auto', maxWidth: '100%'}}>
                                                    {monthCounter[tabValue].months.map((month, index) => (
                                                        <div className="col-md-4 mx-2 my-3" key={index}>
                                                            <h4 className={'text-center bg-custom-secondary m-0 p-0'}>{month}</h4>
                                                            <CommissionReportTable/>
                                                        </div>
                                                    ))}
                                                </div>
                                            </CustomTabPanel>)
                                        }
                                    </Box>
                                </div>
                            }

                            {loading &&
                                <div className="mt-5">
                                    <Skeleton key={'piechart'} variant="rounded" height={'80vh'}/>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Box>
        </Box>
    );
};

export default CommissionReportMain;
