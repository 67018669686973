// @ts-nocheck

import React, { useEffect, useState, useRef } from 'react';
import { useStyles } from './Styles';
import '../../../../../index.scss';
import { Button, IconButton, Skeleton } from "@mui/material";
import { getAll, post, deleteById } from "../../../../../Services/GenericApiService";
import { formatData } from "../../../../../Services/DataFormatService";
import { useTranslation } from "react-i18next";
import { log } from "../../../../../Services/LoggerService";
import Tooltip from '@mui/material/Tooltip';
import DebtordetailView from "../../Debtordetail/PreterminationAccountHandler/DebtordetailView/DebtordetailView";
import Box from '@mui/material/Box';
import MUIDataTable from "mui-datatables";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import TextDisplayM from '../../../../Helpers/TextDisplayM/TextDisplayM';
import ApexPieChart from '../../Chart/Apex/ApexPieChart/ApexPieChart';
import ConfirmationDialog from '../../../../Helpers/ConfirmationDialog/ConfirmationDialog';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EdcUpdate from "../EdcUpdate/EdcUpdate";

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function useForceUpdate() {
    const [value, setValue] = React.useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
    // An function that increment 👆🏻 the previous state like here
    // is better than directly setting `value + 1`
}

function EdcTable({ edcData, refreshDataEvent }) {
    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // force update method
    const forceUpdate = useForceUpdate();

    // module(s) for api
    const moduleMain = 'edcdebtordetail';
    const moduleEdc = 'edc';

    let columns = [
        { name: "Account Name", options: { filter: false } },
        { name: "ID Passport Number", options: { filter: false } },
        { name: "Available Credit Amount (ZAR)", options: { filter: false } },
        { name: "Legal Status", options: { filterOptions: { fullWidth: false } } },
        { name: "Migration Flag", options: { filterOptions: { fullWidth: false } } },
        { name: "Bill Payment Method", options: { filterOptions: { fullWidth: false } } },
        { name: "Service Status NM", options: { filterOptions: { fullWidth: false } } },
        { name: "Account Balance (ZAR)", options: { filter: false } },
        { name: "Age Days", options: { filter: false } },
        { name: "Current Treatment Level", options: { filterOptions: { fullWidth: false } } },
        { name: "Customer Type", options: { filterOptions: { fullWidth: false } } },
        { name: "Open Bill Query", options: { filterOptions: { fullWidth: false } } },
        { name: "Open Dispute", options: { filterOptions: { fullWidth: false } } },
        { name: "SIM Only Flag", options: { filterOptions: { fullWidth: false } } },
        { name: "Collection Score", options: { filter: false } },
        { name: "Affordability Bands", options: { filterOptions: { fullWidth: false } } },
        { name: "Collections Priority", options: { filterOptions: { fullWidth: false } } },
        { name: "Number Of MSISDNs", options: { filterOptions: { fullWidth: false } } },
        { name: "Last Exclusion Date", options: { filterOptions: { fullWidth: false } } },
        { name: "Number Of Exclusions", options: { filterOptions: { fullWidth: false } } },
        { name: "Account Status", options: { filterOptions: { fullWidth: false } } },
        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                setCellHeaderProps: () => {
                    return { align: "right" }
                },
                setCellProps: () => ({
                    align: "right"
                }),
                customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
                    return (
                        <div className='d-inline-flex'>
                            <div className="px-2">
                                {/* Reuse Debtor Detail View as fields are the same */}
                                <DebtordetailView data={allData[rowIndex]} />
                            </div>
                        </div>
                    )
                        ;
                }
            }
        }
    ];

    // data vars
    const [allData, setAllData] = React.useState([]);
    const [dataToEdit, setDataToEdit] = React.useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [tableData, setTableData] = React.useState([]);
    const [chartDetail, setChartDetail] = React.useState({});
    const [apexChartLabel, setApexChartLabel] = React.useState([]);
    const [apexChartData, setApexChartData] = React.useState([]);

    // ui controls
    const [loading, setLoading] = React.useState(true);
    const [editMode, setEditMode] = React.useState(false);

    // table ui controls
    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("auto");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [searchBtn, setSearchBtn] = useState(true);
    const [downloadBtn, setDownloadBtn] = useState(true);
    const [printBtn, setPrintBtn] = useState(true);
    const [viewColumnBtn, setViewColumnBtn] = useState(true);
    const [filterBtn, setFilterBtn] = useState(true);

    // fetch dependent data
    const fetch = () => {
        setLoading(true); // show loader

        // reset
        setTableData([]);
        setAllData([]);

        getAll(moduleMain + '/edc/' + edcData?.id, currentPage, pageSize)
            .then((_res: any) => {
                // set total count
                // let totalCount = 0;

                // for (const item of _res.count) {
                //     totalCount += item.count;
                // }

                setTotalRowCount(_res.count);
                setAllData(_res.rows);

                setChartDetail(_res.chartDetails);

                const chartLabel = _res.chartDetails.accountStatusAccountsCount.labels;
                const data = _res.chartDetails.accountStatusAccountsCount.datasets[0].data;
                // Create a new array and push each data element into it
                const dataForApexChart = [];
                data.forEach(item => {
                    dataForApexChart.push(item);
                });


                setApexChartLabel(chartLabel);
                setApexChartData(dataForApexChart);


                const rows: any = [];
                _res.rows.forEach((_record: any) => {
                    rows.push(formatData([_record?.accountName, _record?.idPassportNumber, _record?.availableCreditAmount, _record?.legalStatus, _record?.migrationFlag, _record?.billPaymentMethod, _record?.serviceStatusNm, _record?.accountBalance, _record?.ageDays, _record?.currentTreatmentLevel, _record?.customerType, _record?.openBillQuery, _record?.openDispute, _record?.simOnlyFlag, _record?.collectabilityScore, _record?.affordabilityBands, _record?.collectionsPriority, _record?.numberOfMsisdn, _record?.lastExclusionDate, _record?.numberOfExclusions, _record['edcdebtordetailAccountStatus.name']])); // need to create table attributes and pass in the value
                });

                setTableData(rows);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    useEffect(() => {
        fetch();
    }, [currentPage, pageSize])

    // event handlers
    const onEditClick = (_dataIndex: any, _rowIndex: any) => {
        setDataToEdit(allData[_dataIndex]);
        setEditMode(true);
    }

    const resetEdit = (_state) => {
        setEditMode(false);
    }

    const handleEdcDelete = (_dataItemIndex: number) => {
        setLoading(true);   // show spinner
        deleteById(moduleEdc, edcData.id)
            .then(async (_res) => {
                refreshDataEvent();    // refresh data
                setLoading(false);  // hide loader
                // @ts-ignore
                notifications.current.successAlert(t('common.deleteSuccess'), '');
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                
            })
    }

    const handleExportData = () => {
        setLoading(true);   // Set Loading

        // post filters
        post(moduleMain + moduleExportPreterminationAccounts, selectedFilters)
            .then((_res: any) => {
                window.open(_res.url, '_blank');
                // @ts-ignore
                // notifications.current.successAlert('Records Rejected', 'Records Were Rejected');
                setLoading(false);
            })
            .catch((_err: any) => {
                log(_err);
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                setLoading(false);   // loading is set false in fetch
            })
    }


    let options = {
        search: false,
        download: true,
        print: true,
        viewColumns: true,
        filter: false,
        filterType: "checkbox",
        responsive: "scroll",
        resizableColumns: true,
        rowsPerPage: 25,
        rowsPerPageOptions: [25, 50, 75, 100],
        onChangePage: (currentPage) => {
            setCurrentPage(currentPage);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            setPageSize(numberOfRows);
        },
        onDownload: () => {
            handleExportData();
            // return false to avoid datatable download
            return false;
        },
        page: currentPage,
        count: totalRowCount,
        serverSide: true,
        loading: true,
        tableBodyHeight: tableBodyHeight,
        // tableBodyMaxHeight,
        selectableRowsHideCheckboxes: true,
        sort: true,
        textLabels: {
            body: {
                noMatch: loading ?
                    <Box>
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                    </Box> :
                    'Sorry, there is no matching data to display',
            },
        },
        onTableChange: (action: any, state: any) => {
        },
    };

    if (allData.length === 0) {
        options = {
            textLabels: {
                body: {
                    noMatch: loading ?
                        <Box>
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                        </Box> :
                        'Sorry, there is no matching data to display',
                },
            },
        };
    }

    return (
        <>
            <div className="row">
                {/* EDC Details */}
                <div className="col-md-4 mt-5 card shadow-lg px-4 py-4">

                    <div className="row">
                        <div className="col-md-10">
                            <h4 className="mt-2 mb-4">
                                EDC Information
                            </h4>
                        </div>
                        <div className="col-md-1">
                            <Tooltip title={t('common.edit')}>
                                <IconButton aria-label="delete" size="small" onClick={() => {
                                    onEditClick()
                                }}>
                                    <FontAwesomeIcon className="edit-btn" icon='edit' />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="col-md-1">
                            {/*  delete confirmation dialog  */}
                            <ConfirmationDialog title={'Delete Confirmation'}
                                body={'Are you sure you want to delete this record?'}
                                type={'delete'}
                                isLoading={loading}
                                dataItemIdx={edcData.id}
                                confirmEvent={handleEdcDelete} />

                        </div>
                    </div>

                    <div className="row">
                        {/* Name */}
                        <div className="col-md-6 mb-4">
                            <Typography variant="subtitle1" component="div" className="text-muted">
                                Abbreviated Name
                            </Typography>
                            <Typography variant="body1" component="div" className="">
                                {edcData.name}
                            </Typography>
                        </div>

                        {/* Full Name */}
                        <div className="col-md-6 mb-4">
                            <Typography variant="subtitle1" component="div" className="text-muted">
                                Full Name
                            </Typography>
                            <Typography variant="body1" component="div" className="">
                                {edcData.fullName}
                            </Typography>
                        </div>

                        {/* EDC ID */}
                        <div className="col-md-6 mb-4">
                            <Typography variant="subtitle1" component="div" className="text-muted">
                                EDC ID
                            </Typography>
                            <Typography variant="body1" component="div" className="">
                                {edcData.edcId}
                            </Typography>
                        </div>

                        {/* Control Account Number */}
                        <div className="col-md-6 mb-4">
                            <Typography variant="subtitle1" component="div" className="text-muted">
                                Control Account Number
                            </Typography>
                            <Typography variant="body1" component="div" className="">
                                {edcData.controlAccountNumber}
                            </Typography>
                        </div>

                        {/* Remote Path */}
                        <div className="col-md-12 mb-4">
                            <Typography variant="subtitle1" component="div" className="text-muted">
                               Remote File Path
                            </Typography>
                            <Typography variant="body1" component="div" className="">
                                {edcData.remotePath}
                            </Typography>
                        </div>
                    </div>
                </div>

                {/* Data Pills */}
                <div className="col-md-4 mt-5 ">
                    {/* Total Count */}
                    <div className="row">
                        <div className="col-md-12">
                            <TextDisplayM label={'Total Accounts'}
                                data={parseFloat(totalRowCount || 0).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                isLoading={loading} />
                        </div>
                    </div>

                    {/* Filtered Count */}
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <TextDisplayM
                                data={parseFloat(chartDetail?.uniqueAccounts || 0).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                label={'Total Unique Accounts'}
                                isLoading={loading} />
                        </div>
                    </div>

                    {/* Total Account Balance */}
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <TextDisplayM data={parseFloat(chartDetail?.totalBreachAmount || 0).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                label={'Total Breach Amount (ZAR)'}
                                isLoading={loading}
                                background={'custom-secondary'} />
                        </div>
                    </div>
                </div>

                {/* Stats and Reports */}
                <div className="col-md-4">
                    {!loading &&
                        <ApexPieChart
                            data={apexChartData}
                            labels={apexChartLabel}
                            chartTitle={'Account Status Breakdown'} />
                    }

                    {loading &&
                        <div className="mt-5">
                            <Skeleton key={'piechart'} variant="rounded" height={375} />
                        </div>
                    }
                </div>

            </div>

            {/* EDC Table */}
            <div className="row">
                <div className="col-md-12">
                    <div className="mt-5" style={{ height: '80vh', maxWidth: '86vw', }}>
                        <MUIDataTable
                            title={t('common.allRecords')}
                            data={tableData}
                            columns={columns}
                            // @ts-ignore
                            options={options}
                        />
                    </div>
                </div>
            </div>

            {/* display edit modal */}
            {editMode &&
                <div>
                    <EdcUpdate refreshDataEvent={refreshDataEvent}
                        resetEditState={resetEdit}
                        recordToUpdate={edcData} />
                </div>
            }

        </>
    );
}

export default EdcTable;
