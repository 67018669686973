import MicrosoftLogin from "react-microsoft-login";
import React, {useRef, useEffect} from 'react';
import {Button, Card, CardContent, TextField, Typography, Box, FormControl, Divider} from "@mui/material";
import {Typewriter, useTypewriter, Cursor} from 'react-simple-typewriter'
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useTranslation, Trans} from 'react-i18next';
import {useForm} from "react-hook-form";
import {post} from "../../../../../Services/GenericApiService";
import AlertM from "../../../../Helpers/AlertM/AlertM";
import {encrypt} from "../../../../../Services/CryptoService";
import {getUserData, storeJWT} from "../../../../../Services/LocalStorageService";
import {login} from "./Actions";
import {log} from "../../../../../Services/LoggerService";


export default (props: any) => {

    // module(s) for api
    const moduleMain = 'user/auth/microsoft';

    // router
    const navigate = useNavigate();

    // Dispatcher
    const dispatch = useDispatch();

    // child ref for alert
    const notifications = useRef();

    // translation
    const {t} = useTranslation();

    const clientId = process.env.REACT_APP_MICROSOFT_SSO_CLIENT_ID || '';
    const tenantUrl = process.env.REACT_APP_MICROSOFT_SSO_TENANT_URL || '';
    const redirectUri = process.env.REACT_APP_MICROSOFT_SSO_REDIRECT_URI || '';
    
    useEffect(() => {

    }, [])

    const authHandler = (err: any, data: any) => {
        // if data is not empty
        if(Object.keys(data).length < 1){
            // @ts-ignore
            notifications.current.errorAlert('Microsoft Authentication Failed', t('common.tryAgain'));
        } else {
            post(moduleMain, data)
                .then(_res => {
                    storeJWT(_res.token)
                    const userdata = getUserData();
                    dispatch(login(true, _res.token, userdata));
                    navigate('/dashboard');
                })
                .catch(_err => {
                    log(_err);
                    // @ts-ignore
                    notifications.current.errorAlert(t('login.loginFailed'), t('login.invalidCredentials'));
                })
        }
    };


    return (
        <>
            <MicrosoftLogin clientId={clientId}
                            withUserData={true}
                            redirectUri={redirectUri}
                            authCallback={authHandler}
                            tenantUrl={tenantUrl}/>

            {/* Alerts */}
            <AlertM ref={notifications}/>
        </>

    );
};
