import React, {useEffect} from 'react';
import {useStyles} from './Styles';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import {post, getAll} from "../../../../../Services/GenericApiService";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {log} from "../../../../../Services/LoggerService";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import {Button, FormControl, TextField, FormControlLabel, Checkbox, FormHelperText} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import {DatePicker} from '@mui/lab';
import FileUploadM from "../../../../Helpers/FileUploadM/FileUploadM";


// @ts-ignore
function GenesysoutboundlistForm({handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent}) {
    // css for module
    const classes = useStyles();

    // router object
    const navigate = useNavigate();

    // module(s) for api
    const moduleMain = 'genesysoutboundlist';

    // linked components API modules (endpoints)
    

    // translation
    const {t} = useTranslation();

    // UI methods
    const [loading, setLoading] = React.useState(true);

    // form data
    const {register, handleSubmit, watch, reset, setValue, formState: {errors}} = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {
        if (!isEditMode){
            handleAddEvent(_data);
        } else {
            handleEditEvent(_data);
        }
    }

    // data vars
    

    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    // fetch dependent data
    


    const fetch = () => {
        
    }

    

    // hooks
    useEffect(() => {
        fetch();
        if (isEditMode) {
            reset(recordToUpdate);

            
        }
    }, []);


    return (
        <Box sx={{display: 'flex'}}>
            <Card sx={{width: '75vw', maxHeight: '90vh', overflowY: 'auto'}}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.genesysoutboundlist')}
                                    </h3>

                                </div>


                                
                                {/* Field accountNumber */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="accountNumberInput"
                                                   {...register("accountNumber", {required: false})}
                                                   label={t('genesysoutboundlist.accountNumber')}
                                                   type={"text"}
                                                   error={!!errors.accountNumber}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field idNumber */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="idNumberInput"
                                                   {...register("idNumber", {required: false})}
                                                   label={t('genesysoutboundlist.idNumber')}
                                                   type={"text"}
                                                   error={!!errors.idNumber}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field debitOrderDate */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="debitOrderDateInput"
                                                   {...register("debitOrderDate", {required: false})}
                                                   label={t('genesysoutboundlist.debitOrderDate')}
                                                   type={"text"}
                                                   error={!!errors.debitOrderDate}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field arrearAmount */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="arrearAmountInput"
                                                   {...register("arrearAmount", {required: false})}
                                                   label={t('genesysoutboundlist.arrearAmount')}
                                                   type={"text"}
                                                   error={!!errors.arrearAmount}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field customerName */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="customerNameInput"
                                                   {...register("customerName", {required: false})}
                                                   label={t('genesysoutboundlist.customerName')}
                                                   type={"text"}
                                                   error={!!errors.customerName}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field totalAccount */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="totalAccountInput"
                                                   {...register("totalAccount", {required: false})}
                                                   label={t('genesysoutboundlist.totalAccount')}
                                                   type={"text"}
                                                   error={!!errors.totalAccount}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field totalNumberOfArrears */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="totalNumberOfArrearsInput"
                                                   {...register("totalNumberOfArrears", {required: false})}
                                                   label={t('genesysoutboundlist.totalNumberOfArrears')}
                                                   type={"text"}
                                                   error={!!errors.totalNumberOfArrears}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field bestDay */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="bestDayInput"
                                                   {...register("bestDay", {required: false})}
                                                   label={t('genesysoutboundlist.bestDay')}
                                                   type={"text"}
                                                   error={!!errors.bestDay}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field payDateRangeStart */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="payDateRangeStartInput"
                                                   {...register("payDateRangeStart", {required: false})}
                                                   label={t('genesysoutboundlist.payDateRangeStart')}
                                                   type={"text"}
                                                   error={!!errors.payDateRangeStart}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field payDateRangeEnd */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="payDateRangeEndInput"
                                                   {...register("payDateRangeEnd", {required: false})}
                                                   label={t('genesysoutboundlist.payDateRangeEnd')}
                                                   type={"text"}
                                                   error={!!errors.payDateRangeEnd}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field esRank */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="esRankInput"
                                                   {...register("esRank", {required: false})}
                                                   label={t('genesysoutboundlist.esRank')}
                                                   type={"text"}
                                                   error={!!errors.esRank}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field predAvailableInstallment */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="predAvailableInstallmentInput"
                                                   {...register("predAvailableInstallment", {required: false})}
                                                   label={t('genesysoutboundlist.predAvailableInstallment')}
                                                   type={"text"}
                                                   error={!!errors.predAvailableInstallment}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field ageDays */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="ageDaysInput"
                                                   {...register("ageDays", {required: false})}
                                                   label={t('genesysoutboundlist.ageDays')}
                                                   type={"text"}
                                                   error={!!errors.ageDays}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field lsRank */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="lsRankInput"
                                                   {...register("lsRank", {required: false})}
                                                   label={t('genesysoutboundlist.lsRank')}
                                                   type={"text"}
                                                   error={!!errors.lsRank}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field xCtel1 */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="xCtel1Input"
                                                   {...register("xCtel1", {required: false})}
                                                   label={t('genesysoutboundlist.xCtel1')}
                                                   type={"text"}
                                                   error={!!errors.xCtel1}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field xCtel2 */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="xCtel2Input"
                                                   {...register("xCtel2", {required: false})}
                                                   label={t('genesysoutboundlist.xCtel2')}
                                                   type={"text"}
                                                   error={!!errors.xCtel2}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field xHtel1 */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="xHtel1Input"
                                                   {...register("xHtel1", {required: false})}
                                                   label={t('genesysoutboundlist.xHtel1')}
                                                   type={"text"}
                                                   error={!!errors.xHtel1}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field xWtel1 */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="xWtel1Input"
                                                   {...register("xWtel1", {required: false})}
                                                   label={t('genesysoutboundlist.xWtel1')}
                                                   type={"text"}
                                                   error={!!errors.xWtel1}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field contactability */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="contactabilityInput"
                                                   {...register("contactability", {required: false})}
                                                   label={t('genesysoutboundlist.contactability')}
                                                   type={"text"}
                                                   error={!!errors.contactability}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field mtnStatus */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="mtnStatusInput"
                                                   {...register("mtnStatus", {required: false})}
                                                   label={t('genesysoutboundlist.mtnStatus')}
                                                   type={"text"}
                                                   error={!!errors.mtnStatus}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field contactDialing */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="contactDialingInput"
                                                   {...register("contactDialing", {required: false})}
                                                   label={t('genesysoutboundlist.contactDialing')}
                                                   type={"text"}
                                                   error={!!errors.contactDialing}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field timezone */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="timezoneInput"
                                                   {...register("timezone", {required: false})}
                                                   label={t('genesysoutboundlist.timezone')}
                                                   type={"text"}
                                                   error={!!errors.timezone}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field dateEntered */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="dateEnteredInput"
                                                   {...register("dateEntered", {required: false})}
                                                   label={t('genesysoutboundlist.dateEntered')}
                                                   type={"text"}
                                                   error={!!errors.dateEntered}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field fileType */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="fileTypeInput"
                                                   {...register("fileType", {required: false})}
                                                   label={t('genesysoutboundlist.fileType')}
                                                   type={"text"}
                                                   error={!!errors.fileType}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>


                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{width: '100%', textAlign: 'right'}}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                    onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                           type="submit"
                                           variant="contained"
                                           className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default GenesysoutboundlistForm;
