import React, {useEffect} from 'react';
import {useStyles} from './Styles';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import {post, getAll} from "../../../../../Services/GenericApiService";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {log} from "../../../../../Services/LoggerService";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import {Button, FormControl, TextField, FormControlLabel, Checkbox, FormHelperText} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import {DatePicker} from '@mui/lab';


// @ts-ignore
function PartybankinfoForm({handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent}) {
    // css for module
    const classes = useStyles();

    // router object
    const navigate = useNavigate();

    // module(s) for api
    const moduleMain = 'partybankinfo';

    // linked components API modules (endpoints)
    const moduleUser = 'user';
const moduleBankdetail = 'bankdetail';


    // translation
    const {t} = useTranslation();

    // UI methods
    const [loading, setLoading] = React.useState(true);

    // form data
    const {register, handleSubmit, watch, reset, setValue, formState: {errors}} = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {
        if (!isEditMode){
            handleAddEvent(_data);
        } else {
            handleEditEvent(_data);
        }
    }

    // data vars
    

    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    // fetch dependent data
    const [allUser, setAllUser] = React.useState([]);
const [allBankdetail, setAllBankdetail] = React.useState([]);



    const fetch = () => {
        fetchUser();
fetchBankdetail();

    }

        // fetch User
    const fetchUser = () => {
        setLoading(true); // show loader
        getAll(moduleUser)
            .then((_res: any) => {
                setAllUser(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch Bankdetail
    const fetchBankdetail = () => {
        setLoading(true); // show loader
        getAll(moduleBankdetail)
            .then((_res: any) => {
                setAllBankdetail(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }



    // hooks
    useEffect(() => {
        fetch();
        if (isEditMode) {
            reset(recordToUpdate);

            
        }
    }, []);


    return (
        <Box sx={{display: 'flex'}}>
            <Card sx={{minWidth: 275}}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.partybankinfo')}
                                    </h3>

                                </div>


                                
                                {/* Field partyId */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="partyIdLabel">
                                            Party
                                        </InputLabel>
                                        {  allUser.length > 0 &&
                                        <Select labelId="partyIdLabel"
                                                id="partyIdSelect"
                                                {...register("partyId", {required: false})}
                                                error={!!errors.partyId}
                                                value={watch('partyId')}
                                                label="partyId">
                                            {
                                                allUser.map((el: any) =>
                                                    <MenuItem value={el.id}
                                                              key={el.id}> {el.name}
                                                    </MenuItem>)
                                            }
                                        </Select>
                                        }
                                         { allUser.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                        disabled
                                                        id="partyIdSelect"
                                                        {...register("partyId", {required: false})}
                                                        error={!!errors.partyId}
                                                        value={watch('partyId')}
                                                        label="partyId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* Field bankInfoId */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="bankInfoIdLabel">
                                            Bank
                                        </InputLabel>
                                        {  allBankdetail.length > 0 &&
                                        <Select labelId="bankInfoIdLabel"
                                                id="bankInfoIdSelect"
                                                {...register("bankInfoId", {required: false})}
                                                error={!!errors.bankInfoId}
                                                value={watch('bankInfoId')}
                                                label="bankInfoId">
                                            {
                                                allBankdetail.map((el: any) =>
                                                    <MenuItem value={el.id}
                                                              key={el.id}> {el.name}
                                                    </MenuItem>)
                                            }
                                        </Select>
                                        }
                                         { allBankdetail.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                        disabled
                                                        id="bankInfoIdSelect"
                                                        {...register("bankInfoId", {required: false})}
                                                        error={!!errors.bankInfoId}
                                                        value={watch('bankInfoId')}
                                                        label="bankInfoId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>


                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{width: '100%', textAlign: 'right'}}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                    onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                           type="submit"
                                           variant="contained"
                                           className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default PartybankinfoForm;
