// @ts-nocheck

import React, { useEffect, useState, useRef } from 'react';
import { useStyles } from './Styles';
import '../../../../../index.scss';
import { getAll, post, deleteById } from "../../../../../Services/GenericApiService";
import { formatData } from "../../../../../Services/DataFormatService";
import { useTranslation } from "react-i18next";
import { log } from "../../../../../Services/LoggerService";
import DebtordetailView from "../../Debtordetail/PreterminationAccountHandler/DebtordetailView/DebtordetailView";
import Box from '@mui/material/Box';
import MUIDataTable from "mui-datatables";
import { Skeleton } from "@mui/material";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import TextDisplayM from '../../../../Helpers/TextDisplayM/TextDisplayM';
import ApexPieChart from '../../Chart/Apex/ApexPieChart/ApexPieChart';
import ConfirmationDialog from '../../../../Helpers/ConfirmationDialog/ConfirmationDialog';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function useForceUpdate() {
    const [value, setValue] = React.useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
    // An function that increment 👆🏻 the previous state like here
    // is better than directly setting `value + 1`
}

function EdcClosedView() {
    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // force update method
    const forceUpdate = useForceUpdate();

    // module(s) for api
    const moduleMain = 'edcdebtordetail';

    let columns = [
        { name: "Account Name", options: { filter: false } },
        { name: "ID Passport Number", options: { filter: false } },
        { name: "Available Credit Amount (ZAR)", options: { filter: false } },
        { name: "Legal Status", options: { filterOptions: { fullWidth: false } } },
        { name: "Migration Flag", options: { filterOptions: { fullWidth: false } } },
        { name: "Bill Payment Method", options: { filterOptions: { fullWidth: false } } },
        { name: "Service Status NM", options: { filterOptions: { fullWidth: false } } },
        { name: "Account Balance (ZAR)", options: { filter: false } },
        { name: "Age Days", options: { filter: false } },
        { name: "Current Treatment Level", options: { filterOptions: { fullWidth: false } } },
        { name: "Customer Type", options: { filterOptions: { fullWidth: false } } },
        { name: "Open Bill Query", options: { filterOptions: { fullWidth: false } } },
        { name: "Open Dispute", options: { filterOptions: { fullWidth: false } } },
        { name: "SIM Only Flag", options: { filterOptions: { fullWidth: false } } },
        { name: "Collection Score", options: { filter: false } },
        { name: "Affordability Bands", options: { filterOptions: { fullWidth: false } } },
        { name: "Collections Priority", options: { filterOptions: { fullWidth: false } } },
        { name: "Number Of MSISDNs", options: { filterOptions: { fullWidth: false } } },
        { name: "Last Exclusion Date", options: { filterOptions: { fullWidth: false } } },
        { name: "Number Of Exclusions", options: { filterOptions: { fullWidth: false } } },
        { name: "Account Status", options: { filterOptions: { fullWidth: false } } },
        { name: "Status Change Reason", options: { filterOptions: { fullWidth: false } } },
        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                setCellHeaderProps: () => {
                    return { align: "right" }
                },
                setCellProps: () => ({
                    align: "right"
                }),
                customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
                    return (
                        <div className='d-inline-flex'>
                            <div className="px-2">
                                {/* Reuse Debtor Detail View as fields are the same */}
                                <DebtordetailView data={allData[rowIndex]} />
                            </div>
                        </div>
                    )
                        ;
                }
            }
        }
    ];

    // data vars
    const [allData, setAllData] = React.useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [tableData, setTableData] = React.useState([]);
    const [chartDetail, setChartDetail] = React.useState({});
    const [apexChartLabel, setApexChartLabel] = React.useState([]);
    const [apexChartData, setApexChartData] = React.useState([]);

    // ui controls
    const [loading, setLoading] = React.useState(true);

    // table ui controls
    const [tableBodyHeight, setTableBodyHeight] = useState("auto");

    // fetch dependent data
    const fetch = () => {
        setLoading(true); // show loader

        // reset
        setTableData([]);
        setAllData([]);
        setTotalRowCount(0);
        setChartDetail({});
        setApexChartLabel([]);
        setApexChartData([]);

        getAll(moduleMain+'/closed', currentPage, pageSize)
            .then((_res: any) => {
                // set total count
                setTotalRowCount(_res.count);
                setAllData(_res.rows);
                setChartDetail(_res.chartDetails);

                const chartLabel = _res.chartDetails.accountStatusAccountsCount.labels;
                const data = _res.chartDetails.accountStatusAccountsCount.datasets[0].data;
                // Create a new array and push each data element into it
                const dataForApexChart = [];
                data.forEach(item => {
                    dataForApexChart.push(item);
                });


                setApexChartLabel(chartLabel);
                setApexChartData(dataForApexChart);

                const rows: any = [];
                _res.rows.forEach((_record: any) => {
                    rows.push(formatData([_record?.accountName, _record?.idPassportNumber, _record?.availableCreditAmount, _record?.legalStatus, _record?.migrationFlag, _record?.billPaymentMethod, _record?.serviceStatusNm, _record?.accountBalance, _record?.ageDays, _record?.currentTreatmentLevel, _record?.customerType, _record?.openBillQuery, _record?.openDispute, _record?.simOnlyFlag, _record?.collectabilityScore, _record?.affordabilityBands, _record?.collectionsPriority, _record?.numberOfMsisdn, _record?.lastExclusionDate, _record?.numberOfExclusions, _record['edcdebtordetailAccountStatus.name'],_record?.statusChangeReason])); // need to create table attributes and pass in the value
                });

                setTableData(rows);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    useEffect(() => {
        fetch();
    }, [currentPage, pageSize])

    const handleExportData = () => {
        setLoading(true);   // Set Loading

        // post filters
        post(moduleMain + moduleExportPreterminationAccounts, selectedFilters)
            .then((_res: any) => {
                window.open(_res.url, '_blank');
                // @ts-ignore
                // notifications.current.successAlert('Records Rejected', 'Records Were Rejected');
                setLoading(false);
            })
            .catch((_err: any) => {
                log(_err);
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                setLoading(false);   // loading is set false in fetch
            })
    }

    const exportClosedAccounts = () => {
        setLoading(true);   // show spinner
        post(moduleMain + '/export/closedAccounts')
            .then((_res: any) => {
                fetch();
                // @ts-ignore
                notifications.current.successAlert('Success', 'Account were exported successfully');
            })
            .catch(_err => {
                setLoading(false);  // hide loader
                log(_err);
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
            })
    }

    let options = {
        search: false,
        download: true,
        print: true,
        viewColumns: true,
        filter: false,
        filterType: "checkbox",
        responsive: "scroll",
        resizableColumns: true,
        rowsPerPage: 25,
        rowsPerPageOptions: [25, 50, 75, 100],
        onChangePage: (currentPage) => {
            setCurrentPage(currentPage);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            setPageSize(numberOfRows);
        },
        onDownload: () => {
            handleExportData();
            // return false to avoid datatable download
            return false;
        },
        page: currentPage,
        count: totalRowCount,
        serverSide: true,
        loading: true,
        tableBodyHeight: tableBodyHeight,
        // tableBodyMaxHeight,
        selectableRowsHideCheckboxes: true,
        sort: true,
        textLabels: {
            body: {
                noMatch: loading ?
                    <Box>
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                    </Box> :
                    'Sorry, there is no matching data to display',
            },
        },
        onTableChange: (action: any, state: any) => {
        },
    };

    if (allData.length === 0) {
        options = {
            textLabels: {
                body: {
                    noMatch: loading ?
                        <Box>
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                        </Box> :
                        'Sorry, there is no matching data to display',
                },
            },
        };
    }

    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <div className="row mt-5 mb-4">
                        <div className="col-12">
                            {!loading &&
                                <ConfirmationDialog
                                    confirmEvent={(_itemIdx) => {
                                        exportClosedAccounts()
                                    }}
                                    type={'confirm'}
                                    showButton={true}
                                    title={'Export Closed Accounts'}
                                    body={'Are you sure you want to split export Closed Accounts?'}
                                    buttonText={'Export Closed Accounts'} />
                            }

                            {loading &&
                                <Skeleton variant="rounded" height={55} />
                            }
                        </div>
                    </div>
                    {/* Chips Row */}

                    {/* Total Count */}
                    <div className="row mt-5">
                        <div className="col-6">
                            <div className="col-md-12">
                                <TextDisplayM label={'Total Accounts'}
                                    data={parseFloat(totalRowCount || 0).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                    isLoading={loading} />
                            </div>
                        </div>

                        {/* Filtered Count */}
                        <div className="col-6">
                            <TextDisplayM
                                data={parseFloat(chartDetail?.uniqueAccounts || 0).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                label={'Total Unique Accounts'}
                                isLoading={loading} />
                        </div>
                    </div>

                    {/* Total Account Balance */}
                    <div className="row mt-4">
                        <div className="col-6">
                            <TextDisplayM data={parseFloat(chartDetail?.totalBreachAmount || 0).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                label={'Total Breach Amount (ZAR)'}
                                isLoading={loading}
                                background={'custom-secondary'} />
                        </div>
                    </div>

                </div>
                {!loading &&
                    <div className="col-md-6">
                        <ApexPieChart
                            data={apexChartData}
                            labels={apexChartLabel}
                            chartTitle={'Account Status Breakdown'} />
                    </div>
                }

                {loading &&
                    <div className="col-md-6 mt-5">
                        <Skeleton key={'piechart'} variant="rounded" height={350} />
                    </div>
                }
            </div>
            <div className="mt-5" style={{ height: '80vh', maxWidth: '86vw', }}>
                <MUIDataTable
                    title={t('common.allRecords')}
                    data={tableData}
                    columns={columns}
                    // @ts-ignore
                    options={options}
                />
            </div>
        </>
    );
}

export default EdcClosedView;
