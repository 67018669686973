import React, { useEffect } from 'react';
import { useStyles } from './Styles';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Header from "../../../../../Layout/Header/Header";
import Sider from "../../../../../Layout/Sider/Sider";
import { post, getAll } from "../../../../../../Services/GenericApiService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { log } from "../../../../../../Services/LoggerService";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import { Button, FormControl, TextField, FormControlLabel, Checkbox, FormHelperText } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from '@mui/lab';


// @ts-ignore
function DebtordetailForm({ handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent, contactUpdate = false }) {
    // css for module
    const classes = useStyles();

    // router object
    const navigate = useNavigate();

    // module(s) for api
    const moduleMain = 'debtordetail';

    // linked components API modules (endpoints)
    const moduleDispositionstatus = 'dispositionstatus';
    const moduleTypeofdebt = 'typeofdebt';
    const moduleAccountstatus = 'accountstatus';


    // translation
    const { t } = useTranslation();

    // UI methods
    const [loading, setLoading] = React.useState(true);

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {
        if (!isEditMode) {
            handleAddEvent(_data);
        } else {
            handleEditEvent(_data);
        }
    }

    // data vars
    const [selectedDateofbirth, setSelectedDateofbirth] = React.useState<Date | null>(null);
    const [selectedLastpaymentdate, setSelectedLastpaymentdate] = React.useState<Date | null>(null);


    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    // fetch dependent data
    const [allDispositionstatus, setAllDispositionstatus] = React.useState([]);
    const [allTypeofdebt, setAllTypeofdebt] = React.useState([]);
    const [allAccountstatus, setAllAccountstatus] = React.useState([]);



    const fetch = () => {
        fetchDispositionstatus();
        fetchTypeofdebt();
        fetchAccountstatus();

    }

    // fetch Dispositionstatus
    const fetchDispositionstatus = () => {
        setLoading(true); // show loader
        getAll(moduleDispositionstatus)
            .then((_res: any) => {
                setAllDispositionstatus(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch Typeofdebt
    const fetchTypeofdebt = () => {
        setLoading(true); // show loader
        getAll(moduleTypeofdebt)
            .then((_res: any) => {
                setAllTypeofdebt(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch Accountstatus
    const fetchAccountstatus = () => {
        setLoading(true); // show loader
        getAll(moduleAccountstatus)
            .then((_res: any) => {
                setAllAccountstatus(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }



    // hooks
    useEffect(() => {
        fetch();
        if (isEditMode) {
            reset(recordToUpdate);

            setSelectedDateofbirth(recordToUpdate.dateOfBirth);
            setSelectedLastpaymentdate(recordToUpdate.lastPaymentDate);

        }
    }, []);


    return (
        <Box sx={{ display: 'flex' }}>
            <Card sx={{ minWidth: 275 }}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.debtordetail')}
                                    </h3>

                                </div>


                                {!contactUpdate &&
                                    <div>
                                        {/* Field companyName */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="companyNameInput"
                                                    {...register("companyName", { required: false })}
                                                    label={t('debtordetail.companyName')}
                                                    type={"text"}
                                                    error={!!errors.companyName}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field surname */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="surnameInput"
                                                    {...register("surname", { required: false })}
                                                    label={t('debtordetail.surname')}
                                                    type={"text"}
                                                    error={!!errors.surname}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field idNumber */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="idNumberInput"
                                                    {...register("idNumber", { required: false })}
                                                    label={t('debtordetail.idNumber')}
                                                    type={"text"}
                                                    error={!!errors.idNumber}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field dateOfBirth */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <DatePicker
                                                    label={t('debtordetail.dateOfBirth')}
                                                    value={selectedDateofbirth}
                                                    onChange={(_newValue) => {
                                                        setSelectedDateofbirth(_newValue);
                                                        setValue("dateOfBirth", _newValue, {
                                                            shouldValidate: true,
                                                            shouldDirty: true
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params}
                                                            {...register("dateOfBirth", { required: false })}
                                                            error={!!errors.dateOfBirth}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </div>

                                        {/* Field gender */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="genderInput"
                                                    {...register("gender", { required: false })}
                                                    label={t('debtordetail.gender')}
                                                    type={"text"}
                                                    error={!!errors.gender}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field martialStatus */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="martialStatusInput"
                                                    {...register("martialStatus", { required: false })}
                                                    label={t('debtordetail.martialStatus')}
                                                    type={"text"}
                                                    error={!!errors.martialStatus}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field jobTitle */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="jobTitleInput"
                                                    {...register("jobTitle", { required: false })}
                                                    label={t('debtordetail.jobTitle')}
                                                    type={"text"}
                                                    error={!!errors.jobTitle}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field income */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="incomeInput"
                                                    {...register("income", { required: false })}
                                                    label={t('debtordetail.income')}
                                                    type={"text"}
                                                    error={!!errors.income}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field email */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="emailInput"
                                                    {...register("email", { required: false })}
                                                    label={t('debtordetail.email')}
                                                    type={"text"}
                                                    error={!!errors.email}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field phoneHome */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="phoneHomeInput"
                                                    {...register("phoneHome", { required: false })}
                                                    label={t('debtordetail.phoneHome')}
                                                    type={"text"}
                                                    error={!!errors.phoneHome}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field phoneWork */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="phoneWorkInput"
                                                    {...register("phoneWork", { required: false })}
                                                    label={t('debtordetail.phoneWork')}
                                                    type={"text"}
                                                    error={!!errors.phoneWork}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field phoneCell */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="phoneCellInput"
                                                    {...register("phoneCell", { required: false })}
                                                    label={t('debtordetail.phoneCell')}
                                                    type={"text"}
                                                    error={!!errors.phoneCell}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field employer */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="employerInput"
                                                    {...register("employer", { required: false })}
                                                    label={t('debtordetail.employer')}
                                                    type={"text"}
                                                    error={!!errors.employer}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field address1 */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="address1Input"
                                                    {...register("address1", { required: false })}
                                                    label={t('debtordetail.address1')}
                                                    type={"text"}
                                                    error={!!errors.address1}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field address2 */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="address2Input"
                                                    {...register("address2", { required: false })}
                                                    label={t('debtordetail.address2')}
                                                    type={"text"}
                                                    error={!!errors.address2}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field address3 */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="address3Input"
                                                    {...register("address3", { required: false })}
                                                    label={t('debtordetail.address3')}
                                                    type={"text"}
                                                    error={!!errors.address3}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field city */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="cityInput"
                                                    {...register("city", { required: false })}
                                                    label={t('debtordetail.city')}
                                                    type={"text"}
                                                    error={!!errors.city}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field province */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="provinceInput"
                                                    {...register("province", { required: false })}
                                                    label={t('debtordetail.province')}
                                                    type={"text"}
                                                    error={!!errors.province}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field country */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="countryInput"
                                                    {...register("country", { required: false })}
                                                    label={t('debtordetail.country')}
                                                    type={"text"}
                                                    error={!!errors.country}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field dispositionStatusId */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth={true}>
                                                <InputLabel id="dispositionStatusIdLabel">
                                                    Disposition Status
                                                </InputLabel>
                                                {allDispositionstatus.length > 0 &&
                                                    <Select labelId="dispositionStatusIdLabel"
                                                        id="dispositionStatusIdSelect"
                                                        {...register("dispositionStatusId", { required: false })}
                                                        error={!!errors.dispositionStatusId}
                                                        value={watch('dispositionStatusId')}
                                                        label="dispositionStatusId">
                                                        {
                                                            allDispositionstatus.map((el: any) =>
                                                                <MenuItem value={el.id}
                                                                    key={el.id}> {el.name}
                                                                </MenuItem>)
                                                        }
                                                    </Select>
                                                }
                                                {allDispositionstatus.length == 0 &&
                                                    <>
                                                        <Select labelId="locationIdLabel"
                                                            disabled
                                                            id="dispositionStatusIdSelect"
                                                            {...register("dispositionStatusId", { required: false })}
                                                            error={!!errors.dispositionStatusId}
                                                            value={watch('dispositionStatusId')}
                                                            label="dispositionStatusId">
                                                            <MenuItem value={''}>
                                                                No Data
                                                            </MenuItem>
                                                        </Select>
                                                        <FormHelperText>
                                                            No Data
                                                        </FormHelperText>
                                                    </>
                                                }
                                            </FormControl>
                                        </div>

                                        {/* Field accountNumber */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="accountNumberInput"
                                                    {...register("accountNumber", { required: false })}
                                                    label={t('debtordetail.accountNumber')}
                                                    type={"text"}
                                                    error={!!errors.accountNumber}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field description */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="descriptionInput"
                                                    {...register("description", { required: false })}
                                                    label={t('debtordetail.description')}
                                                    type={"text"}
                                                    error={!!errors.description}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field age */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="ageInput"
                                                    {...register("age", { required: false })}
                                                    label={t('debtordetail.age')}
                                                    type={"text"}
                                                    error={!!errors.age}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field arrears */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="arrearsInput"
                                                    {...register("arrears", { required: false })}
                                                    label={t('debtordetail.arrears')}
                                                    type={"text"}
                                                    error={!!errors.arrears}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field penaltyFee */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="penaltyFeeInput"
                                                    {...register("penaltyFee", { required: false })}
                                                    label={t('debtordetail.penaltyFee')}
                                                    type={"text"}
                                                    error={!!errors.penaltyFee}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field totalArrears */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="totalArrearsInput"
                                                    {...register("totalArrears", { required: false })}
                                                    label={t('debtordetail.totalArrears')}
                                                    type={"text"}
                                                    error={!!errors.totalArrears}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field balance */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="balanceInput"
                                                    {...register("balance", { required: false })}
                                                    label={t('debtordetail.balance')}
                                                    type={"text"}
                                                    error={!!errors.balance}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field lastPaymentDate */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <DatePicker
                                                    label={t('debtordetail.lastPaymentDate')}
                                                    value={selectedLastpaymentdate}
                                                    onChange={(_newValue) => {
                                                        setSelectedLastpaymentdate(_newValue);
                                                        setValue("lastPaymentDate", _newValue, {
                                                            shouldValidate: true,
                                                            shouldDirty: true
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params}
                                                            {...register("lastPaymentDate", { required: false })}
                                                            error={!!errors.lastPaymentDate}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </div>

                                        {/* Field capital */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="capitalInput"
                                                    {...register("capital", { required: false })}
                                                    label={t('debtordetail.capital')}
                                                    type={"text"}
                                                    error={!!errors.capital}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field agreementPeriod */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="agreementPeriodInput"
                                                    {...register("agreementPeriod", { required: false })}
                                                    label={t('debtordetail.agreementPeriod')}
                                                    type={"text"}
                                                    error={!!errors.agreementPeriod}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field typeOfDebtId */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth={true}>
                                                <InputLabel id="typeOfDebtIdLabel">
                                                    Type of Debt
                                                </InputLabel>
                                                {allTypeofdebt.length > 0 &&
                                                    <Select labelId="typeOfDebtIdLabel"
                                                        id="typeOfDebtIdSelect"
                                                        {...register("typeOfDebtId", { required: false })}
                                                        error={!!errors.typeOfDebtId}
                                                        value={watch('typeOfDebtId')}
                                                        label="typeOfDebtId">
                                                        {
                                                            allTypeofdebt.map((el: any) =>
                                                                <MenuItem value={el.id}
                                                                    key={el.id}> {el.name}
                                                                </MenuItem>)
                                                        }
                                                    </Select>
                                                }
                                                {allTypeofdebt.length == 0 &&
                                                    <>
                                                        <Select labelId="locationIdLabel"
                                                            disabled
                                                            id="typeOfDebtIdSelect"
                                                            {...register("typeOfDebtId", { required: false })}
                                                            error={!!errors.typeOfDebtId}
                                                            value={watch('typeOfDebtId')}
                                                            label="typeOfDebtId">
                                                            <MenuItem value={''}>
                                                                No Data
                                                            </MenuItem>
                                                        </Select>
                                                        <FormHelperText>
                                                            No Data
                                                        </FormHelperText>
                                                    </>
                                                }
                                            </FormControl>
                                        </div>

                                        {/* Field accountStatusId */}
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth={true}>
                                                <InputLabel id="accountStatusIdLabel">
                                                    Account Status
                                                </InputLabel>
                                                {allAccountstatus.length > 0 &&
                                                    <Select labelId="accountStatusIdLabel"
                                                        id="accountStatusIdSelect"
                                                        {...register("accountStatusId", { required: false })}
                                                        error={!!errors.accountStatusId}
                                                        value={watch('accountStatusId')}
                                                        label="accountStatusId">
                                                        {
                                                            allAccountstatus.map((el: any) =>
                                                                <MenuItem value={el.id}
                                                                    key={el.id}> {el.name}
                                                                </MenuItem>)
                                                        }
                                                    </Select>
                                                }
                                                {allAccountstatus.length == 0 &&
                                                    <>
                                                        <Select labelId="locationIdLabel"
                                                            disabled
                                                            id="accountStatusIdSelect"
                                                            {...register("accountStatusId", { required: false })}
                                                            error={!!errors.accountStatusId}
                                                            value={watch('accountStatusId')}
                                                            label="accountStatusId">
                                                            <MenuItem value={''}>
                                                                No Data
                                                            </MenuItem>
                                                        </Select>
                                                        <FormHelperText>
                                                            No Data
                                                        </FormHelperText>
                                                    </>
                                                }

                                            </FormControl>
                                        </div>
                                    </div>
                                }

                                {/* Contact Person */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="contactPersonInput"
                                            {...register("contactPerson", { required: false })}
                                            label={t('debtordetail.contactPerson')}
                                            type={"text"}
                                            error={!!errors.contactPerson}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Contact 1 */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="hTelInput"
                                            {...register("hTel", { required: false })}
                                            label={t('debtordetail.hTel')}
                                            type={"text"}
                                            error={!!errors.hTel}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Contact 2 */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="wTelInput"
                                            {...register("wTel", { required: false })}
                                            label={t('debtordetail.wTel')}
                                            type={"text"}
                                            error={!!errors.wTel}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Contact 3 */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="cTel3Input"
                                            {...register("cTel3", { required: false })}
                                            label={t('debtordetail.cTel3')}
                                            type={"text"}
                                            error={!!errors.cTel3}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Contact 4 */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="cTel4Input"
                                            {...register("cTel4", { required: false })}
                                            label={t('debtordetail.cTel4')}
                                            type={"text"}
                                            error={!!errors.cTel4}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Email 1 */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="email1Input"
                                            {...register("email1", { required: false })}
                                            label={t('debtordetail.email1')}
                                            type={"text"}
                                            error={!!errors.email1}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Email 2 */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="email2Input"
                                            {...register("email2", { required: false })}
                                            label={t('debtordetail.email2')}
                                            type={"text"}
                                            error={!!errors.email2}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Email 3 */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="email3Input"
                                            {...register("email3", { required: false })}
                                            label={t('debtordetail.email3')}
                                            type={"text"}
                                            error={!!errors.email3}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Email 4 */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="email4Input"
                                            {...register("email4", { required: false })}
                                            label={t('debtordetail.email4')}
                                            type={"text"}
                                            error={!!errors.email4}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                type="submit"
                                variant="contained"
                                className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default DebtordetailForm;
