import React, { useRef } from 'react';
import { useStyles } from './Styles';
import { patch } from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import { log } from "../../../../../Services/LoggerService";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import AlertM from '../../../../Helpers/AlertM/AlertM';
import { Button, FormControl, TextField } from "@mui/material";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// @ts-ignore
function UpdateMadEndpoint({ refreshDataEvent, closeModal }) {
    // css for module
    const classes = useStyles();

    // module(s) for api
    const moduleMain = 'key';

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // ui handlers
    const [urlValue, setUrlValue] = React.useState('');
    const [open, setOpen] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleClose = () => {
        setOpen(false);
        closeModal();
    };

    const handleChange = (event: any) => {
        setUrlValue(event.target.value);
    }

    const updateMadEndpoint = () => {
        setLoading(true); // show loader
        let urlPayload = {
            url: urlValue
        }
        patch(moduleMain, 'MADApiEndpoint', urlPayload)
            .then(async (_res) => {
                setLoading(false);
                // @ts-ignore
                notifications.current.successAlert('Endpoint Updated Successfully', '');
                setTimeout(() => {
                    refreshDataEvent();
                    handleClose();
                }, 500);

            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
            })
    }


    return (
        <div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={classes.modal}>
                        <Box sx={{ display: 'flex' }}>
                            <Card sx={{ width: '75vw', maxHeight: '90vh', overflowY: 'auto' }}>
                                {/* Form start */}
                                <form className="row px-1 px-md-0">
                                    <CardContent>
                                        <div className="py-2 px-md-4 px-2">
                                            <div className="row">
                                                <div className="col-md-12 my-2">
                                                    {/* Form name */}
                                                    <h3 className="pb-2">
                                                        Update Endpoint
                                                    </h3>

                                                </div>

                                                {/* Field fullName */}
                                                <div className="col-md-6 mt-4" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <FormControl fullWidth>
                                                        <TextField id="fullNameInput"
                                                            label={'Endpoint'}
                                                            value={urlValue}
                                                            onChange={handleChange}
                                                            type={'text'}
                                                            variant="outlined"
                                                            className="full-width" />
                                                    </FormControl>
                                                </div>

                                            </div>
                                        </div>
                                    </CardContent>
                                    <CardActions>
                                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                                            {/* Cancel Button */}
                                            <Button variant="outlined"
                                                onClick={handleClose}>
                                                {t('common.cancel')}
                                            </Button>

                                            &nbsp;&nbsp;

                                            {/* Okay Button */}
                                            <LoadingButton loading={loading}
                                                onClick={handleClickOpenDialog}
                                                variant="contained"
                                                className="bg-custom-gradient">
                                                Update
                                            </LoadingButton>
                                            <Dialog
                                                open={openDialog}
                                                onClose={handleCloseDialog}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogTitle id="alert-dialog-title">
                                                    {"Update Endpoint"}
                                                </DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="alert-dialog-description">
                                                        Are you sure you want to update Endpoint?
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleCloseDialog}>No</Button>
                                                    <Button onClick={updateMadEndpoint} autoFocus>
                                                        Yes
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                            &nbsp;
                                        </div>
                                    </CardActions>
                                </form>
                            </Card>
                        </Box>
                    </Box>
                </Fade>
            </Modal>


            {/* Alerts */}
            <AlertM ref={notifications} />

        </div>
    );
}

export default UpdateMadEndpoint;
