// @ts-nocheck

import React, { useEffect, useRef } from 'react';
import { useStyles } from './Styles';
import '../../../../../index.scss';
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { getAll } from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import AlertM from '../../../../Helpers/AlertM/AlertM';
import { log } from "../../../../../Services/LoggerService";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)


function EdcscorecardOverview({ edc }) {

    // data vars

    // ui handlers

    useEffect(() => {
    }, [])


    return (
        <>

            <TableContainer className='mt-2' style={{ boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className='border-custom'>EDC Name</TableCell>
                            <TableCell className='border-custom'>Score</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {edc.map((_edc, index) => (
                            <TableRow key='1'>
                                <TableCell className='border-custom' style={{textTransform:'uppercase'}}>{_edc.name}</TableCell>
                                <TableCell className='border-custom'>{_edc.total_weightscore}</TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>


        </>
    );
}

export default EdcscorecardOverview;
