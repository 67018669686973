// @ts-nocheck

import React, { useEffect } from 'react';
import { useStyles } from './Styles';
import '../../../../../../index.scss';
import {
    Box, Button, CssBaseline,
    FormControl, TextField
} from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import Header from "./../../../../../Layout/Header/Header";
import { useTranslation } from 'react-i18next';
import Sider from '../../../../../Layout/Sider/Sider';
import { getAll, getById, post } from "../../../../../../Services/GenericApiService";
import { log } from "../../../../../../Services/LoggerService";
import { getUserData } from '../../../../../../Services/LocalStorageService';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from 'react-router';
import { useForm } from "react-hook-form";
import { RootStateOrAny, useSelector } from "react-redux";
import ConfirmationDialog from "../../../../../Helpers/ConfirmationDialog/ConfirmationDialog";
import CollectionAccountHandlerViewData from '../CollectionAccountHandlerViewData/CollectionAccountHandlerViewData';

const Fade = require("react-reveal/Fade")
const Reveal = require("react-reveal/Reveal")

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)


function CollectionAccountHandlerMain() {
    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {

        const campainIds = [];
        allCampaigns.forEach(item => {
            campainIds.push(item.id);
        });

        _data.campainId = campainIds;
        searchCollectionAccount(_data);
        // reset();
    }

    const refreshData = (billAccNo, cusAccNo) => {
        let payload = {
            billAccNo: billAccNo,
            customerAccountNum: cusAccNo
        }
        searchCollectionAccount(payload);
    };

    // get user data from jwt
    const userData = getUserData();

    const navigate = useNavigate();

    // get theme from redux
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );


    // module(s) for api
    const moduleStrategyCampaign = 'strategycampaign';
    const moduleTeam = 'team';
    const moduleDebtorDetail = 'debtordetail';

    const unassignedAccountStatusId = process.env.REACT_APP_ACCOUNT_STATUS_UNASSIGNED;

    // data vars
    const [allCampaigns, setAllCampaigns] = React.useState([]);
    const [collectionAccount, setCollectionAccount] = React.useState({});
    const [userTeams, setUserTeams] = React.useState([]);
    const [selectedStrategy, setSelectedStrategy] = React.useState({});
    const [isSearch, setIsSearch] = React.useState(false);
    const [showNoAccount, setShowNoAccount] = React.useState(false);
    const [showNoAccountHeading, setShowNoAccountHeading] = React.useState(false);

    // fetch dependent data
    const fetchStrategyCampaigns = () => {
        setLoading(true); // show loader
        post(moduleStrategyCampaign + '/team', {
            teams: userTeams
        })
            .then((_res: any) => {
                let formattedData = [];
                let nodeCounter = 1;    // use for UI tree view

                _res.forEach(_strategyCampaign => {
                    // check if campaign does not exists
                    if (formattedData.filter(_data => _data.id == _strategyCampaign.campaignId).length == 0) {
                        formattedData.push({
                            id: _strategyCampaign.campaignId,
                            name: _strategyCampaign.strategyCampaignCampaign.name,
                            strategy: [],
                            nodeId: nodeCounter,
                            isCampaign: true
                        });
                        nodeCounter++;
                    }

                    // append strategy
                    formattedData.forEach(_campaign => {
                        if (_campaign.id == _strategyCampaign.campaignId) {
                            _campaign.strategy.push({
                                id: _strategyCampaign.strategyId,
                                name: _strategyCampaign.strategyCampaignStrategy.name,
                                nodeId: nodeCounter,
                                isCampaign: false,
                                campaignName: _strategyCampaign.strategyCampaignCampaign.name,
                                campaignId: _strategyCampaign.campaignId
                            })
                            nodeCounter++;
                        }
                    })
                });

                setAllCampaigns(formattedData);
                setLoading(false); // hide loader
            })
            .catch((_err: any) => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    const fetchTeams = () => {
        setLoading(true); // show loader
        // @ts-ignore
        getAll(moduleTeam + '/user/' + userData.id)
            .then((_res: any) => {
                let teamsOfUser = [];
                _res.forEach(_team => {
                    teamsOfUser.push(_team.teamId);
                });
                setUserTeams(teamsOfUser)
                setLoading(false); // hide loader
            })
            .catch((_err: any) => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    const fetch = () => {
        fetchTeams();
    }

    const requestNewAccount = () => {
        setLoading(true);
        getById(moduleDebtorDetail, `/collection/campaign/${selectedStrategy.campaignId}/strategy/${selectedStrategy.id}`)
            .then((_res: any) => {
                setCollectionAccount(_res);
                setShowNoAccountHeading(true);
                if (_res?.id) {
                    setShowNoAccount(false);
                } else {
                    setShowNoAccount(true);
                }
                setLoading(false); // hide loader
            })
            .catch((_err: any) => {
                log(_err)
                setLoading(false); // hide loader
            })
    }


    // fetch search data
    const searchCollectionAccount = (_data) => {
        setLoading(true); // show loader
        setIsSearch(true)
        post(moduleDebtorDetail + '/collection/search', _data)
            .then((_res: any) => {
                setCollectionAccount(_res);
                setLoading(false); // hide loader
                setIsSearch(true);
                setShowNoAccountHeading(true);
                if (_res?.id) {
                    setShowNoAccount(false);
                } else {
                    setShowNoAccount(true);
                }
            })
            .catch((_err: any) => {
                log(_err)
                setLoading(false); // hide loader
                setIsSearch(true)
            })
    }


    // fetch dependent data
    const assignAccount = () => {
        const payload = {
            debtorId: collectionAccount?.id,
            customerAccountNum: collectionAccount?.customerAccountNum
        }

        setLoading(true); // show loader
        post(moduleDebtorDetail + '/collection/assignAgent', payload)
            .then((_res: any) => {
                setLoading(false); // hide loader
                // Redirect to assign leads
                navigate('/assignedLead');
            })
            .catch((_err: any) => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    useEffect(() => {
        fetch();
    }, [])

    useEffect(() => {
        fetchStrategyCampaigns();
    }, [userTeams]);


    // ui controls
    const [loading, setLoading] = React.useState(true);
    const [value, setTabValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setCollectionAccount([]);
        setShowNoAccountHeading(false);
        setShowNoAccount(false);
        setTabValue(newValue);
        reset();
    };

    // ui handlers
    const handleStrategySelect = (_nodeId) => {
        setSelectedStrategy({});    // reset
        setCollectionAccount({});   // reset
        setShowNoAccountHeading(false);
        allCampaigns.forEach(_campaign => {
            _campaign.strategy.forEach(_strategy => {
                if (_strategy.nodeId == _nodeId) {
                    setSelectedStrategy(_strategy);
                }
            })
        })
    }


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* Header */}
            <Header />

            {/* Sider */}
            <Sider />

            {/* Content */}
            <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
                <div className="px-1 px-md-5">

                    {/* Upper Section */}
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className="mt-3 mb-4">
                                Debtor Collection
                            </h2>
                        </div>
                    </div>

                    <div className={'row'}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Request Account" value="1" />
                                    <Tab label="Search Account" value="2" />
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                {/* Campaign and strategy selection */}
                                <div className={'row'}>

                                    {/* Strategy Tree Selection */}
                                    <div className={'col-md-6 '}>
                                        <div className={(theme == 'dark' ? 'bg-dark' : '') + " container card shadow-lg p3"}
                                            style={{ height: '40vh', overflowY: 'auto' }}>
                                            <h4 className="mt-4 text-muted">
                                                Assigned Campaigns
                                            </h4>


                                            {loading &&
                                                <>
                                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                                </>
                                            }

                                            {!loading && allCampaigns.length > 0 &&
                                                <TreeView
                                                    className={'my-3'}
                                                    aria-label="multi-select"
                                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                                    defaultExpandIcon={<ChevronRightIcon />}
                                                    onNodeSelect={(event: React.SyntheticEvent, nodeIds: Array<string> | string) => {
                                                        handleStrategySelect(nodeIds);
                                                    }}
                                                >
                                                    {allCampaigns.map((_campaign) => (
                                                        <TreeItem nodeId={_campaign.nodeId} label={_campaign.name}
                                                            classes={{ label: classes.treeItemCampaignLabel }}>
                                                            {_campaign.strategy.map((_strategy) => (
                                                                <TreeItem nodeId={_strategy.nodeId} label={_strategy.name}
                                                                    classes={{ label: classes.treeItemStrategyLabel }} />))}
                                                        </TreeItem>
                                                    ))
                                                    }
                                                </TreeView>
                                            }

                                            {!loading && allCampaigns.length == 0 &&
                                                <h2 className={'text-center my-5'}>
                                                    <FontAwesomeIcon icon='triangle-exclamation' />
                                                    &nbsp;
                                                    No Data
                                                </h2>
                                            }
                                        </div>
                                    </div>


                                    {/* View Selected Strategy */}
                                    <div className="col-md-6">
                                        <div className={(theme == 'dark' ? 'bg-dark' : '') + " container card shadow-lg p-3 alert alert-warning my-auto "}
                                            style={{ height: '40vh', overflowY: 'auto' }}>
                                            <h4 className="my-2 text-muted">
                                                Selected Strategy
                                            </h4>

                                            {/* No Strategy Div */}
                                            {!loading && Object.keys(selectedStrategy).length == 0 &&
                                                <h2 className={'text-center my-auto'}>
                                                    <FontAwesomeIcon icon='triangle-exclamation' />
                                                    &nbsp;
                                                    No Strategy Selected
                                                </h2>
                                            }

                                            {/* Strategy View */}
                                            {Object.keys(selectedStrategy).length > 0 &&
                                                <div className={'row mt-5'}>
                                                    <div className="col-md-6">
                                                        <h5 className={'text-muted p-0 m-0'}>
                                                            Campaign
                                                        </h5>
                                                        <h2>
                                                            {selectedStrategy.campaignName}
                                                        </h2>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <h5 className={'text-muted p-0 m-0'}>
                                                            Strategy
                                                        </h5>
                                                        <h2>
                                                            {selectedStrategy.name}
                                                        </h2>
                                                    </div>
                                                </div>
                                            }


                                            {/*  Get Account Button  */}
                                            {Object.keys(selectedStrategy).length > 0 &&
                                                <div className={'row mt-5 px-3'}>
                                                    {!loading && Object.keys(collectionAccount).length === 0 &&
                                                        <Button
                                                            onClick={requestNewAccount}
                                                            variant="outlined"
                                                            className={'my-2 py-3'}
                                                            fullWidth>
                                                            Request Debtor Account
                                                        </Button>
                                                    }
                                                    {!loading && Object.keys(collectionAccount).length > 0 &&
                                                        <ConfirmationDialog
                                                            confirmEvent={(_itemIdx) => { assignAccount() }}
                                                            type={'confirm'}
                                                            showButton={true}
                                                            title={'Start Working'}
                                                            body={'Are you sure you want to start working?'}
                                                            buttonText={'Start Working'} />
                                                    }
                                                    {loading &&
                                                        <Skeleton variant="rounded" height={60} />
                                                    }
                                                </div>
                                            }

                                        </div>
                                    </div>


                                </div>

                                {showNoAccountHeading &&
                                    <h2 className="mt-3 mt-5">
                                        Account Details
                                    </h2>
                                }

                                {/*  View request account data  */}
                                {Object.keys(collectionAccount).length > 0 && !loading &&
                                    <div className="row">
                                        <CollectionAccountHandlerViewData account={collectionAccount} hideUpates={true} />
                                    </div>
                                }

                            </TabPanel>

                            {/* Search Collection Account */}
                            <TabPanel value="2">
                                <div className="row">
                                    {/* Strategy Tree Selection */}
                                    <div className={'col-md-12 '}>
                                        <div className={(theme == 'dark' ? 'bg-dark' : '') + " container card shadow-lg p-3 "}
                                            style={{ height: '25vh' }}>
                                            <h4 className="my-2 text-muted">
                                                Search Account
                                            </h4>

                                            <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                                                <div className={'row'}>
                                                    <div className={'col-md-4 mt-4'}>
                                                        {/* billAccNo */}
                                                        <FormControl fullWidth>
                                                            <TextField id="billAccNo"
                                                                {...register("billAccNo", { required: false })}
                                                                label={t('debtordetail.billAccNo')}
                                                                type={"text"}
                                                                error={!!errors.billAccNo}
                                                                variant="outlined"
                                                                className="full-width" />
                                                        </FormControl>
                                                    </div>
                                                    <div className={'col-md-4 mt-4'}>
                                                        {/* customerAccountNum */}
                                                        <FormControl fullWidth>
                                                            <TextField id="customerAccountNum"
                                                                {...register("customerAccountNum", { required: false })}
                                                                label={t('debtordetail.customerAccountNum')}
                                                                type={"text"}
                                                                error={!!errors.customerAccountNum}
                                                                variant="outlined"
                                                                className="full-width" />
                                                        </FormControl>
                                                    </div>
                                                    <div className={'col-4'}>
                                                        {/* Okay Button */}

                                                        {!loading &&
                                                            <LoadingButton
                                                                type="submit"
                                                                variant="contained"
                                                                style={{
                                                                    height: '50px',
                                                                    marginTop: '1.8em'
                                                                }}
                                                                className="btn-lg bg-custom-gradient full-width">
                                                                {t('common.search')}
                                                            </LoadingButton>
                                                        }

                                                        {loading &&
                                                            <Skeleton variant="rounded" style={{
                                                                height: '50px',
                                                                marginTop: '1.5em'
                                                            }} />
                                                        }
                                                    </div>
                                                    <div className="col-12 mt-3">
                                                        {!loading && Object.keys(collectionAccount).length > 0 && collectionAccount.accountStatusId == unassignedAccountStatusId&&
                                                            <div >
                                                                <ConfirmationDialog
                                                                    confirmEvent={(_itemIdx) => { assignAccount() }}
                                                                    type={'confirm'}
                                                                    showButton={true}
                                                                    title={'Start Working'}
                                                                    body={'Are you sure you want to start working?'}
                                                                    buttonText={'Start Working'}
                                                                />
                                                            </div>
                                                        }

                                                        {loading && Object.keys(collectionAccount).length > 0 && collectionAccount.accountStatusId == unassignedAccountStatusId &&
                                                            <Skeleton variant="rounded" style={{
                                                                height: '50px'
                                                            }} />
                                                        }
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                                {showNoAccountHeading &&
                                    <h2 className="mt-3 mt-5">
                                        Account Details
                                    </h2>
                                }

                                {/*  View request account data  */}
                                {Object.keys(collectionAccount).length > 0 && !loading &&
                                    <div className="row">
                                        <CollectionAccountHandlerViewData account={collectionAccount} hideUpates={true} />
                                    </div>
                                }

                            </TabPanel>
                        </TabContext>
                    </div>
                    {/*  View request account data  */}
                    {Object.keys(collectionAccount).length === 0 && !loading && showNoAccount &&
                        <div className="row">
                            <div className="col-md-12">
                                <div className={'card shadow-lg p-3 alert alert-warning my-auto ' + classes.h40}>

                                    {/* No Account Div */}
                                    <h2 className={'text-center my-auto'}>
                                        <FontAwesomeIcon icon='triangle-exclamation' />
                                        &nbsp;
                                        No Account Available
                                    </h2>
                                </div>
                            </div>
                        </div>
                    }

                    {loading &&
                        <Skeleton variant="rounded" height={200} />
                    }
                </div>
            </Box>
        </Box>


    );
}


export default CollectionAccountHandlerMain;
