// @ts-nocheck
import React, { useEffect } from 'react';
import { useStyles } from './Styles';
import { useTranslation } from "react-i18next";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { patch } from '../../../../../Services/GenericApiService';
import { Button, FormControl, TextField, IconButton } from "@mui/material";
import { useForm, useFieldArray } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// @ts-ignore
function EdcscorecardMatrixUpdate({ handleCancelEvent, data, refreshDataEvent, formulaIndex }) {
    // css for module
    const classes = useStyles();

    //module
    const moduleMain = "edcscorecard";

    // translation
    const { t } = useTranslation();

    // ui handlers
    const [open, setOpen] = React.useState(true);
    const [loading, setLoading] = React.useState(false);

    // form data
    const { control, handleSubmit, register, getValues, setValue, formState: { errors } } = useForm({
        defaultValues: {
            ranges: data || [],
        },

    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "ranges"
    });

    const validateStartRange = (newStart, index, prevEndValue, currEndValue) => {

        const newStartNum = Number(newStart);

        // Check if there is a previous range and validate against its end value
        if (index > 0) {
            // const prevEnd = fields[index - 1]?.end ? Number(fields[index - 1].end) : undefined;
            const prevEnd = prevEndValue ? Number(prevEndValue) : undefined;

            // Validate that new start is greater than previous end
            if (prevEnd !== undefined && newStartNum <= prevEnd) {
                return "Start range should always be greater than previous end range"; // Validation fails if new start is not greater than previous end
            }
            //if last end range is null, then donot allow entering start range
            if (prevEnd == undefined) return "Previous End range cannot be null.";
        }

        const currentEnd = currEndValue ? Number(currEndValue) : fields[index].end ? Number(fields[index].end) : undefined;

        // Validate that current start range should always be less than current end range (if ends range changes then pick end range from ranges otherwise pick from fields) 
        if (currentEnd !== undefined && newStartNum >= currentEnd) {
            return "Start range should always be less than its end range."; // Validation fails if start is not less than end
        }

        return true; // Validation passes
    };

    const validateEndRange = (newEnd, index, currStartValue) => {

        const currentStart = currStartValue ? Number(currStartValue) : fields[index]?.start ? Number(fields[index]?.start) : undefined;
        if (currentStart === undefined) return false;
        const newEndNum = newEnd ? Number(newEnd) : undefined;
        if (newEndNum === undefined || newEndNum > currentStart) {
            return true; // Validation passes if newEnd is greater than currentStart or undefined
        }

        return "End range should always be greater than its start range"; // Validation fails if newEnd is not greater than currentStart
    };


    const onSubmit = (data) => {
        updateMatrix(data);
    };

    // update standard matrix
    const updateMatrix = (_formData: any) => {
        setLoading(true); // show loader
        patch(moduleMain+'/matrix', formulaIndex, _formData)
            .then(async (_res) => {
                setLoading(false); // hide loader
                refreshDataEvent(); // refresh data
                handleClose();
            })
            .catch(_err => {
                console.log(_err)
                setLoading(false); // hide loader
            })
    }

    useEffect(() => {
        if (data && data.length) {
            setValue('ranges', data);
        }
    }, [data, setValue]);



    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleCancelEvent}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={classes.modal}>
                        <Box sx={{ display: 'flex' }}>
                            <Card sx={{ width: '75vw', maxHeight: '90vh', overflowY: 'auto' }}>
                                {/* Form start */}
                                <CardContent>
                                    <div className="py-2 px-md-4 px-2">
                                        <div className="row mb-3">
                                            <h3 className="pb-2">
                                                {t('edcScoreCard.performaceCalculator')}
                                            </h3>
                                        </div>
                                    </div>
                                </CardContent>
                                <CardActions>
                                    <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <Box display="flex" flexDirection="column" gap={2}>
                                                {fields.map((field, index) => (
                                                    <Box key={field.id} display="flex" alignItems="center" gap={2}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                label="Start"
                                                                {...register(`ranges[${index}].start`, {
                                                                    required: true,
                                                                    validate: {
                                                                        notInExistingRange: value => validateStartRange(value, index, getValues(`ranges[${index - 1}].end`, getValues(`ranges[${index}].end`)))
                                                                    }
                                                                })}
                                                                error={!!errors.ranges?.[index]?.start}
                                                                helperText={errors.ranges?.[index]?.start && errors.ranges[index].start.message}
                                                                variant="outlined"
                                                            />
                                                        </FormControl>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                label="End"
                                                                {...register(`ranges[${index}].end`, {
                                                                    validate: {
                                                                        greaterThanStart: value => validateEndRange(value, index, getValues(`ranges[${index}].start`))
                                                                    }
                                                                })}
                                                                error={!!errors.ranges?.[index]?.end}
                                                                helperText={errors.ranges?.[index]?.end && errors.ranges[index].end.message}
                                                                variant="outlined"
                                                            />
                                                        </FormControl>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                label="Value"
                                                                type="number"
                                                                {...register(`ranges[${index}].value`, { required: true })}
                                                                error={!!errors.ranges?.[index]?.value}
                                                                variant="outlined"
                                                            />
                                                        </FormControl>
                                                        <IconButton aria-label="delete" size="small" onClick={() => remove(index)}>
                                                            <FontAwesomeIcon className="edit-btn" icon='trash' />
                                                        </IconButton>
                                                    </Box>
                                                ))}
                                                <div className='row'>
                                                    <div className="col-md-2 text-start">
                                                        <Button
                                                            variant="contained"
                                                            onClick={() => append({ start: '', end: '', value: '' })}
                                                            size='small'
                                                            className='py-2'
                                                        >
                                                            Add Range
                                                        </Button>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12 text-end">
                                                        <Button variant="outlined" onClick={handleCancelEvent}>
                                                            {t('common.cancel')}
                                                        </Button>
                                                        &nbsp;&nbsp;
                                                        <Button variant="contained" className="bg-custom-gradient" type="submit" >
                                                            {t('common.edit')}
                                                        </Button>
                                                    </div>
                                                </div>

                                            </Box>
                                        </form>
                                    </div>
                                </CardActions>

                            </Card>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div >
    );
}

export default EdcscorecardMatrixUpdate;
