import {COLLAPSE, EXPAND} from "./Types";

export const collapse = () => {
    return {
        type: COLLAPSE
    }
}
export const expand = () => {
    return {
        type: EXPAND
    }
}
