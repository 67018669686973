// @ts-nocheck

import React, {useEffect, useRef} from 'react';
import Box from '@mui/material/Box';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import {Pie} from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);


function PieChart({dataset = null, labels = null, title = '', height = '', width = ''}) {

    const borderColor = [
        "rgba(255, 255, 0, 1)",
        "rgba(255, 204, 0, 1)",
        "rgba(255, 153, 0, 1)",
        "rgba(255, 102, 0, 1)",
        "rgba(255, 51, 0, 1)",
        "rgba(255, 0, 0, 1)",
        "rgba(204, 255, 0, 1)",
        "rgba(153, 255, 0, 1)",
        "rgba(102, 255, 0, 1)",
        "rgba(51, 255, 0, 1)",
        "rgba(0, 255, 0, 1)",
        "rgba(0, 255, 51, 1)",
        "rgba(0, 255, 102, 1)",
        "rgba(0, 255, 153, 1)",
        "rgba(0, 255, 204, 1)",
        "rgba(0, 255, 255, 1)"
    ];
    const bgColor = [
        "rgba(255, 255, 0, 1)",
        "rgba(255, 204, 0, 1)",
        "rgba(255, 153, 0, 1)",
        "rgba(255, 102, 0, 1)",
        "rgba(255, 51, 0, 1)",
        "rgba(255, 0, 0, 1)",
        "rgba(204, 255, 0, 1)",
        "rgba(153, 255, 0, 1)",
        "rgba(102, 255, 0, 1)",
        "rgba(51, 255, 0, 1)",
        "rgba(0, 255, 0, 1)",
        "rgba(0, 255, 51, 1)",
        "rgba(0, 255, 102, 1)",
        "rgba(0, 255, 153, 1)",
        "rgba(0, 255, 204, 1)",
        "rgba(0, 255, 255, 1)"
    ];

    const data = {
        labels: labels,
        datasets: [
            {
                label: title,
                data: dataset,
                backgroundColor: bgColor,
                borderColor: borderColor,
                borderWidth: 1,
            },
        ],
    };

    // console.log(data);

    return (
        <div className={'mb-4'} style={{ width, height }}>
            {/*{Math.max(...dataset) === 0 &&*/}
            {/*    <h5 className={'py-2 mb-5'}>No Data</h5>*/}
            {/*}*/}

            {/*{Math.max(...dataset) !== 0 &&*/}
                <Pie data={data}/>
            {/*}*/}
        </div>
    );

}

export default PieChart;
