import React, { useEffect } from 'react';
import { useStyles } from './Styles';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { post, getAll } from "../../../../../Services/GenericApiService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { log } from "../../../../../Services/LoggerService";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import { Button, FormControl, TextField, FormControlLabel, Checkbox, FormHelperText } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from '@mui/lab';


// @ts-ignore
function PtpForm({ handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent, customerAccountNum = '' }) {
    
    // css for module
    const classes = useStyles();

    // router object
    const navigate = useNavigate();

    // module(s) for api
    const moduleMain = 'ptp';

    // linked components API modules (endpoints)
    const moduleDebtordetail = 'debtordetail';
    const modulePtpmethod = 'ptpmethod';
    const moduleBankdetail = 'bankdetail';


    // translation
    const { t } = useTranslation();

    // UI methods
    const [loading, setLoading] = React.useState(true);

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {
        if (!isEditMode) {
            handleAddEvent(_data);
        } else {
            handleEditEvent(_data);
        }
    }

    // data vars
    const [selectedPtpdate, setSelectedPtpdate] = React.useState<Date | null>(null);
    const [isRecurringValue, setIsRecurringValue] = React.useState('0');

    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    // fetch dependent data
    const [allPtpmethod, setAllPtpmethod] = React.useState([]);
    const [allBankdetail, setAllBankdetail] = React.useState([]);


    const fetch = () => {
        fetchPtpmethod();
        fetchBankdetail();
    }

    // Handle changes in the isRecurring select input
    const handleIsRecurringChange = (event:any) => {
        setIsRecurringValue(event.target.value);
    };

    // fetch Ptpmethod
    const fetchPtpmethod = () => {
        setLoading(true); // show loader
        getAll(modulePtpmethod)
            .then((_res: any) => {
                setAllPtpmethod(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch Bankdetail
    const fetchBankdetail = () => {
        setLoading(true); // show loader
        getAll(moduleBankdetail)
            .then((_res: any) => {
                setAllBankdetail(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }


    // hooks
    useEffect(() => {
        fetch();
        if (isEditMode) {
            reset(recordToUpdate);

            setSelectedPtpdate(recordToUpdate?.ptpDate);

        }
    }, []);


    return (
        <Box sx={{ display: 'flex' }}>
            <Card sx={{ minWidth: 275 }}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.ptp')}
                                    </h3>

                                </div>


                                {/* Field debtorId */}
                                {/*<div className="col-md-6 mt-4">*/}
                                {/*    <FormControl fullWidth>*/}
                                {/*        <TextField id="debtorId"*/}
                                {/*                   {...register("debtorId", {required: false})}*/}
                                {/*                   label={t('ptp.debtorId')}*/}
                                {/*                   type={"text"}*/}
                                {/*                   error={!!errors.debtorId}*/}
                                {/*                   variant="outlined"*/}
                                {/*                   className="full-width"/>*/}
                                {/*    </FormControl>*/}
                                {/*</div>*/}

                                {/* Customer Account Number (NOT A FORM FIELD */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="customerAccountNum"
                                            disabled
                                            label={'Customer Account Number'}
                                            type={"text"}
                                            value={customerAccountNum}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field ptpDate */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <DatePicker
                                            label={t('ptp.ptpDate')}
                                            value={selectedPtpdate}
                                            onChange={(_newValue) => {
                                                setSelectedPtpdate(_newValue);
                                                setValue("ptpDate", _newValue, {
                                                    shouldValidate: true,
                                                    shouldDirty: true
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    {...register("ptpDate", { required: true })}
                                                    error={!!errors.ptpDate}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </div>

                                {/* Field amount */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="amountInput"
                                            {...register("amount", { required: true })}
                                            label={t('ptp.amount')}
                                            type={"number"}
                                            error={!!errors.amount}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field ptpMethodId */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="ptpMethodIdLabel">
                                            Method
                                        </InputLabel>
                                        {allPtpmethod.length > 0 &&
                                            <Select labelId="ptpMethodIdLabel"
                                                id="ptpMethodIdSelect"
                                                {...register("ptpMethodId", { required: true })}
                                                error={!!errors.ptpMethodId}
                                                value={watch('ptpMethodId')}
                                                label="Method">
                                                {
                                                    allPtpmethod.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                            key={el.id}> {el.name}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allPtpmethod.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                    disabled
                                                    id="ptpMethodIdSelect"
                                                    {...register("ptpMethodId", { required: false })}
                                                    error={!!errors.ptpMethodId}
                                                    value={watch('ptpMethodId')}
                                                    label="ptpMethodId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* Field bankDetailsId */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="bankDetailsIdLabel">
                                            Bank
                                        </InputLabel>
                                        {allBankdetail.length > 0 &&
                                            <Select labelId="bankDetailsIdLabel"
                                                id="bankDetailsIdSelect"
                                                {...register("bankDetailsId", { required: true })}
                                                error={!!errors.bankDetailsId}
                                                value={watch('bankDetailsId')}
                                                label="Bank">
                                                {
                                                    allBankdetail.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                            key={el.id}> {el.bank}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allBankdetail.length == 0 &&
                                            <>
                                                <Select labelId="bankDetailsIdLabel"
                                                    disabled
                                                    id="bankDetailsIdSelect"
                                                    {...register("bankDetailsId", { required: false })}
                                                    error={!!errors.bankDetailsId}
                                                    value={watch('bankDetailsId')}
                                                    label="bankDetailsId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* Field isRecurring */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <InputLabel id="isRecurringInput">
                                            {t('ptp.isRecurring')}
                                        </InputLabel>
                                        <Select labelId="isRecurringInput"
                                            id="bankDetailsIdSelect"
                                            {...register("isRecurring", { required: false })}
                                            error={!!errors.isRecurring}
                                            value={isRecurringValue}
                                            label={t('ptp.isRecurring')}
                                            onChange={handleIsRecurringChange}>

                                            <MenuItem value="1">True</MenuItem>
                                            <MenuItem value="0">False</MenuItem>

                                        </Select>
                                    </FormControl>
                                </div>

                                {/* Field paymentInterval */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="paymentIntervalInput"
                                            {...register("paymentInterval", { required: false })}
                                            label={t('ptp.paymentInterval')}
                                            type={"number"}
                                            error={!!errors.paymentInterval}
                                            disabled={isRecurringValue !== "1"} // Disable when isRecurring is not "1"
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field numberOfPayments */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="numberOfPaymentsInput"
                                            {...register("numberOfPayments", { required: false })}
                                            label={t('ptp.numberOfPayments')}
                                            type={"number"}
                                            error={!!errors.numberOfPayments}
                                            disabled={isRecurringValue !== "1"} // Disable when isRecurring is not "1"
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>




                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                type="submit"
                                variant="contained"
                                className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default PtpForm;
