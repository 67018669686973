import React, {useEffect} from 'react';
import {useStyles} from './Styles';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import {post, getAll} from "../../../../../Services/GenericApiService";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {log} from "../../../../../Services/LoggerService";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import {Button, FormControl, TextField, FormControlLabel, Checkbox, FormHelperText} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import {DatePicker} from '@mui/lab';


// @ts-ignore
function UserForm({handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent}) {
    // css for module
    const classes = useStyles();

    // get agent id from env
    const agentRoleId = process.env.REACT_APP_AGENT_ROLE_ID;

    // router object
    const navigate = useNavigate();

    // module(s) for api
    const moduleMain = 'user';

    // linked components API modules (endpoints)
    const moduleRole = 'role';
    const moduleTeam = 'team';

    // translation
    const {t} = useTranslation();

    // UI methods
    const [loading, setLoading] = React.useState(true);

    // form data
    const {register, handleSubmit, watch, reset, setValue, formState: {errors}} = useForm(); // watch is used to get the value e.g watch("name")
    const [selectedTeams, setSelectedTeams] = React.useState([]);   // store teams seperately to validate team (one or multiple) based on role when submitting
    
    // form methods
    const onSubmit = (_data: any) => {
        _data.teamIds = selectedTeams;

        if (!isEditMode) {
            handleAddEvent(_data);
        } else {
            handleEditEvent(_data);
        }
    }

    // data vars
    const [isCheckedIsactive, setIsCheckedIsactive] = React.useState(false);
    const [isCheckedIsmicrosoftlinked, setIsCheckedIsmicrosoftlinked] = React.useState(false);
    const [isCheckedIsgooglelinked, setIsCheckedIsgooglelinked] = React.useState(false);


    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    // fetch dependent data
    const [allRole, setAllRole] = React.useState([]);
    const [allTeams, setAllTeams] = React.useState([]);


    const fetch = () => {
        fetchRole();
        fetchTeam();
    }

    // fetch Role
    const fetchRole = () => {
        setLoading(true); // show loader
        getAll(moduleRole)
            .then((_res: any) => {
                setAllRole(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch Teams
    const fetchTeam = () => {
        setLoading(true); // show loader
        getAll(moduleTeam)
            .then((_res: any) => {
                setAllTeams(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // hooks
    useEffect(() => {
        fetch();
        if (isEditMode) {
            reset(recordToUpdate);

            setIsCheckedIsactive(recordToUpdate.isActive);
            setIsCheckedIsmicrosoftlinked(recordToUpdate.isMicrosoftLinked);
            setIsCheckedIsgooglelinked(recordToUpdate.isGoogleLinked);
            
            // @ts-ignore
            let teams: any[] = [];
            recordToUpdate.userUserTeam.forEach((_team: any) => {
                teams.push(_team.teamId)
            })
            if(selectedTeams != teams){
                // @ts-ignore
                setSelectedTeams(teams);
            }
        }
    }, []);


    return (
        <Box sx={{display: 'flex'}}>
            <Card sx={{minWidth: 275}}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.user')}
                                    </h3>

                                </div>


                                {/* Field firstName */}
                                {!isEditMode &&
                                    <div className="col-md-6 mt-4">
                                        <FormControl fullWidth>
                                            <TextField id="firstNameInput"
                                                       {...register("firstName", {required: true})}
                                                       label={t('user.firstName')}
                                                       type={"text"}
                                                       error={!!errors.firstName}
                                                       variant="outlined"
                                                       className="full-width"/>
                                        </FormControl>
                                    </div>
                                }

                                {/* Field middleName */}
                                {!isEditMode &&
                                    <div className="col-md-6 mt-4">
                                        <FormControl fullWidth>
                                            <TextField id="middleNameInput"
                                                       {...register("middleName", {required: false})}
                                                       label={t('user.middleName')}
                                                       type={"text"}
                                                       error={!!errors.middleName}
                                                       variant="outlined"
                                                       className="full-width"/>
                                        </FormControl>
                                    </div>
                                }

                                {/* Field lastName */}
                                {!isEditMode &&
                                    <div className="col-md-6 mt-4">
                                        <FormControl fullWidth>
                                            <TextField id="lastNameInput"
                                                       {...register("lastName", {required: true})}
                                                       label={t('user.lastName')}
                                                       type={"text"}
                                                       error={!!errors.lastName}
                                                       variant="outlined"
                                                       className="full-width"/>
                                        </FormControl>
                                    </div>
                                }

                                {/* Field email */}
                                {!isEditMode &&
                                    <div className="col-md-6 mt-4">
                                        <FormControl fullWidth>
                                            <TextField id="emailInput"
                                                       {...register("email", {required: true})}
                                                       label={t('user.email')}
                                                       type={"text"}
                                                       error={!!errors.email}
                                                       variant="outlined"
                                                       className="full-width"/>
                                        </FormControl>
                                    </div>
                                }

                                {/* Field contact */}
                                {!isEditMode &&
                                    <div className="col-md-6 mt-4">
                                        <FormControl fullWidth>
                                            <TextField id="contactInput"
                                                       {...register("contact", {required: true})}
                                                       label={t('user.contact')}
                                                       type={"text"}
                                                       error={!!errors.contact}
                                                       variant="outlined"
                                                       className="full-width"/>
                                        </FormControl>
                                    </div>
                                }

                                {/* Field roleId */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="roleIdLabel">
                                            Role
                                        </InputLabel>
                                        {allRole.length > 0 &&
                                            <Select labelId="roleIdLabel"
                                                    id="roleIdSelect"
                                                    {...register("roleId", {required: false})}
                                                    error={!!errors.roleId}
                                                    value={watch('roleId')}
                                                    label="roleId">
                                                {
                                                    allRole.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                                  key={el.id}> {el.title}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allRole.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                        disabled
                                                        id="roleIdSelect"
                                                        {...register("roleId", {required: false})}
                                                        error={!!errors.roleId}
                                                        value={watch('roleId')}
                                                        label="roleId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* Field teamId */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="teamIdLabel">
                                            Team
                                        </InputLabel>
                                        {allTeams.length > 0 &&
                                            <Select labelId="teamIdLabel"
                                                    id="teamIdSelect"
                                                    multiple={watch('roleId') != agentRoleId}
                                                    onChange={(event: any) => {
                                                        if(watch('roleId') != agentRoleId){
                                                            setSelectedTeams(event.target.value)
                                                        } else {
                                                            // @ts-ignore
                                                            setSelectedTeams([event.target.value])
                                                        }
                                                    }}
                                                    value={selectedTeams}
                                                    label="teamId">
                                                {
                                                    allTeams.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                                  key={el.id}> {el.name}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allTeams.length == 0 &&
                                            <>
                                                <Select labelId="teamIdLabel"
                                                        disabled
                                                        id="teamIdSelect"
                                                        {...register("teamId", {required: false})}
                                                        error={!!errors.roleId}
                                                        value={watch('teamId')}
                                                        label="teamId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* Field isActive */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth error={!!errors.isActive}>
                                        <FormControlLabel
                                            {...register("isActive", {})}
                                            label={t('user.isActive')}
                                            control={<Checkbox checked={isCheckedIsactive}
                                                               onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
                                                                   setIsCheckedIsactive(_event.target.checked)
                                                                   setValue("isActive", _event.target.checked);
                                                               }}/>}
                                        />
                                    </FormControl>
                                </div>


                                {/*/!* Field isMicrosoftLinked *!/*/}
                                {/*<div className="col-md-6 mt-4">*/}
                                {/*    <FormControl fullWidth error={!!errors.isMicrosoftLinked}>*/}
                                {/*        <FormControlLabel*/}
                                {/*            {...register("isMicrosoftLinked", {})}*/}
                                {/*            label={t('user.isMicrosoftLinked')}*/}
                                {/*            control={<Checkbox checked={isCheckedIsmicrosoftlinked}*/}
                                {/*                               onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {*/}
                                {/*                                   setIsCheckedIsmicrosoftlinked(_event.target.checked)*/}
                                {/*                                   setValue("isMicrosoftLinked", _event.target.checked);*/}
                                {/*                               }}/>}*/}
                                {/*        />*/}
                                {/*    </FormControl>*/}
                                {/*</div>*/}


                                {/*/!* Field isGoogleLinked *!/*/}
                                {/*<div className="col-md-6 mt-4">*/}
                                {/*    <FormControl fullWidth error={!!errors.isGoogleLinked}>*/}
                                {/*        <FormControlLabel*/}
                                {/*            {...register("isGoogleLinked", {})}*/}
                                {/*            label={t('user.isGoogleLinked')}*/}
                                {/*            control={<Checkbox checked={isCheckedIsgooglelinked}*/}
                                {/*                               onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {*/}
                                {/*                                   setIsCheckedIsgooglelinked(_event.target.checked)*/}
                                {/*                                   setValue("isGoogleLinked", _event.target.checked);*/}
                                {/*                               }}/>}*/}
                                {/*        />*/}
                                {/*    </FormControl>*/}
                                {/*</div>*/}


                                {/* Field password */}
                                {/*<div className="col-md-6 mt-4">*/}
                                {/*    <FormControl fullWidth>*/}
                                {/*        <TextField id="passwordInput"*/}
                                {/*                   {...register("password", {required: true})}*/}
                                {/*                   label={t('user.password')}*/}
                                {/*                   type={"text"}*/}
                                {/*                   error={!!errors.password}*/}
                                {/*                   variant="outlined"*/}
                                {/*                   className="full-width"/>*/}
                                {/*    </FormControl>*/}
                                {/*</div>*/}

                                {/* Field token */}
                                {/*<div className="col-md-6 mt-4">*/}
                                {/*    <FormControl fullWidth>*/}
                                {/*        <TextField id="tokenInput"*/}
                                {/*                   {...register("token", {required: false})}*/}
                                {/*                   label={t('user.token')}*/}
                                {/*                   type={"text"}*/}
                                {/*                   error={!!errors.token}*/}
                                {/*                   variant="outlined"*/}
                                {/*                   className="full-width"/>*/}
                                {/*    </FormControl>*/}
                                {/*</div>*/}

                                {/* Field lastLogin */}
                                {!isEditMode &&
                                    <div className="col-md-6 mt-4">
                                        <FormControl fullWidth>
                                            <TextField id="lastLoginInput"
                                                       {...register("lastLogin", {required: false})}
                                                       label={t('user.lastLogin')}
                                                       type={"text"}
                                                       error={!!errors.lastLogin}
                                                       variant="outlined"
                                                       className="full-width"/>
                                        </FormControl>
                                    </div>
                                }


                                {/* Field legalAgent */}
                                {/*<div className="col-md-6 mt-4">*/}
                                {/*    <FormControl fullWidth>*/}
                                {/*        <TextField id="legalAgentInput"*/}
                                {/*                   {...register("legalAgent", {required: false})}*/}
                                {/*                   label={t('user.legalAgent')}*/}
                                {/*                   type={"text"}*/}
                                {/*                   error={!!errors.legalAgent}*/}
                                {/*                   variant="outlined"*/}
                                {/*                   className="full-width"/>*/}
                                {/*    </FormControl>*/}
                                {/*</div>*/}

                                {/* Field idNumber */}
                                {/*<div className="col-md-6 mt-4">*/}
                                {/*    <FormControl fullWidth>*/}
                                {/*        <TextField id="idNumberInput"*/}
                                {/*                   {...register("idNumber", {required: false})}*/}
                                {/*                   label={t('user.idNumber')}*/}
                                {/*                   type={"text"}*/}
                                {/*                   error={!!errors.idNumber}*/}
                                {/*                   variant="outlined"*/}
                                {/*                   className="full-width"/>*/}
                                {/*    </FormControl>*/}
                                {/*</div>*/}


                                {/* Field phoneUsername */}
                                {/*<div className="col-md-6 mt-4">*/}
                                {/*    <FormControl fullWidth>*/}
                                {/*        <TextField id="phoneUsernameInput"*/}
                                {/*                   {...register("phoneUsername", {required: false})}*/}
                                {/*                   label={t('user.phoneUsername')}*/}
                                {/*                   type={"text"}*/}
                                {/*                   error={!!errors.phoneUsername}*/}
                                {/*                   variant="outlined"*/}
                                {/*                   className="full-width"/>*/}
                                {/*    </FormControl>*/}
                                {/*</div>*/}

                                {/* Field phonePassword */}
                                {/*<div className="col-md-6 mt-4">*/}
                                {/*    <FormControl fullWidth>*/}
                                {/*        <TextField id="phonePasswordInput"*/}
                                {/*                   {...register("phonePassword", {required: false})}*/}
                                {/*                   label={t('user.phonePassword')}*/}
                                {/*                   type={"text"}*/}
                                {/*                   error={!!errors.phonePassword}*/}
                                {/*                   variant="outlined"*/}
                                {/*                   className="full-width"/>*/}
                                {/*    </FormControl>*/}
                                {/*</div>*/}

                                {/* Field username */}
                                {/*<div className="col-md-6 mt-4">*/}
                                {/*    <FormControl fullWidth>*/}
                                {/*        <TextField id="usernameInput"*/}
                                {/*                   {...register("username", {required: false})}*/}
                                {/*                   label={t('user.username')}*/}
                                {/*                   type={"text"}*/}
                                {/*                   error={!!errors.username}*/}
                                {/*                   variant="outlined"*/}
                                {/*                   className="full-width"/>*/}
                                {/*    </FormControl>*/}
                                {/*</div>*/}


                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{width: '100%', textAlign: 'right'}}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                    onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                           type="submit"
                                           variant="contained"
                                           className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default UserForm;
