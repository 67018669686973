// @ts-nocheck
import { Box, Paper, Skeleton, OutlinedInput, InputAdornment, Button } from '@mui/material';
import { log } from '../../../../../Services/LoggerService';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatData } from '../../../../../Services/DataFormatService';
import { getAll, patch, post } from '../../../../../Services/GenericApiService';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

const ActionReportTable = ({ edcId, edcConversion }) => {
    // translation
    const { t } = useTranslation();

    // module(s) for api
    const moduleMain = 'actionreport/';

    // data vars
    const [ptpCountReport, setPtpCountReport] = useState([]);
    const [totalCollectionReport, setTotalCollectionReport] = useState([]);
    const [totalCollectionReportSum, setTotalCollectionReportSum] = useState(0);
    const [accountProjectedCashPtp_A, setAccountProjectedCashPtp_A] = useState(0);
    const [amountProjectedCashPtp_A, setAmountProjectedCashPtp_A] = useState(0);
    const [conversionRate, setConversionRate] = useState(0);
    const [accountProjectedCashPtp_ACal, setAccountProjectedCashPtp_ACal] = useState(0);
    const [amountProjectedCashPtp_ACal, setAmountProjectedCashPtp_ACal] = useState(0);
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [wokredMonth, setWorkedMonth] = useState(dayjs().format('MMMM - YYYY'));
    const [wokredAccount, setWorkedAccount] = useState(0);
    const [longDateStart, setLongDateStart] = useState("");
    const [longDateEnd, setLongDateEnd] = useState("");
    const [allData, setAllData] = React.useState([]);
    const [projectedCash, setProjectedCash] = useState(0);

    let columns = [
        { name: "Account Number", options: { filterOptions: { filter: false, sort: false, } } },
        { name: "Action Type", options: { filterOptions: { filter: false, sort: false, } } },
        { name: "Action Outcome", options: { filterOptions: { filter: false, sort: false, } } },
        { name: "Reference", options: { filterOptions: { filter: false, sort: false, } } },
        { name: "Comment", options: { filterOptions: { filter: false, sort: false, } } },
        { name: "Amount", options: { filterOptions: { filter: false, sort: false, } } },
        { name: "Action Date", options: { filterOptions: { filter: false, sort: false, } } },
        { name: "Due Date", options: { filterOptions: { filter: false, sort: false, } } },

    ];
    const [tableData, setTableData] = useState([]);

    // ui controls
    const [tabValue, setTabValue] = useState('1');
    const [loading, setLoading] = useState(true);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    function calculatePtpCount(a, b) {
        // Check if a and b are not null and not zero
        if (a != null && b != null && a !== 0 && b !== 0) {
            // Perform the calculation
            let result = (parseFloat(b) / parseFloat(a)) * 100;
            // Round the result to two decimal places
            return result.toFixed(2);
        } else {
            return 0; // or throw an error or handle as needed
        }
    }

    // Function to format date as yyyy-mm-dd
    function formatDateYYYYMMDD(date) {
        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, '0');
        let day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    // Function to format date as 24 June 2024
    function formatDateLong(date) {
        let day = date.getDate();
        let monthNames = [
            "January", "February", "March", "April", "May", "June", "July",
            "August", "September", "October", "November", "December"
        ];
        let month = monthNames[date.getMonth()];
        let year = date.getFullYear();
        return `${day} ${month} ${year}`;
    }


    function collectionSum(dataArray) {
        return dataArray.reduce((total, item) => {
            return total + item.collectionAmount;
        }, 0);
    }

    function sumNumbers(a, b) {
        // Check if the numbers are undefined or null and treat them as 0 if they are
        const num1 = a != null ? a : 0;
        const num2 = b != null ? b : 0;

        return num1 + num2;
    }

    function subtractNumbers(a, b) {
        // Check if the numbers are undefined or null and treat them as 0 if they are
        const num1 = a != null ? a : 0;
        const num2 = b != null ? b : 0;

        return num1 - num2;
    }

    const formatAmount = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(amount);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
        if (newValue == 3) {
            if (accountProjectedCashPtp_A > 0) {
                const percentage = (conversionRate / 100) * accountProjectedCashPtp_A;
                setAccountProjectedCashPtp_ACal(percentage);
            }
            if (amountProjectedCashPtp_A > 0) {
                const percentage = (conversionRate / 100) * amountProjectedCashPtp_A;
                setAmountProjectedCashPtp_ACal(percentage);
            }
        }
    };

    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
    };

    // authentication methods
    const getWorkedAccount = (_data: any) => {
        setLoading(true); // show loader
        const formattedDate = dayjs(selectedDate).format('YYYY-MM');

        post(moduleMain + "workaccount/total", { date: formattedDate })
            .then(_res => {
                if (_res.data.length > 0) {
                    const tempDate = dayjs(selectedDate).format('MMMM - YYYY');
                    setWorkedMonth(tempDate);
                    setWorkedAccount(_res.data[0].distinctAccountCount);
                } else {
                    const tempDate = dayjs(selectedDate).format('MMMM - YYYY');
                    setWorkedMonth(tempDate);
                    setWorkedAccount(0);
                }
                setLoading(false); // hide loader
            })
            .catch(_err => {
                setLoading(false); // hide loader
            })
    }


    // function to set new standard weight temp

    // fetch dependent data
    const fetch = () => {
        setTableData([]);  // reset table data
        setLoading(true); // show loader
        getAll(moduleMain + 'edc/' + edcId, currentPage, pageSize)
            .then((_res: any) => {
                // set all data
                setAllData(_res.data.rows);
                setPtpCountReport(_res.data.ptpCountReport);
                setTotalCollectionReport(_res.data.totalCollection);
                setTotalCollectionReportSum(collectionSum(_res.data.totalCollection));
                setProjectedCash(_res.data.projectedCash);
                // set total count
                setTotalRowCount(_res.data.count);

                const rows: any = [];
                _res.data.rows.forEach((_record: any) => {
                    rows.push(formatData([_record?.accountName, _record?.actionType, _record?.actionOutcome || null, _record?.reference, _record?.comment || null, _record?.amount, _record?.actionDate, _record?.dueDate || null]))
                });

                setTableData(rows);

                if (_res.data.ptpCountReport) {
                    const ptpAData = _res.data.ptpCountReport.find(item => item.actionOutcome === "PTP_A");
                    if (ptpAData) {
                        setAccountProjectedCashPtp_A(ptpAData.ptpCountAccounts);
                        setAmountProjectedCashPtp_A(ptpAData.ptpSumAmounts);

                        if (ptpAData.ptpCountAccounts > 0) {
                            const percentage = (conversionRate / 100) * ptpAData.ptpCountAccounts;
                            setAccountProjectedCashPtp_ACal(percentage);
                        }
                        if (ptpAData.ptpSumAmounts > 0) {
                            const percentage = (conversionRate / 100) * ptpAData.ptpSumAmounts;
                            setAmountProjectedCashPtp_ACal(percentage);
                        }
                    }
                }

                // calculation for cash projected
                let today = new Date();
                // Store today's date in long format 24 june 2024
                setLongDateStart(formatDateLong(today));

                // Create a new Date object for the date one month from today
                let nextMonthDate = new Date(today);
                nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
                nextMonthDate.setDate(nextMonthDate.getDate() - 1);

                // Store today's date in long format 24 june 2024
                setLongDateEnd(formatDateLong(nextMonthDate));

                getWorkedAccount();
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    useEffect(() => {
        fetch();
    }, [currentPage, pageSize])

    useEffect(() => {
        setConversionRate(edcConversion);
    }, [edcConversion])


    let options = {
        search: true,
        download: true,
        print: true,
        viewColumns: true,
        filter: true,
        filterType: "textField",
        responsive: "simple",
        selectableRowsHideCheckboxes: true,
        sort: true,
        onChangePage: (currentPage) => { setCurrentPage(currentPage); },
        onChangeRowsPerPage: (numberOfRows) => { setPageSize(numberOfRows); },
        page: currentPage,
        count: totalRowCount,
        serverSide: true,
        loading: true,
        rowsPerPageOptions: [25, 50, 75, 100],
        rowsPerPage: pageSize,
        textLabels: {
            body: {
                noMatch: loading ?
                    <Box>
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                    </Box> :
                    'Sorry, there is no matching data to display',
            },
        },
        onTableChange: () => {
        }
    };

    if (tableData.length === 0) {
        options = {
            textLabels: {
                body: {
                    noMatch: loading ?
                        <Box>
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                        </Box> :
                        'Sorry, there is no matching data to display',
                },
            },
        };
    }

    return (
        <>
            {/* Filters */}
            <div className="row mt-1 mb-5 px-2">
                <div className={" card shadow-lg p-4"} style={{ boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)' }}>
                    <div className="row mb-2 d-flex">
                        <div className="col-md-3 justify-content-center text-start align-items-center">
                            {tabValue == 1 &&
                                <h3 className='pt-2'>PTP Report</h3>
                            }
                        </div>
                        <div className="col-md-9">
                            <TabContext value={tabValue}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <Tab label="PTP Count" value="1" />
                                        <Tab label="Total Collections" value="2" />
                                        <Tab label="Projected Cash" value="3" />
                                        <Tab label="Total Worked Account" value="4" />
                                    </TabList>
                                </Box>
                            </TabContext>
                        </div>
                    </div>
                    <div>
                        {/* Ptp Count */}
                        {tabValue === "1" &&
                            <>
                                <div className="row mt-4">
                                    <h5>Total Rows</h5>
                                    <p>{totalRowCount}</p>
                                </div>
                                <div className="row">
                                    <div className="col-md-2"></div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-2">
                                        <b>No. of PTP's</b>
                                    </div>
                                    <div className="col-md-2">
                                        <b>Value of PTP's</b>
                                    </div>
                                </div>

                                {ptpCountReport && ptpCountReport.length > 0 &&
                                    <>
                                        <div className="row mt-4">
                                            <div className="col-md-2">
                                                <p>PTP Arrangments</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>Sum of PTP_A</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>{ptpCountReport[1].ptpCountAccounts ? ptpCountReport[1].ptpCountAccounts : 0}</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>{`R ${formatAmount(ptpCountReport[1].ptpSumAmounts ? ptpCountReport[1].ptpSumAmounts : 0)}`}</p>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-2">
                                                <p>PTP Settlements</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>Sum of PTP_S</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>{ptpCountReport[2].ptpCountAccounts ? ptpCountReport[2].ptpCountAccounts : 0}</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>{`R ${formatAmount(ptpCountReport[2].ptpSumAmounts ? ptpCountReport[2].ptpSumAmounts : 0)}`}</p>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-2">
                                            </div>
                                            <div className="col-md-2">
                                                <b>Total</b>
                                            </div>
                                            <div className="col-md-2">
                                                <b>
                                                    <p>{sumNumbers(ptpCountReport[1].ptpCountAccounts, ptpCountReport[2].ptpCountAccounts)}</p>
                                                </b>
                                            </div>
                                            <div className="col-md-2">
                                                <b>
                                                    <p>{`R ${formatAmount(sumNumbers(ptpCountReport[1].ptpSumAmounts, ptpCountReport[2].ptpSumAmounts))}`}</p>
                                                </b>
                                            </div>
                                        </div>
                                        {/* -------------------------------2nd part of first one------------------------------ */}
                                        <div className="row mt-4">
                                            <div className="col-md-2">
                                                <p>PTP Arrangments Paid</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>Sum of A_Paid</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>{ptpCountReport[0].ptpCountAccounts ? ptpCountReport[0].ptpCountAccounts : 0}</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>{`R ${formatAmount(ptpCountReport[0].ptpSumAmounts ? ptpCountReport[0].ptpSumAmounts : 0)}`}</p>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-2">
                                                <p>PTP Settlements Paid</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>Sum of S_Paid</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>{ptpCountReport[3].ptpCountAccounts ? ptpCountReport[3].ptpCountAccounts : 0}</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>{`R ${formatAmount(ptpCountReport[3].ptpSumAmounts ? ptpCountReport[3].ptpSumAmounts : 0)}`}</p>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-2">
                                            </div>
                                            <div className="col-md-2">
                                                <b>Total</b>
                                            </div>
                                            <div className="col-md-2">
                                                <b>
                                                    <p>{sumNumbers(ptpCountReport[0].ptpCountAccounts, ptpCountReport[3].ptpCountAccounts)}</p>
                                                </b>
                                            </div>
                                            <div className="col-md-2">
                                                <b>
                                                    <p>{`R ${formatAmount(sumNumbers(ptpCountReport[0].ptpSumAmounts, ptpCountReport[3].ptpSumAmounts))}`}</p>
                                                </b>
                                            </div>
                                        </div>

                                        {/* -------------------------------final part first one------------------------------ */}
                                        <div className="row mt-3">
                                            <div className="col-md-2">
                                                <p>Total PTP's</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>PTP_A + PTP_S</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>{sumNumbers(ptpCountReport[1].ptpCountAccounts, ptpCountReport[2].ptpCountAccounts)}</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>{`R ${formatAmount(sumNumbers(ptpCountReport[1].ptpSumAmounts, ptpCountReport[2].ptpSumAmounts))}`}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2">
                                                <p>Paid PTP's</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>Sum of PTP_A & PTP_S for account no's of PTP_A and PTP_S </p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>{ptpCountReport[4].accountCount ? ptpCountReport[4].accountCount : 0}</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>{`R ${formatAmount(ptpCountReport[4].totalAmount ? ptpCountReport[4].totalAmount : 0)}`}</p>
                                            </div>
                                        </div>

                                        {/* conversionRate */}
                                        <div className="row">
                                            <div className="col-md-2">
                                                Conversion Rate
                                            </div>
                                            <div className="col-md-2">
                                                PTP's Paid / Total PTP's
                                            </div>
                                            <div className="col-md-2">
                                                {`${calculatePtpCount(sumNumbers(ptpCountReport[1].ptpCountAccounts, ptpCountReport[2].ptpCountAccounts), ptpCountReport[4].accountCount)}%`}
                                            </div>
                                            <div className="col-md-2">
                                                <p>{`${calculatePtpCount(sumNumbers(ptpCountReport[1].ptpSumAmounts, ptpCountReport[2].ptpSumAmounts), ptpCountReport[4].totalAmount)}%`}</p>
                                            </div>
                                        </div>
                                    </>
                                }
                            </>
                        }

                        {/* Total Collections */}
                        {tabValue === "2" &&
                            <>

                                <div className="row mt-4">
                                    <h5>Total Rows</h5>
                                    <p>{totalRowCount}</p>
                                </div>

                                {totalCollectionReport && totalCollectionReport.length > 0 &&
                                    <>
                                        <div className="row mt-4">
                                            <div className="col-md-2">
                                                <p>Paid Arrangments</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>Sum of A_Paid</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>{`R ${formatAmount(totalCollectionReport[0].collectionAmount ? totalCollectionReport[0].collectionAmount : 0)}`}</p>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-2">
                                                <p>Paid Settlements</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>Sum of S_Paid</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>{`R ${formatAmount(totalCollectionReport[3].collectionAmount ? totalCollectionReport[3].collectionAmount : 0)}`}</p>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-2">
                                            </div>
                                            <div className="col-md-2">
                                                <b>Total</b>
                                            </div>
                                            <div className="col-md-2">
                                                <b>
                                                    <p>{`R ${formatAmount(sumNumbers(totalCollectionReport[0].collectionAmount, totalCollectionReport[3].collectionAmount))}`}</p>
                                                </b>
                                            </div>
                                        </div>
                                        {/* -------------------------------2nd part------------------------------ */}
                                        <div className="row mt-4">
                                            <div className="col-md-2">
                                                <p>Returned Arrangments</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>Sum of RD_A_Paid</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>{`R ${formatAmount(totalCollectionReport[1].collectionAmount ? totalCollectionReport[1].collectionAmount : 0)}`}</p>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-2">
                                                <p>Returned Settlements</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>Sum of RD_S_Paid</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>{`R ${formatAmount(totalCollectionReport[2].collectionAmount ? totalCollectionReport[2].collectionAmount : 0)}`}</p>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-2">
                                            </div>
                                            <div className="col-md-2">
                                                <b>Total</b>
                                            </div>
                                            <div className="col-md-2">
                                                <b>
                                                    <p>{`R ${formatAmount(sumNumbers(totalCollectionReport[1].collectionAmount, totalCollectionReport[2].collectionAmount))}`}</p>
                                                </b>
                                            </div>
                                        </div>

                                        {/* -------------------------------final part------------------------------ */}
                                        <div className="row mt-2">
                                            <div className="col-md-2">
                                            </div>
                                            <div className="col-md-2">
                                                <b>Net Total Payment Received</b>
                                            </div>
                                            <div className="col-md-2">
                                                <b>
                                                    <p>{`R ${formatAmount(subtractNumbers(
                                                        sumNumbers(totalCollectionReport[0].collectionAmount, totalCollectionReport[3].collectionAmount),
                                                        sumNumbers(totalCollectionReport[1].collectionAmount, totalCollectionReport[2].collectionAmount)
                                                    ))}`}</p>
                                                </b>
                                            </div>
                                        </div>
                                    </>
                                }

                            </>
                        }

                        {/* action outcome */}
                        {tabValue === "3" &&
                            <>
                                <div className="row mt-4">
                                    <h5>Total Rows</h5>
                                    <p>{totalRowCount}</p>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-2">
                                        <p>Start Date</p>
                                    </div>
                                    <div className="col-md-2">
                                        <p>{longDateStart}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-2">
                                        <p>End Date</p>
                                    </div>
                                    <div className="col-md-2">
                                        <p>{longDateEnd}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-2">
                                        <p>Monthly Cash Projection</p>
                                    </div>
                                    <div className="col-md-2">
                                        <p>{`R ${formatAmount(projectedCash)}`}</p>
                                    </div>
                                </div>

                            </>
                        }

                        {/* Total Worked Account */}
                        {tabValue === "4" &&
                            <>
                                <div className="row">
                                    <div className="col-md-3">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateCalendar
                                                value={selectedDate}
                                                views={['year', 'month']}
                                                openTo="month"
                                                onChange={handleDateChange}
                                            />
                                        </LocalizationProvider>

                                        <Button
                                            className='mt-2 ms-2'
                                            variant="contained"
                                            onClick={getWorkedAccount}
                                            fullWidth>
                                            Apply Filter
                                        </Button>
                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-6 mt-4">
                                        {!loading &&
                                            <>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <p><b>Month</b></p>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <p>{wokredMonth}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <p><b>Accounts Worked</b></p>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <p>{wokredAccount}</p>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {loading &&
                                            <>
                                                <Skeleton className="my-4" />
                                                <Skeleton className="my-4" />
                                            </>
                                        }

                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>


            <div>
                <Paper elevation={12}>
                    <MUIDataTable
                        title={t('common.allRecords')}
                        data={tableData}
                        columns={columns}
                        // @ts-ignore
                        options={options}
                    />
                </Paper>
            </div>
        </>

    );
};

export default ActionReportTable;
