// @ts-nocheck

import React, {useEffect, useState, useRef} from 'react';
import {useStyles} from './Styles';
import '../../../../../index.scss';
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {getAll, getById, post, patch, deleteById} from "../../../../../Services/GenericApiService";
import {formatData} from "../../../../../Services/DataFormatService";
import {useTranslation} from "react-i18next";
import AlertM from '../../../../Helpers/AlertM/AlertM';
import ClaimdetailCreate from "../ClaimdetailCreate/ClaimdetailCreate";
import ClaimdetailUpdate from "../ClaimdetailUpdate/ClaimdetailUpdate";
import ClaimdetailView from "../ClaimdetailView/ClaimdetailView";
import {log} from "../../../../../Services/LoggerService";

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MUIDataTable from "mui-datatables";
import {Button, IconButton, Skeleton} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {library} from '@fortawesome/fontawesome-svg-core';
import Tooltip from '@mui/material/Tooltip';
import ConfirmationDialog from "../../../../Helpers/ConfirmationDialog/ConfirmationDialog";

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function ClaimdetailMain() {
    // css for module
    const classes = useStyles();

    // translation
    const {t} = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // module(s) for api
    const moduleMain = 'claimdetail';

    // data vars
    const [allData, setAllData] = React.useState([]);
    const [dataToEdit, setDataToEdit] = React.useState({});

    let columns = [
                {name: "Arrears to Collect", options: {filterOptions: {fullWidth: true}}},
        {name: "Balance", options: {filterOptions: {fullWidth: true}}},
        {name: "Last Payment Date", options: {filterOptions: {fullWidth: true}}},
        {name: "Last Payment Amount", options: {filterOptions: {fullWidth: true}}},
        {name: "Status Description", options: {filterOptions: {fullWidth: true}}},
        {name: "Account Ending With", options: {filterOptions: {fullWidth: true}}},
        {name: "Contract End Date", options: {filterOptions: {fullWidth: true}}},

        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                setCellHeaderProps: () => { return { align: "right" } },
                setCellProps: () => ({
                    align: "right"
                }),
                customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
                    return (
                        <div className='d-inline-flex'>
                            <div className="px-2">
                                <Tooltip title={t('common.edit')}>
                                    <IconButton aria-label="delete" size="small" onClick={() => {
                                            onEditClick(dataIndex, rowIndex)
                                        }}>
                                        <FontAwesomeIcon className="edit-btn" icon='edit'/>
                                    </IconButton>
                                </Tooltip>
                            </div>

                            <div className="px-2">
                                    {/*  delete confirmation dialog  */}
                                    <ConfirmationDialog title={'Delete Confirmation'}
                                                        body={'Are you sure you want to delete this record?'}
                                                        type={'delete'}
                                                        isLoading={loading}
                                                        dataItemIdx={dataIndex}
                                                        confirmEvent={handleDelete}/>
                            </div>

                             <div className="px-2">
                                <ClaimdetailView data={allData[rowIndex]} />
                            </div>

                        </div>
                    )
                        ;
                }
            }
        }
    ];
    const [tableData, setTableData] = React.useState([]);

    // ui controls
    const [loading, setLoading] = React.useState(true);
    const [editMode, setEditMode] = React.useState(false);

    // table ui controls
    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [searchBtn, setSearchBtn] = useState(true);
    const [downloadBtn, setDownloadBtn] = useState(true);
    const [printBtn, setPrintBtn] = useState(true);
    const [viewColumnBtn, setViewColumnBtn] = useState(true);
    const [filterBtn, setFilterBtn] = useState(true);


    // fetch dependent data
    const fetch = () => {
        setLoading(true); // show loader
        getAll(moduleMain)
            .then((_res: any) => {
                setAllData(_res)

                const rows: any = [];
                _res.forEach((_record: any) => {
                    rows.push(formatData([_record?.arrearsToCollect, _record?.balance, _record?.lastPaymentDate, _record?.lastPaymentAmount, _record?.statusDescription, _record?.lastFourDigitsAccountNumber, _record?.contractEndDate]))
                });

                setTableData(rows);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    useEffect(() => {
        fetch()
    }, [])

    // event handlers
    const onEditClick = (_dataIndex: any, _rowIndex: any) => {
        setDataToEdit(allData[_dataIndex]);
        setEditMode(true);
    }

    const resetEdit = (_state) => {
        setEditMode(false);
    }

    const handleDelete = (_dataItemIndex: number) => {
        setLoading(true);   // show spinner
        deleteById(moduleMain, allData[_dataItemIndex].id)
            .then(async (_res) => {
                setLoading(false);  // hide loader
                // @ts-ignore
                notifications.current.successAlert(t('common.deleteSuccess'), '');
                fetch();    // refresh data
            })
            .catch(_err => {
                log(_err)
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                setLoading(false); // hide loader
            })
    }


    let options = {
        search: true,
        download: true,
        print: true,
        viewColumns: true,
        filter: true,
        filterType: "textField",
        responsive: "simple",
        // tableBodyHeight: "500px",
        // tableBodyMaxHeight,
        selectableRowsHideCheckboxes: true,
        sort: true,
        textLabels: {
            body: {
                noMatch: loading ?
                    <Box>
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                    </Box> :
                    'Sorry, there is no matching data to display',
            },
        },
        onTableChange: (action: any, state: any) => {
            // console.log(action);
            // console.dir(state);
        }
    };

  if (tableData.length === 0) {
        options = {
            textLabels: {
                body: {
                    noMatch: loading ?
                        <Box>
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                        </Box> :
                        'Sorry, there is no matching data to display',
                },
            },
        };
    }

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>

            {/* Header */}
            <Header/>

            {/* Sider */}
            <Sider/>

            {/* Content */}
            <Box component="main" sx={{flexGrow: 2, p: 3}} mt={10}>
                <div className="px-1 px-md-5">

                    {/* Upper Section */}
                    <div className="row">
                        <div className="col-md-10">
                            <h2 className="mt-3">
                            {t('module.claimdetail')}
                            </h2>
                        </div>
                        <div className="col-md-2">
                            <ClaimdetailCreate refreshDataEvent={fetch}/>
                        </div>
                    </div>

                    <div className="mt-5">
                        <Paper elevation={12}>
                            <MUIDataTable
                                title={t('common.allRecords')}
                                data={tableData}
                                columns={columns}
                                // @ts-ignore
                                options={options}
                            />
                        </Paper>
                    </div>

                </div>

                {/* display edit modal */}
                {editMode &&
                    <div>
                        <ClaimdetailUpdate refreshDataEvent={fetch}
                                        resetEditState={resetEdit}
                                        recordToUpdate={dataToEdit}/>
                    </div>
                }


                 {/* Alerts */}
                <AlertM ref={notifications}/>

            </Box>


        </Box>
    );
}

export default ClaimdetailMain;
