// @ts-nocheck
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    currentMonthData: {
        color: 'yellow'
    },
    prevMonthData: {
        color: 'blue'
    },
    tableBorder: {
        // '& td': {
        //     borderLeft: '1px solid rgba(224, 224, 224, 1)',
        //     borderRight: '1px solid rgba(224, 224, 224, 1)',
        // },
    },
    hrStyle: {
        // margin: '1em auto',
        width: '5em',
        // border: 'none',
        // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
});

export { useStyles }
