// @ts-nocheck

import '../../../../../index.scss';
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import { Button, FormControl, TextField, FormControlLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)


function EdcscorecardRangeMatrix({ rangeMatrix }) {

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Start</TableCell>
                            <TableCell>End</TableCell>
                            <TableCell>Score</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rangeMatrix && rangeMatrix.map((range, index) => (
                            <TableRow key={index}>
                                <TableCell>{range.end === "" ? range.start + '+' : range.start}</TableCell>
                                <TableCell>{range.end}</TableCell>
                                <TableCell>{range.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </>
    );
}

export default EdcscorecardRangeMatrix;
