import React, {useEffect} from 'react';
import {useStyles} from './Styles';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import {post, getAll} from "../../../../../Services/GenericApiService";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {log} from "../../../../../Services/LoggerService";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import {Button, FormControl, TextField, FormControlLabel, Checkbox, FormHelperText} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import {DatePicker} from '@mui/lab';
import FileUploadM from "../../../../Helpers/FileUploadM/FileUploadM";


// @ts-ignore
function CollectionextractForm({handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent}) {
    // css for module
    const classes = useStyles();

    // router object
    const navigate = useNavigate();

    // module(s) for api
    const moduleMain = 'collectionextract';

    // linked components API modules (endpoints)
    

    // translation
    const {t} = useTranslation();

    // UI methods
    const [loading, setLoading] = React.useState(true);

    // form data
    const {register, handleSubmit, watch, reset, setValue, formState: {errors}} = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {
        if (!isEditMode){
            handleAddEvent(_data);
        } else {
            handleEditEvent(_data);
        }
    }

    // data vars
    

    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    // fetch dependent data
    


    const fetch = () => {
        
    }

    

    // hooks
    useEffect(() => {
        fetch();
        if (isEditMode) {
            reset(recordToUpdate);

            
        }
    }, []);


    return (
        <Box sx={{display: 'flex'}}>
            <Card sx={{width: '75vw', maxHeight: '90vh', overflowY: 'auto'}}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.collectionextract')}
                                    </h3>

                                </div>


                                
                                {/* Field accountName */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="accountNameInput"
                                                   {...register("accountName", {required: false})}
                                                   label={t('collectionextract.accountName')}
                                                   type={"text"}
                                                   error={!!errors.accountName}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field mtnAccountStatus */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="mtnAccountStatusInput"
                                                   {...register("mtnAccountStatus", {required: false})}
                                                   label={t('collectionextract.mtnAccountStatus')}
                                                   type={"text"}
                                                   error={!!errors.mtnAccountStatus}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field riskBand */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="riskBandInput"
                                                   {...register("riskBand", {required: false})}
                                                   label={t('collectionextract.riskBand')}
                                                   type={"text"}
                                                   error={!!errors.riskBand}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field currentTreatmentLevel */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="currentTreatmentLevelInput"
                                                   {...register("currentTreatmentLevel", {required: false})}
                                                   label={t('collectionextract.currentTreatmentLevel')}
                                                   type={"text"}
                                                   error={!!errors.currentTreatmentLevel}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field collectionStage */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="collectionStageInput"
                                                   {...register("collectionStage", {required: false})}
                                                   label={t('collectionextract.collectionStage')}
                                                   type={"text"}
                                                   error={!!errors.collectionStage}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field currentStageEntryDate */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="currentStageEntryDateInput"
                                                   {...register("currentStageEntryDate", {required: false})}
                                                   label={t('collectionextract.currentStageEntryDate')}
                                                   type={"text"}
                                                   error={!!errors.currentStageEntryDate}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field msisdn */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="msisdnInput"
                                                   {...register("msisdn", {required: false})}
                                                   label={t('collectionextract.msisdn')}
                                                   type={"text"}
                                                   error={!!errors.msisdn}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field spul */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="spulInput"
                                                   {...register("spul", {required: false})}
                                                   label={t('collectionextract.spul')}
                                                   type={"text"}
                                                   error={!!errors.spul}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field customerName */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="customerNameInput"
                                                   {...register("customerName", {required: false})}
                                                   label={t('collectionextract.customerName')}
                                                   type={"text"}
                                                   error={!!errors.customerName}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field homeContact */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="homeContactInput"
                                                   {...register("homeContact", {required: false})}
                                                   label={t('collectionextract.homeContact')}
                                                   type={"text"}
                                                   error={!!errors.homeContact}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field workContact */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="workContactInput"
                                                   {...register("workContact", {required: false})}
                                                   label={t('collectionextract.workContact')}
                                                   type={"text"}
                                                   error={!!errors.workContact}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field mobileContact */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="mobileContactInput"
                                                   {...register("mobileContact", {required: false})}
                                                   label={t('collectionextract.mobileContact')}
                                                   type={"text"}
                                                   error={!!errors.mobileContact}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field outstandingAmount */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="outstandingAmountInput"
                                                   {...register("outstandingAmount", {required: false})}
                                                   label={t('collectionextract.outstandingAmount')}
                                                   type={"text"}
                                                   error={!!errors.outstandingAmount}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field accountBalance */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="accountBalanceInput"
                                                   {...register("accountBalance", {required: false})}
                                                   label={t('collectionextract.accountBalance')}
                                                   type={"text"}
                                                   error={!!errors.accountBalance}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field b_0_30 */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="b_0_30Input"
                                                   {...register("b_0_30", {required: false})}
                                                   label={t('collectionextract.b_0_30')}
                                                   type={"text"}
                                                   error={!!errors.b_0_30}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field b_31_60 */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="b_31_60Input"
                                                   {...register("b_31_60", {required: false})}
                                                   label={t('collectionextract.b_31_60')}
                                                   type={"text"}
                                                   error={!!errors.b_31_60}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field b_61_90 */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="b_61_90Input"
                                                   {...register("b_61_90", {required: false})}
                                                   label={t('collectionextract.b_61_90')}
                                                   type={"text"}
                                                   error={!!errors.b_61_90}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field b_91_120 */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="b_91_120Input"
                                                   {...register("b_91_120", {required: false})}
                                                   label={t('collectionextract.b_91_120')}
                                                   type={"text"}
                                                   error={!!errors.b_91_120}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field b_121_150 */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="b_121_150Input"
                                                   {...register("b_121_150", {required: false})}
                                                   label={t('collectionextract.b_121_150')}
                                                   type={"text"}
                                                   error={!!errors.b_121_150}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field b_151_180 */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="b_151_180Input"
                                                   {...register("b_151_180", {required: false})}
                                                   label={t('collectionextract.b_151_180')}
                                                   type={"text"}
                                                   error={!!errors.b_151_180}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field b_180_plus */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="b_180_plusInput"
                                                   {...register("b_180_plus", {required: false})}
                                                   label={t('collectionextract.b_180_plus')}
                                                   type={"text"}
                                                   error={!!errors.b_180_plus}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field lastPaymentAmount */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="lastPaymentAmountInput"
                                                   {...register("lastPaymentAmount", {required: false})}
                                                   label={t('collectionextract.lastPaymentAmount')}
                                                   type={"text"}
                                                   error={!!errors.lastPaymentAmount}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field lastPaymentDate */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="lastPaymentDateInput"
                                                   {...register("lastPaymentDate", {required: false})}
                                                   label={t('collectionextract.lastPaymentDate')}
                                                   type={"text"}
                                                   error={!!errors.lastPaymentDate}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field paymentMethod */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="paymentMethodInput"
                                                   {...register("paymentMethod", {required: false})}
                                                   label={t('collectionextract.paymentMethod')}
                                                   type={"text"}
                                                   error={!!errors.paymentMethod}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field paymentDueDate */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="paymentDueDateInput"
                                                   {...register("paymentDueDate", {required: false})}
                                                   label={t('collectionextract.paymentDueDate')}
                                                   type={"text"}
                                                   error={!!errors.paymentDueDate}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field debitOrderDate */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="debitOrderDateInput"
                                                   {...register("debitOrderDate", {required: false})}
                                                   label={t('collectionextract.debitOrderDate')}
                                                   type={"text"}
                                                   error={!!errors.debitOrderDate}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>


                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{width: '100%', textAlign: 'right'}}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                    onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                           type="submit"
                                           variant="contained"
                                           className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default CollectionextractForm;
