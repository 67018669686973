// @ts-nocheck

import React, {useEffect} from 'react';
import {useStyles} from './Styles';
import {styled, useTheme, Theme, CSSObject} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import {expand} from "../Header/Actions"
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {collapse} from "./Actions";
import {useNavigate} from "react-router-dom";
import '../../../index.scss';
import {useLocation} from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

const drawerWidth = parseInt(process.env.REACT_APP_DRAWER_WIDTH)

interface menuItem {
    title: '',
    url: '',
    icon: ''
}

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

function Sider() {
    // Set Theme
    const theme = useTheme();

    // Css for module
    const classes = useStyles();

    // Router object
    const navigate = useNavigate();

    // current location
    const location = useLocation();

    // Redux
    const sider = useSelector(
        (state: RootStateOrAny) => state.sider,
    );

    const header = useSelector(
        (state: RootStateOrAny) => state.header,
    );

    // Dispatcher
    const dispatch = useDispatch()

    // UI vars
    const [open, setOpen] = React.useState(sider.state.isExpanded);

    // Hooks
    useEffect(() => {
        setOpen(sider.state.isExpanded);
    }, [sider]);

    useEffect(() => {
        // setOpen(!header.state.isExpanded)
    }, [header]);


    // UI toggles
    const handleDrawerClose = () => {
        dispatch(collapse()); //sider state collapses
        dispatch(expand()); //header state expands
        // setOpen(false);
    };

    const navigateToComponent = (url) => {
        navigate(url)
    }

    // Menu Items
    const menuItems: any = [
        {
            title: 'Pre Termination Management',
            url: '',
            icon: 'file-excel',
            showDivider: true,
            isHeading: true
        },
        {
            title: 'Import',
            url: '/bookimporter',
            icon: 'upload',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Filter',
            url: '/preterminationfiltering',
            icon: 'filter',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Pre Termination Books',
            url: '/bookdetail',
            icon: 'book',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'EDC',
            url: '/edc',
            icon: 'user',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'EDC Scorecard',
            url: '/edcscorecard',
            icon: 'address-card',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'EDC Configuration',
            url: '/edcConfiguration',
            icon: 'screwdriver-wrench',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Collection Extract',
            url: '/collectionExtract',
            icon: 'piggy-bank',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Genesys Outbound List',
            url: '/genesysOutboundList',
            icon: 'headset',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Wrap up Codes',
            url: '/wrapupCode',
            icon: 'boxes-stacked',
            showDivider: true,
            isHeading: false
        },
        {
            title: 'Collections Management',
            url: '',
            icon: 'file-excel',
            showDivider: true,
            isHeading: true
        },
        {
            title: 'Import',
            url: '/collectionsbookimporter',
            icon: 'upload',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Collection Books',
            url: '/collectionbookdetail',
            icon: 'book',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Campaigns',
            url: '/campaign',
            icon: 'magnifying-glass-chart',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Strategy',
            url: '/strategy',
            icon: 'arrow-down-wide-short',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Locations',
            url: '/location',
            icon: 'location-pin',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Suppliers',
            url: '/supplier',
            icon: 'user',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'PTP Methods',
            url: '/ptpmethod',
            icon: 'flag',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Bank Details',
            url: '/bankdetail',
            icon: 'building-columns',
            showDivider: false,
            isHeading: false
        },
        // {
        //     title: 'Leads',
        //     url: '/leads',
        //     icon: 'fa-solid fa-handshake-angle',
        //     showDivider: false,
        //     isHeading: false
        // },
        {
            title: 'Lead Reports',
            url: '/leads-reports',
            icon: 'fa-solid fa-file-contract',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Assigned Lead',
            url: '/assignedLead',
            icon: 'people-roof',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Debtors Collection',
            url: '/collectionAccount',
            icon: 'play',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Reports',
            url: '',
            icon: 'file-contract',
            showDivider: true,
            isHeading: true
        },
        {
            title: 'Vintage Report',
            url: '/vintageReport',
            icon: 'chart-simple',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Roll Report',
            url: '/rollReport',
            icon: 'chart-gantt',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Commission Report',
            url: '/commissionReport',
            icon: 'chart-bar',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Action Report',
            url: '/actionReport',
            icon: 'chart-area',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Account Management',
            url: '',
            icon: 'user',
            showDivider: true,
            isHeading: true
        },
        {
            title: 'Users',
            url: '/user',
            icon: 'users',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Roles',
            url: '/role',
            icon: 'user-lock',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Teams',
            url: '/team',
            icon: 'users',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Integrations',
            url: '/integrations',
            icon: 'fa-solid fa-gear',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Logout',
            url: '/logout',
            icon: 'power-off',
            showDivider: true,
            isHeading: false
        },
        // {
        //     title: 'Pre-Termination Books',
        //     url: '/preterminationaccounts',
        //     icon: 'chart-bar',
        //     showDivider: false,
        //     isHeading: false
        // },
        // {
        //     title: 'Book Fields',
        //     url: '/bookfield',
        //     icon: 'gear',
        //     showDivider: true,
        //     isHeading: false
        // },
        // {
        //     title: 'Roles',
        //     url: '/role',
        //     icon: 'user-lock',
        //     showDivider: false,
        //     isHeading: false
        // },
        // {
        //     title: 'Account Statuses',
        //     url: '/accountstatus',
        //     icon: 'flag',
        //     showDivider: false,
        //     isHeading: false
        // },
        // {
        //     title: 'Disposition Statuses',
        //     url: '/dispositionstatus',
        //     icon: 'flag',
        //     showDivider: false,
        //     isHeading: false
        // },
        // {
        //     title: 'Agent Account Statues',
        //     url: '/agentaccountstatus',
        //     icon: 'flag',
        //     showDivider: false,
        //     isHeading: false
        // },
        // {
        //     title: 'Reporting Teams',
        //     url: '/reportingteam',
        //     icon: 'users',
        //     showDivider: false,
        //     isHeading: false
        // },
        // {
        //     title: 'Type of Debts',
        //     url: '/typeofdebt',
        //     icon: 'tag',
        //     showDivider: false,
        //     isHeading: false
        // },
        // {
        //     title: 'Accounts',
        //     url: '/account',
        //     icon: 'id-card',
        //     showDivider: false,
        //     isHeading: false
        // },
        // {
        //     title: 'Claim Details',
        //     url: '/claimdetail',
        //     icon: 'file-invoice-dollar',
        //     showDivider: false,
        //     isHeading: false
        // },
        // {
        //     title: 'Accounts Notepad',
        //     url: '/accountnotepad',
        //     icon: 'comment',
        //     showDivider: false,
        //     isHeading: false
        // },
        // {
        //     title: 'Debtors Books',
        //     url: '/bookdebtors',
        //     icon: 'book',
        //     showDivider: false,
        //     isHeading: false
        // },
        // {
        //     title: 'Debtor Agents',
        //     url: '/debtoragent',
        //     icon: 'user',
        //     showDivider: false,
        //     isHeading: false
        // },
        // {
        //     title: 'SMS Templates',
        //     url: '/smstemplate',
        //     icon: 'message',
        //     showDivider: false,
        //     isHeading: false
        // },
        // {
        //     title: 'PTPs',
        //     url: '/ptp',
        //     icon: 'flag',
        //     showDivider: false,
        //     isHeading: false
        // },
        // {
        //     title: 'Secondary Contact Details',
        //     url: '/secondarycontactdetail',
        //     icon: 'address-book',
        //     showDivider: false,
        //     isHeading: false
        // },
        // {
        //     title: 'Contact Types',
        //     url: '/contacttype',
        //     icon: 'address-card',
        //     showDivider: false,
        //     isHeading: false
        // },
        // {
        //     title: 'Party Bank Info',
        //     url: '/partybankinfo',
        //     icon: 'building-columns',
        //     showDivider: false,
        //     isHeading: false
        // },
        // %TEMPLATE_MENU_ITEMS%


    ];

    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader>

                <img className="img-fluid mx-auto" style={{maxWidth: '64px'}}
                     src={require('../../../Assets/img/logo/mtn_alt.jpg')}/>

                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                </IconButton>
            </DrawerHeader>
            <Divider/>
            <List className={'pt-0 mt-0'}>
                {menuItems.map((item: any, index: any) => (
                    <>
                        {/* If menu item */}
                        {!item.isHeading &&
                            <Tooltip title={item.title} placement="right">

                            <ListItemButton
                                className={location.pathname == item.url ? 'bg-custom-primary-faded' : ''}
                                onClick={() => navigateToComponent(item.url)}
                                key={item.title}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                        <FontAwesomeIcon icon={item.icon}/>

                                </ListItemIcon>
                                <ListItemText primary={item.title} sx={{opacity: open ? 1 : 0}}/>
                            </ListItemButton>
                            </Tooltip>

                        }

                        {/* If Heading */}
                        {item.isHeading &&
                            <ListItemButton
                                className={'bg-custom-secondary-faded '}
                                key={item.title}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <FontAwesomeIcon icon={item.icon}/>

                                </ListItemIcon>
                                <ListItemText primary={item.title} sx={{opacity: open ? 1 : 0}}/>
                            </ListItemButton>
                        }
                        {item.showDivider &&
                            <Divider/>
                        }
                    </>
                ))}
            </List>
        </Drawer>
    );
}

export default Sider;
