// @ts-nocheck

import {Box, Checkbox, CssBaseline, FormControlLabel, FormGroup, Button} from '@mui/material';
import React, {useEffect, useState, useRef} from 'react';
import Header from '../../../../Layout/Header/Header';
import Sider from '../../../../Layout/Sider/Sider';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {getAll, getById, post, patch, deleteById} from "../../../../../Services/GenericApiService";
import VintageReportTable from './VintageReportTable';
import {log} from '../../../../../Services/LoggerService';

const VintageReport = () => {


    // get theme from redux
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );

    // APIs module
    const moduleMain = 'vintagerollratereport/'

    // filter object
    const availableFilters = {
        "range": {},
        "filter": {
            "contractStartYear": {
                "displayName": "Year",
                "options": [
                    1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
            },
            "contractStartMonth": {
                "displayName": "Month",
                "options": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            },
            "billCycle": {
                "displayName": "Bill Cycle",
                "options": ["SKA BR1", "SKA BR 5", "SKA BR 11", "SKA BR 16", "SKA BR 21", "SKA BR M2M"]
            },
            "paymentMethod": {
                "displayName": "Payment Method",
                "options": ["Auto-Debit", "Cash", "Credit Card", "Payroll"]
            },
            "contractPeriod": {
                "displayName": "Contract Period",
                "options": [1, 2, 3, 4],
                "optionLabel": ["12 Months", "24 Months", "36 Months", "36+ Months"]

            },
        }
    }

    // data vars
    const [selectedFilters, setSelectedFilters] = React.useState({
        "range": {},
        "filter": {
            "contractStartYear": [],
            "contractStartMonth": [],
            "billCycle": [],
            "paymentMethod": [],
            "contractPeriod": []
        }
    });

    const [vintageData, setVintageData] = React.useState({});
    const [dataForMonth, setDataForMonth] = useState([0]); // 0, -1, -2 ...

    // UI vars
    const [loading, setLoading] = React.useState(false);


    const addMonth = () => {
        setDataForMonth(prev => [...prev, (dataForMonth.length * -1)]); // Add a new element to the array
    };

    // Get Data From API
    const fetch = async () => {
        setLoading(true);
        try {
            let mapper = {};

            for (let _m of dataForMonth) {
                mapper[_m] = {} // create json for x month's data

                let filters = selectedFilters
                filters.month = _m

                const _res = await post(moduleMain + 'report/vintage', filters, 0, 0);


                // data is an array (since data volume is big, the array is broken into smaller arrays
                _res.data.forEach(_dataArr => {
                    _dataArr.forEach(_rec => {
                        // create year key if doesnt exist
                        if (!mapper[_m].hasOwnProperty(_rec.year)) {
                            mapper[_m][_rec.year] = {};
                        }

                        // create month key if doesnt exist
                        if (!mapper[_m][_rec.year].hasOwnProperty(_rec.month)) {
                            mapper[_m][_rec.year][_rec.month] = {};
                        }

                        mapper[_m][_rec.year][_rec.month] = {
                            msisdnCount: _rec.uniqueMsisdn,
                            accountCount: _rec.uniqueAccountName,
                            amoundDue: _rec.totalDueAmount
                        };
                    });
                });
            }

            console.log(mapper);

            setVintageData(mapper);
            setLoading(false); // hide loader
        } catch (_err) {
            log(_err);
            setLoading(false); // hide loader
        }
    };

    // hooks
    useEffect(() => {
        fetch();
    }, [])

    useEffect(() => {
        // do not call when setting default month
        if (dataForMonth.length > 1){
            fetch();
        }
    }, [dataForMonth])


    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>

            {/* Header */}
            <Header/>

            {/* Sider */}
            <Sider/>

            {/* Content */}
            <Box component="main" sx={{flexGrow: 2, p: 3}} mt={10}>
                <div className="px-1 px-md-5">

                    <div style={{maxWidth: '88vw'}}>
                        {/* Upper Section Buttons */}
                        <div className="row">
                            <div className="col-md-8">
                                <h2 className="mt-3">
                                    Vintage Report
                                </h2>
                            </div>
                            <div className="col-md-4">
                                <Button
                                    variant="contained"
                                    onClick={() => {fetch()}}
                                    className={'bg-custom-gradient my-2 py-3'}
                                    fullWidth>
                                    Apply Filters
                                </Button>
                            </div>
                        </div>

                        {/* Filters */}
                        <div className="row">
                            <div className={(theme == 'dark' ? 'bg-dark' : '') + " card shadow-lg p-3 ms-0"}>
                                <div className="row mt-2 px-2">
                                    {Object.keys(availableFilters.filter).map(_key => {
                                        return (
                                            <div className="col-md-12 mt-2 mb-5">
                                                <p className={'m-0 p-0 text-muted'}>
                                                    {availableFilters.filter[_key].displayName}
                                                </p>
                                                <div className="row p-0 m-0 mt-2">
                                                    {
                                                        availableFilters.filter[_key].options.map((_option, _optionIdx) => (
                                                            <div className="col-md-1 p-0 m-0">
                                                                <FormGroup>
                                                                    <FormControlLabel control={<Checkbox/>}
                                                                                      onChange={(e) => {
                                                                                          // get current array of selected options
                                                                                          let options = selectedFilters.filter[_key];

                                                                                          // select option
                                                                                          if (e.target.checked) {
                                                                                              // push option in array
                                                                                              options.push(_option);
                                                                                          } else {
                                                                                              // unselect option
                                                                                              options = options.filter((item) => {
                                                                                                  return item !== _option
                                                                                              })
                                                                                          }

                                                                                          // update array
                                                                                          setSelectedFilters((prevState) => ({
                                                                                              ...prevState,
                                                                                              filter: {
                                                                                                  ...prevState.filter,
                                                                                                  [_key]: options
                                                                                              }
                                                                                          }));

                                                                                      }}
                                                                                      label={availableFilters.filter[_key].optionLabel ? availableFilters.filter[_key].optionLabel[_optionIdx] : _option}/>
                                                                </FormGroup>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        </div>

                        {/* Button to fetch current-n month's data */}
                        <div className="row my-5 ">
                            <div className="col-12 text-end">
                                <Button variant="contained" onClick={addMonth}>Get Month {dataForMonth.length * -1} Data</Button>
                            </div>
                        </div>

                        {/* Data */}
                        <div className="d-flex flex-row" style={{overflowX: 'auto', maxWidth: '100%'}}>
                            {dataForMonth.map((month, index) => (
                                <div className="col-md-4 mx-3" key={index}>
                                    <VintageReportTable _data={vintageData[month]} _loading={loading} _month={index}/>
                                </div>
                            ))}
                        </div>

                    </div>

                </div>
            </Box>
        </Box>
    );
};

export default VintageReport;
