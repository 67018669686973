// @ts-nocheck

import React, { useEffect, useRef } from 'react';
import { useStyles } from './Styles';
import '../../../../../../index.scss';
import {
    Box, FormControl, Button, Skeleton,
    FormHelperText, InputLabel, MenuItem, Select, Tooltip
} from "@mui/material";
import IconButton from '@mui/material/IconButton/IconButton';
import { useTranslation } from 'react-i18next';
import { getAll, patch, post, getById } from "../../../../../../Services/GenericApiService";
import TextField from '@mui/material/TextField';
import { log } from "../../../../../../Services/LoggerService";
import { getUserData } from '../../../../../../Services/LocalStorageService';
import AlertM from '../../../../../Helpers/AlertM/AlertM';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import DataDisplayM from '../../../../../Helpers/DataDisplayM/DataDisplayM';
import { useForm } from "react-hook-form";
import Chip from '@mui/material/Chip';
import { useNavigate } from 'react-router';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Timeline from '@mui/lab/Timeline';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import PtpView from '../../../Ptp/PtpView/PtpView';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import ConfirmationDialog from '../../../../../Helpers/ConfirmationDialog/ConfirmationDialog';
import DebtordetailUpdate from '../../PreterminationAccountHandler/DebtordetailUpdate/DebtordetailUpdate';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import PtpCreate from '../../../Ptp/PtpCreate/PtpCreate';
const Fade = require("react-reveal/Fade");
const Reveal = require("react-reveal/Reveal");

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)


function CollectionAccountHandlerViewData({ account, hideUpates, refreshDataCallback, disableDisposition = false }) {

    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // get user data from jwt
    const userData = getUserData();

    // get theme from redux
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );


    const navigate = useNavigate();

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    // module(s) for api
    const moduleDispositionStatus = 'dispositionstatus';
    const moduleAccountNotepad = 'accountnotepad';
    const moduleDebtordetail = 'debtordetail';
    const modulePtps = 'ptp';

    // data vars
    const [notesInput, setNotesInput] = React.useState('');
    const [dispositionStatus, setDispositionStatus] = React.useState({});
    const [allPtps, setAllPtps] = React.useState([]);
    const [accountNotepad, setAccountNotepad] = React.useState({});
    const [selectedDispositionStatus, setSelectedDispositionStatus] = React.useState(account?.dispositionStatusId);
    const [showEditForm, setShowEditForm] = React.useState(false);

    const fetch = () => {
        getAllDispositionStatus();
        getAllAccountNodepad();
        getPtps();
    }

    const stopWorking = () => {
        unassignAccount();
    };

    const showEditFormEvent = () => {
        setShowEditForm(true);
    };

    const resetEdit = (_state) => {
        refreshContactData();
        setShowEditForm(false);
        getPtps();
    }

    const closeEditContact = (_state) => {
        setShowEditForm(false);
    }

    const submitNotes = () => {
        setLoading(true); // show loader
        post(moduleAccountNotepad, {
            debtorId: account.id,
            agentId: userData.id,
            event: 'Notes',
            notes: notesInput
        })
            .then((_res) => {
                setNotesInput('');

                // get updated notes
                getAllAccountNodepad();
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            });
    }

    //refresh contact data
    const refreshContactData = () => {
        setLoading(true);
        getById(moduleDebtordetail + '/contact', account.id)
            .then((_res: any) => {
                account.contactPerson = _res.contactPerson;
                account.hTel = _res.hTel;
                account.wTel = _res.wTel;
                account.cTel3 = _res.cTel3;
                account.cTel4 = _res.cTel4;
                account.email1 = _res.email1;
                account.email2 = _res.email2;
                account.email3 = _res.email3;
                account.email4 = _res.cTel4;
                setLoading(false);
            })
            .catch((_err: any) => {
                log(_err)
                setLoading(false); // hide loader
            })
    }
    // update account status
    const unassignAccount = () => {
        const payload = {
            debtorId: account?.id,
            customerAccountNum: account?.customerAccountNum
        }
        setLoading(true); // show loader
        post(moduleDebtordetail + '/collection/unassignAgent', payload)
            .then((_res: any) => {
                setLoading(false); // hide loader
                refreshDataCallback();
            })
            .catch((_err: any) => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    //get all disposition status
    const getAllDispositionStatus = () => {
        setLoading(true); // show loader
        getAll(moduleDispositionStatus)
            .then((_res: any) => {
                setDispositionStatus(_res)

                // setTableData(rows);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    //get ptps against debtor id
    const getPtps = () => {
        setLoading(true); // show loader
        getAll(modulePtps + '/debtor/' + account.id)
            .then((_res: any) => {
                if (_res.length > 0) {
                    account.debtorAccountStatus.dataValues.name = 'PTP In Effect'
                }
                setAllPtps(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    //get all account nodepad against debtor id
    const getAllAccountNodepad = () => {
        setLoading(true); // show loader
        getAll(moduleAccountNotepad + '/debtorId/' + account.id)
            .then((_res: any) => {
                setAccountNotepad(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // handle edit record event
    const updateDispositionStatus = (debtordetailId: any, _payload: any) => {
        setLoading(true); // show loader
        patch(moduleDebtordetail + '/status', debtordetailId, _payload)
            .then(async (_res) => {
                setLoading(false); // hide loader
                getAllAccountNodepad();
                // @ts-ignore
                notifications.current.successAlert('Account Suspended', '');
                setTimeout(() => {
                    refreshDataCallback();
                }, 1500);
                // @ts-ignore
                await sleep(process.env.REACT_APP_UPDATE_NOTIFICATION_TIMEOUT_MS || 1000);
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    const handleDispositionStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedDispositionStatus(event.target.value as number);

        const item = dispositionStatus.find(item => item.id === event.target.value);
        let debtorId = account.id
        let payload = {
            dispositionStatusId: event.target.value,
            notes: item.name,
            agentId: userData.id

        }
        updateDispositionStatus(debtorId, payload);
    };

    // form methods
    const onSubmit = (_data: any) => {
        // handleAddEvent(_data);
    }

    useEffect(() => {
        fetch();
    }, [])


    // ui controls
    const [loading, setLoading] = React.useState(true);

    // ui handlers


    return (
        <Box sx={{ display: 'flex' }}>
            {/* Content */}
            <Box component="main" sx={{ flexGrow: 2 }} mt={2}>

                {/* Status Rows */}
                {!hideUpates &&
                    <div className="row mb-4">
                        <div className="col-md-12">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    {/* Disposition Status Selection Column */}
                                    <div className="col-4">
                                        {!loading &&
                                            <div className={'pt-3 pb-3' + classes.h40}>
                                                {/* Disposition Status */}

                                                <FormControl fullWidth={true}>
                                                    <InputLabel id="statusIdLabel">
                                                        Disposition Status
                                                    </InputLabel>
                                                    {dispositionStatus.length > 0 &&
                                                        <Select labelId="dispositionStatusLabel"
                                                            id="dispositionStatusSelect"
                                                            value={selectedDispositionStatus}
                                                            onChange={handleDispositionStatusChange}
                                                            disabled={disableDisposition}
                                                            label="dispositionStatus">
                                                            {
                                                                dispositionStatus.map((el: any) =>
                                                                    <MenuItem value={el.id}
                                                                        key={el.id}> {el.name}
                                                                    </MenuItem>)
                                                            }
                                                        </Select>
                                                    }
                                                    {dispositionStatus.length == 0 &&
                                                        <>
                                                            <Select labelId="dispositionStatusLabel"
                                                                disabled
                                                                id="dispositionStatusSelect"
                                                                label="dispositionStatus">
                                                                <MenuItem value={''}>
                                                                    No Data
                                                                </MenuItem>
                                                            </Select>
                                                            <FormHelperText>
                                                                No Data
                                                            </FormHelperText>
                                                        </>
                                                    }

                                                </FormControl>


                                            </div>
                                        }
                                        {loading &&
                                            <Skeleton className="my-4" height={100} />
                                        }
                                    </div>

                                    {/* TODO*/}
                                    <div className="col-4">
                                        {!loading &&
                                            <div className=' pt-2'>
                                                <PtpCreate refreshDataEvent={() => {
                                                    getPtps();
                                                    getAllAccountNodepad();
                                                }
                                                } label={true} debtorId={account.id}
                                                    customerAccountNum={account.customerAccountNum} />
                                            </div>
                                        }
                                        {loading &&
                                            <Skeleton className="my-4" height={100} />
                                        }

                                    </div>
                                    {/* Stop Working Button Column */}
                                    <div className="col-4 pt-2">
                                        {!loading &&

                                            <ConfirmationDialog title={'Confirmation'}
                                                body={'Are you sure you want to stop working?'}
                                                type={'accept'}
                                                showButton={true}
                                                buttonText={' Stop Working'}
                                                isLoading={loading}
                                                dataItemIdx={1}
                                                confirmEvent={stopWorking} />

                                        }
                                        {loading &&
                                            <Skeleton className="my-4  pt-2" height={100} />
                                        }

                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                }

                {!loading &&
                    <div className="row">
                        <div className="col-8">
                            <div className="row">
                                {/* Upcomming Ptps Column */}
                                {!loading && allPtps.length > 0 &&
                                    <div className="col-md-12">
                                        <div className={(theme == 'dark' ? 'bg-dark' : '') + " container card shadow-lg me-0 ms-0 mb-5"}>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <h4 className="">
                                                            <FontAwesomeIcon icon='fa-handshake' />
                                                            &nbsp;&nbsp;Upcomming PTPs
                                                        </h4>
                                                    </div>
                                                    <div className="col-md-6 text-end">
                                                        <Chip label="Upcoming" color="info" />&nbsp;&nbsp;
                                                        <Chip label="Honored" color="success" />&nbsp;&nbsp;
                                                        <Chip label="Not Honored" color="error" />
                                                    </div>
                                                </div>
                                                <div className="row mb-2 mt-4 py-4 px-3 "
                                                    style={{ maxHeight: '27vh', overflowY: 'auto' }}>
                                                    {allPtps.map(item => (
                                                        <div className="col-md-6 pointer">
                                                            <PtpView refreshDataEvent={() => {
                                                                getAllAccountNodepad();
                                                                getPtps();
                                                            }} data={item} showCardView={true} />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {/* First Column */}
                                <div className="col-md-12">

                                    {/* Debtor Details (Contact, name etc) */}
                                    <div className={(theme == 'dark' ? 'bg-dark' : '') + " container card shadow-lg ms-0 me-0"}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-8">
                                                    <h4 className="card-title">
                                                        <FontAwesomeIcon icon='address-card' />
                                                        &nbsp;&nbsp;Debtor Details
                                                    </h4>
                                                </div>
                                                {!disableDisposition &&
                                                    <div className="col-4 text-end">
                                                        <Chip color="success"
                                                            label={account?.debtorAccountStatus?.dataValues?.name} />
                                                    </div>
                                                }
                                                {disableDisposition &&
                                                    <div className="col-4 text-end">
                                                        <Chip color="success"
                                                            label={account['debtorAccountStatus.name']} />
                                                    </div>
                                                }

                                            </div>
                                            <div className="row mt-4">

                                                <div className="col-md-3">
                                                    <DataDisplayM label={'Bill Account Number'}
                                                        data={account?.billAccNo} />
                                                </div>

                                                <div className="col-md-3">
                                                    <DataDisplayM label={'Customer Account Number'}
                                                        data={account?.customerAccountNum} />
                                                </div>

                                                <div className="col-md-3">
                                                    <DataDisplayM label={'Customer Name'} data={account?.customerName} />
                                                </div>


                                                <div className="col-md-3">
                                                    <DataDisplayM label={'Legacy Account'}
                                                        data={account?.legacyAccountNum} />
                                                </div>


                                                <div className="col-md-3">
                                                    <DataDisplayM label={'Credit Controller'}
                                                        data={account?.creditController} />
                                                </div>

                                                <div className="col-md-3">
                                                    {account?.region !== "" ? (
                                                        <DataDisplayM label={'Region'} data={account?.region} />
                                                    ) : (
                                                        <DataDisplayM label={'Region'} data={null} />
                                                    )}
                                                </div>

                                                <div className="col-md-3">
                                                    {account?.Segment !== "" ? (
                                                        <DataDisplayM label={'Segment'} data={account?.Segment} />
                                                    ) : (
                                                        <DataDisplayM label={'Segment'} data={null} />
                                                    )}
                                                </div>

                                                <div className="col-md-3">
                                                    {account?.subSegment !== "" ? (
                                                        <DataDisplayM label={'Sub Segment'} data={account?.subSegment} />
                                                    ) : (
                                                        <DataDisplayM label={'Sub Segment'} data={null} />
                                                    )}

                                                </div>

                                                <div className="col-md-3">
                                                    {account?.gtm !== "" ? (
                                                        <DataDisplayM label={'GTM'} data={account?.gtm} />
                                                    ) : (
                                                        <DataDisplayM label={'GTM'} data={null} />
                                                    )}
                                                </div>

                                                <div className="col-md-3">
                                                    {account?.rsm !== "" ? (
                                                        <DataDisplayM label={'RSM'} data={account?.rsm} />
                                                    ) : (
                                                        <DataDisplayM label={'RSM'} data={null} />
                                                    )}
                                                </div>

                                                <div className="col-md-3">
                                                    {account?.am !== "" ? (
                                                        <DataDisplayM label={'AM'} data={account?.am} />
                                                    ) : (
                                                        <DataDisplayM label={'AM'} data={null} />
                                                    )}
                                                </div>

                                                <div className="col-md-3">
                                                    <DataDisplayM label={'Book Name'} data={account?.bookName} />
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>


                                {/* Second Column */}
                                <div className="col-md-12 mt-5">
                                    {/* Debtor Details (Contact, name etc) */}
                                    <div className={(theme == 'dark' ? 'bg-dark' : '') + " container card shadow-lg ms-0 me-0"}>
                                        <div className="card-body">
                                            <h4 className="card-title">
                                                <FontAwesomeIcon icon='square-phone' />
                                                &nbsp;&nbsp;Contact Details
                                                <Tooltip title="Update" style={{ float: 'right' }} >
                                                    <IconButton onClick={showEditFormEvent}>
                                                        <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                                                    </IconButton>
                                                </Tooltip>
                                            </h4>

                                            {showEditForm &&
                                                <div>
                                                    <DebtordetailUpdate
                                                        recordToUpdate={account}
                                                        resetEditState={resetEdit}
                                                        closeEditState={closeEditContact}
                                                        contactDetails={true} />
                                                </div>
                                            }

                                            <div className="row mt-4">

                                                <div className="col-md-3">
                                                    {account?.contactPerson !== "" ? (
                                                        <DataDisplayM label={'Contact Personn'}
                                                            data={account?.contactPerson} />
                                                    ) : (
                                                        <DataDisplayM label={'Contact Personn'} data={null} />
                                                    )}

                                                </div>


                                                <div className="col-md-3">
                                                    {account?.hTel !== "" ? (
                                                        <DataDisplayM label={'Contact 1'} data={account?.hTel} />
                                                    ) : (
                                                        <DataDisplayM label={'Contact 1'} data={null} />
                                                    )}

                                                </div>

                                                <div className="col-md-3">
                                                    {account?.wTel !== "" ? (
                                                        <DataDisplayM label={'Contact 2'} data={account?.wTel} />
                                                    ) : (
                                                        <DataDisplayM label={'Contact 2'} data={null} />
                                                    )}

                                                </div>

                                                <div className="col-md-3">
                                                    {account?.cTel3 !== "" ? (
                                                        <DataDisplayM label={'Contact 3'} data={account?.cTel3} />
                                                    ) : (
                                                        <DataDisplayM label={'Contact 3'} data={null} />
                                                    )}
                                                </div>

                                                <div className="col-md-3">
                                                    {account?.cTel4 !== "" ? (
                                                        <DataDisplayM label={'Contact 4'} data={account?.cTel4} />
                                                    ) : (
                                                        <DataDisplayM label={'Contact 4'} data={null} />
                                                    )}

                                                </div>

                                                <div className="col-md-3">
                                                    <DataDisplayM label={'Email 1'} data={account?.email1} />
                                                </div>

                                                <div className="col-md-3">
                                                    {account?.email2 !== "" ? (
                                                        <DataDisplayM label={'Email 2'} data={account?.email2} />
                                                    ) : (
                                                        <DataDisplayM label={'Email 2'} data={null} />
                                                    )}

                                                </div>

                                                <div className="col-md-3">
                                                    {account?.email3 !== "" ? (
                                                        <DataDisplayM label={'Email 3'} data={account?.email3} />
                                                    ) : (
                                                        <DataDisplayM label={'Email 3'} data={null} />
                                                    )}

                                                </div>

                                                <div className="col-md-3">
                                                    <DataDisplayM label={'Email 4'} data={account?.email4} />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* Third Column */}
                                <div className="col-md-12 mt-5">
                                    {/* Debtor Details (Contact, name etc) */}
                                    <div className={(theme == 'dark' ? 'bg-dark' : '') + " container card shadow-lg ms-0 me-0"}>
                                        <div className="card-body">
                                            <h4 className="card-title">
                                                <FontAwesomeIcon icon='file-invoice-dollar' />
                                                &nbsp;&nbsp;Payment Details

                                            </h4>
                                            <div className="row mt-4">

                                                <div className="col-md-3">
                                                    <DataDisplayM label={'Payment Method'}
                                                        data={account?.paymentMethod} />
                                                </div>

                                                <div className="col-md-3">
                                                    <DataDisplayM label={'Currency'} data={account?.currency} />
                                                </div>

                                                <div className="col-md-3">
                                                    <DataDisplayM label={'Currency Conv'} data={account?.currencyConv} />
                                                </div>

                                                <div className="col-md-3">
                                                    <DataDisplayM label={'Conv Currency Amount'}
                                                        data={account?.convCurrencyAmount} />
                                                </div>

                                                <div className="col-md-3">
                                                    <DataDisplayM label={'Conv 30 Days'} data={parseFloat(account?.conv30Days).toLocaleString('en-US', { maximumFractionDigits: 2 })} />
                                                </div>

                                                <div className="col-md-3">
                                                    <DataDisplayM label={'Conv 60 Days'} data={parseFloat(account?.conv60Days).toLocaleString('en-US', { maximumFractionDigits: 2 })} />
                                                </div>

                                                <div className="col-md-3">
                                                    <DataDisplayM label={'Conv 90 Days'} data={parseFloat(account?.conv90Days).toLocaleString('en-US', { maximumFractionDigits: 2 })} />
                                                </div>

                                                <div className="col-md-3">
                                                    <DataDisplayM label={'Conv 120 Days'} data={parseFloat(account?.conv120Days).toLocaleString('en-US', { maximumFractionDigits: 2 })} />
                                                </div>

                                                <div className="col-md-3">
                                                    <DataDisplayM label={'Conv 150 Days'} data={parseFloat(account?.conv150Days).toLocaleString('en-US', { maximumFractionDigits: 2 })} />
                                                </div>

                                                <div className="col-md-3">
                                                    <DataDisplayM label={'Conv 180 Days'} data={parseFloat(account?.conv180Days).toLocaleString('en-US', { maximumFractionDigits: 2 })} />
                                                </div>

                                                <div className="col-md-3">
                                                    <DataDisplayM label={'Conv More Than 180 Days'}
                                                        data={parseFloat(account?.convMoreThan180Days).toLocaleString('en-US', { maximumFractionDigits: 2 })} />
                                                </div>

                                                <div className="col-md-3">
                                                    <DataDisplayM label={'Conv Total Arrears'}
                                                        data={parseFloat(account?.convTotalArrears).toLocaleString('en-US', { maximumFractionDigits: 2 })} />
                                                </div>

                                                <div className="col-md-3">
                                                    <DataDisplayM label={'Conv Total Due'}
                                                        data={parseFloat(account?.convTotalDue).toLocaleString('en-US', { maximumFractionDigits: 2 })} />
                                                </div>


                                                <div className="col-md-3">
                                                    <DataDisplayM label={'Conv Unallocated Amount'}
                                                        data={parseFloat(account?.convUnallocatedAmount).toLocaleString('en-US', { maximumFractionDigits: 2 })} />
                                                </div>


                                                <div className="col-md-3">
                                                    <DataDisplayM label={'Conv Unallocated Amount Cash'}
                                                        data={parseFloat(account?.typeUnallocatedAmountCash).toLocaleString('en-US', { maximumFractionDigits: 2 })} />
                                                </div>


                                                <div className="col-md-3">
                                                    <DataDisplayM label={'Conv Unallocated Amount Credit'}
                                                        data={parseFloat(account?.typeUnallocatedAmountCredit).toLocaleString('en-US', { maximumFractionDigits: 2 })} />
                                                </div>

                                                <div className="col-md-3">
                                                    <DataDisplayM label={'Revenue Type'} data={account?.revenueType} />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-4">
                            {/* Debtor Details (Account Notepad) */}
                            <div className={(theme == 'dark' ? 'bg-dark' : '') + " container card shadow-lg  mb-5 ms-0 me-0"}
                                style={{ maxHeight: '74%', overflow: 'auto' }}>
                                <div className="card-body">
                                    <h4 className="card-title">
                                        <FontAwesomeIcon icon='fa-timeline' />
                                        &nbsp;&nbsp;Account Notepad
                                    </h4>
                                    <div className="row mt-4">
                                        {!hideUpates &&
                                            <div className="mt-2 mb-5">
                                                <div className="p-3">
                                                    {/*<h4 className="card-title">*/}
                                                    {/*    Add Notes*/}
                                                    {/*</h4>*/}

                                                    <TextField
                                                        id="addNotesInput"
                                                        label="Add Notes"
                                                        multiline
                                                        className={'full-width'}
                                                        minRows={2}
                                                        value={notesInput}
                                                        onChange={e => setNotesInput(e.target.value)}
                                                    />

                                                    {/*<Button variant="outlined" className={'full-width mt-4'} onClick={submitNotes}>*/}
                                                    {/*    Save Notes*/}
                                                    {/*</Button>*/}

                                                    <LoadingButton
                                                        variant="outlined" className={'full-width mt-4'}
                                                        size="small"
                                                        onClick={submitNotes}
                                                        loading={loading}
                                                        loadingPosition="start"
                                                        startIcon={<SaveIcon />}
                                                        disabled={!notesInput}
                                                    >
                                                        <span>Save</span>
                                                    </LoadingButton>

                                                </div>
                                            </div>
                                        }
                                        {accountNotepad.length > 0 && (
                                            <Timeline
                                                sx={{
                                                    [`& .${timelineItemClasses.root}:before`]: {
                                                        flex: 0,
                                                        padding: 0,
                                                    },
                                                }}
                                            >
                                                {accountNotepad.map(item => (
                                                    <TimelineItem key={item.id}>
                                                        <TimelineSeparator>
                                                            <TimelineConnector />
                                                            <TimelineDot />
                                                            <TimelineConnector />
                                                        </TimelineSeparator>
                                                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                                                            <Typography variant="h6" component="span">
                                                                {item.event}
                                                            </Typography>

                                                            <p className='py-0 my-0 text-muted'>{new Date(item.createdAt).toDateString()}</p>
                                                            <p className='py-0 my-0 mb-2 text-muted'>{item.agentId == null ? 'System Event' : item.accountNotepadAgent.email}</p>

                                                            <Typography>
                                                                {item.notes}
                                                            </Typography>
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                ))}
                                            </Timeline>
                                        )}

                                        {!loading && accountNotepad.length == 0 &&
                                            <h2 className={'text-center my-5'}>
                                                <FontAwesomeIcon icon='triangle-exclamation' />
                                                &nbsp;
                                                No Data
                                            </h2>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                }
                {loading &&
                    <div className="row">
                        <div className="col-8">
                            <Skeleton className="my-4" height={500} />
                        </div>
                        <div className="col-4">
                            <Skeleton className="my-4" height={500} />
                        </div>
                    </div>

                }


            </Box>
            {/* Alerts */}
            <AlertM ref={notifications} />

        </Box>

    );
}


export default CollectionAccountHandlerViewData;
