// @ts-nocheck

import React, {useEffect} from 'react';
import {useStyles} from './Styles';
import Box from '@mui/material/Box';
import Tooltip from "@mui/material/Tooltip";
import {Button, Card, CardContent, IconButton} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {library} from '@fortawesome/fontawesome-svg-core';
import {useTranslation} from "react-i18next";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import CardActions from "@mui/material/CardActions";
import DataDisplayM from "../../../../../Helpers/DataDisplayM/DataDisplayM";
import {log} from "../../../../../../Services/LoggerService";

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function DebtordetailView({data = null}) {
    // css for module
    const classes = useStyles();

    // translation
    const {t} = useTranslation();

    // ui handlers
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    // handle cancel event
    const handleCancelEvent = () => {
        handleClose();
    }

    // hooks
    useEffect(() => {

    }, []);


    return (
        <div>
            <Box sx={{display: 'flex'}}>
                <Tooltip title={t('common.view')}>
                    <IconButton aria-label="delete" size="small" onClick={handleOpen}>
                        <FontAwesomeIcon className="edit-btn" icon='eye'/>
                    </IconButton>
                </Tooltip>
            </Box>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={classes.modal}>
                        <Card sx={{minWidth: '75vw', maxHeight: '90vh', overflowY: 'auto'}}>
                            <CardContent>
                                <div className="py-4 px-md-4 px-0">
                                    <h1 className="mb-3">{t('common.recordDetails')}</h1>
                                    <div className="row full-width">


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.appDate')} data={data.appDate} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.dlrCode')} data={data.dlrCode} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.source')} data={data.source} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.finalDecision')} data={data.finalDecision} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.appNumber')} data={data.appNumber} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.orderNumber')} data={data.orderNumber} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.caseNumber')} data={data.caseNumber} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.prospectId')} data={data.prospectId} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.appSeq')} data={data.appSeq} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.idType')} data={data.idType} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.firstName')} data={data.firstName} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.surName')} data={data.surName} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.accLines')} data={data.accLines} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.oldestContract')} data={data.oldestContract} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.churned')} data={data.churned} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.terminationDate')} data={data.terminationDate} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.tblDt')} data={data.tblDt} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.accountName')} data={data.accountName} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.availCreditAmt')} data={data.availCreditAmt} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.brcChannel')} data={data.brcChannel} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.brcOwnhership')} data={data.brcOwnhership} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.legalStatus')} data={data.legalStatus} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.migrationFlag')} data={data.migrationFlag} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.xMtnAccountLock')} data={data.xMtnAccountLock} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.debtCollecFlg')} data={data.debtCollecFlg} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.billPaymentMethod')} data={data.billPaymentMethod} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.billStatusCd')} data={data.billStatusCd} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.bankName')} data={data.bankName} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.bankAccountType')} data={data.bankAccountType} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.curcyCd')} data={data.curcyCd} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.classificationNm')} data={data.classificationNm} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.classificationDescr')} data={data.classificationDescr} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.activeDt')} data={data.activeDt} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.msisdn')} data={data.msisdn} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.serviceTypeNm')} data={data.serviceTypeNm} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.serviceStatusNm')} data={data.serviceStatusNm} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.contractStartDt')} data={data.contractStartDt} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.contractEndDt')} data={data.contractEndDt} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.accountBalance')} data={data.accountBalance} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.arrearFlg')} data={data.arrearFlg} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.balanceDate')} data={data.balanceDate} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.ageDays')} data={data.ageDays} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.openInv')} data={data.openInv} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.b_0_30')} data={data.b_0_30} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.b_30_60')} data={data.b_30_60} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.b_60_90')} data={data.b_60_90} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.b_90_120')} data={data.b_90_120} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.b_120_150')} data={data.b_120_150} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.b_150_180')} data={data.b_150_180} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.b_plus_180')} data={data.b_plus_180} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.treatmentBreachAmnt')} data={data.treatmentBreachAmnt} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.currTreatmentStatus')} data={data.currTreatmentStatus} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.trtmntStsCd')} data={data.trtmntStsCd} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.trtmntStartDt')} data={data.trtmntStartDt} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.resolutionDueDate')} data={data.resolutionDueDate} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.trtmntCloseDt')} data={data.trtmntCloseDt} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.trtmntQueryTxt')} data={data.trtmntQueryTxt} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.trtmntReslnTxt')} data={data.trtmntReslnTxt} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.currTreatmentLvl')} data={data.currTreatmentLvl} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.nxtTreatmentLvl')} data={data.nxtTreatmentLvl} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.ptpTotal')} data={data.ptpTotal} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.ptpStsCd')} data={data.ptpStsCd} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.ptpSartDt')} data={data.ptpSartDt} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.ptpDueDt')} data={data.ptpDueDt} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.ptpQueryTxt')} data={data.ptpQueryTxt} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.ptpReslnTxt')} data={data.ptpReslnTxt} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.ptpResolutionId')} data={data.ptpResolutionId} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.ptpInstallmentTtl')} data={data.ptpInstallmentTtl} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.ptpInstallmentAmnt')} data={data.ptpInstallmentAmnt} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.customerType')} data={data.customerType} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.invoiceAmt')} data={data.invoiceAmt} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.insertDt')} data={data.insertDt} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.lastPaymentAmt')} data={data.lastPaymentAmt} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.lastPaymentDt')} data={data.lastPaymentDt} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.customerNodeStatusNm')} data={data.customerNodeStatusNm} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.category')} data={data.category} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.idPassportNumber')} data={data.idPassportNumber} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.birthDt')} data={data.birthDt} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.balanceCategory')} data={data.balanceCategory} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.mob')} data={data.mob} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.mobCat')} data={data.mobCat} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.arrearPlus180Cat')} data={data.arrearPlus180Cat} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.exception1')} data={data.exception1} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.exception2')} data={data.exception2} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.exception3')} data={data.exception3} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.exception4')} data={data.exception4} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.exception5')} data={data.exception5} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.openBillQuery')} data={data.openBillQuery} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.openDispute')} data={data.openDispute} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.simOnlyFlag')} data={data.simOnlyFlag} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.collectabilityScore')} data={data.collectabilityScore} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.affordabilityBands')} data={data.affordabilityBands} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.collectionsPriority')} data={data.collectionsPriority} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.numberOfMsisdn')} data={data.numberOfMsisdn} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.lastExclusionDate')} data={data.lastExclusionDate} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.numberOfExclusions')} data={data.numberOfExclusions} />
                                            }                                        </div>

                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('debtordetail.exclusionReason')} data={data.exclusionReason} />
                                            }                                        </div>

                                    </div>
                                </div>
                            </CardContent>
                            <CardActions>
                                <div className="pb-4 pt-2 px-md-4 px-2 " style={{width: '100%', textAlign: 'right'}}>

                                    {/* Cancel Button */}
                                    <Button variant="outlined"
                                            onClick={handleCancelEvent}>
                                        {t('common.cancel')}
                                    </Button>

                                    &nbsp;&nbsp;
                                </div>
                            </CardActions>
                        </Card>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default DebtordetailView;
