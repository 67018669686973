import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';

// @ts-ignore
const VintageReportTable = ({_data, _loading, _month}) => {

    // UI vars
    const [tableHeader, setTableHeader] = React.useState(['Year', 'Month', 'Accounts Count', 'MSISDN Count', 'Sum of Average Balance']);

    return (
        <div>
            {!_loading &&
                <>
                    <div className={'bg-custom-secondary-faded'}>
                        <h5 className={'text-center p-2 m-0'}>Current
                            Month {_month > 0 ? '- ' + _month : ''}</h5>
                    </div>

                    {_data &&
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead className={'bg-custom-secondary'}>
                                    <TableRow>
                                        {tableHeader.map((header) => (
                                            <TableCell>{header}</TableCell>
                                        ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(_data).map((_year: any) => (
                                        <>
                                            <TableRow
                                                className={'bg-custom-primary-faded'}
                                                key={_year}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <b>{_year}</b>
                                                </TableCell>
                                                <TableCell align="center"></TableCell>
                                                <TableCell align="center"></TableCell>
                                                <TableCell align="center"></TableCell>
                                                <TableCell align="center"></TableCell>
                                            </TableRow>

                                            {Object.keys(_data[_year]).map((_month: any) => (
                                                <TableRow
                                                    key={_year}
                                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                >
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left">{_month}</TableCell>
                                                    <TableCell align="left">{_data[_year][_month].accountCount}</TableCell>
                                                    <TableCell align="left">{_data[_year][_month].msisdnCount}</TableCell>
                                                    <TableCell align="left">{parseFloat(_data[_year][_month].amoundDue).toFixed(2)}</TableCell>
                                                </TableRow>
                                            ))
                                            }
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }

                    {/*  No Data*/}
                    {_data && Object.keys(_data).length == 0 &&
                        <h5 className={'p-0 m-0 text-center pt-2 '}>No Data</h5>
                    }
                </>
            }

            {_loading &&
                <>
                    <Skeleton style={{height: '3.8em'}} />
                    <Skeleton style={{height: '3.3m'}} />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </>
            }
        </div>
    );
};

export default VintageReportTable;
