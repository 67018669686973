// @ts-nocheck

import React, {useEffect, useRef} from 'react';
import {useStyles} from './Styles';
import '../../../../../index.scss';
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import {getAll} from "../../../../../Services/GenericApiService";
import {useTranslation} from "react-i18next";
import AlertM from '../../../../Helpers/AlertM/AlertM';
import {log} from "../../../../../Services/LoggerService";
import AccountFileUploadM from '../../../../Helpers/AccountFileUploadM/AccountFileUploadM';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import {Skeleton} from "@mui/material";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {library} from '@fortawesome/fontawesome-svg-core';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import KillListFileUpload from '../KillListFileUpload/KillListFileUpload';
import AutomatedImporterTrigger from '../AutomatedImporterTrigger/AutomatedImporterTrigger';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function BookImporterMain() {
    // css for module
    const classes = useStyles();

    // translation
    const {t} = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // module(s) for api
    const moduleMain = 'debtordetail/';
    const moduleTU = 'import/pretermination/tu';
    const moduleMTN = 'import/pretermination/globalscape';

    // data vars
    const [allData, setAllData] = React.useState([]);

    // ui controls
    const [loading, setLoading] = React.useState(true);
    const [tabValue, setTabValue] = React.useState(0);

    // ui handlers
    const handleGlobalscapeImport = () => {
        setLoading(true);
        getAll(moduleMain + moduleMTN)
            .then(_res => {
                setLoading(false);
                // @ts-ignore
                notifications.current.successAlert('Globalscape Import Completed', _res.success + ' accounts were imported from Globalscape Successfully');
            })
            .catch(_err => {
                log(err);
                setLoading(false);
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
            })
    }

    const handleTUImport = () => {
        setLoading(true);
        getAll(moduleMain + moduleTU)
            .then(_res => {
                setLoading(false);
                // @ts-ignore
                notifications.current.successAlert('TU Scoring Import Completed', 'Account scorings were imported from TU Successfully');
            })
            .catch(_err => {
                log(err);
                setLoading(false);
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
            })
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        setLoading(false);
    }, [])

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>

            {/* Header */}
            <Header/>

            {/* Sider */}
            <Sider/>

            {/* Content */}
            <Box component="main" sx={{flexGrow: 2, p: 3}} mt={10}>

                {loading &&
                    <div className="mt-5">
                        <Skeleton key={'piechart'} variant="rounded" height={'80vh'} />
                    </div>
                }


                {!loading &&
                    <>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                                    <Tab label="Manual Import" />
                                    <Tab label="Automated Import" />
                                    <Tab label="Exclusion List Import" />
                                </Tabs>
                            </Box>
                        </Box>


                        <div className="mt-4">
                            {/* Manual Import */}
                            {tabValue == 0 &&
                                <div className="row">
                                    <AccountFileUploadM importType={'pretermination'} />
                                </div>
                            }

                            {/* Automated Import */}
                            {tabValue == 1 &&
                                <div className="row">
                                    <AutomatedImporterTrigger/>
                                </div>
                            }

                            {tabValue == 2 &&
                                <div className="row">
                                    <KillListFileUpload />
                                </div>
                            }
                        </div>
                    </>
                }


                {/* Alerts */}
                <AlertM ref={notifications}/>

            </Box>


        </Box>
    );
}

export default BookImporterMain;
