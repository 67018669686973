// @ts-nocheck

import React, {useEffect} from 'react';
import {useStyles} from './Styles';
import Box from '@mui/material/Box';
import Tooltip from "@mui/material/Tooltip";
import {Button, Card, CardContent, IconButton} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {library} from '@fortawesome/fontawesome-svg-core';
import {useTranslation} from "react-i18next";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import CardActions from "@mui/material/CardActions";
import DataDisplayM from "../../../../Helpers/DataDisplayM/DataDisplayM";
import {log} from "../../../../../Services/LoggerService";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {getAll, getById, post, patch, deleteById} from "../../../../../Services/GenericApiService";

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function StrategyView({data = null}) {
    // css for module
    const classes = useStyles();

    // translation
    const {t} = useTranslation();

    // module(s) for api
    const moduleBookFields = 'bookfield';

    // data vars
    const [allFieldsData, setAllFieldsData] = React.useState('');
    const [allTeamsData, setAllTeamsData] = React.useState('');

    // ui controls
    const [loading, setLoading] = React.useState(true);

    // ui handlers
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    // handle cancel event
    const handleCancelEvent = () => {
        handleClose();
    }

    // fetch dependent data
    const fetch = () => {
        setLoading(true); // show loader
        getAll(moduleBookFields)
            .then((_res: any) => {
                // restructure fields to display name mapper
                let mapper = {};
                _res.forEach(_field => {
                    mapper[_field.title] = _field.displayName
                });

                //----------------- add all filters and ranges in a string to display them -----------------//

                let dataString = '';

                // get filters
                Object.keys(JSON.parse(data.filters).filter).forEach((_filter, _idx) => {
                    dataString += mapper[_filter] + (_idx == JSON.parse(data.filters).filter[_filter].length - 1 ? '' : ', ');
                });

                // get ranges
                Object.keys(JSON.parse(data.filters).range).forEach((_range, _idx) => {
                    if ((JSON.parse(data.filters).range[_range].min != null || JSON.parse(data.filters).range[_range].max != null)) {
                        dataString += (_idx == JSON.parse(data.filters).range.length - 1 ? '' : ', ') + mapper[_range];
                    }
                });
                setAllFieldsData(dataString);

                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // hooks
    useEffect(() => {
        fetch();
        extractTeamNamesAsString();
    }, []);


    const extractTeamNamesAsString = () => {

        //----------------- add all team names in a string to display them -----------------//
        let dataString = '';
        data.strategyStrategyCampaign.forEach((_strategy, _idx) => {
            dataString += _strategy.strategyCampaignTeam.name;
            if (_idx != data.strategyStrategyCampaign.length - 1) {
                dataString += ', ';
            }
        })
        setAllTeamsData(dataString);
    }

    return (
        <div>
            <Box sx={{display: 'flex'}}>
                <Tooltip title={t('common.view')}>
                    <IconButton aria-label="delete" size="small" onClick={handleOpen}>
                        <FontAwesomeIcon className="edit-btn" icon='eye'/>
                    </IconButton>
                </Tooltip>
            </Box>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={classes.modal}>
                        <Card sx={{width: '75vw', maxHeight: '90vh', overflowY: 'auto'}}>
                            <CardContent>
                                <div className="py-4 px-md-4 px-0">
                                    <h1 className="mb-3">{t('common.recordDetails')}</h1>
                                    <div className="row full-width">


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('strategy.name')} data={data.name}/>
                                            }                                        </div>

                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('strategy.campaign')} data={data?.strategyStrategyCampaign[0]?.strategyCampaignCampaign?.name}/>
                                            }
                                        </div>

                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('strategy.filters')} data={allFieldsData}/>
                                            }
                                        </div>

                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('strategy.teams')} data={allTeamsData}/>
                                            }
                                        </div>


                                    </div>
                                </div>
                            </CardContent>
                            <CardActions>
                                <div className="pb-4 pt-2 px-md-4 px-2 " style={{width: '100%', textAlign: 'right'}}>

                                    {/* Cancel Button */}
                                    <Button variant="outlined"
                                            onClick={handleCancelEvent}>
                                        {t('common.cancel')}
                                    </Button>

                                    &nbsp;&nbsp;
                                </div>
                            </CardActions>
                        </Card>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default StrategyView;
