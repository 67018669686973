// @ts-nocheck
import React, { useState } from 'react';
import { useStyles } from './Styles';
import { useTranslation } from "react-i18next";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Button, FormControl, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm } from "react-hook-form";
import EdcscorecardRangeMatrix from '../EdcscorecardRangeMatrix/EdcscorecardRangeMatrix';

// @ts-ignore
function EdcscorecardCalculator({ handleCancelEvent, formulaIndex, onDataSubmit, rangeMatrix }) {
    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    const labelForFormulaReporting = [
        'MTN Matrix updated (by the #th of every month)',
        'MTN Matrix saved on Globalscape/Sharepoin (by the #th of every month)',
        'Monthly EDC reports sent to MTN (by the #th of every month with a Quarterly view at the end of the quarter)',
        'Monthly EDC reports saved on Globalscape/Sharepoint (by the #th of every month with a Quarterly view at the end of the quarter)',
        'Weekly pay over schedules sent to MTN (Weekly)',
        'Monthly base recon sent to MTN (by the # of every month)',
    ];

    //data handlers
    //score state
    const [yeildRateScore, setYeildRateScore] = useState(0);
    const [yeildRateScoreSM, setYeildRateScoreSM] = useState(0);

    const [activationRateScore, setActivationRateScore] = useState(0);
    const [activationRateScoreSM, setActivationRateScoreSM] = useState(0);

    const [prescriptionRateScore, setPrescriptionRateScore] = useState(0);
    const [prescriptionRateScoreSM, setPrescriptionRateScoreSM] = useState(0);

    const [deviceRecoveryRateScore, setDeviceRecoveryRateScore] = useState(0);
    const [deviceRecoveryRateScoreSM, setDeviceRecoveryRateScoreSM] = useState(0);

    const [rehabilitationsScore, setRehabilitationsScore] = useState(0);
    const [rehabilitationsScoreSM, setRehabilitationsScoreSM] = useState(0);

    const [collaborationScore, setCollaborationScore] = useState(0);
    const [collaborationScoreSM, setCollaborationScoreSM] = useState(0);

    const [fixRateScore, setFixRateScore] = useState(0);
    const [fixRateScoreSM, setFixRateScoreSM] = useState(0);

    const [reportingScore, setReportingScore] = useState(0);
    const [reportingScoreSM, setReportingScoreSM] = useState(0);

    const [qualityAssuranceScore, setQualityAssuranceScore] = useState(0);
    const [qualityAssuranceScoreSM, setQualityAssuranceScoreSM] = useState(0);

    const [specialProjectsScore, setSpecialProjectsScore] = useState(0);
    const [specialProjectsScoreSM, setSpecialProjectsScoreSM] = useState(0);

    const [escalationScore, setEscalationScore] = useState(0);
    const [escalationScoreSM, setEscalationScoreSM] = useState(0);

    // ui handlers
    const [open, setOpen] = React.useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [loading, setLoading] = React.useState(false);

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    const checkScoreBasedOnRangeMatrix = (value) => {
        for (let range of rangeMatrix) {
            const start = parseFloat(range.start);
            const end = range.end ? parseFloat(range.end) : Infinity;

            if (value >= start && value <= end) {
                return parseFloat(range.value);
            }
        }
        return 0;
    };

    const calculateScoreBasedOnMatrix = (_value) => {
        const multiplier = checkScoreBasedOnRangeMatrix(_value);
        return multiplier;
    }

    const onSubmit = (_data: any) => {
        switch (formulaIndex) {
            case 1:
                const calculatedYieldRate = calculateYieldRateScore(_data.recoveryRate, _data.writtenOffValue, _data.fraud, _data.deceased, _data.debtReview, _data.prescribed, _data.sold);
                setYeildRateScore(calculatedYieldRate.toFixed(2));
                setYeildRateScoreSM(calculateScoreBasedOnMatrix(calculatedYieldRate));
                break;
            case 2:
                const calculatedActivationRate = calculateActivationRateScore(_data.noOfAccountEdc, _data.noOfAccountBook);
                setActivationRateScore(calculatedActivationRate.toFixed(2));
                setActivationRateScoreSM(calculateScoreBasedOnMatrix(calculatedActivationRate));
                break;
            case 3:
                const calculatedPrescriptionRate = calculatePrescriptionRateScore(_data.prescribedAccounts, _data.judgement, _data.summons, _data.writtenOffValue, _data.fraud, _data.deceased, _data.debtReview, _data.sold);
                setPrescriptionRateScore(calculatedPrescriptionRate.toFixed(2));
                setPrescriptionRateScoreSM(calculateScoreBasedOnMatrix(calculatedPrescriptionRate));
                break;
            case 4:
                const calculateDeviceRecoveryRate = calculateDeviceRecoveryRateScore(_data.deviceRecovery, _data.msisdn, _data.missdnSimOnly);
                setDeviceRecoveryRateScore(calculateDeviceRecoveryRate.toFixed(2));
                setDeviceRecoveryRateScoreSM(calculateScoreBasedOnMatrix(calculateDeviceRecoveryRate));
                break;
            case 5:
                setRehabilitationsScore(_data.rehabilitations);
                setRehabilitationsScoreSM(calculateScoreBasedOnMatrix(_data.rehabilitations));
                break;
            case 6:
                const calculatedCollaborationScore = calculateCollaborationScore(_data.accountMatched, _data.legalAccount);
                setCollaborationScore(calculatedCollaborationScore.toFixed(2));
                setCollaborationScoreSM(calculateScoreBasedOnMatrix(calculatedCollaborationScore));
                break;
            case 7:
                const calculatedFixRate = calculateFixRateScore(_data.ticketResolved, _data.tikcetReceived);
                setFixRateScore(calculatedFixRate.toFixed(2));
                setFixRateScoreSM(calculateScoreBasedOnMatrix(calculatedFixRate));
                break;
            case 8:
                const calculatedReportingScore = calculateReportingScore(_data.mtnMatrixUpdated, _data.mtnMatrixSaved, _data.monthlyEdcReportToMtn, _data.monthlyEdcReportToGs, _data.weeklyPay, _data.monthlyBase);
                setReportingScore(calculatedReportingScore);
                setReportingScoreSM(calculateScoreBasedOnMatrix(calculatedReportingScore));
                break;
            case 9:
                setQualityAssuranceScore(_data.qaScore);
                setQualityAssuranceScoreSM(calculateScoreBasedOnMatrix(_data.qaScore));
                break;
            case 10:
                setSpecialProjectsScore(_data.specialProject);
                setSpecialProjectsScoreSM(calculateScoreBasedOnMatrix(_data.specialProject));
                break;
            case 11:
                setEscalationScore(_data.escalation);
                setEscalationScoreSM(calculateScoreBasedOnMatrix(_data.escalation));
                break;
            default:
                break;
        }
    }

    const calculateYieldRateScore = (recoveryRate, writtenOffValue, fraud, deceased, debtReview, prescribed, sold) => {
        const temp = writtenOffValue - fraud - deceased - debtReview - prescribed - sold;
        return (recoveryRate / temp) * 100;
    }

    const calculateActivationRateScore = (noOfAccountEdc, noOfAccountBook) => {
        return (noOfAccountEdc / noOfAccountBook) * 100;
    }

    const calculatePrescriptionRateScore = (prescribedAccounts, judgement, summons, writtenOffValue, fraud, deceased, debtReview, sold) => {
        const adjustedWrittenOffValue = writtenOffValue - fraud - deceased - debtReview - sold;
        const adjustedPrescribedAccounts = prescribedAccounts - judgement - summons;
        return (adjustedPrescribedAccounts / adjustedWrittenOffValue) * 100;
    }

    const calculateDeviceRecoveryRateScore = (deviceRecovery, msisdn, missdnSimOnly) => {
        return (deviceRecovery / (msisdn - missdnSimOnly)) * 100;
    }

    const calculateCollaborationScore = (accountMatched, legalAccount) => {
        return (accountMatched / legalAccount) * 100;
    }

    const calculateFixRateScore = (ticketResolved, tikcetReceived) => {
        return (ticketResolved / tikcetReceived) * 100;
    }

    const calculateReportingScore = (mtnMatrixUpdated, mtnMatrixSaved, monthlyEdcReportToMtn, monthlyEdcReportToGs, weeklyPay, monthlyBase) => {
        return parseFloat(mtnMatrixUpdated) + parseFloat(mtnMatrixSaved) + parseFloat(monthlyEdcReportToMtn) + parseFloat(monthlyEdcReportToGs) + parseFloat(weeklyPay) + parseFloat(monthlyBase);
    }

    const calculatedScore = () => {
        //send data to parent
        const dataToSend = {
            formulaIndex: formulaIndex
        }

        switch (formulaIndex) {
            case 1:
                dataToSend.score = yeildRateScoreSM;
                break;
            case 2:
                dataToSend.score = activationRateScoreSM;
                break;
            case 3:
                dataToSend.score = prescriptionRateScoreSM;
                break;
            case 4:
                dataToSend.score = deviceRecoveryRateScoreSM;
                break;
            case 5:
                dataToSend.score = rehabilitationsScoreSM;
                break;
            case 6:
                dataToSend.score = collaborationScoreSM;
                break;
            case 7:
                dataToSend.score = fixRateScoreSM;
                break;
            case 8:
                dataToSend.score = reportingScoreSM;
                break;
            case 9:
                dataToSend.score = qualityAssuranceScoreSM;
                break;
            case 10:
                dataToSend.score = specialProjectsScoreSM;
                break;
            case 11:
                dataToSend.score = escalationScoreSM;
                break;
            default:
                break;
        }

        onDataSubmit(dataToSend);
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleCancelEvent}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={classes.modal}>
                        <Box sx={{ display: 'flex' }}>
                            <Card sx={{ width: '75vw', maxHeight: '90vh', overflowY: 'auto' }}>
                                {/* Form start */}
                                <CardContent>
                                    <div className="py-2 px-md-4 px-2">
                                        <div className="row mb-3">
                                            <h3 className="pb-2">
                                                {t('edcScoreCard.performaceCalculator')}
                                            </h3>
                                        </div>

                                        {formulaIndex === 1 &&
                                            <>
                                                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="row my-2">
                                                        <h6>Performance Model</h6>
                                                        <p>% Yield Rate Collection for the Quarter - Amount</p>
                                                    </div>
                                                    <div className="row my-2">
                                                        <h6>Formula</h6>
                                                        <p>Recovery Rate / (Written Off  value - (Fraud-Deceased - Debt Review - Prescribed - Sold))</p>
                                                    </div>

                                                    <div className="row my-2">
                                                        <div className="col-12">
                                                            <h6>Calculator</h6>
                                                        </div>

                                                        {/* Recovery Rate */}
                                                        <div className="col-4 mt-3">
                                                            <FormControl fullWidth>
                                                                <TextField id="recoveryRate"
                                                                    {...register("recoveryRate", { required: true })}
                                                                    onChange={() => setYeildRateScore(0)}
                                                                    label={t('edcScoreCard.recoveryRate')}
                                                                    type={"number"}
                                                                    error={!!errors.recoveryRate}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        {/* written Off Value */}
                                                        <div className="col-4 mt-3">
                                                            <FormControl fullWidth>
                                                                <TextField id="writtenOffValue"
                                                                    {...register("writtenOffValue", { required: true })}
                                                                    onChange={() => setYeildRateScore(0)}
                                                                    label={t('edcScoreCard.writtenOffValue')}
                                                                    type={"number"}
                                                                    error={!!errors.writtenOffValue}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        {/* fraud */}
                                                        <div className="col-4 mt-3">
                                                            <FormControl fullWidth>
                                                                <TextField id="fraud"
                                                                    {...register("fraud", { required: true })}
                                                                    onChange={() => setYeildRateScore(0)}
                                                                    label={t('edcScoreCard.fraud')}
                                                                    type={"number"}
                                                                    error={!!errors.fraud}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        {/* deceased */}
                                                        <div className="col-4 mt-3">
                                                            <FormControl fullWidth>
                                                                <TextField id="deceased"
                                                                    {...register("deceased", { required: true })}
                                                                    onChange={() => setYeildRateScore(0)}
                                                                    label={t('edcScoreCard.deceased')}
                                                                    type={"number"}
                                                                    error={!!errors.deceased}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        {/* debt review */}
                                                        <div className="col-4 mt-3">
                                                            <FormControl fullWidth>
                                                                <TextField id="debtReview"
                                                                    {...register("debtReview", { required: true })}
                                                                    onChange={() => setYeildRateScore(0)}
                                                                    label={t('edcScoreCard.debtReview')}
                                                                    type={"number"}
                                                                    error={!!errors.debtReview}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        {/* prescribed  */}
                                                        <div className="col-4 mt-3">
                                                            <FormControl fullWidth>
                                                                <TextField id="prescribed"
                                                                    {...register("prescribed", { required: true })}
                                                                    onChange={() => setYeildRateScore(0)}
                                                                    label={t('edcScoreCard.prescribed')}
                                                                    type={"number"}
                                                                    error={!!errors.prescribed}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        {/* sold */}
                                                        <div className="col-4 mt-3">
                                                            <FormControl fullWidth>
                                                                <TextField id="sold"
                                                                    {...register("sold", { required: true })}
                                                                    onChange={() => setYeildRateScore(0)}
                                                                    label={t('edcScoreCard.sold')}
                                                                    type={"number"}
                                                                    error={!!errors.sold}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        <div className="col-4 mt-3 py-2">
                                                            {/* Calculate 1 Result */}
                                                            <Button
                                                                variant="contained"
                                                                type="submit"
                                                                size='small'
                                                                className='py-2'
                                                            >
                                                                {t('edcScoreCard.calculateScore')}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </form>

                                                <div className="row mt-4">
                                                    <h5>Yield Rate</h5>
                                                    <p>{yeildRateScore}%</p>
                                                </div>

                                                <div className="row mt-4">
                                                    <h5>Standard Matrix</h5>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-md-6">
                                                        <EdcscorecardRangeMatrix rangeMatrix={rangeMatrix} />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <h5>Score based on standard matrix</h5>
                                                    <p>{yeildRateScoreSM}</p>
                                                </div>
                                            </>
                                        }

                                        {/* -----------------------------------------------------------------Formula 2 Start --------------------------------------------------- */}
                                        {formulaIndex === 2 &&
                                            <>
                                                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="row my-2">
                                                        <h6>Performance Model</h6>
                                                        <p>% Activation Rate Collection for the Quarter - # of Accounts</p>
                                                    </div>
                                                    <div className="row my-2">
                                                        <h6>Formula</h6>
                                                        <p>Number of Legal accounts on which activities were performed on by EDC \ Number of Legal Accounts on Book</p>
                                                    </div>

                                                    <div className="row my-2">
                                                        <div className="col-12">
                                                            <h6>Calculator</h6>
                                                        </div>

                                                        {/* Account By Edc */}
                                                        <div className="col-4 mt-3">
                                                            <FormControl fullWidth>
                                                                <TextField id="noOfAccountEdc"
                                                                    {...register("noOfAccountEdc", { required: true })}
                                                                    onChange={() => setActivationRateScore(0)}
                                                                    label={t('edcScoreCard.noOfAccountEdc')}
                                                                    type={"number"}
                                                                    error={!!errors.noOfAccountEdc}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        {/* Account By Book */}
                                                        <div className="col-4 mt-3">
                                                            <FormControl fullWidth>
                                                                <TextField id="noOfAccountBook"
                                                                    {...register("noOfAccountBook", { required: true })}
                                                                    onChange={() => setActivationRateScore(0)}
                                                                    label={t('edcScoreCard.noOfAccountBook')}
                                                                    type={"number"}
                                                                    error={!!errors.noOfAccountBook}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        <div className="col-4 mt-3 py-2">
                                                            {/* Calculate 2 Result */}
                                                            <Button
                                                                variant="contained"
                                                                type="submit"
                                                                size='small'
                                                                className='py-2'
                                                            >
                                                                {t('edcScoreCard.calculateScore')}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </form>

                                                <div className="row mt-4">
                                                    <h5>Calculated Score</h5>
                                                    <p>{activationRateScore}%</p>
                                                </div>

                                                <div className="row mt-4">
                                                    <h5>Standard Matrix</h5>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-md-6">
                                                        <EdcscorecardRangeMatrix rangeMatrix={rangeMatrix} />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <h5>Score based on standard matrix</h5>
                                                    <p>{activationRateScoreSM}</p>
                                                </div>
                                            </>
                                        }

                                        {/* -----------------------------------------------------------------Formula 3 Start --------------------------------------------------- */}
                                        {formulaIndex === 3 &&
                                            <>
                                                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="row my-2">
                                                        <h6>Performance Model</h6>
                                                        <p>% Prescription Rate</p>
                                                    </div>
                                                    <div className="row my-2">
                                                        <h6>Formula</h6>
                                                        <p>Prescribed Accounts - (Judgement-Summons) / (Written Off accounts - (Fraud - Deceased-Debt Review - Sold))</p>
                                                    </div>

                                                    <div className="row my-2">
                                                        <div className="col-12">
                                                            <h6>Calculator</h6>
                                                        </div>

                                                        {/* Prescribed Account */}
                                                        <div className="col-4 mt-3">
                                                            <FormControl fullWidth>
                                                                <TextField id="prescribedAccounts"
                                                                    {...register("prescribedAccounts", { required: true })}
                                                                    onChange={() => setPrescriptionRateScore(0)}
                                                                    label={t('edcScoreCard.prescribedAccounts')}
                                                                    type={"number"}
                                                                    error={!!errors.prescribedAccounts}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        {/* Judgement */}
                                                        <div className="col-4 mt-3">
                                                            <FormControl fullWidth>
                                                                <TextField id="judgement"
                                                                    {...register("judgement", { required: true })}
                                                                    onChange={() => setPrescriptionRateScore(0)}
                                                                    label={t('edcScoreCard.judgement')}
                                                                    type={"number"}
                                                                    error={!!errors.judgement}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        {/* Summons */}
                                                        <div className="col-4 mt-3">
                                                            <FormControl fullWidth>
                                                                <TextField id="summons"
                                                                    {...register("summons", { required: true })}
                                                                    onChange={() => setPrescriptionRateScore(0)}
                                                                    label={t('edcScoreCard.summons')}
                                                                    type={"number"}
                                                                    error={!!errors.summons}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        {/* written Off Value */}
                                                        <div className="col-4 mt-3">
                                                            <FormControl fullWidth>
                                                                <TextField id="writtenOffValue"
                                                                    {...register("writtenOffValue", { required: true })}
                                                                    onChange={() => setPrescriptionRateScore(0)}
                                                                    label={t('edcScoreCard.writtenOffValue')}
                                                                    type={"number"}
                                                                    error={!!errors.writtenOffValue}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        {/* fraud */}
                                                        <div className="col-4 mt-3">
                                                            <FormControl fullWidth>
                                                                <TextField id="fraud"
                                                                    {...register("fraud", { required: true })}
                                                                    onChange={() => setPrescriptionRateScore(0)}
                                                                    label={t('edcScoreCard.fraud')}
                                                                    type={"number"}
                                                                    error={!!errors.fraud}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        {/* deceased */}
                                                        <div className="col-4 mt-3">
                                                            <FormControl fullWidth>
                                                                <TextField id="deceased"
                                                                    {...register("deceased", { required: true })}
                                                                    onChange={() => setPrescriptionRateScore(0)}
                                                                    label={t('edcScoreCard.deceased')}
                                                                    type={"number"}
                                                                    error={!!errors.deceased}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        {/* debt review */}
                                                        <div className="col-4 mt-3">
                                                            <FormControl fullWidth>
                                                                <TextField id="debtReview"
                                                                    {...register("debtReview", { required: true })}
                                                                    onChange={() => setPrescriptionRateScore(0)}
                                                                    label={t('edcScoreCard.debtReview')}
                                                                    type={"number"}
                                                                    error={!!errors.debtReview}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        {/* sold */}
                                                        <div className="col-4 mt-3">
                                                            <FormControl fullWidth>
                                                                <TextField id="sold"
                                                                    {...register("sold", { required: true })}
                                                                    onChange={() => setPrescriptionRateScore(0)}
                                                                    label={t('edcScoreCard.sold')}
                                                                    type={"number"}
                                                                    error={!!errors.sold}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        <div className="col-4 mt-3 py-2">
                                                            {/* Calculate 3 Result */}
                                                            <Button
                                                                variant="contained"
                                                                type="submit"
                                                                size='small'
                                                                className='py-2'
                                                            >
                                                                {t('edcScoreCard.calculateScore')}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </form>

                                                <div className="row mt-4">
                                                    <h5>Calculated Score</h5>
                                                    <p>{prescriptionRateScore}%</p>
                                                </div>

                                                <div className="row mt-4">
                                                    <h5>Standard Matrix</h5>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-md-6">
                                                        <EdcscorecardRangeMatrix rangeMatrix={rangeMatrix} />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <h5>Score based on standard satrix</h5>
                                                    <p>{prescriptionRateScoreSM}</p>
                                                </div>
                                            </>
                                        }

                                        {/* -----------------------------------------------------------------Formula 4 Start --------------------------------------------------- */}
                                        {formulaIndex === 4 &&
                                            <>
                                                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="row my-2">
                                                        <h6>Performance Model</h6>
                                                        <p>% Device Recovery Rate for the Quarter - # of Accounts</p>
                                                    </div>
                                                    <div className="row my-2">
                                                        <h6>Formula</h6>
                                                        <p>The number of devices recovered / The number of in contract MSISDN's @ date of Cancelation- In contract MSISDN SimOnly's@ date of Cancelation</p>
                                                    </div>

                                                    <div className="row my-2">
                                                        <div className="col-12">
                                                            <h6>Calculator</h6>
                                                        </div>

                                                        {/* Device Recovered */}
                                                        <div className="col-4 mt-3">
                                                            <FormControl fullWidth>
                                                                <TextField id="deviceRecovery"
                                                                    {...register("deviceRecovery", { required: true })}
                                                                    onChange={() => setDeviceRecoveryRateScore(0)}
                                                                    label={t('edcScoreCard.deviceRecovery')}
                                                                    type={"number"}
                                                                    error={!!errors.deviceRecovery}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        {/* msisdn */}
                                                        <div className="col-4 mt-3">
                                                            <FormControl fullWidth>
                                                                <TextField id="msisdn"
                                                                    {...register("msisdn", { required: true })}
                                                                    onChange={() => setDeviceRecoveryRateScore(0)}
                                                                    label={t('edcScoreCard.msisdn')}
                                                                    type={"number"}
                                                                    error={!!errors.msisdn}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        {/* msisdn sim only */}
                                                        <div className="col-4 mt-3">
                                                            <FormControl fullWidth>
                                                                <TextField id="missdnSimOnly"
                                                                    {...register("missdnSimOnly", { required: true })}
                                                                    onChange={() => setDeviceRecoveryRateScore(0)}
                                                                    label={t('edcScoreCard.missdnSimOnly')}
                                                                    type={"number"}
                                                                    error={!!errors.missdnSimOnly}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>
                                                        <div className="col-4 mt-3 py-2">
                                                            {/* Calculate 4 Result */}
                                                            <Button
                                                                variant="contained"
                                                                type="submit"
                                                                size='small'
                                                                className='py-2'
                                                            >
                                                                {t('edcScoreCard.calculateScore')}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </form>

                                                <div className="row mt-4">
                                                    <h5>Calculated Score</h5>
                                                    <p>{deviceRecoveryRateScore}%</p>
                                                </div>
                                                <div className="row mt-4">
                                                    <h5>Standard Matrix</h5>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-md-6">
                                                        <EdcscorecardRangeMatrix rangeMatrix={rangeMatrix} />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <h5>Score based on standard matrix</h5>
                                                    <p>{deviceRecoveryRateScoreSM}</p>
                                                </div>
                                            </>
                                        }
                                        {/* -----------------------------------------------------------------Formula 5 Start -> Not Applicable  --------------------------------------------------- */}
                                        {formulaIndex === 5 &&
                                            <>
                                                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="row my-2">
                                                        <h6>Performance Model</h6>
                                                        <p>% Rehabilitations</p>
                                                    </div>
                                                    <div className="row my-2">
                                                        <h6>Formula</h6>
                                                        <p>Not Applicable for Legal</p>
                                                    </div>

                                                    <div className="row my-2">
                                                        <div className="col-12">
                                                            <h6>Calculator</h6>
                                                        </div>

                                                        {/* Account matched */}
                                                        <div className="col-4 mt-3">
                                                            <FormControl fullWidth>
                                                                <TextField id="rehabilitations"
                                                                    {...register("rehabilitations", { required: true })}
                                                                    onChange={() => setRehabilitationsScore(0)}
                                                                    label={t('edcScoreCard.rehabilitations')}
                                                                    type={"number"}
                                                                    error={!!errors.rehabilitations}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        <div className="col-4 mt-3 py-2">
                                                            {/* Calculate 6 Result */}
                                                            <Button
                                                                variant="contained"
                                                                type="submit"
                                                                size='small'
                                                                className='py-2'
                                                            >
                                                                {t('edcScoreCard.calculateScore')}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </form>

                                                <div className="row mt-4">
                                                    <h5>Calculated Score</h5>
                                                    <p>{rehabilitationsScore}%</p>
                                                </div>
                                                <div className="row mt-4">
                                                    <h5>Standard Matrix</h5>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-md-6">
                                                        <EdcscorecardRangeMatrix rangeMatrix={rangeMatrix} />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <h5>Score based on sandard matrix</h5>
                                                    <p>{rehabilitationsScoreSM}</p>
                                                </div>
                                            </>
                                        }
                                        {/* -----------------------------------------------------------------Formula 6 Start -> Req Not Clear  --------------------------------------------------- */}
                                        {formulaIndex === 6 &&
                                            <>
                                                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="row my-2">
                                                        <h6>Performance Model</h6>
                                                        <p>% Collaboration (Quarterly)</p>
                                                    </div>
                                                    <div className="row my-2">
                                                        <h6>Formula</h6>
                                                        <p>The number of accounts matched, number of RPC made using third-party data, Number of AKC registered on account, Number of New DebiCHechs registered on Account /Number of legal accounts handed over
                                                        </p>
                                                    </div>

                                                    <div className="row my-2">
                                                        <div className="col-12">
                                                            <h6>Calculator</h6>
                                                        </div>

                                                        {/* Account matched */}
                                                        <div className="col-4 mt-3">
                                                            <FormControl fullWidth>
                                                                <TextField id="accountMatched"
                                                                    {...register("accountMatched", { required: true })}
                                                                    onChange={() => setCollaborationScore(0)}
                                                                    label={t('edcScoreCard.accountMatched')}
                                                                    type={"number"}
                                                                    error={!!errors.accountMatched}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        {/* Number of legal account */}
                                                        <div className="col-4 mt-3">
                                                            <FormControl fullWidth>
                                                                <TextField id="legalAccount"
                                                                    {...register("legalAccount", { required: true })}
                                                                    onChange={() => setCollaborationScore(0)}
                                                                    label={t('edcScoreCard.legalAccount')}
                                                                    type={"number"}
                                                                    error={!!errors.legalAccount}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        <div className="col-4 mt-3 py-2">
                                                            {/* Calculate 6 Result */}
                                                            <Button
                                                                variant="contained"
                                                                type="submit"
                                                                size='small'
                                                                className='py-2'
                                                            >
                                                                {t('edcScoreCard.calculateScore')}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </form>

                                                <div className="row mt-4">
                                                    <h5>Calculated Score</h5>
                                                    <p>{collaborationScore}%</p>
                                                </div>
                                                <div className="row mt-4">
                                                    <h5>Standard Matrix</h5>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-md-6">
                                                        <EdcscorecardRangeMatrix rangeMatrix={rangeMatrix} />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <h5>Score based on standard matrix</h5>
                                                    <p>{collaborationScoreSM}</p>
                                                </div>
                                            </>
                                        }
                                        {/* -----------------------------------------------------------------Formula 7 Start --------------------------------------------------- */}
                                        {formulaIndex === 7 &&
                                            <>
                                                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="row my-2">
                                                        <h6>Performance Model</h6>
                                                        <p>%  Fix Rate - Service Levels Query Resolution</p>
                                                    </div>
                                                    <div className="row my-2">
                                                        <h6>Formula</h6>
                                                        <p>Number of tickets and request serviced within a 24hr period/Number of request received with in a Month</p>
                                                    </div>

                                                    <div className="row my-2">
                                                        <div className="col-12">
                                                            <h6>Calculator</h6>
                                                        </div>

                                                        {/* Tickets resolved within 24 hrs */}
                                                        <div className="col-4 mt-3">
                                                            <FormControl fullWidth>
                                                                <TextField id="ticketResolved"
                                                                    {...register("ticketResolved", { required: true })}
                                                                    onChange={() => setFixRateScore(0)}
                                                                    label={t('edcScoreCard.ticketResolved')}
                                                                    type={"number"}
                                                                    error={!!errors.ticketResolved}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        {/* Number of legal account */}
                                                        <div className="col-4 mt-3">
                                                            <FormControl fullWidth>
                                                                <TextField id="tikcetReceived"
                                                                    {...register("tikcetReceived", { required: true })}
                                                                    onChange={() => setFixRateScore(0)}
                                                                    label={t('edcScoreCard.tikcetReceived')}
                                                                    type={"number"}
                                                                    error={!!errors.tikcetReceived}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        <div className="col-4 mt-3 py-2">
                                                            {/* Calculate 7 Result */}
                                                            <Button
                                                                variant="contained"
                                                                type="submit"
                                                                size='small'
                                                                className='py-2'
                                                            >
                                                                {t('edcScoreCard.calculateScore')}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </form>

                                                <div className="row mt-4">
                                                    <h5>Calculated Score</h5>
                                                    <p>{fixRateScore}%</p>
                                                </div>
                                                <div className="row mt-4">
                                                    <h5>Standard Matrix</h5>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-md-6">
                                                        <EdcscorecardRangeMatrix rangeMatrix={rangeMatrix} />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <h5>Score based on standard matrix</h5>
                                                    <p>{fixRateScoreSM}</p>
                                                </div>
                                            </>
                                        }
                                        {/* -----------------------------------------------------------------Formula 8 Start -> Not Applicable  --------------------------------------------------- */}
                                        {formulaIndex === 8 &&
                                            <>
                                                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="row my-2">
                                                        <h6>Performance Model</h6>
                                                        <p>Reporting</p>
                                                    </div>
                                                    <div className="row my-2">
                                                        <h6>Formula</h6>
                                                        <p>All Required report to be placed on Globalscape drive by no later than the 1st of each month</p>
                                                    </div>

                                                    <div className="row my-2">
                                                        <div className="col-12">
                                                            <h6>Calculator</h6>
                                                        </div>

                                                        {/* MTN Matrix updated */}
                                                        <div className="col-6 mt-3">
                                                            <p>{labelForFormulaReporting[0]}</p>
                                                            <FormControl fullWidth>
                                                                <TextField id="mtnMatrixUpdated"
                                                                    {...register("mtnMatrixUpdated", { required: true })}
                                                                    onChange={() => setReportingScore(0)}
                                                                    label={t('edcScoreCard.mtnMatrixUpdated')}
                                                                    type={"number"}
                                                                    error={!!errors.mtnMatrixUpdated}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        {/* MTN Matrix saved */}
                                                        <div className="col-6 mt-3">
                                                            <p>{labelForFormulaReporting[1]}</p>
                                                            <FormControl fullWidth>
                                                                <TextField id="mtnMatrixSaved"
                                                                    {...register("mtnMatrixSaved", { required: true })}
                                                                    onChange={() => setReportingScore(0)}
                                                                    label={t('edcScoreCard.mtnMatrixSaved')}
                                                                    type={"number"}
                                                                    error={!!errors.mtnMatrixSaved}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        {/* Monthly EDC reports to mtn */}
                                                        <div className="col-6 mt-5">
                                                            <p>{labelForFormulaReporting[2]}</p>
                                                            <FormControl fullWidth>
                                                                <TextField id="monthlyEdcReportToMtn"
                                                                    {...register("monthlyEdcReportToMtn", { required: true })}
                                                                    onChange={() => setReportingScore(0)}
                                                                    label={t('edcScoreCard.monthlyEdcReportToMtn')}
                                                                    type={"number"}
                                                                    error={!!errors.monthlyEdcReportToMtn}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        {/* Monthly EDC reports to GS */}
                                                        <div className="col-6 mt-5">
                                                            <p>{labelForFormulaReporting[3]}</p>
                                                            <FormControl fullWidth>
                                                                <TextField id="monthlyEdcReportToGs"
                                                                    {...register("monthlyEdcReportToGs", { required: true })}
                                                                    onChange={() => setReportingScore(0)}
                                                                    label={t('edcScoreCard.monthlyEdcReportToGs')}
                                                                    type={"number"}
                                                                    error={!!errors.monthlyEdcReportToGs}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        {/*weeklyPay */}
                                                        <div className="col-6 mt-5">
                                                            <p>{labelForFormulaReporting[4]}</p>
                                                            <FormControl fullWidth>
                                                                <TextField id="weeklyPay"
                                                                    {...register("weeklyPay", { required: true })}
                                                                    onChange={() => setReportingScore(0)}
                                                                    label={t('edcScoreCard.weeklyPay')}
                                                                    type={"number"}
                                                                    error={!!errors.weeklyPay}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        {/* monthlyBase */}
                                                        <div className="col-6 mt-5">
                                                            <p>{labelForFormulaReporting[5]}</p>
                                                            <FormControl fullWidth>
                                                                <TextField id="monthlyBase"
                                                                    {...register("monthlyBase", { required: true })}
                                                                    onChange={() => setReportingScore(0)}
                                                                    label={t('edcScoreCard.monthlyBase')}
                                                                    type={"number"}
                                                                    error={!!errors.monthlyBase}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        <div className="col-4 mt-3 py-2">
                                                            {/* Calculate 6 Result */}
                                                            <Button
                                                                variant="contained"
                                                                type="submit"
                                                                size='small'
                                                                className='py-2'
                                                            >
                                                                {t('edcScoreCard.calculateScore')}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </form>

                                                <div className="row mt-4">
                                                    <h5>Calculated Score</h5>
                                                    <p>{reportingScore}</p>
                                                </div>
                                                <div className="row mt-4">
                                                    <h5>Standard Matrix</h5>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-md-6">
                                                        <EdcscorecardRangeMatrix rangeMatrix={rangeMatrix} />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <h5>Score based on standard matrix</h5>
                                                    <p>{reportingScoreSM}</p>
                                                </div>
                                            </>
                                        }
                                        {/* -----------------------------------------------------------------Formula 9 Start  --------------------------------------------------- */}
                                        {formulaIndex === 9 &&
                                            <>
                                                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="row my-2">
                                                        <h6>Performance Model</h6>
                                                        <p>Quality Assurance</p>
                                                    </div>
                                                    <div className="row my-2">
                                                        <h6>Formula</h6>
                                                        <p>A 10% Representative Sample of with Score 1.RPC no PTP 2.RPC PTP no Payment 3. RPC PTP Payment Scores to be Colbrated by MTN and applied on a wiegted AVG to the total population based on 1,2&3 segmentation</p>
                                                    </div>

                                                    <div className="row my-2">
                                                        <div className="col-12">
                                                            <h6>Calculator</h6>
                                                        </div>

                                                        {/* qa score */}
                                                        <div className="col-6 mt-3">
                                                            <p>{labelForFormulaReporting[0]}</p>
                                                            <FormControl fullWidth>
                                                                <TextField id="qaScore"
                                                                    {...register("qaScore", { required: true })}
                                                                    onChange={() => setQualityAssuranceScore(0)}
                                                                    label={t('edcScoreCard.qaScore')}
                                                                    type={"number"}
                                                                    error={!!errors.qaScore}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        <div className="col-4 mt-3 py-2">
                                                            {/* Calculate 9 Result */}
                                                            <Button
                                                                variant="contained"
                                                                type="submit"
                                                                size='small'
                                                                className='py-2'
                                                            >
                                                                {t('edcScoreCard.calculateScore')}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </form>

                                                <div className="row mt-4">
                                                    <h5>Calculated Score</h5>
                                                    <p>{qualityAssuranceScore}%</p>
                                                </div>
                                                <div className="row mt-4">
                                                    <h5>Standard Matrix</h5>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-md-6">
                                                        <EdcscorecardRangeMatrix rangeMatrix={rangeMatrix} />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <h5>Score based on standard matrix</h5>
                                                    <p>{qualityAssuranceScoreSM}</p>
                                                </div>
                                            </>
                                        }
                                        {/* -----------------------------------------------------------------Formula 10 Start --------------------------------------------------- */}
                                        {formulaIndex === 10 &&
                                            <>
                                                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="row my-2">
                                                        <h6>Performance Model</h6>
                                                        <p>Special Projects</p>
                                                    </div>
                                                    <div className="row my-2">
                                                        <h6>Formula</h6>
                                                        <p>Project are delivered on time</p>
                                                    </div>

                                                    <div className="row my-2">
                                                        <div className="col-12">
                                                            <h6>Calculator</h6>
                                                        </div>

                                                        {/* specialProject */}
                                                        <div className="col-6 mt-3">
                                                            <p>{labelForFormulaReporting[0]}</p>
                                                            <FormControl fullWidth>
                                                                <TextField id="specialProject"
                                                                    {...register("specialProject", { required: true })}
                                                                    onChange={() => setSpecialProjectsScore(0)}
                                                                    label={t('edcScoreCard.specialProject')}
                                                                    type={"number"}
                                                                    error={!!errors.specialProject}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        <div className="col-4 mt-3 py-2">
                                                            {/* Calculate 9 Result */}
                                                            <Button
                                                                variant="contained"
                                                                type="submit"
                                                                size='small'
                                                                className='py-2'
                                                            >
                                                                {t('edcScoreCard.calculateScore')}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </form>

                                                <div className="row mt-4">
                                                    <h5>Calculated Score</h5>
                                                    <p>{specialProjectsScore}%</p>
                                                </div>
                                                <div className="row mt-4">
                                                    <h5>Standard Matrix</h5>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-md-6">
                                                        <EdcscorecardRangeMatrix rangeMatrix={rangeMatrix} />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <h5>Score based on standard matrix</h5>
                                                    <p>{specialProjectsScoreSM}</p>
                                                </div>
                                            </>
                                        }
                                        {/* -----------------------------------------------------------------Formula 11 Start --------------------------------------------------- */}
                                        {formulaIndex === 11 &&
                                            <>
                                                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="row my-2">
                                                        <h6>Performance Model</h6>
                                                        <p>% Escalation per Month</p>
                                                    </div>
                                                    <div className="row my-2">
                                                        <h6>Formula</h6>
                                                        <p>Number of escalation or repeated service request received in a month</p>
                                                    </div>

                                                    <div className="row my-2">
                                                        <div className="col-12">
                                                            <h6>Calculator</h6>
                                                        </div>

                                                        {/* escalation per month  */}
                                                        <div className="col-4 mt-3">
                                                            <FormControl fullWidth>
                                                                <TextField id="escalation"
                                                                    {...register("escalation", { required: true })}
                                                                    onChange={() => setEscalationScore(0)}
                                                                    label={t('edcScoreCard.escalation')}
                                                                    type={"number"}
                                                                    error={!!errors.escalation}
                                                                    variant="outlined"
                                                                    className="full-width" />
                                                            </FormControl>
                                                        </div>

                                                        <div className="col-4 mt-3 py-2">
                                                            {/* Calculate 1 Result */}
                                                            <Button
                                                                variant="contained"
                                                                type="submit"
                                                                size='small'
                                                                className='py-2'
                                                            >
                                                                {t('edcScoreCard.calculateScore')}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </form>

                                                <div className="row mt-4">
                                                    <h5>Calculated Score</h5>
                                                    <p>{escalationScore}%</p>
                                                </div>
                                                <div className="row mt-4">
                                                    <h5>Standard Matrix</h5>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-md-6">
                                                        <EdcscorecardRangeMatrix rangeMatrix={rangeMatrix} />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <h5>Score based on standard matrix</h5>
                                                    <p>{escalationScoreSM}</p>
                                                </div>
                                            </>
                                        }
                                    </div>

                                </CardContent>
                                <CardActions>
                                    <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                                        {/* Cancel Button */}
                                        <Button variant="outlined"
                                            onClick={handleCancelEvent}>
                                            {t('common.cancel')}
                                        </Button>

                                        &nbsp;&nbsp;

                                        {/* Okay Button */}
                                        <LoadingButton
                                            loading={loading}
                                            disabled={(formulaIndex === 1 && yeildRateScoreSM === 0) ||
                                                (formulaIndex === 2 && activationRateScoreSM === 0) ||
                                                (formulaIndex === 3 && prescriptionRateScoreSM === 0) ||
                                                (formulaIndex === 4 && deviceRecoveryRateScoreSM === 0) ||
                                                (formulaIndex === 5 && rehabilitationsScoreSM === 0) ||
                                                (formulaIndex === 6 && collaborationScoreSM === 0) ||
                                                (formulaIndex === 7 && fixRateScoreSM === 0) ||
                                                (formulaIndex === 8 && reportingScoreSM === 0) ||
                                                (formulaIndex === 9 && qualityAssuranceScoreSM === 0) ||
                                                (formulaIndex === 10 && specialProjectsScoreSM === 0) ||
                                                (formulaIndex === 11 && escalationScoreSM === 0)
                                            }
                                            variant="contained"
                                            onClick={calculatedScore}
                                        >
                                            {t('common.edit')}
                                        </LoadingButton>
                                        &nbsp;
                                    </div>
                                </CardActions>

                            </Card>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div >
    );
}

export default EdcscorecardCalculator;
