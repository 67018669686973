// @ts-nocheck

import React, {useEffect, useState, useRef} from 'react';
import {useStyles} from './Styles';
import '../../../../../index.scss';
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {getAll, getById, post, patch, deleteById} from "../../../../../Services/GenericApiService";
import {formatData} from "../../../../../Services/DataFormatService";
import {useTranslation} from "react-i18next";
import AlertM from '../../../../Helpers/AlertM/AlertM';
import {log} from "../../../../../Services/LoggerService";

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MUIDataTable from "mui-datatables";
import {Button, IconButton, Skeleton} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {library} from '@fortawesome/fontawesome-svg-core';
import Tooltip from '@mui/material/Tooltip';
import ConfirmationDialog from "../../../../Helpers/ConfirmationDialog/ConfirmationDialog";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function AutomatedImporterTrigger({payment = false}) {
    // css for module
    const classes = useStyles();

    // translation
    const {t} = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // get theme from redux
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );

    // module(s) for api
    const moduleMain = 'debtordetail/';
    const moduleTU = 'import/pretermination/tu';
    const moduleMTN = 'import/pretermination/globalscape';
    const modulePaymentUpdate = 'collection/payment/update';
    const moduleCollectionReport = 'status/successFailure'; // success failure file
    const moduleEdcDebtorDetail = 'edcdebtordetail/';
    const moduleVintageReport = 'vintagerollratereport/';

    // data vars
    const [allData, setAllData] = React.useState([]);

    // ui controls
    const [loading, setLoading] = React.useState(false);

    // ui handlers
    const handleGlobalscapeImport = () => {
        setLoading(true);
        getAll(moduleMain + moduleMTN)
            .then(_res => {
                setLoading(false);
                // @ts-ignore
                notifications.current.successAlert('Globalscape Import Completed', _res.success + ' accounts were imported from Globalscape Successfully');
            })
            .catch(_err => {
                log(_err);
                setLoading(false);
                if (_err.response.data.code == 403) {
                    // @ts-ignore
                    notifications.current.errorAlert('No new file Found on SFTP', t('common.tryAgain'));
                } else {
                    // @ts-ignore
                    notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                }
            })
    }

    // ui handlers
    const handlePaymentImport = () => {
        setLoading(true);
        getAll(moduleMain + modulePaymentUpdate)
            .then(_res => {
                setLoading(false);
                // @ts-ignore
                notifications.current.successAlert('Payment Import Completed', 'Payments for ' + _res.rowCount + ' accounts were updated');
            })
            .catch(_err => {
                log(_err);
                setLoading(false);
                if (_err.response.data.code == 403) {
                    // @ts-ignore
                    notifications.current.errorAlert('No new file Found on SFTP', t('common.tryAgain'));
                } else {
                    // @ts-ignore
                    notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                }
            })
    }


    const handleTUImport = () => {
        setLoading(true);
        getAll(moduleMain + moduleTU)
            .then(_res => {
                setLoading(false);
                // @ts-ignore
                notifications.current.successAlert('TU Scoring Import Completed', 'Account scorings were imported from TU Successfully');
            })
            .catch(_err => {
                log(_err);
                setLoading(false);
                if (_err.response.data.code == 403) {
                    // @ts-ignore
                    notifications.current.errorAlert('No new file Found on SFTP', t('common.tryAgain'));
                } else {
                    // @ts-ignore
                    notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                }
            })
    }

    const handleVintageRollRateImport = () => {
        setLoading(true);
        getAll(moduleVintageReport + 'sftp/import')
            .then(_res => {
                setLoading(false);
                // @ts-ignore
                notifications.current.successAlert('Vintage Roll Rate Import Completed', '');
            })
            .catch(_err => {
                log(_err);
                setLoading(false);
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
            })
    }

    const handleCollectionReportImport = () => {
        setLoading(true);
        post(moduleMain + moduleCollectionReport, {})
            .then(_res => {
                console.log(_res);
                setLoading(false);
                // @ts-ignore
                notifications.current.successAlert('Pre-Termination Collection Report Import Completed', 'Account statuses were updated successfully');
            })
            .catch(_err => {
                log(_err);
                setLoading(false);
                if (_err.response.data.code == 403) {
                    // @ts-ignore
                    notifications.current.errorAlert('No new file Found on SFTP', t('common.tryAgain'));
                } else {
                    // @ts-ignore
                    notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                }
            })
    }

    const handleBalanceUpdateImport = () => {
        setLoading(true);
        post(moduleEdcDebtorDetail + 'balance/import', {})
            .then(_res => {
                setLoading(false);
                // @ts-ignore
                notifications.current.successAlert('Balance File Import Completed', 'Account breach amounts were updated successfully');
            })
            .catch(_err => {
                log(_err);
                setLoading(false);
                if (_err.response.data.code == 403) {
                    // @ts-ignore
                    notifications.current.errorAlert('No new file Found on SFTP', t('common.tryAgain'));
                } else {
                    // @ts-ignore
                    notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                }
            })
    }

    const handlePaymentUpdateImport = () => {
        setLoading(true);
        post(moduleEdcDebtorDetail + 'payment/import', {})
            .then(_res => {
                setLoading(false);
                // @ts-ignore
                notifications.current.successAlert('Payment File Import Completed', 'Account breach amounts were updated successfully');
            })
            .catch(_err => {
                log(_err);
                setLoading(false);
                if (_err.response.data.code == 403) {
                    // @ts-ignore
                    notifications.current.errorAlert('No new file Found on SFTP', t('common.tryAgain'));
                } else {
                    // @ts-ignore
                    notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                }
            })
    }


    return (
        <div>
            <div className={(theme == 'dark' ? 'bg-dark' : '') + " container card shadow-lg py-4 px-3"}>
                <div className="px-3 pb-1">
                    <h2 className={'mb-5'}>
                        Automated Import
                    </h2>

                    {/* Importer */}
                    <div className="mt-3">
                        {/* If payment is true then show payment importing options */}
                        {payment &&
                            <div className="row">
                                <div className="col-md-8">
                                    <h4>
                                        Payment Import
                                    </h4>
                                    <p>
                                        This will pull the payment data from Globalscape SFTP, store it in the system,
                                        preprocess some columns, and update payments of the accounts.
                                    </p>
                                </div>

                                <div className="col-md-4">
                                    {!loading &&
                                        <ConfirmationDialog
                                            confirmEvent={(_itemIdx) => {
                                                handlePaymentImport()
                                            }}
                                            type={'confirm'}
                                            showButton={true}
                                            title={'Payment Import'}
                                            body={'Are you sure you want to start Payment Import?'}
                                            buttonText={'Start Payment Import'}/>
                                    }

                                    {loading &&
                                        <Skeleton variant="rounded" height={60}/>
                                    }
                                </div>
                            </div>
                        }

                        {/* If payment is false then show pre term importing options */}
                        {!payment &&
                            <>
                                <div className="row mt-4 mb-4">
                                    <div className="col-md-8">
                                        <h4>
                                            Globalscape Import
                                        </h4>
                                        <p>
                                            This will pull the data from Globalscape SFTP, store it in the system,
                                            preprocess some columns and prepare an input file for TU Scoring.
                                        </p>
                                    </div>
                                    <div className="col-md-4">
                                        {!loading &&
                                            <ConfirmationDialog
                                                confirmEvent={(_itemIdx) => {
                                                    handleGlobalscapeImport()
                                                }}
                                                type={'confirm'}
                                                showButton={true}
                                                title={'Globalscape Import'}
                                                body={'Are you sure you want to start Globalscape Import?'}
                                                buttonText={'Start Globalscape Import'}/>
                                        }
                                        {loading &&
                                            <Skeleton variant="rounded" height={60}/>
                                        }
                                    </div>
                                </div>

                                <div className="row my-3">
                                    <Divider/>
                                </div>

                                <div className="row mt-5 mb-5">
                                    <div className="col-md-8">
                                        <h4>
                                            TU Scoring Import
                                        </h4>
                                        <p>
                                            This will pull the scores from TU, update the records in app by updating
                                            the score and account states.
                                        </p>
                                    </div>
                                    <div className="col-md-4">
                                        {!loading &&
                                            <ConfirmationDialog
                                                confirmEvent={(_itemIdx) => {
                                                    handleTUImport()
                                                }}
                                                type={'confirm'}
                                                showButton={true}
                                                title={'TU Scoring Import'}
                                                body={'Are you sure you want to start TU Scoring Import?'}
                                                buttonText={'Start TU Import'}/>
                                        }
                                        {loading &&
                                            <Skeleton variant="rounded" height={60}/>
                                        }
                                    </div>
                                </div>

                                <div className="row my-3">
                                    <Divider/>
                                </div>

                                <div className="row mt-5 mb-5">
                                    <div className="col-md-8">
                                        <h4>
                                            Payment File Import
                                        </h4>
                                        <p>
                                            This will pull the Payment file from globalscape and update the breach amount of accounts.
                                        </p>
                                    </div>
                                    <div className="col-md-4">
                                        {!loading &&
                                            <ConfirmationDialog
                                                confirmEvent={(_itemIdx) => {
                                                    handlePaymentUpdateImport()
                                                }}
                                                type={'confirm'}
                                                showButton={true}
                                                title={'Payment File Import'}
                                                body={'Are you sure you want to start Payment File Import?'}
                                                buttonText={'Start Payment File Import'}/>
                                        }
                                        {loading &&
                                            <Skeleton variant="rounded" height={60}/>
                                        }
                                    </div>
                                </div>

                                <div className="row my-3">
                                    <Divider/>
                                </div>

                                <div className="row mt-5 mb-5">
                                    <div className="col-md-8">
                                        <h4>
                                            Balance File Import
                                        </h4>
                                        <p>
                                            This will pull the Balance file from globalscape and update the breach amount of accounts.
                                        </p>
                                    </div>
                                    <div className="col-md-4">
                                        {!loading &&
                                            <ConfirmationDialog
                                                confirmEvent={(_itemIdx) => {
                                                    handleBalanceUpdateImport()
                                                }}
                                                type={'confirm'}
                                                showButton={true}
                                                title={'Balance File Import'}
                                                body={'Are you sure you want to start Balance File Import?'}
                                                buttonText={'Start Balance File Import'}/>
                                        }
                                        {loading &&
                                            <Skeleton variant="rounded" height={60}/>
                                        }
                                    </div>
                                </div>

                                <div className="row my-3">
                                    <Divider/>
                                </div>

                                <div className="row mt-5">
                                    <div className="col-md-8">
                                        <h4>
                                            Pre-Termination Collection Report Import
                                        </h4>
                                        <p>
                                            This will pull the Pre-Termination Collection Report from Globalscape and update the account statuses as Termination Success or Success Failure.
                                        </p>
                                    </div>
                                    <div className="col-md-4">
                                        {!loading &&
                                            <ConfirmationDialog
                                                confirmEvent={(_itemIdx) => {
                                                    handleCollectionReportImport()
                                                }}
                                                type={'confirm'}
                                                showButton={true}
                                                title={'Collection Report Import'}
                                                body={'Are you sure you want to start Pre-Termination Collection Report Import?'}
                                                buttonText={'Start Collection Report Import'}/>
                                        }
                                        {loading &&
                                            <Skeleton variant="rounded" height={60}/>
                                        }
                                    </div>
                                </div>

                                <div className="row my-3">
                                    <Divider/>
                                </div>

                                <div className="row mt-5 mb-5">
                                    <div className="col-md-8">
                                        <h4>
                                            Vintage Roll Rate Report Import
                                        </h4>
                                        <p>
                                            This will pull the most recent Vintage Roll Rate Report File from Globalscape.
                                        </p>
                                    </div>
                                    <div className="col-md-4">
                                        {!loading &&
                                            <ConfirmationDialog
                                                confirmEvent={(_itemIdx) => {
                                                    handleVintageRollRateImport()
                                                }}
                                                type={'confirm'}
                                                showButton={true}
                                                title={'Vintage Roll Rate Report Import'}
                                                body={'Are you sure you want to start Vintage Roll Rate Report Import?'}
                                                buttonText={'Start Vintage Roll Rate Report Import'}/>
                                        }
                                        {loading &&
                                            <Skeleton variant="rounded" height={60}/>
                                        }
                                    </div>
                                </div>

                            </>
                        }

                    </div>
                </div>
            </div>

            {/* Alerts */}
            <AlertM ref={notifications}/>

        </div>
    );
}

export default AutomatedImporterTrigger;
