// @ts-nocheck
import React, {useEffect, useState, useRef} from 'react';
import '../../../../../../index.scss';
import { Box, CssBaseline, Skeleton } from "@mui/material";
import Header from "../../../../../Layout/Header/Header";
import Sider from '../../../../../Layout/Sider/Sider';
import AccountFileUploadM from '../../../../../Helpers/AccountFileUploadM/AccountFileUploadM';
import AutomatedImporterTrigger from '../../../BookImporter/AutomatedImporterTrigger/AutomatedImporterTrigger';
import { useTranslation } from 'react-i18next';
import { useStyles } from './Styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
const Fade = require("react-reveal/Fade")
const Reveal = require("react-reveal/Reveal")

function Landing() {
    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // ui controls
    const [loading, setLoading] = React.useState(true);
    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        setLoading(false);
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* Header */}
            <Header />

            {/* Sider */}
            <Sider />

            {/* Content */}
            <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>

                {loading &&
                    <div className="mt-5">
                        <Skeleton key={'piechart'} variant="rounded" height={'80vh'} />
                    </div>
                }

                {!loading &&
                    <>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                                    <Tab label="Collection Book Import" />
                                    <Tab label="Payment Import" />
                                </Tabs>
                            </Box>
                        </Box>

                        <div className="mt-4">
                            {/* Manual Import */}
                            {tabValue == 0 &&
                                <div className="row">
                                    <AccountFileUploadM importType={'collection'} />
                                </div>
                            }

                            {/* Automated Import */}
                            {tabValue == 1 &&
                                <div className="row">
                                    <AutomatedImporterTrigger payment={true}/>
                                </div>
                            }
                        </div>
                    </>
                }

            </Box>
        </Box>
    );
}


export default Landing;
