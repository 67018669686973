//@ts-nocheck
import React, { useEffect, useRef } from 'react';
import { useStyles } from './Styles';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Button, FormControl, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { isIfStatement } from 'typescript';
import AlertM from '../../../../Helpers/AlertM/AlertM';

// @ts-ignore
function EdcconfigurationForm({ handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent }) {

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    //data handler
    const [totalPercentage, setTotalPercentage] = React.useState(0);
    const [inputValues, setInputValues] = React.useState(
        recordToUpdate.reduce((acc, item) => ({ ...acc, [item.id]: item.percentage }), {})
    );

    // UI methods
    const [loading, setLoading] = React.useState(true);

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {

        if (totalPercentage != 100) {            
            // @ts-ignore
            notifications.current.errorAlert("Percentage Error", "Total percentage must be 100%");
        } else {
            handleEditEvent(inputValues);
        }
    }

    const handleInputChange = (event, id) => {
        let value = parseInt(event.target.value);

        if (isNaN(value)) {  // Check if the value is NaN
            value = 0;  // If NaN, set the value to 0
        }

        setInputValues((prevValues) => {
            const newValues = { ...prevValues, [id]: value }
            const sum = Object.values(newValues).reduce((acc, value) => acc + value, 0);
            setTotalPercentage(sum);
            return newValues;
        });
    };


    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    // hooks
    useEffect(() => {
        if (isEditMode) {
            reset(recordToUpdate);
        }
    }, []);

    // hooks
    useEffect(() => {
        if (recordToUpdate.length > 0) {
            const total = recordToUpdate.reduce((sum, item) => sum + item.percentage, 0);
            setTotalPercentage(total);
        }
    }, [recordToUpdate]);


    return (
        <Box sx={{ display: 'flex' }}>
            <Card sx={{ minWidth: 275 }}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row my-2">
                                <h3 className="pb-2">
                                    {isEditMode ? t('common.edit') : t('common.add')} {t('module.edcConfiguration')}
                                </h3>
                            </div>
                            <div className="row">
                                {recordToUpdate.map((item) => (
                                    <React.Fragment key={item.id}>
                                        <div className="col-md-6 mt-4">
                                            <p>Update Distribution For <b>{item['edcconfigurationEdc.name']}</b></p>
                                        </div>
                                        <div className="col-md-6 mt-4">
                                            <FormControl fullWidth>
                                                <TextField
                                                    id={`percentageInput-${item.id}`}
                                                    {...register(`${item.id}`, { required: true })}
                                                    label={`${item['edcconfigurationEdc.name']} %`}
                                                    defaultValue={item.percentage}
                                                    type="number"
                                                    onChange={(e) => handleInputChange(e, item.id)}
                                                    error={!!errors[`${item.id}`]}
                                                    variant="outlined"
                                                    className="full-width"
                                                />
                                            </FormControl>
                                        </div>
                                    </React.Fragment>
                                ))}
                            </div>
                            <div className="row">
                                <div className="col-md-6 mt-4">
                                    <p>Total EDC Percentage</p>
                                </div>
                                <div className="col-md-6 mt-4">
                                    <p><b>{totalPercentage} %</b></p>
                                </div>
                            </div>

                            <div className="row">
                                {/* Alerts */}
                                <AlertM ref={notifications} />
                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2" style={{ width: '100%', textAlign: 'right' }}>
                            <Button variant="outlined" onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>
                            &nbsp;&nbsp;
                            <LoadingButton loading={isLoading} onClick={onSubmit} variant="contained" className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card >
        </Box >
    );
}

export default EdcconfigurationForm;
