// @ts-nocheck

import { useEffect, useState, useRef } from 'react';
import '../../../../../index.scss';
import { useTranslation } from "react-i18next";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { patch } from '../../../../../Services/GenericApiService';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Table, TableBody, TableCell, TableContainer, Button, List, ListItem, ListItemText, Skeleton, TableHead, TableRow, TextField, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EdcscorecardCalculator from '../EdcscorecardCalculator/EdcscorecardCalculator';
import ConfirmationDialog from '../../../../Helpers/ConfirmationDialog/ConfirmationDialog';
import AlertM from '../../../../Helpers/AlertM/AlertM';
import EdcscorecardMatrixUpdate from '../EdcscorecardMatrixUpdate/EdcscorecardMatrixUpdate';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)


function EdcscorecardTable({ edcData, refreshDataEvent, allScorecardData }) {

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();


    //api detail
    const moduleEdc = "edc";

    // data vars
    const [yeildRateW, setYeildRateW] = useState(10);
    const [activationRateW, setActivationRateW] = useState(10);
    const [prescriptionRateW, setPrescriptionRateW] = useState(10);
    const [deviceRecoveryRateW, setDeviceRecoveryRateW] = useState(10);
    const [rehabilitationsW, setRehabilitationsW] = useState(0);
    const [collaborationW, setCollaborationW] = useState(10);
    const [fixRateW, setFixRateW] = useState(10);
    const [reportingW, setReportingW] = useState(10);
    const [qualityAssuranceW, setQualityAssuranceW] = useState(10);
    const [specialProjectsW, setSpecialProjectsW] = useState(10);
    const [escalationW, setEscalationW] = useState(10);

    //score state
    const [yeildRateScore, setYeildRateScore] = useState(10);
    const [activationRateScore, setActivationRateScore] = useState(10);
    const [prescriptionRateScore, setPrescriptionRateScore] = useState(10);
    const [deviceRecoveryRateScore, setDeviceRecoveryRateScore] = useState(10);
    const [rehabilitationsScore, setRehabilitationsScore] = useState(0);
    const [collaborationScore, setCollaborationScore] = useState(10);
    const [fixRateScore, setFixRateScore] = useState(10);
    const [reportingScore, setReportingScore] = useState(10);
    const [qualityAssuranceScore, setQualityAssuranceScore] = useState(10);
    const [specialProjectsScore, setSpecialProjectsScore] = useState(10);
    const [escalationScore, setEscalationScore] = useState(10);


    //score weight state
    const [yeildRateScoreW, setYeildRateScoreW] = useState(0);
    const [activationRateScoreW, setActivationRateScoreW] = useState(0);
    const [prescriptionRateScoreW, setPrescriptionRateScoreW] = useState(0);
    const [deviceRecoveryRateScoreW, setDeviceRecoveryRateScoreW] = useState(0);
    const [rehabilitationsScoreW, setRehabilitationsScoreW] = useState(0);
    const [collaborationScoreW, setCollaborationScoreW] = useState(0);
    const [fixRateScoreW, setFixRateScoreW] = useState(0);
    const [reportingScoreW, setReportingScoreW] = useState(0);
    const [qualityAssuranceScoreW, setQualityAssuranceScoreW] = useState(0);
    const [specialProjectsScoreW, setSpecialProjectsScoreW] = useState(0);
    const [escalationScoreW, setEscalationScoreW] = useState(10);

    const [yeildRateMatrix, setYeildRateMatrix] = useState([]);
    const [activationRateMatrix, setActivationRateMatrix] = useState([]);
    const [prescriptionRateMatrix, setPrescriptionRateMatrix] = useState([]);
    const [deviceRecoveryRateMatrix, setDeviceRecoveryRateMatrix] = useState([]);
    const [rehabilitationsMatrix, setRehabilitationsMatrix] = useState(0);
    const [collaborationMatrix, setCollaborationMatrix] = useState([]);
    const [fixRateMatrix, setFixRateMatrix] = useState([]);
    const [reportingMatrix, setReportingMatrix] = useState([]);
    const [qualityAssuranceMatrix, setQualityAssuranceMatrix] = useState([]);
    const [specialProjectsMatrix, setSpecialProjectsMatrix] = useState([]);
    const [escalationMatrix, setEscalationMatrix] = useState([]);
    const [dataToSendMatrix, setDataToSendMatrix] = useState([]);

    // ui handlers
    const [loading, setLoading] = useState(false);
    const [yeildRateWInput, setYeildRateWInput] = useState(false);
    const [activationRateWInput, setActivationRateWInput] = useState(false);
    const [prescriptionRateWInput, setPrescriptionRateWInput] = useState(false);
    const [deviceRecoveryRateWInput, setDeviceRecoveryRateWInput] = useState(false);
    const [rehabilitationsWInput, setRehabilitationsWInput] = useState(false);
    const [collaborationWInput, setCollaborationWInput] = useState(false);
    const [fixRateWInput, setFixRateWInput] = useState(false);
    const [reportingWInput, setReportingWInput] = useState(false);
    const [qualityAssuranceWInput, setQualityAssuranceWInput] = useState(false);
    const [specialProjectsWInput, setSpecialProjectsWInput] = useState(false);
    const [escalationWInput, setEscalationWInput] = useState(false);
    const [showCalculator, setShowCalculator] = useState(false);
    const [showStandardMatrixUpdate, setShowStandardMatrixUpdate] = useState(false);
    const [formulaIndex, setFormulaIndex] = useState(1);

    const tirggerCalculatorModal = (_index: any) => {
        setFormulaIndex(_index);
        setShowCalculator(true);
    }

    const triggerMatrixEditModal = (_index: any, _data: any) => {
        setDataToSendMatrix(_data);
        setFormulaIndex(_index);
        setShowStandardMatrixUpdate(true);
    }

    const refreshData = () => {
        setShowStandardMatrixUpdate(false);
        refreshDataEvent();
    }

    const handleDataFromChild = (data) => {
        setShowCalculator(false);

        switch (data.formulaIndex) {

            case 1:
                setYeildRateScore(data.score);
                setYeildRateScoreW(calculateWeightingPercentage(data.score, yeildRateW));
                break;
            case 2:
                setActivationRateScore(data.score);
                setActivationRateScoreW(calculateWeightingPercentage(data.score, activationRateW));
                break;
            case 3:
                setPrescriptionRateScore(data.score);
                setPrescriptionRateScoreW(calculateWeightingPercentage(data.score, prescriptionRateW));
                break;
            case 4:
                setDeviceRecoveryRateScore(data.score);
                setDeviceRecoveryRateScoreW(calculateWeightingPercentage(data.score, deviceRecoveryRateW));
                break;
            case 5:
                setRehabilitationsScore(data.score);
                setRehabilitationsScoreW(calculateWeightingPercentage(data.score, rehabilitationsW));
                break;
            case 6:
                setCollaborationScore(data.score);
                setCollaborationScoreW(calculateWeightingPercentage(data.score, collaborationW));
                break;
            case 7:
                setFixRateScore(data.score);
                setFixRateScoreW(calculateWeightingPercentage(data.score, fixRateW));
                break;
            case 8:
                setReportingScore(data.score);
                setReportingScoreW(calculateWeightingPercentage(data.score, reportingW));
                break;
            case 9:
                setQualityAssuranceScore(data.score);
                setQualityAssuranceScoreW(calculateWeightingPercentage(data.score, qualityAssuranceW));
                break;
            case 10:
                setSpecialProjectsScore(data.score);
                setSpecialProjectsScoreW(calculateWeightingPercentage(data.score, specialProjectsW));
                break;
            case 11:
                setEscalationScore(data.score);
                setEscalationScoreW(calculateWeightingPercentage(data.score, escalationW));
                break;

            default:
                break;
        }
    };

    // function to trigger input field
    const openInputField = (event, _index: any) => {

        switch (_index) {
            case 1:
                setYeildRateWInput(true);
                break;
            case 2:
                setActivationRateWInput(true);
                break;
            case 3:
                setPrescriptionRateWInput(true);
                break;
            case 4:
                setDeviceRecoveryRateWInput(true);
                break;
            case 5:
                setRehabilitationsWInput(true);
                break;
            case 6:
                setCollaborationWInput(true);
                break;
            case 7:
                setFixRateWInput(true);
                break;
            case 8:
                setReportingWInput(true);
                break;
            case 9:
                setQualityAssuranceWInput(true);
                break;
            case 10:
                setSpecialProjectsWInput(true);
                break;
            case 11:
                setEscalationWInput(true);
                break;
            default:
                break;
        }
    }

    // function to hide input field
    const handleBlur = (event, _index) => {

        switch (_index) {
            case 1:
                setYeildRateWInput(false);
                break;
            case 2:
                setActivationRateWInput(false);
                break;
            case 3:
                setPrescriptionRateWInput(false);
                break;
            case 4:
                setDeviceRecoveryRateWInput(false);
                break;
            case 5:
                setRehabilitationsWInput(false);
                break;
            case 6:
                setCollaborationWInput(false);
                break;
            case 7:
                setFixRateWInput(false);
                break;
            case 8:
                setReportingWInput(false);
                break;
            case 9:
                setQualityAssuranceWInput(false);
                break;
            case 10:
                setSpecialProjectsWInput(false);
                break;
            case 11:
                setEscalationWInput(false);
                break;
            default:
                break;
        }
    };


    // sum the standard weight
    const getSum = (_column) => {

        if (_column === "score") {
            let score = parseFloat(yeildRateScore) + parseFloat(activationRateScore) + parseFloat(prescriptionRateScore) +
                parseFloat(deviceRecoveryRateScore) + parseFloat(rehabilitationsScore) + parseFloat(collaborationScore) +
                parseFloat(fixRateScore) + parseFloat(reportingScore) + parseFloat(qualityAssuranceScore) + parseFloat(specialProjectsScore) + parseFloat(escalationScore);

            return score ? score.toFixed(2) : 0;

        } else if (_column === "weight") {

            let weight = parseFloat(yeildRateW) + parseFloat(activationRateW) + parseFloat(prescriptionRateW) +
                parseFloat(deviceRecoveryRateW) + parseFloat(rehabilitationsW) + parseFloat(collaborationW) +
                parseFloat(fixRateW) + parseFloat(reportingW) + parseFloat(qualityAssuranceW) + parseFloat(specialProjectsW) + parseFloat(escalationW);

            return weight ? weight.toFixed(2) : 0;

        }
        else if (_column === "weight-score") {
            let weightScore = parseFloat(yeildRateScoreW) + parseFloat(activationRateScoreW) + parseFloat(prescriptionRateScoreW) +
                parseFloat(deviceRecoveryRateScoreW) + parseFloat(rehabilitationsScoreW) + parseFloat(collaborationScoreW) +
                parseFloat(fixRateScoreW) + parseFloat(reportingScoreW) + parseFloat(qualityAssuranceScoreW) + parseFloat(specialProjectsScoreW) + parseFloat(escalationScoreW);

            return weightScore ? weightScore.toFixed(2) : 0;
        }

    };

    //function to calculate weighting %
    function calculateWeightingPercentage(percent1: float, percent2: float): number {
        const result = (percent1 / 100) * percent2;
        return parseFloat(result.toFixed(2));
    }

    // function to set new standard weight temp
    const handleStandardWeightChange = (event, _index) => {
        const value = event.target.value;

        // Allow only numbers and decimal point
        if (/^\d*\.?\d*$/.test(value)) {
            switch (_index) {
                case 1:
                    setYeildRateW(event.target.value);
                    setYeildRateScoreW(calculateWeightingPercentage(yeildRateScore, event.target.value));
                    break;
                case 2:
                    setActivationRateW(event.target.value);
                    setActivationRateScoreW(calculateWeightingPercentage(activationRateScore, event.target.value));
                    break;
                case 3:
                    setPrescriptionRateW(event.target.value);
                    setPrescriptionRateScoreW(calculateWeightingPercentage(prescriptionRateScore, event.target.value));
                    break;
                case 4:
                    setDeviceRecoveryRateW(event.target.value);
                    setDeviceRecoveryRateScoreW(calculateWeightingPercentage(deviceRecoveryRateScore, event.target.value));
                    break;
                case 5:
                    setRehabilitationsW(event.target.value);
                    setRehabilitationsScoreW(calculateWeightingPercentage(rehabilitationsScore, event.target.value));
                    break;
                case 6:
                    setCollaborationW(event.target.value);
                    setCollaborationScoreW(calculateWeightingPercentage(collaborationScore, event.target.value));
                    break;
                case 7:
                    setFixRateW(event.target.value);
                    setFixRateScoreW(calculateWeightingPercentage(fixRateScore, event.target.value));
                    break;
                case 8:
                    setReportingW(event.target.value);
                    setReportingScoreW(calculateWeightingPercentage(reportingScore, event.target.value));
                    break;
                case 9:
                    setQualityAssuranceW(event.target.value);
                    setQualityAssuranceScoreW(calculateWeightingPercentage(qualityAssuranceScore, event.target.value));
                    break;
                case 10:
                    setSpecialProjectsW(event.target.value);
                    setSpecialProjectsScoreW(calculateWeightingPercentage(specialProjectsScore, event.target.value));
                    break;
                case 11:
                    setEscalationW(event.target.value);
                    setEscalationScoreW(calculateWeightingPercentage(escalationScore, event.target.value));
                    break;
                default:
                    break;
            }
        }
    };

    const savePerformanceScore = () => {

        if (Math.trunc(getSum("weight")) == 100) {
            setLoading(true);

            const jsonData = {
                yieldRate_score: yeildRateScore,
                yieldRate_weight: yeildRateW,
                yieldRate_weightscore: yeildRateScoreW,
                activationRate_score: activationRateScore,
                activationRate_weight: activationRateW,
                activationRate_weightscore: activationRateScoreW,
                prescriptionRate_score: prescriptionRateScore,
                prescriptionRate_weight: prescriptionRateW,
                prescriptionRate_weightscore: prescriptionRateScoreW,
                drRate_score: deviceRecoveryRateScore,
                drRate_weight: deviceRecoveryRateW,
                drRate_weightscore: deviceRecoveryRateScoreW,
                rehabilitations_score: rehabilitationsScore,
                rehabilitations_weight: rehabilitationsW,
                rehabilitations_weightscore: rehabilitationsScoreW,
                collaboration_score: collaborationScore,
                collaboration_weight: collaborationW,
                collaboration_weightscore: collaborationScoreW,
                fixRate_score: fixRateScore,
                fixRate_weight: fixRateW,
                fixRate_weightscore: fixRateScoreW,
                reporting_score: reportingScore,
                reporting_weight: reportingW,
                reporting_weightscore: reportingScoreW,
                qa_score: qualityAssuranceScore,
                qa_weight: qualityAssuranceW,
                qa_weightscore: qualityAssuranceScoreW,
                sp_score: specialProjectsScore,
                sp_weight: specialProjectsW,
                sp_weightscore: specialProjectsScoreW,
                escalation_score: escalationScore,
                escalation_weight: escalationW,
                escalation_weightscore: escalationScoreW,
                total_weightscore: getSum("weight-score")
            };

            patch(moduleEdc, edcData.id, jsonData)
                .then(_res => {
                    setLoading(false);
                    refreshDataEvent();
                })
                .catch(_err => {
                    log(_err);
                    setLoading(false);
                    notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                })
        }
        else {
            notifications.current.errorAlert("Error in calculation", "Total Weight must be 100% in weighing column");
        }
    }

    //focus input field based on the icon click
    useEffect(() => {

        if (yeildRateWInput) {
            document.getElementById("yeildRateW").focus();
        } else if (activationRateWInput) {
            document.getElementById("activationRateW").focus();
        } else if (prescriptionRateWInput) {
            document.getElementById("prescriptionRateW").focus();
        } else if (deviceRecoveryRateWInput) {
            document.getElementById("deviceRecoveryRateW").focus();
        } else if (rehabilitationsWInput) {
            document.getElementById("rehabilitationsW").focus();
        } else if (collaborationWInput) {
            document.getElementById("collaborationW").focus();
        } else if (fixRateWInput) {
            document.getElementById("fixRateW").focus();
        } else if (reportingWInput) {
            document.getElementById("reportingW").focus();
        } else if (qualityAssuranceWInput) {
            document.getElementById("qualityAssuranceW").focus();
        } else if (specialProjectsWInput) {
            document.getElementById("specialProjectsW").focus();
        }
        else if (escalationWInput) {
            document.getElementById("escalationW").focus();
        }
    }, [yeildRateWInput, activationRateWInput, prescriptionRateWInput, deviceRecoveryRateWInput, rehabilitationsWInput, collaborationWInput, fixRateWInput, reportingWInput, qualityAssuranceWInput, specialProjectsWInput, escalationWInput])

    // on init set values
    useEffect(() => {

        //set weight
        setYeildRateW(edcData.yieldRate_weight);
        setActivationRateW(edcData.activationRate_weight);
        setPrescriptionRateW(edcData.prescriptionRate_weight);
        setDeviceRecoveryRateW(edcData.drRate_weight);
        setRehabilitationsW(edcData.rehabilitations_weight);
        setCollaborationW(edcData.collaboration_weight);
        setFixRateW(edcData.fixRate_weight);
        setReportingW(edcData.reporting_weight);
        setQualityAssuranceW(edcData.qa_weight);
        setSpecialProjectsW(edcData.sp_weight);
        setEscalationW(edcData.escalation_weight);

        //set score
        setYeildRateScore(edcData.yieldRate_score);
        setActivationRateScore(edcData.activationRate_score);
        setPrescriptionRateScore(edcData.prescriptionRate_score);
        setDeviceRecoveryRateScore(edcData.drRate_score);
        setRehabilitationsScore(edcData.rehabilitations_score);
        setCollaborationScore(edcData.collaboration_score);
        setFixRateScore(edcData.fixRate_score);
        setReportingScore(edcData.reporting_score);
        setQualityAssuranceScore(edcData.qa_score);
        setSpecialProjectsScore(edcData.sp_score);
        setEscalationScore(edcData.escalation_score);

        //score weight state
        setYeildRateScoreW(edcData.yieldRate_weightscore);
        setActivationRateScoreW(edcData.activationRate_weightscore);
        setPrescriptionRateScoreW(edcData.prescriptionRate_weightscore);
        setDeviceRecoveryRateScoreW(edcData.drRate_weightscore);
        setRehabilitationsScoreW(edcData.rehabilitations_weightscore);
        setCollaborationScoreW(edcData.collaboration_weightscore);
        setFixRateScoreW(edcData.fixRate_weightscore);
        setReportingScoreW(edcData.reporting_weightscore);
        setQualityAssuranceScoreW(edcData.qa_weightscore);
        setSpecialProjectsScoreW(edcData.sp_weightscore);
        setEscalationScoreW(edcData.escalation_weightscore);

    }, [edcData])

    // on init set values
    useEffect(() => {

        if (allScorecardData.length > 0) {
            setYeildRateMatrix(JSON.parse(allScorecardData[0]?.matrix));
            setActivationRateMatrix(JSON.parse(allScorecardData[1]?.matrix));
            setPrescriptionRateMatrix(JSON.parse(allScorecardData[2]?.matrix));
            setDeviceRecoveryRateMatrix(JSON.parse(allScorecardData[3]?.matrix));
            setRehabilitationsMatrix(JSON.parse(allScorecardData[4]?.matrix));
            setCollaborationMatrix(JSON.parse(allScorecardData[5]?.matrix));
            setFixRateMatrix(JSON.parse(allScorecardData[6]?.matrix));
            setReportingMatrix(JSON.parse(allScorecardData[7]?.matrix));
            setQualityAssuranceMatrix(JSON.parse(allScorecardData[8]?.matrix));
            setSpecialProjectsMatrix(JSON.parse(allScorecardData[9]?.matrix));
            setEscalationMatrix(JSON.parse(allScorecardData[10]?.matrix));
        }

    }, [allScorecardData])
    return (
        <>
            <div className="row d-flex justify-content-end">
                <div className="col-3 mb-3">
                    {!loading &&
                        <ConfirmationDialog
                            confirmEvent={(_itemIdx) => {
                                savePerformanceScore()
                            }}
                            vari
                            type={'confirm'}
                            showButton={true}
                            title={'Save Scores'}
                            body={'Are you sure you want to save changes?'}
                            buttonText={'Save'} />
                    }
                    {loading &&
                        <Skeleton variant="rounded" height={60} />
                    }
                </div>
            </div>
            <TableContainer className='mt-2' style={{ boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className="border-custom">Performance Model</TableCell>
                            <TableCell className="border-custom">Formula's</TableCell>
                            <TableCell className="border-custom">Standard Matrix</TableCell>
                            <TableCell className="border-custom">Standards</TableCell>
                            <TableCell className="border-custom">Score</TableCell>
                            <TableCell className="border-custom">Weighting</TableCell>
                            <TableCell className="border-custom">Weighting Score</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading &&
                            <>
                                {/* Yeld Rate */}
                                <TableRow key='1'>
                                    <TableCell className="border-custom">{allScorecardData[0]?.name}</TableCell>
                                    <TableCell className="border-custom">{allScorecardData[0]?.formula}
                                        <br /><br />
                                        <Button
                                            variant="contained"
                                            onClick={() => tirggerCalculatorModal(1)}
                                            style={{ float: 'inline-end' }}
                                            className={'bg-custom-secondary py-0'}
                                        >
                                            {t('edcScoreCard.calculate')}
                                        </Button>
                                    </TableCell>

                                    <TableCell className="border-custom">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-4" >
                                                    <p className="text-center">Start Range</p>
                                                </div>
                                                <div className="col-md-4" >
                                                    <p className="text-center">End Range</p>
                                                </div>
                                                <div className="col-md-4" >
                                                    <p className="text-center">Value</p>
                                                </div>
                                            </div>
                                            {yeildRateMatrix && yeildRateMatrix.map((item, index) => (
                                                <>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.end ? item.start : `${item.start}+`}</p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.end}</p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.value}</p>
                                                        </div>
                                                    </div>

                                                </>
                                            ))}
                                        </div>
                                        <br />
                                        <Button
                                            variant="contained"
                                            onClick={() => triggerMatrixEditModal(allScorecardData[0]?.id, yeildRateMatrix)}
                                            style={{ float: 'inline-end' }}
                                            className={'bg-custom-secondary py-0'}
                                        >
                                            {t('common.editText')}
                                        </Button>
                                    </TableCell>
                                    <TableCell className="border-custom"><p>5% 1st Placement <br /> 1.5% 2rd Placement <br /> 0.5% 3rdPlacement</p>
                                    </TableCell>
                                    <TableCell className="border-custom">{yeildRateScore}</TableCell>
                                    <TableCell className="border-custom">
                                        <>

                                            {!yeildRateWInput &&
                                                <p>
                                                    {yeildRateW}%
                                                    <FontAwesomeIcon className="edit-btn custom-grey ms-3" icon='pencil' onClick={(event) => openInputField(event, 1)} />
                                                </p>
                                            }

                                            {yeildRateWInput &&
                                                <OutlinedInput
                                                    id="yeildRateW"
                                                    value={yeildRateW}
                                                    onChange={(event) => handleStandardWeightChange(event, 1)}
                                                    onBlur={(event) => handleBlur(event, 1)}
                                                    startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                                />
                                            }
                                        </>


                                    </TableCell>
                                    <TableCell className="border-custom">{yeildRateScoreW}</TableCell>
                                </TableRow>

                                {/* Activation Rate */}
                                <TableRow key='2'>
                                    <TableCell className="border-custom">{allScorecardData[1]?.name}</TableCell>
                                    <TableCell className="border-custom">{allScorecardData[1]?.formula}
                                        <br /><br />
                                        <Button
                                            variant="contained"
                                            onClick={() => tirggerCalculatorModal(2)}
                                            style={{ float: 'inline-end' }}
                                            className={'bg-custom-secondary py-0'}
                                        >
                                            {t('edcScoreCard.calculate')}
                                        </Button>
                                    </TableCell>
                                    <TableCell className="border-custom">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-4" >
                                                    <p className="text-center">Start Range</p>
                                                </div>
                                                <div className="col-md-4" >
                                                    <p className="text-center">End Range</p>
                                                </div>
                                                <div className="col-md-4" >
                                                    <p className="text-center">Value</p>
                                                </div>
                                            </div>
                                            {activationRateMatrix && activationRateMatrix.map((item, index) => (
                                                <>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.end ? item.start : `${item.start}+`}</p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.end}</p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.value}</p>
                                                        </div>
                                                    </div>

                                                </>
                                            ))}
                                        </div>
                                        <br />
                                        <Button
                                            variant="contained"
                                            onClick={() => triggerMatrixEditModal(allScorecardData[1]?.id, activationRateMatrix)}
                                            style={{ float: 'inline-end' }}
                                            className={'bg-custom-secondary py-0'}
                                        >
                                            {t('common.editText')}
                                        </Button>
                                    </TableCell>
                                    <TableCell className="border-custom"><p>A 95% activity Standard</p></TableCell>
                                    <TableCell className="border-custom">{activationRateScore}</TableCell>
                                    <TableCell className="border-custom">
                                        <>

                                            {!activationRateWInput &&
                                                <p>
                                                    {activationRateW}%
                                                    <FontAwesomeIcon className="edit-btn custom-grey ms-3" icon='pencil' onClick={(event) => openInputField(event, 2)} />
                                                </p>
                                            }

                                            {activationRateWInput &&
                                                <OutlinedInput
                                                    id="activationRateW"
                                                    value={activationRateW}
                                                    onChange={(event) => handleStandardWeightChange(event, 2)}
                                                    onBlur={(event) => handleBlur(event, 2)}
                                                    startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                                />
                                            }
                                        </>

                                    </TableCell>
                                    <TableCell className="border-custom">{activationRateScoreW}</TableCell>
                                </TableRow>

                                {/* Prescription Rate */}
                                <TableRow key='3'>
                                    <TableCell className="border-custom">{allScorecardData[2]?.name}</TableCell>
                                    <TableCell className="border-custom">{allScorecardData[2]?.formula}
                                        <br /><br />
                                        <Button
                                            variant="contained"
                                            onClick={() => tirggerCalculatorModal(3)}
                                            style={{ float: 'inline-end' }}
                                            className={'bg-custom-secondary py-0'}
                                        >
                                            {t('edcScoreCard.calculate')}
                                        </Button>
                                    </TableCell>
                                    <TableCell className="border-custom">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-4" >
                                                    <p className="text-center">Start Range</p>
                                                </div>
                                                <div className="col-md-4" >
                                                    <p className="text-center">End Range</p>
                                                </div>
                                                <div className="col-md-4" >
                                                    <p className="text-center">Value</p>
                                                </div>
                                            </div>
                                            {prescriptionRateMatrix && prescriptionRateMatrix.map((item, index) => (
                                                <>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.end ? item.start : `${item.start}+`}</p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.end}</p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.value}</p>
                                                        </div>
                                                    </div>

                                                </>
                                            ))}
                                        </div>
                                        <br />
                                        <Button
                                            variant="contained"
                                            onClick={() => triggerMatrixEditModal(allScorecardData[2]?.id, prescriptionRateMatrix)}
                                            style={{ float: 'inline-end' }}
                                            className={'bg-custom-secondary py-0'}
                                        >
                                            {t('common.editText')}
                                        </Button>
                                    </TableCell>
                                    <TableCell className="border-custom"><p>A 5% prescription Standard </p></TableCell>
                                    <TableCell className="border-custom">{prescriptionRateScore}</TableCell>
                                    <TableCell className="border-custom">
                                        <>

                                            {!prescriptionRateWInput &&
                                                <p>
                                                    {prescriptionRateW}%
                                                    <FontAwesomeIcon className="edit-btn custom-grey ms-3" icon='pencil' onClick={(event) => openInputField(event, 3)} />
                                                </p>
                                            }

                                            {prescriptionRateWInput &&
                                                <OutlinedInput
                                                    id="prescriptionRateW"
                                                    value={prescriptionRateW}
                                                    onChange={(event) => handleStandardWeightChange(event, 3)}
                                                    onBlur={(event) => handleBlur(event, 3)}
                                                    startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                                />
                                            }
                                        </>

                                    </TableCell>
                                    <TableCell className="border-custom">{prescriptionRateScoreW}</TableCell>
                                </TableRow>

                                {/* Device Recovery Rate */}
                                <TableRow key='4'>
                                    <TableCell className="border-custom">{allScorecardData[3]?.name}</TableCell>
                                    <TableCell className="border-custom">{allScorecardData[3]?.formula}
                                        <br /><br />
                                        <Button
                                            variant="contained"
                                            onClick={() => tirggerCalculatorModal(4)}
                                            style={{ float: 'inline-end' }}
                                            className={'bg-custom-secondary py-0'}
                                        >
                                            {t('edcScoreCard.calculate')}
                                        </Button>
                                    </TableCell>
                                    <TableCell className="border-custom">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-4" >
                                                    <p className="text-center">Start Range</p>
                                                </div>
                                                <div className="col-md-4" >
                                                    <p className="text-center">End Range</p>
                                                </div>
                                                <div className="col-md-4" >
                                                    <p className="text-center">Value</p>
                                                </div>
                                            </div>
                                            {deviceRecoveryRateMatrix && deviceRecoveryRateMatrix.map((item, index) => (
                                                <>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.end ? item.start : `${item.start}+`}</p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.end}</p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.value}</p>
                                                        </div>
                                                    </div>

                                                </>
                                            ))}
                                        </div>
                                        <br />
                                        <Button
                                            variant="contained"
                                            onClick={() => triggerMatrixEditModal(allScorecardData[3]?.id, deviceRecoveryRateMatrix)}
                                            style={{ float: 'inline-end' }}
                                            className={'bg-custom-secondary py-0'}
                                        >
                                            {t('common.editText')}
                                        </Button>
                                    </TableCell>
                                    <TableCell className="border-custom"><p>A 5% Recovery Standard </p></TableCell>
                                    <TableCell className="border-custom">{deviceRecoveryRateScore}</TableCell>
                                    <TableCell className="border-custom">
                                        <>

                                            {!deviceRecoveryRateWInput &&
                                                <p>
                                                    {deviceRecoveryRateW}%
                                                    <FontAwesomeIcon className="edit-btn custom-grey ms-3" icon='pencil' onClick={(event) => openInputField(event, 4)} />
                                                </p>
                                            }

                                            {deviceRecoveryRateWInput &&
                                                <OutlinedInput
                                                    id="deviceRecoveryRateW"
                                                    value={deviceRecoveryRateW}
                                                    onChange={(event) => handleStandardWeightChange(event, 4)}
                                                    onBlur={(event) => handleBlur(event, 4)}
                                                    startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                                />
                                            }
                                        </>

                                    </TableCell>
                                    <TableCell className="border-custom">{deviceRecoveryRateScoreW}</TableCell>
                                </TableRow>

                                {/* Rehabilitations */}
                                <TableRow key='5'>
                                    <TableCell className="border-custom">{allScorecardData[4]?.name}</TableCell>
                                    <TableCell className="border-custom">{allScorecardData[4]?.formula}
                                        <br /><br />
                                        <Button
                                            variant="contained"
                                            onClick={() => tirggerCalculatorModal(5)}
                                            style={{ float: 'inline-end' }}
                                            className={'bg-custom-secondary py-0'}
                                        >
                                            {t('edcScoreCard.calculate')}
                                        </Button>
                                    </TableCell>
                                    <TableCell className="border-custom">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-4" >
                                                    <p className="text-center">Start Range</p>
                                                </div>
                                                <div className="col-md-4" >
                                                    <p className="text-center">End Range</p>
                                                </div>
                                                <div className="col-md-4" >
                                                    <p className="text-center">Value</p>
                                                </div>
                                            </div>
                                            {rehabilitationsMatrix && rehabilitationsMatrix.map((item, index) => (
                                                <>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.end ? item.start : `${item.start}+`}</p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.end}</p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.value}</p>
                                                        </div>
                                                    </div>

                                                </>
                                            ))}
                                        </div>
                                        <br />
                                        <Button
                                            variant="contained"
                                            onClick={() => triggerMatrixEditModal(allScorecardData[4]?.id, rehabilitationsMatrix)}
                                            style={{ float: 'inline-end' }}
                                            className={'bg-custom-secondary py-0'}
                                        >
                                            {t('common.editText')}
                                        </Button>
                                    </TableCell>
                                    <TableCell className="border-custom"><p>Not Applicable for Legal</p></TableCell>
                                    <TableCell className="border-custom">{rehabilitationsScore}</TableCell>
                                    <TableCell className="border-custom">
                                        <>

                                            {!rehabilitationsWInput &&
                                                <p>
                                                    {rehabilitationsW}%
                                                    <FontAwesomeIcon className="edit-btn custom-grey ms-3" icon='pencil' onClick={(event) => openInputField(event, 5)} />
                                                </p>
                                            }

                                            {rehabilitationsWInput &&
                                                <OutlinedInput
                                                    id="rehabilitationsW"
                                                    value={rehabilitationsW}
                                                    onChange={(event) => handleStandardWeightChange(event, 5)}
                                                    onBlur={(event) => handleBlur(event, 5)}
                                                    startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                                />
                                            }
                                        </>

                                    </TableCell>
                                    <TableCell className="border-custom">{rehabilitationsScoreW}</TableCell>
                                </TableRow>

                                {/* Collaboration */}
                                <TableRow key='6'>
                                    <TableCell className="border-custom">{allScorecardData[5]?.name}</TableCell>
                                    <TableCell className="border-custom">{allScorecardData[5]?.formula}
                                        <br /><br />
                                        <Button
                                            variant="contained"
                                            onClick={() => tirggerCalculatorModal(6)}
                                            style={{ float: 'inline-end' }}
                                            className={'bg-custom-secondary py-0'}
                                        >
                                            {t('edcScoreCard.calculate')}
                                        </Button>
                                    </TableCell>
                                    <TableCell className="border-custom">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-4" >
                                                    <p className="text-center">Start Range</p>
                                                </div>
                                                <div className="col-md-4" >
                                                    <p className="text-center">End Range</p>
                                                </div>
                                                <div className="col-md-4" >
                                                    <p className="text-center">Value</p>
                                                </div>
                                            </div>
                                            {collaborationMatrix && collaborationMatrix.map((item, index) => (
                                                <>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.end ? item.start : `${item.start}+`}</p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.end}</p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.value}</p>
                                                        </div>
                                                    </div>

                                                </>
                                            ))}
                                        </div>
                                        <br />
                                        <Button
                                            variant="contained"
                                            onClick={() => triggerMatrixEditModal(allScorecardData[5]?.id, collaborationMatrix)}
                                            style={{ float: 'inline-end' }}
                                            className={'bg-custom-secondary py-0'}
                                        >
                                            {t('common.editText')}
                                        </Button>
                                    </TableCell>
                                    <TableCell className="border-custom"><p>A 20% Collaboration</p></TableCell>
                                    <TableCell className="border-custom">{collaborationScore}</TableCell>
                                    <TableCell className="border-custom">
                                        <>

                                            {!collaborationWInput &&
                                                <p>
                                                    {collaborationW}%
                                                    <FontAwesomeIcon className="edit-btn custom-grey ms-3" icon='pencil' onClick={(event) => openInputField(event, 6)} />
                                                </p>
                                            }

                                            {collaborationWInput &&
                                                <OutlinedInput
                                                    id="collaborationW"
                                                    value={collaborationW}
                                                    onChange={(event) => handleStandardWeightChange(event, 6)}
                                                    onBlur={(event) => handleBlur(event, 6)}
                                                    startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                                />
                                            }
                                        </>

                                    </TableCell>
                                    <TableCell className="border-custom">{collaborationScoreW}</TableCell>
                                </TableRow>

                                {/* Fix Rate */}
                                <TableRow key='7'>
                                    <TableCell className="border-custom">{allScorecardData[6]?.name}</TableCell>
                                    <TableCell className="border-custom"><p>{allScorecardData[6]?.formula}</p>
                                        <br /><br />
                                        <Button
                                            variant="contained"
                                            onClick={() => tirggerCalculatorModal(7)}
                                            style={{ float: 'inline-end' }}
                                            className={'bg-custom-secondary py-0'}
                                        >
                                            {t('edcScoreCard.calculate')}
                                        </Button>
                                    </TableCell>
                                    <TableCell className="border-custom">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-4" >
                                                    <p className="text-center">Start Range</p>
                                                </div>
                                                <div className="col-md-4" >
                                                    <p className="text-center">End Range</p>
                                                </div>
                                                <div className="col-md-4" >
                                                    <p className="text-center">Value</p>
                                                </div>
                                            </div>
                                            {fixRateMatrix && fixRateMatrix.map((item, index) => (
                                                <>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.end ? item.start : `${item.start}+`}</p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.end}</p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.value}</p>
                                                        </div>
                                                    </div>

                                                </>
                                            ))}
                                        </div>
                                        <br />
                                        <Button
                                            variant="contained"
                                            onClick={() => triggerMatrixEditModal(allScorecardData[6]?.id, fixRateMatrix)}
                                            style={{ float: 'inline-end' }}
                                            className={'bg-custom-secondary py-0'}
                                        >
                                            {t('common.editText')}
                                        </Button>
                                    </TableCell>
                                    <TableCell className="border-custom"><p>Service level of 90% or Higher as a standard</p></TableCell>
                                    <TableCell className="border-custom">{fixRateScore}</TableCell>
                                    <TableCell className="border-custom">
                                        <>

                                            {!fixRateWInput &&
                                                <p>
                                                    {fixRateW}%
                                                    <FontAwesomeIcon className="edit-btn custom-grey ms-3" icon='pencil' onClick={(event) => openInputField(event, 7)} />
                                                </p>
                                            }

                                            {fixRateWInput &&
                                                <OutlinedInput
                                                    id="fixRateW"
                                                    value={fixRateW}
                                                    onChange={(event) => handleStandardWeightChange(event, 7)}
                                                    onBlur={(event) => handleBlur(event, 7)}
                                                    startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                                />
                                            }
                                        </>

                                    </TableCell>
                                    <TableCell className="border-custom">{fixRateScoreW}</TableCell>
                                </TableRow>

                                {/* Reporting */}
                                <TableRow key='8'>
                                    <TableCell className="border-custom">{allScorecardData[7]?.name}</TableCell>
                                    <TableCell className="border-custom">{allScorecardData[7]?.formula}
                                        <br /><br />
                                        <Button
                                            variant="contained"
                                            onClick={() => tirggerCalculatorModal(8)}
                                            style={{ float: 'inline-end' }}
                                            className={'bg-custom-secondary py-0'}
                                        >
                                            {t('edcScoreCard.calculate')}
                                        </Button>
                                    </TableCell>
                                    <TableCell className="border-custom">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-4" >
                                                    <p className="text-center">Start Range</p>
                                                </div>
                                                <div className="col-md-4" >
                                                    <p className="text-center">End Range</p>
                                                </div>
                                                <div className="col-md-4" >
                                                    <p className="text-center">Value</p>
                                                </div>
                                            </div>
                                            {reportingMatrix && reportingMatrix.map((item, index) => (
                                                <>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.end ? item.start : `${item.start}+`}</p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.end}</p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.value}</p>
                                                        </div>
                                                    </div>

                                                </>
                                            ))}
                                        </div>
                                        <br />
                                        <Button
                                            variant="contained"
                                            onClick={() => triggerMatrixEditModal(allScorecardData[7]?.id, reportingMatrix)}
                                            style={{ float: 'inline-end' }}
                                            className={'bg-custom-secondary py-0'}
                                        >
                                            {t('common.editText')}
                                        </Button>
                                    </TableCell>
                                    <TableCell className="border-custom"><p>A 95% Standard </p></TableCell>
                                    <TableCell className="border-custom">{reportingScore}</TableCell>
                                    <TableCell className="border-custom">
                                        <>

                                            {!reportingWInput &&
                                                <p>
                                                    {reportingW}%
                                                    <FontAwesomeIcon className="edit-btn custom-grey ms-3" icon='pencil' onClick={(event) => openInputField(event, 8)} />
                                                </p>
                                            }

                                            {reportingWInput &&
                                                <OutlinedInput
                                                    id="reportingW"
                                                    value={reportingW}
                                                    onChange={(event) => handleStandardWeightChange(event, 8)}
                                                    onBlur={(event) => handleBlur(event, 8)}
                                                    startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                                />
                                            }
                                        </>

                                    </TableCell>
                                    <TableCell className="border-custom">{reportingScoreW}</TableCell>
                                </TableRow>

                                {/* Quality Assurance */}
                                <TableRow key='9'>
                                    <TableCell className="border-custom">{allScorecardData[8]?.name}</TableCell>
                                    <TableCell className="border-custom">{allScorecardData[8]?.formula}
                                        <br /><br />
                                        <Button
                                            variant="contained"
                                            onClick={() => tirggerCalculatorModal(9)}
                                            style={{ float: 'inline-end' }}
                                            className={'bg-custom-secondary py-0'}
                                        >
                                            {t('edcScoreCard.calculate')}
                                        </Button>
                                    </TableCell>
                                    <TableCell className="border-custom">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-4" >
                                                    <p className="text-center">Start Range</p>
                                                </div>
                                                <div className="col-md-4" >
                                                    <p className="text-center">End Range</p>
                                                </div>
                                                <div className="col-md-4" >
                                                    <p className="text-center">Value</p>
                                                </div>
                                            </div>
                                            {qualityAssuranceMatrix && qualityAssuranceMatrix.map((item, index) => (
                                                <>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.end ? item.start : `${item.start}+`}</p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.end}</p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.value}</p>
                                                        </div>
                                                    </div>

                                                </>
                                            ))}
                                        </div>
                                        <br />
                                        <Button
                                            variant="contained"
                                            onClick={() => triggerMatrixEditModal(allScorecardData[8]?.id, qualityAssuranceMatrix)}
                                            style={{ float: 'inline-end' }}
                                            className={'bg-custom-secondary py-0'}
                                        >
                                            {t('common.editText')}
                                        </Button>
                                    </TableCell>
                                    <TableCell className="border-custom"><p>A 95% Standard is Expected</p></TableCell>
                                    <TableCell className="border-custom">{qualityAssuranceScore}</TableCell>
                                    <TableCell className="border-custom">
                                        <>

                                            {!qualityAssuranceWInput &&
                                                <p>
                                                    {qualityAssuranceW}%
                                                    <FontAwesomeIcon className="edit-btn custom-grey ms-3" icon='pencil' onClick={(event) => openInputField(event, 9)} />
                                                </p>
                                            }

                                            {qualityAssuranceWInput &&
                                                <OutlinedInput
                                                    id="qualityAssuranceW"
                                                    value={qualityAssuranceW}
                                                    onChange={(event) => handleStandardWeightChange(event, 9)}
                                                    onBlur={(event) => handleBlur(event, 9)}
                                                    startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                                />
                                            }
                                        </>

                                    </TableCell>
                                    <TableCell className="border-custom">{qualityAssuranceScoreW}</TableCell>
                                </TableRow>

                                {/* Special Projects */}
                                <TableRow key='10'>
                                    <TableCell className="border-custom">{allScorecardData[9]?.name}</TableCell>
                                    <TableCell className="border-custom">{allScorecardData[9]?.formula}
                                        <br /><br />
                                        <Button
                                            variant="contained"
                                            onClick={() => tirggerCalculatorModal(10)}
                                            style={{ float: 'inline-end' }}
                                            className={'bg-custom-secondary py-0'}
                                        >
                                            {t('edcScoreCard.calculate')}
                                        </Button>
                                    </TableCell>
                                    <TableCell className="border-custom">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-4" >
                                                    <p className="text-center">Start Range</p>
                                                </div>
                                                <div className="col-md-4" >
                                                    <p className="text-center">End Range</p>
                                                </div>
                                                <div className="col-md-4" >
                                                    <p className="text-center">Value</p>
                                                </div>
                                            </div>
                                            {specialProjectsMatrix && specialProjectsMatrix.map((item, index) => (
                                                <>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.end ? item.start : `${item.start}+`}</p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.end}</p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.value}</p>
                                                        </div>
                                                    </div>

                                                </>
                                            ))}
                                        </div>
                                        <br />
                                        <Button
                                            variant="contained"
                                            onClick={() => triggerMatrixEditModal(allScorecardData[9]?.id, specialProjectsMatrix)}
                                            style={{ float: 'inline-end' }}
                                            className={'bg-custom-secondary py-0'}
                                        >
                                            {t('common.editText')}
                                        </Button>
                                    </TableCell>
                                    <TableCell className="border-custom"><p>100%</p></TableCell>
                                    <TableCell className="border-custom">{specialProjectsScore}</TableCell>
                                    <TableCell className="border-custom">
                                        <>

                                            {!specialProjectsWInput &&
                                                <p>
                                                    {specialProjectsW}%
                                                    <FontAwesomeIcon className="edit-btn custom-grey ms-3" icon='pencil' onClick={(event) => openInputField(event, 10)} />
                                                </p>
                                            }

                                            {specialProjectsWInput &&
                                                <OutlinedInput
                                                    id="specialProjectsW"
                                                    value={specialProjectsW}
                                                    onChange={(event) => handleStandardWeightChange(event, 10)}
                                                    onBlur={(event) => handleBlur(event, 10)}
                                                    startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                                />
                                            }
                                        </>

                                    </TableCell>
                                    <TableCell className="border-custom">{specialProjectsScoreW}</TableCell>
                                </TableRow>

                                {/* Escalation */}
                                <TableRow key='11'>
                                    <TableCell className="border-custom">{allScorecardData[10]?.name}</TableCell>
                                    <TableCell className="border-custom">{allScorecardData[10]?.formula}
                                        <br /><br />
                                        <Button
                                            variant="contained"
                                            onClick={() => tirggerCalculatorModal(11)}
                                            style={{ float: 'inline-end' }}
                                            className={'bg-custom-secondary py-0'}
                                        >
                                            {t('edcScoreCard.calculate')}
                                        </Button>
                                    </TableCell>
                                    <TableCell className="border-custom">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-4" >
                                                    <p className="text-center">Start Range</p>
                                                </div>
                                                <div className="col-md-4" >
                                                    <p className="text-center">End Range</p>
                                                </div>
                                                <div className="col-md-4" >
                                                    <p className="text-center">Value</p>
                                                </div>
                                            </div>
                                            {escalationMatrix && escalationMatrix.map((item, index) => (
                                                <>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.end ? item.start : `${item.start}+`}</p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.end}</p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="text-center">{item.value}</p>
                                                        </div>
                                                    </div>

                                                </>
                                            ))}
                                        </div>
                                        <br />
                                        <Button
                                            variant="contained"
                                            onClick={() => triggerMatrixEditModal(allScorecardData[10]?.id, escalationMatrix)}
                                            style={{ float: 'inline-end' }}
                                            className={'bg-custom-secondary py-0'}
                                        >
                                            {t('common.editText')}
                                        </Button>
                                    </TableCell>
                                    <TableCell className="border-custom"><p>Non-Fixed or escalation rate of 5% or Less as a standard</p></TableCell>
                                    <TableCell className="border-custom">{escalationScore}</TableCell>
                                    <TableCell className="border-custom">
                                        <>

                                            {!escalationWInput &&
                                                <p>
                                                    {escalationW}%
                                                    <FontAwesomeIcon className="edit-btn custom-grey ms-3" icon='pencil' onClick={(event) => openInputField(event, 11)} />
                                                </p>
                                            }

                                            {escalationWInput &&
                                                <OutlinedInput
                                                    id="specialProjectsW"
                                                    value={escalationW}
                                                    onChange={(event) => handleStandardWeightChange(event, 11)}
                                                    onBlur={(event) => handleBlur(event, 11)}
                                                    startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                                />
                                            }
                                        </>

                                    </TableCell>
                                    <TableCell className="border-custom">{escalationScoreW}</TableCell>
                                </TableRow>

                                {/* Total */}
                                <TableRow key='10'>
                                    <TableCell className="border-custom"><b>Total</b></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell className="border-custom"><p style={{ visibility: 'hidden' }}>dumytextdumytextdumytextdumytextdumytext</p></TableCell>
                                    <TableCell className="border-custom"></TableCell>
                                    <TableCell className="border-custom"><b>{getSum("score")}</b></TableCell>
                                    <TableCell className="border-custom"><b>{getSum("weight")}%</b></TableCell>
                                    <TableCell className="border-custom"><b>{getSum("weight-score")}</b></TableCell>
                                </TableRow>
                            </>
                        }

                        {loading &&
                            <>
                                <TableRow>
                                    <TableCell className="border-custom"><Skeleton className="my-4" /></TableCell>
                                    <TableCell className="border-custom"><Skeleton className="my-4" /></TableCell>
                                    <TableCell className="border-custom"><Skeleton className="my-4" /></TableCell>
                                    <TableCell className="border-custom"><Skeleton className="my-4" /></TableCell>
                                    <TableCell className="border-custom"><Skeleton className="my-4" /></TableCell>
                                    <TableCell className="border-custom"><Skeleton className="my-4" /></TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className="border-custom"><Skeleton className="my-4" /></TableCell>
                                    <TableCell className="border-custom"><Skeleton className="my-4" /></TableCell>
                                    <TableCell className="border-custom"><Skeleton className="my-4" /></TableCell>
                                    <TableCell className="border-custom"><Skeleton className="my-4" /></TableCell>
                                    <TableCell className="border-custom"><Skeleton className="my-4" /></TableCell>
                                    <TableCell className="border-custom"><Skeleton className="my-4" /></TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className="border-custom"><Skeleton className="my-4" /></TableCell>
                                    <TableCell className="border-custom"><Skeleton className="my-4" /></TableCell>
                                    <TableCell className="border-custom"><Skeleton className="my-4" /></TableCell>
                                    <TableCell className="border-custom"><Skeleton className="my-4" /></TableCell>
                                    <TableCell className="border-custom"><Skeleton className="my-4" /></TableCell>
                                    <TableCell className="border-custom"><Skeleton className="my-4" /></TableCell>
                                </TableRow>

                            </>
                        }

                    </TableBody>
                </Table >
            </TableContainer >

            {showCalculator && (
                <EdcscorecardCalculator handleCancelEvent={() => setShowCalculator(false)} formulaIndex={formulaIndex} onDataSubmit={handleDataFromChild}
                    rangeMatrix={
                        formulaIndex === 1
                            ? yeildRateMatrix
                            : formulaIndex === 2
                                ? activationRateMatrix
                                : formulaIndex === 3
                                    ? prescriptionRateMatrix
                                    : formulaIndex === 4
                                        ? deviceRecoveryRateMatrix
                                        : formulaIndex === 5
                                            ? rehabilitationsMatrix
                                            : formulaIndex === 6
                                                ? collaborationMatrix
                                                : formulaIndex === 7
                                                    ? fixRateMatrix
                                                    : formulaIndex === 8
                                                        ? reportingMatrix
                                                        : formulaIndex === 9
                                                            ? qualityAssuranceMatrix
                                                            : formulaIndex === 10
                                                                ? specialProjectsMatrix
                                                                : formulaIndex === 11
                                                                    ? escalationMatrix
                                                                    : []

                    }
                />
            )}


            {showStandardMatrixUpdate &&
                <EdcscorecardMatrixUpdate handleCancelEvent={() => setShowStandardMatrixUpdate(false)} data={dataToSendMatrix} formulaIndex={formulaIndex} refreshDataEvent={refreshData} />
            }


            {/* Alerts */}
            <AlertM ref={notifications} />
        </>
    );
}

export default EdcscorecardTable;
