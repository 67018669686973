// @ts-nocheck

import React, { useEffect, useState, useRef } from 'react';
import { useStyles } from './Styles';
import '../../../../../index.scss';
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { getAll, post } from "../../../../../Services/GenericApiService";
import { formatData } from "../../../../../Services/DataFormatService";
import { useTranslation } from "react-i18next";
import AlertM from '../../../../Helpers/AlertM/AlertM';
import { log } from "../../../../../Services/LoggerService";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useForm } from "react-hook-form";
import MUIDataTable from "mui-datatables";
import { IconButton, Skeleton, Button, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import { FormControl, FormHelperText } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import { RootStateOrAny, useSelector } from "react-redux";
import TextDisplayM from '../../../../Helpers/TextDisplayM/TextDisplayM';
import ApexPieChart from '../../Chart/Apex/ApexPieChart/ApexPieChart';
import CollectionAccountHandlerViewData from '../../Debtordetail/CollectionAccountHandler/CollectionAccountHandlerViewData/CollectionAccountHandlerViewData';
// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function LeadsMain() {
    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // module(s) for api
    const moduleMain = 'debtordetail';
    const moduleDispositionstatus = 'dispositionstatus';
    const moduleAccountstatus = 'accountstatus';
    const moduleCampaign = 'campaign';
    const moduleDebtorAgent = 'debtoragent';
    const moduleExportLeads = '/export/leads';

    // data vars
    const [allData, setAllData] = React.useState([]);
    const [allDispositionstatus, setAllDispositionstatus] = React.useState([]);
    const [allAgent, setAllAgent] = React.useState({});
    const [allAccountStatus, setAllAccountStatus] = React.useState([]);
    const [allCampaign, setAllCampaign] = React.useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [paginationFlag, setPaginationFlag] = useState(false);
    const [breachAmount, setBreachAmount] = useState(0);
    const [accountStatusId, setAccountStatusId] = useState([51, 52, 53, 60]);
    const [dispositionStatusId, setDispositionStatusId] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]);
    const [selectedDispositionStatusIds, setSelectedDispositionStatusIds] = useState(dispositionStatusId);
    const [selectedAccountStatusIds, setSelectedAccountStatusIds] = useState(accountStatusId);
    const [selectedCampaignIds, setSelectedCampaignIds] = useState([]);
    const [selectedAgentIds, setSelectedAgentIds] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [surName, setSurName] = useState('');
    const [accountName, setAccountName] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [hTel, sethTel] = useState('');
    const [cTel, setcTel] = useState('');
    const [wTel, setwTel] = useState('');
    const [allDispositionStatusData, setDispositionStatusData] = React.useState([]);
    const [allDispositionStatusLabel, setDispositionStatusLabel] = React.useState([]);
    const [allAccountStatusData, setAccountStatusData] = React.useState([]);
    const [allAccountStatusLabel, setAccountStatusLabel] = React.useState([]);

    const [selectedFilters, setSelectedFilters] = React.useState({
        "range": {
            "ageDays": {
                "min": null,
                "max": null
            },
            "accountBalance": {
                "min": null,
                "max": null
            },
            "availCreditAmt": {
                "min": null,
                "max": null
            },
            "collectabilityScore": {
                "min": null,
                "max": null
            }
        },
        "filter": {
            "accountStatusId": selectedAccountStatusIds,
            "dispositionStatusId": selectedDispositionStatusIds
        }
    });

    let columns = [
        { name: "Customer Name", options: { filterOptions: { fullWidth: true } } },
        { name: "Customer Account No.", options: { filterOptions: { fullWidth: true } } },
        { name: "Bill Account No.", options: { filterOptions: { fullWidth: true } } },
        { name: "Disposition Status", options: { filterOptions: { fullWidth: true } } },
        { name: "Account Status", options: { filterOptions: { fullWidth: true } } },
        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                setCellHeaderProps: () => { return { align: "right" } },
                setCellProps: () => ({
                    align: "right"
                }),
                customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
                    return (
                        <div className='d-inline-flex'>
                            <div className="px-2">
                                {/* <LeadsView data={allData[rowIndex]} /> */}
                                <Tooltip title={t('common.view')}>
                                    <IconButton aria-label="delete" size="small" onClick={() => handleToggleViewLeads(allData[rowIndex])}>
                                        <FontAwesomeIcon className="edit-btn" icon='eye' />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    )
                        ;
                }
            }
        }
    ];
    const [tableData, setTableData] = React.useState([]);
    const [selectedData, setSelectedData] = React.useState({});
    const [toggleViewDetails, setToggleViewDetails] = React.useState(false);
    // ui controls
    const [loading, setLoading] = React.useState(true);

    // get theme from redux
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );

    const handleToggleViewLeads = (_data = null) => {
        if (_data) {
            setSelectedData(_data);
        }
        setToggleViewDetails(!toggleViewDetails);
    }

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    // table ui controls
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");

    const handleSelectCampaignChange = (event) => {
        setSelectedCampaignIds(event.target.value);
    }

    const handleAgentChange = (event) => {
        setSelectedAgentIds(event.target.value);
    }

    const handleCustomerNameChange = (event) => {
        let selectedValues = event.target.value;
        setCustomerName(selectedValues);
    };

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    }

    const handleSurNameChange = (event) => {
        setSurName(event.target.value);
    }

    const handleAccountNameChange = (event) => {
        setAccountName(event.target.value);
    }

    const handlehTelChange = (event) => {
        sethTel(event.target.value);
    }
    const handlecTelChange = (event) => {
        setcTel(event.target.value);
    }
    const handlewTelChange = (event) => {
        setwTel(event.target.value);
    }

    const handleSelectDispositionChange = (event) => {
        let selectedValues = event.target.value;
        const updateFilter = {
            "range": {
                "ageDays": {
                    "min": null,
                    "max": null
                },
                "accountBalance": {
                    "min": null,
                    "max": null
                },
                "availCreditAmt": {
                    "min": null,
                    "max": null
                },
                "collectabilityScore": {
                    "min": null,
                    "max": null
                }
            },
            "filter": {
                "accountStatusId": selectedAccountStatusIds,
                "dispositionStatusId": selectedValues
            }
        };
        setSelectedFilters(updateFilter);
        setSelectedDispositionStatusIds(selectedValues);

    };

    const handleSelectAccountChange = (event) => {
        const selectedValues = event.target.value;
        const updateFilter = {
            "range": {
                "ageDays": {
                    "min": null,
                    "max": null
                },
                "accountBalance": {
                    "min": null,
                    "max": null
                },
                "availCreditAmt": {
                    "min": null,
                    "max": null
                },
                "collectabilityScore": {
                    "min": null,
                    "max": null
                }
            },
            "filter": {
                "accountStatusId": selectedValues,
                "dispositionStatusId": selectedDispositionStatusIds,
                "customerName": customerName
            }
        };
        setSelectedFilters(updateFilter);
        setSelectedAccountStatusIds(selectedValues);
    };

    // fetch dependent data
    const fetch = () => {
        fetchCampaign();
        fetchDispositionstatus();
        fetchAccountstatus();
        fetchAgent();
        // getCollectionAccount();

    }

    //table data

    const getCollectionAccount = () => {
        setLoading(true); // show loader
        let payload = {
            "customerName": customerName,
            "selectedFilters": selectedFilters,
            "campaignId": selectedCampaignIds,
            "agentId": selectedAgentIds,
            "firstName": firstName,
            "surName": surName,
            "accountName": accountName,
            "hTel": hTel,
            "cTel": cTel,
            "wTel": wTel
        }
        post(moduleMain + '/collection/filter', payload, currentPage, pageSize)
            .then((_res: any) => {
                setAllData(_res)
                setTotalRowCount(_res.count);
                setBreachAmount(_res.totalBreachAmount)
                setAllData(_res.rows);
                setPaginationFlag(true);

                // store account and disposition lables
                const dispositionStatusData = [];
                const dispositionStatusLabel = [];

                const accountStatusData = [];
                const accountStatusLabel = [];

                _res.countByDispositionStatus.forEach(item => {
                    dispositionStatusData.push(item.count);
                    dispositionStatusLabel.push(item.dispositionStatus);
                });

                _res.countByAccountStatus.forEach(item => {
                    accountStatusData.push(item.count);
                    accountStatusLabel.push(item.accountStatus);
                });

                setDispositionStatusData(dispositionStatusData);
                setDispositionStatusLabel(dispositionStatusLabel);
                setAccountStatusData(accountStatusData);
                setAccountStatusLabel(accountStatusLabel);

                const rows: any = [];
                _res.rows.forEach((_record: any) => {
                    rows.push(formatData([_record?.customerName, _record?.customerAccountNum, _record?.billAccNo, _record['debtorDispositionStatus.name'], _record['debtorAccountStatus.name']]))
                });

                setTableData(rows);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch Agents
    const fetchAgent = () => {
        setLoading(true); // show loader
        getAll(moduleDebtorAgent + '/agent/all')
            .then((_res: any) => {
                setAllAgent(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch Dispositionstatus
    const fetchDispositionstatus = () => {
        setLoading(true); // show loader
        getAll(moduleDispositionstatus)
            .then((_res: any) => {
                setAllDispositionstatus(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch Account
    const fetchAccountstatus = () => {
        setLoading(true); // show loader
        getAll(moduleAccountstatus)
            .then((_res: any) => {
                setAllAccountStatus(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    const handleExportData = () => {
        setLoading(true);   // Set Loading
        let payload = {
            "customerName": customerName,
            "selectedFilters": selectedFilters,
            "campaignId": selectedCampaignIds,
            "agentId": selectedAgentIds,
            "firstName": firstName,
            "surName": surName,
            "accountName": accountName,
            "hTel": hTel,
            "cTel": cTel,
            "wTel": wTel
        }

        // post filters
        post(moduleMain + moduleExportLeads, payload)
            .then((_res: any) => {
                window.open(_res.url, '_blank');
                // @ts-ignore
                // notifications.current.successAlert('Records Rejected', 'Records Were Rejected');
                setLoading(false);
            })
            .catch((_err: any) => {
                log(_err);
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                setLoading(false);   // loading is set false in fetch
            })
    }


    // fetch Campaign
    const fetchCampaign = () => {
        let id = [];
        setLoading(true); // show loader
        getAll(moduleCampaign)
            .then((_res: any) => {
                setAllCampaign(_res)
                id = _res.map(item => item.id);
                setSelectedCampaignIds(id);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // form methods
    const onSubmit = (_data: any) => {

    }

    const backToMain = () => {
        setToggleViewDetails(false);
    };

    useEffect(() => {
        fetch()
    }, [currentPage])

    useEffect(() => {
        if (paginationFlag) {
            getCollectionAccount();
        }
    }, [currentPage])


    let options = {
        search: true,
        download: true,
        print: true,
        viewColumns: true,
        filter: true,
        filterType: "textField",
        responsive: "simple",
        resizableColumns: false,
        rowsPerPage: 25,
        rowsPerPageOptions: [25, 50, 75, 100],
        onChangePage: (currentPage) => {
            setCurrentPage(currentPage);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            setPageSize(numberOfRows);
        },
        onDownload: () => {
            if(!loading) {
                handleExportData();
            }
            // return false to avoid datatable download
            return false;
        },
        page: currentPage,
        count: totalRowCount,
        serverSide: true,
        loading: true,
        tableBodyHeight: tableBodyHeight,
        selectableRowsHideCheckboxes: true,
        sort: true,
        textLabels: {
            body: {
                noMatch: loading ?
                    <Box>
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                    </Box> :
                    'Sorry, there is no matching data to display',
            },
        },
        onTableChange: (action: any, state: any) => {
        },
    };

    if (tableData.length === 0) {
        options = {
            textLabels: {
                body: {
                    noMatch: loading ?
                        <Box>
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                        </Box> :
                        'Sorry, there is no matching data to display',
                },
            },
        };
    }

    return (
        <>
            {/* <CssBaseline /> */}

            {/* Header */}
            {/* <Header /> */}

            {/* Sider */}
            {/* <Sider /> */}

            {/* Content */}
            {/* sx={{ flexGrow: 2, p: 3 }} mt={10} */}
            <Box component="main">

                {/* <div className="px-1 px-md-5"> */}

                    {/* Upper Section */}
                    <div className="row">
                        <div className="col-md-10">
                            <h2 className="mt-3">
                                {!toggleViewDetails &&
                                    <>View All Leads</>
                                }
                                {toggleViewDetails &&
                                    <>Account Details</>
                                }

                            </h2>
                        </div>
                        {toggleViewDetails &&
                            <div className="col-md-2">
                                <Button
                                    onClick={backToMain}
                                    className={'full-width my-2 py-3 px-5'}
                                    variant="outlined">
                                    Back
                                </Button>
                            </div>
                        }
                    </div>

                    {!loading && !toggleViewDetails &&
                        <>
                            {/* Filter row */}
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className={(theme == 'dark' ? 'bg-dark' : '') + "card shadow-lg ms-0 me-0"}>
                                    <div className="card-body">
                                        <div className='row'>
                                            {/* Disposition Status */}
                                            <div className='col-3 mt-5'>
                                                {/* Field dispositionStatusId */}
                                                <FormControl fullWidth={true}>
                                                    <InputLabel id="dispositionStatusIdLabel">
                                                        Disposition Status
                                                    </InputLabel>
                                                    {allDispositionstatus.length > 0 &&
                                                        <Select labelId="dispositionStatusIdLabel"
                                                            id="dispositionStatusIdSelect"
                                                            {...register("dispositionStatusId", { required: false })}
                                                            error={!!errors.dispositionStatusId}
                                                            value={selectedDispositionStatusIds}
                                                            onChange={handleSelectDispositionChange} // Handle selection disposition changes
                                                            multiple
                                                            label="dispositionStatusId">
                                                            {
                                                                allDispositionstatus.map((el: any) =>
                                                                    <MenuItem value={el.id}
                                                                        key={el.id}> {el.name}
                                                                    </MenuItem>)
                                                            }
                                                        </Select>
                                                    }
                                                    {allDispositionstatus.length == 0 &&
                                                        <>
                                                            <Select labelId="dispositionStatusLabel"
                                                                disabled
                                                                id="dispositionStatusIdSelect"
                                                                {...register("dispositionStatusId", { required: false })}
                                                                error={!!errors.dispositionStatusId}
                                                                value={watch('dispositionStatusId')}
                                                                label="dispositionStatusId">
                                                                <MenuItem value={''}>
                                                                    No Data
                                                                </MenuItem>
                                                            </Select>
                                                            <FormHelperText>
                                                                No Data
                                                            </FormHelperText>
                                                        </>
                                                    }
                                                </FormControl>
                                            </div>

                                            {/* Account Status */}
                                            <div className='col-3 mt-5'>
                                                {/* Field accountStatusId */}
                                                <FormControl fullWidth={true}>
                                                    <InputLabel id="accountStatusIdLabel">
                                                        Account Status
                                                    </InputLabel>
                                                    {allAccountStatus.length > 0 &&
                                                        <Select labelId="accountStatusIdLabel"
                                                            id="accountStatusIdSelect"
                                                            {...register("accountStatusId", { required: false })}
                                                            value={selectedAccountStatusIds}
                                                            onChange={handleSelectAccountChange} // Handle selection disposition changes
                                                            error={!!errors.accountStatusId}
                                                            multiple
                                                            label="accountStatusId">
                                                            {
                                                                allAccountStatus.map((el: any) =>
                                                                    <MenuItem value={el.id}
                                                                        key={el.id}> {el.name}
                                                                    </MenuItem>)
                                                            }
                                                        </Select>
                                                    }
                                                    {allAccountStatus.length == 0 &&
                                                        <>
                                                            <Select labelId="accountLabel"
                                                                disabled
                                                                id="accountIdSelect"
                                                                {...register("accountStatusId", { required: false })}
                                                                error={!!errors.accountStatusId}
                                                                value={watch('accountStatusId')}
                                                                label="accountStatusId">
                                                                <MenuItem value={''}>
                                                                    No Data
                                                                </MenuItem>
                                                            </Select>
                                                            <FormHelperText>
                                                                No Data
                                                            </FormHelperText>
                                                        </>
                                                    }
                                                </FormControl>
                                            </div>

                                            {/* Campaign */}
                                            <div className='col-3 mt-5'>
                                                {/* Field campaignId */}
                                                <FormControl fullWidth={true}>
                                                    <InputLabel id="campaignStatusIdLabel">
                                                        Campaign
                                                    </InputLabel>
                                                    {allCampaign.length > 0 &&
                                                        <Select labelId="campaignStatusIdLabel"
                                                            id="campaignStatusIdSelect"
                                                            {...register("campaignStatusId", { required: false })}
                                                            error={!!errors.campaignStatusId}
                                                            value={selectedCampaignIds}
                                                            onChange={handleSelectCampaignChange} // Handle selection disposition changes
                                                            multiple
                                                            label="campaignStatusId">
                                                            {
                                                                allCampaign.map((el: any) =>
                                                                    <MenuItem value={el.id}
                                                                        key={el.id}> {el.name}
                                                                    </MenuItem>)
                                                            }
                                                        </Select>
                                                    }
                                                    {allCampaign.length == 0 &&
                                                        <>
                                                            <Select labelId="campaignStatusLabel"
                                                                disabled
                                                                id="campaignStatusIdSelect"
                                                                {...register("campaignStatusId", { required: false })}
                                                                error={!!errors.campaignStatusId}
                                                                value={watch('campaignStatusId')}
                                                                label="campaignStatusId">
                                                                <MenuItem value={''}>
                                                                    No Data
                                                                </MenuItem>
                                                            </Select>
                                                            <FormHelperText>
                                                                No Data
                                                            </FormHelperText>
                                                        </>
                                                    }
                                                </FormControl>
                                            </div>
                                            {/* Apply Filter Button */}
                                            <div className='col-3 mt-5'>
                                                <Button
                                                    onClick={getCollectionAccount}
                                                    className={'full-width bg-custom-gradient py-3 px-5'}
                                                    variant="contained">
                                                    Apply Filters
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/* Agent */}
                                            <div className='col-3 mt-4'>
                                                {/* Field Agent */}
                                                <FormControl fullWidth={true}>
                                                    <InputLabel id="agentIdLabel">
                                                        Agent
                                                    </InputLabel>
                                                    {allAgent.length > 0 &&
                                                        <Select labelId="agentIdLabel"
                                                            id="agentIdSelect"
                                                            {...register("id", { required: false })}
                                                            error={!!errors.id}
                                                            value={selectedAgentIds}
                                                            onChange={handleAgentChange} // Handle selection disposition changes
                                                            multiple
                                                            label="agentId">
                                                            {
                                                                allAgent.map((el: any) =>
                                                                    <MenuItem value={el.id}
                                                                        key={el.id}> {el.email}
                                                                    </MenuItem>)
                                                            }
                                                        </Select>
                                                    }
                                                    {allAgent.length == 0 &&
                                                        <>
                                                            <Select labelId="agentIdLabel"
                                                                disabled
                                                                id="agentIdSelect"
                                                                {...register("agentId", { required: false })}
                                                                error={!!errors.id}
                                                                value={watch('id')}
                                                                label="agentId">
                                                                <MenuItem value={''}>
                                                                    No Data
                                                                </MenuItem>
                                                            </Select>
                                                            <FormHelperText>
                                                                No Data
                                                            </FormHelperText>
                                                        </>
                                                    }
                                                </FormControl>
                                            </div>

                                            {/* Customer Name */}
                                            <div className="col-3 mt-4">
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="customerNameInput"
                                                        label="Customer Name"
                                                        type="text"
                                                        error={!!errors.customerName}
                                                        variant="outlined"
                                                        className="full-width"
                                                        value={customerName} // Set the value of the input field to the state variable
                                                        onChange={handleCustomerNameChange} />
                                                </FormControl>
                                            </div>
                                            {/* First Name */}
                                            <div className="col-3 mt-4">
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="firstNameInput"
                                                        label="First Name"
                                                        type="text"
                                                        error={!!errors.firstNameName}
                                                        variant="outlined"
                                                        className="full-width"
                                                        value={firstName} // Set the value of the input field to the state variable
                                                        onChange={handleFirstNameChange} />
                                                </FormControl>
                                            </div>
                                            {/* Sur Name */}
                                            <div className="col-3 mt-4">
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="surNameInput"
                                                        label="Surname"
                                                        type="text"
                                                        error={!!errors.surName}
                                                        variant="outlined"
                                                        className="full-width"
                                                        value={surName} // Set the value of the input field to the state variable
                                                        onChange={handleSurNameChange} />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/* Account Name */}
                                            <div className="col-3 mt-4">
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="accountNameInput"
                                                        label="Account Name"
                                                        type="text"
                                                        error={!!errors.surName}
                                                        variant="outlined"
                                                        className="full-width"
                                                        value={accountName} // Set the value of the input field to the state variable
                                                        onChange={handleAccountNameChange} />
                                                </FormControl>
                                            </div>
                                            {/* hTel */}
                                            <div className="col-3 mt-4">
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="hTelInput"
                                                        label="hTel"
                                                        type="text"
                                                        error={!!errors.hTel}
                                                        variant="outlined"
                                                        className="full-width"
                                                        value={hTel} // Set the value of the input field to the state variable
                                                        onChange={handlehTelChange} />
                                                </FormControl>
                                            </div>

                                            {/* cTel */}
                                            <div className="col-3 mt-4">
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="cTelInput"
                                                        label="cTel"
                                                        type="text"
                                                        error={!!errors.cTel}
                                                        variant="outlined"
                                                        className="full-width"
                                                        value={cTel} // Set the value of the input field to the state variable
                                                        onChange={handlecTelChange} />
                                                </FormControl>
                                            </div>
                                            {/* cTe */}
                                            <div className="col-3 mt-4">
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="wTelInput"
                                                        label="wTel"
                                                        type="text"
                                                        error={!!errors.wTel}
                                                        variant="outlined"
                                                        className="full-width"
                                                        value={wTel} // Set the value of the input field to the state variable
                                                        onChange={handlewTelChange} />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </>
                    }
                    {loading &&
                        <Skeleton height={100} />
                    }

                    <div className='row'>
                        <div className="col-md-12 mb-2 mt-5">
                            <h4 className={'fw-bolder'}>
                                Lead Reports
                            </h4>
                        </div>
                        {!loading && !toggleViewDetails &&
                            <div className="row">
                                {/* Total Count */}
                                <div className="col-6">
                                    <TextDisplayM
                                        data={parseFloat(totalRowCount || 0).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                        label={'Total Records'}
                                        isLoading={loading} />
                                </div>
                                {/* Total Account Balance */}
                                <div className="col-md-6">
                                    <TextDisplayM data={parseFloat(breachAmount || 0).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                        label={'Total Breach Amount (ZAR)'}
                                        isLoading={loading}
                                        background={'custom-secondary'} />
                                </div>

                            </div>
                        }

                        {loading &&
                            <Skeleton height={100} />
                        }
                    </div>

                    {/* Graph */}
                    <div className='row'>
                        {!loading && !toggleViewDetails &&
                            <div className="row">
                                <div className="col-md-6">
                                    <ApexPieChart data={allDispositionStatusData}
                                        labels={allDispositionStatusLabel}
                                        chartTitle={'Disposition Status Breakdown'} />
                                </div>
                                <div className="col-md-6">
                                    <ApexPieChart data={allAccountStatusData}
                                        labels={allAccountStatusLabel}
                                        chartTitle={'Account Status Breakdown'} />
                                </div>
                            </div>
                        }

                        {loading &&
                            <Skeleton height={100} />
                        }
                    </div>

                    {/* Data Table */}
                    {!loading && !toggleViewDetails &&
                        <div className="mt-5">
                            <Paper elevation={6}>
                                <MUIDataTable
                                    title={t('common.allRecords')}
                                    data={tableData}
                                    columns={columns}
                                    // @ts-ignore
                                    options={options}
                                />
                            </Paper>
                        </div>
                    }

                    {/* display view modal */}
                    {toggleViewDetails &&
                        <div>
                            <CollectionAccountHandlerViewData account={selectedData} disableDisposition={true} />
                        </div>
                    }

                    {loading &&

                        <Skeleton height={250} />
                    }

                {/* </div> */}

                {/* Alerts */}
                <AlertM ref={notifications} />

            </Box>
        </>
    );
}

export default LeadsMain;
