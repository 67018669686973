// @ts-nocheck

import { Typography } from '@mui/material';
import React, {useEffect} from 'react';
import ApexPieChart from '../../Chart/Apex/ApexPieChart/ApexPieChart';

function CampaignAccordionView({_chart = null}) {
    return (
        <div className="col-md-12">
            <div className="row">
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-md-12">
                            <h6 className={'text-muted mt-5'}>
                                Book Name
                            </h6>
                            <Typography
                                variant="h5"
                                className={'fw-bolder '}>
                                {_chart.bookReference}
                            </Typography>
                        </div>
                        <div className="col-md-6">
                            <h6 className={'text-muted mt-5'}>
                                Book
                            </h6>
                            <a href={process.env.REACT_APP_SERVER + _chart.filePath} target='_blank'>
                                <Typography
                                    variant="h5"
                                    className={' '}>
                                    Download
                                </Typography>
                            </a>
                        </div>
                        <div className="col-md-6">
                            <h6 className={'text-muted mt-5'}>
                                Records Count
                            </h6>
                            <Typography
                                variant="h5"
                                className={' '}>
                                {_chart.recordCount}
                            </Typography>
                        </div>
                        <div className="col-md-6">
                            <h6 className={'text-muted mt-5'}>
                                Import Date
                            </h6>
                            <Typography
                                variant="h5"
                                className={''}>
                                {new Date(_chart.date).toDateString()}
                            </Typography>
                        </div>

                    </div>
                </div>
                <div className="col-md-4">
                    <ApexPieChart data={_chart.dispositionStatusByBookPieChart.data}
                                  labels={_chart.dispositionStatusByBookPieChart.labels}
                                  chartTitle={'Disposition Status Breakdown'}/>
                </div>
                <div className="col-md-4">
                    <ApexPieChart data={_chart.accountStatusByBookPieChart.data}
                                  labels={_chart.accountStatusByBookPieChart.labels}
                                  chartTitle={'Account Status Breakdown'}/>
                </div>
            </div>
        </div>
    )
}

export default CampaignAccordionView;
