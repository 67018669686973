// @ts-nocheck

import React, { useEffect, useState, useRef } from 'react';
import { useStyles } from './Styles';
import '../../../../../index.scss';
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { getAll, post } from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import AlertM from '../../../../Helpers/AlertM/AlertM';
import { log } from "../../../../../Services/LoggerService";
import { getUserData } from "../../../../../Services/LocalStorageService";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TreeItem from '@mui/lab/TreeItem';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Button, Skeleton } from "@mui/material";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import CollectionAccountHandlerViewData from '../../Debtordetail/CollectionAccountHandler/CollectionAccountHandlerViewData/CollectionAccountHandlerViewData';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function AssignedleadMain() {
    // css for module
    const classes = useStyles();
    // translation
    const { t } = useTranslation();
    
    // get account suspended id from env
    const agentRoleId = process.env.REACT_APP_ACCOUNT_STATUS_SUSPENDED;

    // child ref for alert
    const notifications = useRef();

    // module(s) for api
    const moduleMain = 'debtoragent';
    const moduleBookDetail = 'bookdetail';
    const moduleStrategyCampaign = 'strategycampaign';
    const moduleTeam = 'team';
    const moduleDebtorDetail = 'debtordetail';

    // data vars
    const [allData, setAllData] = React.useState([]);
    const [bookDetail, setAllBookDetail] = React.useState([]);
    const [allCampaigns, setAllCampaigns] = React.useState([]);
    const [userTeams, setUserTeams] = React.useState([]);
    const [collectionAccount, setCollectionAccount] = React.useState({});
    const [bookAndampaign, setBookAndampaign] = React.useState({});
    const [showAccountDetail, setShowAccountDetail] = React.useState(false);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [defaultExpandedNodeIds, setDefaultExpandedNodeIds] = useState([]);

    // ui controls
    const [loading, setLoading] = React.useState(true);
    const [accountDetailsLoading, setAccountDetailsLoading] = React.useState(false);

    const handleAccountClick = (customerAccountNum) => {
        setSelectedAccount(customerAccountNum);
    };

    const backToLeads = () => {
        setShowAccountDetail(false);
        setCollectionAccount({});
    };

    const viewDetails = () => {
        setAccountDetailsLoading(true);
        searchCollectionAccount({
            customerAccountNum: selectedAccount
        });
    };

    // get user data from jwt
    const userData = getUserData();

    // get theme from redux
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );


    // fetch dependent data
    const fetch = () => {
        fetchTeams();
        getAllBookgs();
        getAllDebtoragent();
    }

    // fetch search data
    const searchCollectionAccount = (_data) => {
        setLoading(true); // show loader
        post(moduleDebtorDetail + '/collection/search', _data)
            .then((_res: any) => {
                setCollectionAccount(_res);
                setShowAccountDetail(true)
                setLoading(false); // hide loader
                setAccountDetailsLoading(false)
            })
            .catch((_err: any) => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    const fetchTeams = () => {
        setLoading(true); // show loader
        // @ts-ignore
        getAll(moduleTeam + '/user/' + userData.id)
            .then((_res: any) => {
                let teamsOfUser = [];
                _res.forEach(_team => {
                    teamsOfUser.push(_team.teamId);
                });
                setUserTeams(teamsOfUser)
                setLoading(false); // hide loader
            })
            .catch((_err: any) => {
                log(_err)
                setLoading(false); // hide loader
            })
    }


    // fetch dependent data
    const fetchStrategyCampaigns = () => {
        setLoading(true); // show loader
        post(moduleStrategyCampaign + '/team', {
            teams: userTeams
        })
            .then((_res: any) => {
                let formattedData = [];
                let nodeCounter = 1;    // use for UI tree view
                let temp = [];

                _res.forEach(_strategyCampaign => {
                    // check if campaign does not exists
                    if (formattedData.filter(_data => _data.id == _strategyCampaign.campaignId).length == 0) {
                        formattedData.push({
                            id: _strategyCampaign.campaignId,
                            name: _strategyCampaign.strategyCampaignCampaign.name,
                            strategy: [],
                            nodeId: nodeCounter,
                            isCampaign: true
                        });
                        temp.push(nodeCounter);
                        nodeCounter++;
                    }

                    // append strategy
                    formattedData.forEach(_campaign => {
                        if (_campaign.id == _strategyCampaign.campaignId) {
                            _campaign.strategy.push({
                                id: _strategyCampaign.strategyId,
                                name: _strategyCampaign.strategyCampaignStrategy.name,
                                nodeId: nodeCounter,
                                isCampaign: false,
                                campaignName: _strategyCampaign.strategyCampaignCampaign.name,
                                campaignId: _strategyCampaign.campaignId
                            })
                            nodeCounter++;
                        }
                    })
                });
                setDefaultExpandedNodeIds(temp)
                setAllCampaigns(formattedData);
                setLoading(false); // hide loader
            })
            .catch((_err: any) => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    //get all debtoragent
    const getAllDebtoragent = () => {
        setLoading(true); // show loader
        getAll(moduleMain + '/user/' + getUserData().id)
            .then((_res: any) => {
                setAllData(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    const refreshData = () => {
        backToLeads();
        fetch();
    };

    //get all books
    const getAllBookgs = () => {
        setLoading(true); // show loader
        getAll(moduleBookDetail)
            .then((_res: any) => {
                setAllBookDetail(_res)

                const idCampaignMapping = {};
                for (const item of _res) {
                    const id = item.id;
                    const campaignId = item.campaignId;

                    if (id !== null && campaignId !== null) {
                        idCampaignMapping[id] = campaignId;
                    }
                }

                setBookAndampaign(idCampaignMapping);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    useEffect(() => {
        fetch();
    }, [])

    useEffect(() => {
        fetchStrategyCampaigns();
    }, [userTeams]);

    let options = {
        search: true,
        download: true,
        print: true,
        viewColumns: true,
        filter: true,
        filterType: "textField",
        responsive: "simple",
        // tableBodyHeight: "500px",
        // tableBodyMaxHeight,
        selectableRowsHideCheckboxes: true,
        sort: true,
        textLabels: {
            body: {
                noMatch: loading ?
                    <Box>
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                    </Box> :
                    'Sorry, there is no matching data to display',
            },
        },
        onTableChange: (action: any, state: any) => {
            // console.log(action);
            // console.dir(state);
        }
    };
    
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* Header */}
            <Header />

            {/* Sider */}
            <Sider />

            {/* Content */}
            <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
                <div className="px-1 px-md-5">

                    {/* Upper Section */}
                    <div className="row mb-3">
                        <div className="col-md-10">
                            {!showAccountDetail && !accountDetailsLoading &&
                                <h2 className="mt-3">
                                    Assigned Leads
                                </h2>
                            }
                            {accountDetailsLoading &&
                                <h2 className="mt-3">
                                    <Skeleton className="my-4" height={50} />
                                </h2>
                            }
                            {!accountDetailsLoading && showAccountDetail &&
                                <h2 className="mt-3">
                                    {collectionAccount.customerAccountNum} | {collectionAccount.customerName}
                                </h2>
                            }
                        </div>

                        {accountDetailsLoading &&
                            <div className="col-md-2 mt-4">
                                <>
                                    <Skeleton variant="text" height={50} />
                                </>
                            </div>
                        }

                        {!loading && showAccountDetail &&
                            <div className="col-md-2">
                                <Button
                                    onClick={backToLeads}
                                    className={'full-width my-2 py-3 px-5'}
                                    variant="outlined">
                                    Back
                                </Button>
                            </div>
                        }

                    </div>

                    {!loading && !showAccountDetail && !accountDetailsLoading &&
                        <div className={(theme == 'dark' ? 'bg-dark' : '') + " container card shadow-lg py-4 px-3"}>

                            {/* Campaign and strategy selection */}
                            <div className={'row'}>

                                {/* Customer Account No Selection */}
                                <div className={'col-md-8 '}>

                                    {loading &&
                                        <>
                                            <Skeleton variant="text" height={100} />
                                        </>
                                    }

                                    {!loading && !showAccountDetail && allCampaigns.length > 0 && allData.length > 0 &&
                                        <TreeView
                                            className={'my-3'}
                                            aria-label="multi-select"
                                            defaultExpanded={defaultExpandedNodeIds}
                                            defaultCollapseIcon={<ExpandMoreIcon />}
                                            defaultExpandIcon={<ChevronRightIcon />}
                                        >
                                            {
                                                allCampaigns.map((_campaign, index1) => (
                                                    <TreeItem
                                                        key={_campaign.nodeId}
                                                        nodeId={_campaign.nodeId}
                                                        label={_campaign.name}
                                                        classes={{ label: classes.treeItemCampaignLabel }}
                                                    >
                                                        {allData.map((_record, index2) => {
                                                            if (
                                                                _record.debtorAgentDebtorDetail &&
                                                                _record.debtorAgentDebtorDetail.accountStatusId != agentRoleId &&
                                                                _record.debtorAgentDebtorDetail.bookDetailsId &&
                                                                bookAndampaign[_record.debtorAgentDebtorDetail.bookDetailsId] === _campaign.id
                                                            ) {
                                                                return (
                                                                    <TreeItem
                                                                        nodeId={_record.debtorAgentDebtorDetail.customerAccountNum}
                                                                        key={_record.debtorAgentDebtorDetail.customerAccountNum}
                                                                        classes={{ label: classes.treeItemCampaignLabel }}
                                                                        label={'Customer Account No. ' + _record.debtorAgentDebtorDetail.customerAccountNum}
                                                                        onClick={() => handleAccountClick(_record.debtorAgentDebtorDetail.customerAccountNum)}
                                                                        selected={selectedAccount === _record.debtorAgentDebtorDetail.customerAccountNum}
                                                                    />
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                    </TreeItem>
                                                ))
                                            }
                                        </TreeView>
                                    }

                                </div>

                                <div className={'col-md-4 '}>
                                    {loading &&
                                        <>
                                            <Skeleton variant="text" height={100} />
                                        </>
                                    }
                                    {!loading && !showAccountDetail && allCampaigns.length > 0 && allData.length > 0 &&
                                        <Button
                                            onClick={viewDetails}
                                            className={'full-width bg-custom-gradient my-2 py-3 px-5'}
                                            variant="contained">
                                            View Lead Details
                                        </Button>
                                    }
                                </div>

                                {!loading && !showAccountDetail && allData.length == 0 &&
                                    <div className="row">
                                        <h2 className={'text-center my-5'}>
                                            <FontAwesomeIcon icon='triangle-exclamation' />
                                            &nbsp;
                                            No Data
                                        </h2>
                                    </div>
                                }
                            </div>
                        </div>
                    }

                    {loading && !showAccountDetail && accountDetailsLoading &&
                        <>
                            <div className="row">
                                <div className="col-4">
                                    <Skeleton variant="text" height={50} />
                                </div>
                                <div className="col-4">
                                    <Skeleton variant="text" height={50} />
                                </div>
                                <div className="col-4">
                                    <Skeleton variant="text" height={50} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-8">
                                    <Skeleton variant="text" height={500} />
                                </div>
                                <div className="col-4">
                                    <Skeleton variant="text" height={500} />
                                </div>
                            </div>

                        </>
                    }
                    <div className="row">
                        <div className="col-12">
                            {/*  View request account data  */}
                            {Object.keys(collectionAccount).length > 0 && !loading &&
                                <div className="row">
                                    <CollectionAccountHandlerViewData refreshDataCallback={refreshData} account={collectionAccount} hideUpates={false} />
                                </div>
                            }

                        </div>
                    </div>

                </div>

                {/* Alerts */}
                <AlertM ref={notifications} />

            </Box>


        </Box>
    );
}

export default AssignedleadMain;
