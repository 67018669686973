// @ts-nocheck

import React, { useEffect, useRef } from 'react';
import { useStyles } from './Styles';
import '../../../../../index.scss';
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { RootStateOrAny, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { Skeleton } from "@mui/material";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import { decrypt } from "../../../../../Services/CryptoService";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { getById, patch } from "../../../../../Services/GenericApiService";
import EditIcon from '@mui/icons-material/Edit';
import AlertM from '../../../../Helpers/AlertM/AlertM';
import ConfirmationDialog from '../../../../Helpers/ConfirmationDialog/ConfirmationDialog';
import UpdateMadXKey from '../UpdateMadXKey/UpdateMadXKey';
import UpdateMadEndpoint from '../UpdateMadEndpoint/UpdateMadEndpoint';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function IntegrationsMain() {
    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // module(s) for api
    const moduleMain = 'key';

    // data controls
    const [token, setToken] = React.useState('');
    const [tokenMad, setTokenMad] = React.useState('');
    const [apiEndpoint, setApiEndpoint] = React.useState('');


    // ui controls
    const [loading, setLoading] = React.useState(false);
    const [showToken, setShowToken] = React.useState(false);
    const [showTokenMad, setShowTokenMad] = React.useState(false);
    const [showUrl, setShowUrl] = React.useState(false);    
    const [updateMadKeyModal, setUpdateMadKeyModal] = React.useState(false);
    const [updateMadEndpointModal, setUpdateMadEndpointModal] = React.useState(false);
    const [inputTypeMad, setInputTypeMad] = React.useState('password');
    const [inputType, setInputType] = React.useState('password');
    const [apiEndpointType, setApiEndpointType] = React.useState('password');

    // get theme from redux
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );

    const openEditModal = () => {
        setInputTypeMad('password');
        setShowTokenMad(false);
        setUpdateMadKeyModal(true);
    }

    const openEditModalApi = () => {
        setUpdateMadEndpointModal(true);
    }

    const fetchToken = () => {
        setLoading(true);
        getById(moduleMain, 'SVtoTUManager_Notify')
            .then((_res: any) => {
                setToken(decrypt(_res?.value));
                setLoading(false);
            })
            .catch(_err => {
                log(_err);
                setLoading(false);
            })
    }

    const fetchMadToken = () => {
        setLoading(true);
        getById(moduleMain, 'MADApiXKey')
            .then((_res: any) => {
                setTokenMad(decrypt(_res?.value));
                setLoading(false);
            })
            .catch(_err => {
                log(_err);
                setLoading(false);
            })
    }

    const fetchMadEndpoint = () => {
        setLoading(true);
        getById(moduleMain, 'MADApiEndpoint')
            .then((_res: any) => {
                setApiEndpoint(_res?.value);
                setLoading(false);
            })
            .catch(_err => {
                log(_err);
                setLoading(false);
            })
    }

    const refreshToken = () => {
        setLoading(true); // show loader
        setShowToken(false);
        setInputType('password')
        patch(moduleMain, 'SVtoTUManager_Notify', {})
            .then(async (_res) => {
                fetchToken()
                setLoading(false);

                // @ts-ignore
                notifications.current.successAlert('Token Refresh Successfully', '');
                // @ts-ignore
                await sleep(process.env.REACT_APP_UPDATE_NOTIFICATION_TIMEOUT_MS || 1000);
                resetEdit();
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                resetEdit();
            })
    }

    const handleCopyClick = (type: string) => {
        notifications.current.successAlert('Text Copied');
        const inputElement = document.createElement('input');
        if (type === 'integration') {
            inputElement.value = token;
        } else if (type === 'madToken') {
            inputElement.value = tokenMad;
        } else if (type === 'madEndpoint') {
            inputElement.value = apiEndpoint;
        }

        document.body.appendChild(inputElement);
        inputElement.select();
        document.execCommand('copy');
        document.body.removeChild(inputElement);
    };

    const triggerTokenView = (flag) => {
        setShowToken(!showToken);
        flag == 'show' ? setInputType('text') : setInputType('password');
    }

    const triggerTokenViewMad = (flag) => {
        setShowTokenMad(!showTokenMad);
        flag == 'show' ? setInputTypeMad('text') : setInputTypeMad('password');
    }

    const triggerUrlView = (flag) => {
        setShowUrl(!showUrl);
        flag == 'show' ? setApiEndpointType('text') : setApiEndpointType('password');
    }

    useEffect(() => {
        fetchToken();
        fetchMadToken();
        fetchMadEndpoint();
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* Header */}
            <Header />

            {/* Sider */}
            <Sider />

            {/* Content */}
            <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
                <div className="px-1 px-md-5">

                    {/* Upper Section */}
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="mt-3">
                                Integration Token
                            </h2>
                        </div>
                    </div>

                    {/* SV to TU Manager */}
                    <div className="row mt-4">
                        <div className="col-12">
                            {!loading &&
                                <div className={(theme == 'dark' ? 'bg-dark' : '') + " container card shadow-lg py-4 px-3"}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h4>
                                                SV To TU Manager
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-10">
                                            <input
                                                type={inputType}
                                                value={token}
                                                readOnly
                                                fullWidth
                                                style={{ backgroundColor: 'transparent' }}
                                                className="form-control d-inline-block mr-2 mt-2"
                                            />
                                        </div>
                                        <div className="col-md-2 d-flex justify-content-between">
                                            <Tooltip title="Copy">
                                                <IconButton>
                                                    <ContentCopyIcon onClick={() => { handleCopyClick('integration') }} />
                                                </IconButton>
                                            </Tooltip>

                                            <Tooltip title="Refresh">
                                                <IconButton>
                                                    <ConfirmationDialog title={'Refresh Token'}
                                                        body={'Are you sure you want to refresh token?'}
                                                        type={'refresh'}
                                                        isLoading={loading}
                                                        confirmEvent={refreshToken} />
                                                </IconButton>
                                            </Tooltip>


                                            {!showToken &&
                                                <Tooltip title="Show Token">
                                                    <IconButton>
                                                        <RemoveRedEyeIcon onClick={() => triggerTokenView('show')} />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            {showToken &&
                                                <Tooltip title="Hide Token">
                                                    <IconButton>
                                                        <VisibilityOffIcon onClick={() => triggerTokenView('hide')} />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </div>
                                    </div>

                                </div>
                            }
                            {loading &&
                                <Skeleton variant="text" height={100} />
                            }
                        </div>
                    </div>

                    {/* Mad x key heading */}
                    <div className="row mt-5">
                        <div className="col-md-12">
                            <h2 className="mt-3">
                                MAD API Integration
                            </h2>
                        </div>
                    </div>

                    {/* made api token card */}
                    <div className="row mt-4">
                        <div className="col-12">
                            {!loading &&
                                <div className={(theme == 'dark' ? 'bg-dark' : '') + " container card shadow-lg py-4 px-3"}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h4>
                                                X-Api-Key
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-10">
                                            <input
                                                type={inputTypeMad}
                                                value={tokenMad}
                                                readOnly
                                                fullWidth
                                                style={{ backgroundColor: 'transparent' }}
                                                className="form-control d-inline-block mr-2 mt-2"
                                            />
                                        </div>
                                        <div className="col-md-2 d-flex justify-content-between">
                                            <Tooltip title="Copy">
                                                <IconButton>
                                                    <ContentCopyIcon onClick={() => { handleCopyClick('madToken') }} />
                                                </IconButton>
                                            </Tooltip>

                                            <Tooltip title="Edit">
                                                <IconButton>
                                                    <EditIcon onClick={openEditModal} />
                                                </IconButton>
                                            </Tooltip>


                                            {!showTokenMad &&
                                                <Tooltip title="Show Token">
                                                    <IconButton>
                                                        <RemoveRedEyeIcon onClick={() => triggerTokenViewMad('show')} />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            {showTokenMad &&
                                                <Tooltip title="Hide Token">
                                                    <IconButton>
                                                        <VisibilityOffIcon onClick={() => triggerTokenViewMad('hide')} />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </div>
                                    </div>

                                </div>
                            }
                            {loading &&
                                <Skeleton variant="text" height={100} />
                            }
                        </div>
                    </div>

                    {/* x api end point card */}
                    <div className="row mt-4">
                        <div className="col-12">
                            {!loading &&
                                <div className={(theme == 'dark' ? 'bg-dark' : '') + " container card shadow-lg py-4 px-3"}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h4>
                                                Endpoint
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-10">
                                            <input
                                                type={'text'}
                                                value={apiEndpoint}
                                                type={apiEndpointType}
                                                readOnly
                                                fullWidth
                                                style={{ backgroundColor: 'transparent' }}
                                                className="form-control d-inline-block mr-2 mt-2"
                                            />
                                        </div>
                                        <div className="col-md-2 d-flex justify-content-between">
                                            <Tooltip title="Copy">
                                                <IconButton>
                                                    <ContentCopyIcon onClick={() => { handleCopyClick('madEndpoint') }} />
                                                </IconButton>
                                            </Tooltip>

                                            <Tooltip title="Edit">
                                                <IconButton>
                                                    <EditIcon onClick={openEditModalApi} />
                                                </IconButton>
                                            </Tooltip>
                                            {!showUrl &&
                                                <Tooltip title="Show Token">
                                                    <IconButton>
                                                        <RemoveRedEyeIcon onClick={() => triggerUrlView('show')} />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            {showUrl &&
                                                <Tooltip title="Hide Token">
                                                    <IconButton>
                                                        <VisibilityOffIcon onClick={() => triggerUrlView('hide')} />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </div>
                                    </div>

                                </div>
                            }
                            {loading &&
                                <Skeleton variant="text" height={100} />
                            }
                        </div>
                    </div>

                    {updateMadKeyModal &&
                        <UpdateMadXKey refreshDataEvent={fetchMadToken} closeModal={() => setUpdateMadKeyModal(false)} />
                    }

                    {updateMadEndpointModal &&
                        <UpdateMadEndpoint refreshDataEvent={fetchMadEndpoint} closeModal={() => setUpdateMadEndpointModal(false)} />
                    }

                </div>

                {/* Alerts */}
                <AlertM ref={notifications} />

            </Box>
        </Box>
    );
}

export default IntegrationsMain;
